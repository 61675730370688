.notification {
    .button {
        background-color: transparent !important;
    }

    i[class*="icon"],
    i[class~="icon"] {
        &::before {
            vertical-align: inherit;
        }
    }
}

.view--pack {
    @media (max-width: 1120px) {
        .banner--quechua {
            display: none !important;
        }
    }
}

.view--detail { 
    @media (max-width: 992px) {
        .banner--quechua {
            display: none !important;
        }
    }
}

.notification--alert {
    i {
        @extend .icon;
        color: #fff;
    }

    .notification__close {
        margin: 0;
        line-height: 44px;
        padding: 0 15px;
        height: 100%;
    }
}