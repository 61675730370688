.detail__mobile-holder {
    padding: 10px 0 20px;
    .download-action {
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(9, 1fr);
        grid-template-areas:
            'png png png svg svg svg copy formats share'
            'addtc addtc addtc addtc addtc addtc addtc addtc addtc';

        &-without-copy-paste {
            grid-template-columns: repeat(8, 1fr);
            grid-template-areas:
                'png png png svg svg svg formats share'
                'addtc addtc addtc addtc addtc addtc addtc addtc';
        }

        .btn-share,
        .btn-formats,
        .btn-copy {
            justify-self: center;
        }

        .btn-png,
        .btn-svg {
            grid-area: png;
            .row {
                margin: unset;
            }
        }
        .btn-svg {
            grid-area: svg;
            .popover-svg-header {
                display: flex;
                align-items: center;
            }
        }
        .btn-copy {
            grid-area: copy;
        }
        .btn-formats {
            grid-area: formats;
        }
        .btn-share {
            grid-area: share;
            .popover-button {
                > span {
                    display: none;
                }
            }

            ul.popover-content {
                padding: $small-spacing;
                right: -$small-spacing;
                z-index: 5;
            }
        }
        .btn-addtc {
            grid-area: addtc;
        }
        .popover-button {
            min-width: 44px;
            .icon {
                width: unset;
            }
        }
    }
    .download-action.copy-svg-png {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .btn-share,
        .btn-formats,
        .btn-copy {
            justify-self: center;
            flex: unset;
        }

        .btn-png,
        .btn-svg {
            flex: unset;
            width: calc(50% - 5px);
            .row {
                margin: unset;
            }
        }
        .btn-svg {
            .popover-svg-header {
                display: flex;
                align-items: center;
            }
        }
        .btn-copy.detail__copypng,
        .btn-copy.detail__copysvg {
            flex: unset;
            width: calc(50% - 5px);
            button {
                width: 100%;
                font-size: 14px;
                &.bj-button--secondary:focus {
                    background: color('100', 'gray');
                }
            }
        }

        .btn-formats,
        .btn-share,
        .btn-addtc {
            flex: unset;
            flex-grow: 1;
            .bj-button--link {
                color: color('800', 'gray');
                font-weight: $font-weight-regular;
                font-size: $font-size-sm;
                padding: 10px;
            }
            button {
                &:hover {
                    text-decoration: none;
                }
            }
        }

        .btn-formats {
            .popover-button,
            .popover-container {
                width: 100%;
                > span {
                    display: block;
                }
            }
        }
        .btn-share {
            .popover-button,
            .popover-container {
                width: 100%;
                height: 100%;
                > span {
                    display: block;
                }
            }

            ul.popover-content {
                padding: $small-spacing;
                right: -$small-spacing;
                z-index: 5;
            }
        }
        .btn-addtc {
            .add-to-collection {
                height: 100%;
            }
        }
        .popover-button {
            min-width: 44px;
            line-height: 34px;
            .icon {
                width: unset;
            }
        }
        @include breakpoint('lg') {
            flex-wrap: nowrap;
            .btn-addtc {
                button {
                    background-color: color('100', 'gray');
                    border-radius: $base-border-radius;
                    &:hover {
                        background-color: color('200', 'gray');
                    }
                }
            }
            .btn-share {
                button {
                    border-radius: $base-border-radius;
                    box-shadow: inset 0 0 0 2px color('200', 'gray');
                }
            }
            .popover-button {
                line-height: inherit;
            }
        }
    }

    &.only-share .download-action.copy-svg-png {
        .download-action.copy-svg-png {
            .btn-formats,
            .btn-share {
                width: calc(50% - 5px);
            }
        }
    }

    // El grid cambia si no está el botón de añadir a colecciones en la versión tablet (caso stickers)
    &.only-share {
        @include breakpoint('lg') {
            .download {
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-areas: 'png svg share';
                .btn-share {
                    .popover-button {
                        > span {
                            display: block;
                        }
                    }
                }
            }
        }
        @include breakpoint('md') {
            .download {
                grid-template-columns: 1fr 1fr auto;
                grid-template-areas: 'png svg share';
                .btn-share {
                    .popover-button {
                        > span {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    // FIN El grid cambia si no está el botón de añadir a colecciones en la versión tablet (caso stickers)

    // El grid cambia para Download Extended Component
    &.download-extended {
        .download-action {
            display: grid;
            gap: 10px;
            grid-template-columns: 1fr auto auto;
            grid-template-areas: 'png copy share';
        }
        @include breakpoint('lg') {
            .download {
                grid-template-columns: 1fr 1fr;
                grid-template-areas: 'png share';
                .btn-share {
                    .popover-button {
                        > span {
                            display: block;
                        }
                    }
                }
            }
        }
        @include breakpoint('md') {
            .download {
                grid-template-columns: 1fr auto;
                grid-template-areas: 'png share';
                .btn-share {
                    .popover-button {
                        > span {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    // FIN El grid cambia para Download Extended Component

    @include breakpoint('lg') {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #fff;
        z-index: 12;
        padding: 10px 20px;
        border-top: 1px solid color('100', 'gray');

        .download {
            grid-template-columns: 1fr 1fr auto auto;
            grid-template-areas: 'png svg addtc share';

            .not-mobile {
                display: none !important;
            }
        }

        .premium-icon__button {
            .fi-not-premium-icon {
                margin: 0;

                > div {
                    padding: 0 10px 0 0;
                }
            }
        }

        .detail__copypng,
        .detail__copysvg {
            display: none;
        }

        .btn-upgrade {
            padding: 0 20px !important;
            height: $base-element-height !important;
            line-height: $base-element-height !important;

            span {
                font-size: 16px;
                margin: 0;
                line-height: $base-element-height;
            }
        }

        .popover-share {
            .popover-content {
                bottom: 50px;
                top: initial;

                &:before {
                    border-bottom: none;
                    border-top: 10px solid #999;
                    top: initial;
                    bottom: -0.45em;
                }
                &:after {
                    border-bottom: none;
                    border-top: 10px solid #fff;
                    top: initial;
                    bottom: -0.45em;
                }
            }
        }
    }

    @include breakpoint('md') {
        .download {
            grid-template-columns: 1fr 1fr auto auto;
        }
    }

    @media (max-width: 768px) {
        .add-to-collection {
            width: $base-element-height !important;
            padding: 0;
        }
    }
}

.view--detail {
    @media (max-width: 992px) {
        .detail {
            position: relative;
            padding-bottom: 50px;

            &.footer-visible {
                .detail__mobile-holder {
                    position: absolute;
                }
            }
        }
    }
}

.download.svg-editable {
    .btn-size,
    .btn-svg {
        @include flex(1.5);

        .btn {
            padding: 0.75em 0;
            border-radius: $base-border-radius 0 0 $base-border-radius;
            white-space: normal;
        }
        .popover-button {
            font-size: 10px;
            padding: 0 3px;
            border-radius: 0 3px 3px 0;
            box-shadow: none;
            height: $base-element-height;
            background: color('600', 'green');
            color: #fff;
            &.btn-warning {
                background: color('500', 'yellow');
            }
        }

        .popover-content {
            width: auto;
            padding: $base-spacing 0 $small-spacing;
            p {
                color: color('500', 'gray');
                line-height: 1.4;
            }
            > p {
                margin-bottom: $extra-small-spacing;
                padding: 0 $base-spacing;
            }
            > a {
                padding: $small-spacing $base-spacing;
                display: block;
                background-color: transparent;
                border-radius: 0;
            }
            > a:hover {
                background-color: color('50', 'gray');
            }
            .size {
                display: flex;
                padding: 0 $base-spacing;
                li {
                    a {
                        display: block;
                        padding: $extra-small-spacing $small-spacing;
                        &.active {
                            background-color: color('75', 'gray');
                            border-radius: $base-border-radius;
                        }
                        &:hover {
                            background-color: color('50', 'gray');
                        }
                    }
                }
            }
        }

        span {
            height: $base-element-height;
            line-height: $base-element-height;
            display: inline-block;
            vertical-align: bottom;
            &.badge {
                padding: $extra-small-spacing;
                background-color: color('500', 'green');
                border-radius: $base-border-radius;
                padding: 0 5px;
                font-size: 12px;
                height: 20px;
                line-height: 20px;
                vertical-align: top;
                color: white;
            }
        }

        i {
            height: $base-element-height;
            line-height: $base-element-height;
            display: inline-block;
        }
    }

    .btn-size {
        .popover-content {
            left: -135px;
            width: fit-content;
            padding: 20px 0;
            &::before {
                left: 135px;
            }
            &::after {
                left: 133px;
            }
        }
    }

    .btn-svg {
        .btn {
            > i::before {
                margin-top: -2px;
            }
        }
        .popover-content {
            width: 350px;
            &::before {
                right: 1px;
            }
            &::after {
                right: -1px;
            }
            .font-md {
                color: color('800', 'gray');
            }
        }
        i {
            line-height: unset;
            height: unset;
        }
        i::before {
            margin-top: -9px;
        }
    }

    .popover--download {
        .popover-button {
            padding: 0.75em 0;
        }

        .popover-content {
            width: auto;
            right: 0px;
            padding: $base-spacing 0 $small-spacing;
            > ul {
                > p {
                    padding: 0 $base-spacing;
                    margin-bottom: $extra-small-spacing;
                }

                li {
                    a {
                        padding: $small-spacing $base-spacing;
                    }
                    a:hover {
                        background-color: color('50', 'gray');
                    }
                }
            }

            &::before {
                right: 12px;
            }
            &::after {
                right: 10px;
            }
            p {
                color: color('500', 'gray');
            }
            ul > p {
            }
        }

        a {
            display: flex;
            &.btn {
                padding: 0;

                background-color: transparent;
                i::before {
                    vertical-align: baseline;
                    margin-top: -1px;
                }
                p {
                    color: color('800', 'gray');
                    span {
                        vertical-align: top;
                        color: color('700', 'gray');
                    }
                }
            }
        }
    }
}
