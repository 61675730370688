.pickr {
    button.pcr-button {
        visibility: hidden;
        width: 0;
        height: 0;
        padding: 0;
        display: block;
    }
}

.pcr-app[data-theme='classic'] {
    width: 100%;
    margin-bottom: $base-spacing;
    box-shadow: none;
    padding: 0;
    background: none;
    position: static;
    min-width: 200px;
}

.pcr-app[data-theme='classic'] .pcr-swatches {
    margin: 0;
    position: absolute;
    bottom: 9px;
    left: 11px;
}

.pcr-app .pcr-interaction .pcr-result {
    background: #fff;
    border: none;
    border: 1px solid color('100', 'gray');
    width: 100px;
    min-width: 0;
    padding: 0 6px;
    height: 24px;
    line-height: 24px;
    flex: none;
    margin-left: auto;
    margin-top: 10px;
    outline: none;

    &:focus {
        border-color: #67879d;
        box-shadow: none;
    }
}

.gpickr .pcr-app .pcr-interaction .pcr-result {
    text-align: right;
    width: 170px;
}

.pickers-wrapper {
    position: relative;
}

.color-picker-wrapper,
.color-stroke-picker-wrapper,
.color-picker-btn {
    background: url('~Media/colorWheel.png') center no-repeat;
    background-size: 100%;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-bottom: 20px;
    cursor: pointer;
}

.pcr-app[data-theme='classic'],
.pcr-app[data-theme='nano'] {
    padding: $small-spacing;
    border: 1px solid color('100', 'gray');
    border-radius: $base-border-radius;
    background: #ffffff;
    box-shadow: 0 0 60px rgba(14, 42, 71, 0.25);
    position: absolute;
    top: 100% !important;
    left: 0 !important;
    margin-top: $small-spacing;

    &:before {
        font-size: 1.4em;
        display: block;
        width: 0;
        height: 0;
        content: '';
        pointer-events: none;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #999;
        border-left: 10px solid transparent;
        position: absolute;
        top: -0.45em;
        left: 4px;
    }

    &:after {
        font-size: 1.4em;
        display: block;
        width: 0;
        height: 0;
        content: '';
        pointer-events: none;
        border-right: 12px solid transparent;
        border-bottom: 12px solid #ffffff;
        border-left: 12px solid transparent;
        position: absolute;
        top: -0.45em;
        left: 2px;
    }
}

.detail__editor__forms {
    .pcr-app[data-theme='classic'] {
        margin-top: 0;
    }
}

.pcr-app[data-theme='nano'] {
    margin-top: 0px;

    &:before,
    &:after {
        display: none;
    }
}
.color-picker-btn {
    padding: 12px;
}
