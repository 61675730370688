.modal-craft-info {
    .modal__close {
        top: 15px;
        right: 15px;
        .icon--cross {
            color: color('800', 'gray');
            font-size: 20px;
        }
    }
    .modal__container {
        max-width: 740px;
        .content {
            padding: 0;
            display: flex;
            border-radius: 8px;
        }
    }
    &__left {
        width: 250px;
        background: url('~Media/modals/craft-more-info/craft-more-info.png');
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        img {
            mix-blend-mode: multiply;
        }
    }
    &__right {
        padding: 40px 50px 40px 40px;
        h6 {
            margin: 0 0 $small-spacing;
        }
        > p {
            color: color('800', 'gray');
        }
        &__item {
            display: flex;
            &__image {
                flex-shrink: 0;
                margin-right: $base-spacing;
                width: 44px;
                i::before {
                    width: 44px;
                    height: 44px;
                    background: rgba(74, 210, 149, 0.1);
                    color: color('800', 'green');
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 8px;
                }
            }
            &__text {
                p:first-child {
                    color: color('800', 'gray');
                }
            }
        }
        &__buttons {
            display: flex;
            > a {
                width: 50%;
                line-height: 1.2;
            }
            > a:first-child {
                color: color('700', 'green');
            }
        }
    }
    @include breakpoint('md') {
        padding: 0;
        &__left {
            display: none;
        }
        &__right {
            &__buttons {
                flex-wrap: wrap;
                > a {
                    width: 100%;
                }
                > a:first-child {
                    margin-bottom: $base-spacing;
                }
            }
        }
    }
}
