.sp-slidesgo {
    b {
        color: color('800', 'gray');
    }

    &__header {
        padding: 20px;
        background-color: color('50', 'gray');
    }

    &__list {
        height: 183px;
        overflow: hidden;
        background-color: color('50', 'gray');
        padding: 0 20px;
    }

    &__element {
        margin-bottom: 100px !important;

        img {
            height: 168px;
            margin: auto;
            display: block;
            width: auto;
            max-width: none;
        }

        &:hover {
            opacity: 0.8;
        }
    }

    .row {
        margin: 0px;
    }
}

.sp-slidesgo-container {
    height: 250px;
}
