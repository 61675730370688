#crusade-flaticon {
    .notification__close {
        box-shadow: inset 0px 0px 0px 100px color('600', 'green') !important;
    }

    .crusades__description {
        b {
            color: color('600', 'green');
        }
    }

    .crusades__countdown li {
        color: color('600', 'green');

        .countdown__description {
            display: none;
        }
    }

    .crusades__discount {
        background: url('~Media/crusades/bg-flaticon.svg') center center no-repeat;
    }
}

#crusade-freepik {
    .notification__close {
        box-shadow: inset 0px 0px 0px 100px color('500', 'blue') !important;
    }

    .crusades__description {
        b {
            color: color('500', 'blue');
        }
    }

    .crusades__countdown li {
        color: color('500', 'blue');

        .countdown__description {
            display: none;
        }
    }

    .crusades__discount {
        background: url('~Media/crusades/bg-freepik.svg') center center no-repeat;
    }
}

.crusades {
    padding: 0 !important;
    background: color('light', 'background') !important;
    color: color('text', 'general') !important;

    .row {
        & > * {
            margin: 0 $small-spacing;
        }
    }

    &__countdown {
        ul {
            @include prefixer(display, flex, ms spec);
            @include prefixer(align-items, center, ms spec);
            @include prefixer(flex-wrap, wrap, ms spec);
        }

        li {
            @include font-size($h5-font-size);

            font-weight: $font-weight-bold;
            line-height: 28px;

            &:not(:last-child) span::after {
                content: ':';
            }
        }

        &__description {
            margin: 0;
            color: color('600', 'gray');
            font-size: $font-size-xs;
        }
    }

    &__description {
        @include prefixer(flex, 1, ms spec);

        max-width: 340px;
        font-size: $font-size-lg;
        font-weight: $font-weight-semibold;
        text-align: center;
    }

    &__discount {
        @include font-size($h2-font-size);

        width: 260px;
        height: 100px;
        background-size: 260px auto;
        color: color('text-inverted', 'general');
        font-weight: $font-weight-bold;
        text-align: center;
        line-height: 100px;
    }

    &__schedule {
        padding: 0 $base-spacing;
        border-left: $base-border;
        border-right: $base-border;
        text-align: center;
    }

    @include breakpoint('lg') {
        .container-fluid {
            padding-top: $small-spacing;
            padding-bottom: $small-spacing;
        }

        .crusades__discount {
            display: none;
        }

        .crusades__description {
            text-align: left;

            br {
                display: none;
            }
        }
    }

    @include breakpoint('sm') {
        text-align: center;

        .crusades__description {
            max-width: inherit;
            margin: 0 0 $small-spacing;
            text-align: center;
        }

        .crusades__schedule {
            display: none;
        }

        .crusades__countdown ul {
            @include prefixer(justify-content, center, ms spec);
        }

        .row {
            display: block;
        }
    }
}
