.errorpage.e404 {
    background-color: color('50', 'gray');
    text-align: center;
    padding: $extra-large-spacing * 2 $base-spacing;

    .image {
        width: 625px;
        height: 314px;
        background: url('~Media/error/404.png') no-repeat center;
        background-size: cover;
        position: relative;
        margin: auto;
    }
    .ufo {
        width: 70px;
        height: 59px;
        background: url('~Media/error/ufo.png') no-repeat center;
        background-size: cover;
        position: absolute;
        top: $extra-large-spacing + $base-spacing;
        right: 47px;
        @include animation(floatingOvni ease-in-out 1500ms infinite);
    }
    .alien {
        width: 13px;
        height: 21px;
        background: url('~Media/error/alien.png') no-repeat center;
        background-size: cover;
        position: absolute;
        bottom: 97px;
        right: -2px;
        @include transform-origin(bottom left);
        @include animation(alien linear 300ms infinite);
    }
    .estela {
        width: 40px;
        height: 3px;
        background: url('~Media/error/estela.png') no-repeat center;
        background-size: cover;
        position: absolute;
        bottom: 76px;
        left: 144px;
        @include animation(estela ease-in-out 1000ms infinite);
        &:after {
            content: '';
            display: block;
            width: 20px;
            height: 3px;
            position: absolute;
            top: 0;
            right: 0;
            @include linear-gradient(to left, #072f3d, transparent);
            background-color: transparent;
        }
    }
    .satellite-container {
        width: 0px;
        height: 100px;
        top: 108px;
        left: $base-spacing;
        position: absolute;
        @include animation(rotate linear 2000ms infinite);
    }
    .satellite {
        width: 65px;
        height: 65px;
        background: url('~Media/error/satellite.png') no-repeat center;
        background-size: cover;
        position: relative;
        @include transform-origin(bottom left);
        @include animation(rotateInverse linear 2000ms infinite);
    }
    h1 {
        color: color('800', 'blue');
        font-size: $font-size-2xl;
        margin: $large-spacing 0 $extra-large-spacing;
        font-family: $base-font-family;
    }
    h3 {
        font-size: $font-size-lg;
        line-height: $line-height-160;
        font-family: $base-font-family;
    }
    p {
        margin-top: $base-spacing;
    }
    @include breakpoint('md') {
        padding: $base-spacing;
        .image {
            width: 320px;
            height: 161px;
        }
        .alien,
        .ufo,
        .estela,
        .satellite-container,
        .mask {
            display: none;
        }
    }
}
