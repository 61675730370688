.detail__editor {
    border-bottom: 1px solid color('100', 'gray');
    position: relative;

    &__header {
        border-bottom: 1px solid color('75', 'gray');
        height: $extra-large-element-height;
        padding: 0 $small-spacing;
        position: relative;
        z-index: 3;

        .link--arrow {
            color: color('800', 'gray');

            i {
                color: color('800', 'gray');
            }
        }
    }

    &__body {
        background-color: color('50', 'gray');
    }

    &__controls {
        button {
            &:focus {
                color: color('500', 'gray');
            }
        }
    }

    &__menu {
        padding: $small-spacing;
        border-right: 1px solid color('75', 'gray');
        background-color: #fff;

        label {
            width: 68px;
            text-align: center;
            color: color('500', 'gray');
            font-size: $font-size-xs;
            border-radius: $base-border-radius;
            padding: $small-spacing $extra-small-spacing;
            display: block;
            margin: 0;
            font-weight: $font-weight-semibold;
            cursor: pointer;

            i {
                color: color('500', 'gray');
            }

            &:hover {
                color: color('800', 'gray');

                i {
                    color: color('800', 'gray');
                }
            }

            &.active {
                color: color('800', 'gray');
                background-color: color('75', 'gray');

                i {
                    color: color('800', 'gray');
                }
            }
        }
    }

    &__submenu {
        padding: $base-spacing;
        border-right: 1px solid color('75', 'gray');
        width: 300px;
        background-color: #fff;
        min-height: 520px;

        input[type='radio'] {
            display: none;
            appearance: none;
            -webkit-appearance: none;

            &:checked + .detail__editor__options {
                display: block;
            }
        }

        .detail__editor__options {
            display: none;
            &.detail__editor__stroke {
                .stroke-width-number {
                    display: flex;
                    gap: 5px;
                }
            }
        }

        .pcr-picker {
            box-shadow: 0px 0px 2px color('600', 'gray');
        }
    }

    &__canvas {
        margin: 0;
        padding: 0;
        position: static;
    }

    &__colors {
        .popover-button {
            width: $small-element-height;
            height: $small-element-height;
            border-radius: $base-border-radius;
            padding: 0;
        }
    }

    &__icon-wrapper {
        width: 300px;
        margin: $extra-large-spacing - ($small-spacing + $extra-small-element-height) auto $extra-large-spacing;

        button {
            background: none;
            color: color('500', 'gray');

            &:hover {
                background-color: color('100', 'gray');
                color: color('800', 'gray');
            }
        }
    }

    &__icon-holder {
        background-color: #fff;
        background-image: linear-gradient(45deg, color('100', 'gray') 25%, transparent 25%),
            linear-gradient(-45deg, color('100', 'gray') 25%, transparent 25%),
            linear-gradient(45deg, transparent 75%, color('100', 'gray') 75%),
            linear-gradient(-45deg, transparent 75%, color('100', 'gray') 75%);
        background-size: 20px 20px;
        background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
        width: 300px;
        height: 300px;
        margin-top: $small-spacing;
        border: 1px solid color('200', 'gray');
        box-shadow: 0 2px 4px color('900', 'gray', 0.1);

        svg {
            width: 100%;
            height: 100%;

            &.selected-paths,
            &.hovered-paths {
                *:not(g) {
                    opacity: 0.4;

                    &.active-path,
                    &.hovered-path {
                        cursor: pointer;
                        opacity: 1;
                    }
                }
            }
        }
    }

    &__sizes {
        display: none;
        margin-top: $small-spacing;
        position: absolute;
        background: #fff;
        z-index: 1;
        left: 0;
        top: 100%;
        left: -9px;
        top: 100%;
        width: calc(100% + 17px);
        padding: 0 10px 10px;

        a {
            height: $base-element-height;
        }
    }

    .popover--download {
        .popover-content {
            width: 160px;
        }

        .bj-button {
            width: 100%;
        }

        input[type='checkbox'] {
            display: none;
            appearance: none;
            -webkit-appearance: none;

            &:checked + div {
                display: block;
            }
        }

        li {
            position: relative;
        }
    }

    .alert {
        max-width: 300px;
        position: absolute;
        top: 75px;
        right: 10px;
        z-index: 1;

        p {
            padding: 1em 1em 1em 3em;
        }

        a + p {
            padding: 1em 4.5em 1em 3em;
        }
    }

    .only_registered {
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.8);
        width: 100%;
        height: 100%;
        z-index: 1;

        .alert {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 600px;
        }
    }

    .colors {
        margin: 0 0 $base-spacing;
        li {
            margin: 0 $small-spacing $small-spacing 0;

            &:nth-child(6n) {
                margin-right: 0;
            }
        }

        button {
            width: $small-element-height;
            height: $small-element-height;
            border-radius: $base-border-radius;
            padding: 0;
            box-shadow: inset 0 0 0 1px color('900', 'gray', 0.1);

            &.active {
                box-shadow: inset 0 0 0 1px color('900', 'gray', 0.1), 0px 0px 0px 2px #fff,
                    0px 0px 0px 4px color('600', 'green');
            }
        }
    }

    .btn-group {
        display: inline-block;
        box-shadow: 0 0 0 1px color('100', 'gray');
        border-radius: 4px;

        .tooltip {
            float: left;

            &:last-of-type {
                button {
                    border-right: 0px;
                }
            }
        }

        button {
            border-radius: 0;
            border-right: 1px solid color('100', 'gray');
            background: none;

            &:hover {
                background-color: color('75', 'gray');
            }

            i {
                color: color('800', 'gray');
            }

            &[data-values='none'] {
                i {
                    color: color('400', 'gray');
                }
            }
        }
    }

    @include breakpoint('xs') {
        &__header {
            #save_into_active {
                span {
                    display: none;
                }
            }
        }
    }

    @include breakpoint('md') {
        position: fixed;
        top: 0;
        left: 0;
        background: #fff;
        z-index: 15;
        height: 100%;
        width: 100%;
        @include display(flex);
        @include flex-direction(column);

        &__header {
            height: $large-element-height;
            padding: 0 $extra-small-spacing + $small-spacing;
            position: relative;
            z-index: 2;

            .bj-button {
                height: $small-element-height;
                line-height: $small-element-height;
                padding: 0 $small-spacing;
            }

            .add-to-collection {
                padding: 0;
            }
        }

        &__body {
            @include flex-direction(column);
            @include flex(1);
            height: calc(100% - 54px);
        }

        &__menu {
            @include order(2);
            padding: 0;
            border-top: 1px solid color('75', 'gray');
            border-right: none;

            ul {
                @include display(flex);
            }

            li {
                @include flex(1);
            }

            label {
                width: 100%;
                border-radius: 0;
                font-size: 11px;
            }

            i {
                width: 16px;
                height: 16px;
                font-size: 16px;
                line-height: 16px;
            }
        }

        &__submenu {
            @include order(1);
            @include flex(1);
            width: 100%;
            overflow: auto;
            min-height: 0;
        }

        &__canvas {
            @include order(0);
            @include flex(none);
            width: 100%;
        }

        &__icon-wrapper {
            width: 180px;
            margin: $small-spacing auto;
        }

        &__icon-holder {
            width: 180px;
            height: 180px;
        }

        &__controls {
            @include justify-content(center);

            > * {
                margin: 0 $extra-small-spacing;
            }
        }

        .alert {
            position: relative;
            top: 0;
            max-width: none;
            right: 0;
            border-radius: 0;
            font-size: $font-size-sm;

            p {
                padding: 10px 10px 10px 45px;
            }
        }

        .only_registered {
            .alert {
                max-width: calc(100% - 40px);
            }
        }

        .colors {
            display: block;
            white-space: nowrap;
            overflow: auto;
            margin-left: -4px;
            padding: 4px;

            li {
                display: inline-block;

                &:nth-child(6n) {
                    margin-right: $small-spacing;
                }
            }
        }
    }

    input[type='range'] {
        height: $extra-small-element-height;
        display: block;
        -webkit-appearance: none;
        width: 100%;
    }
    input[type='range']:focus {
        outline: none;
    }
    input[type='range']::-webkit-slider-runnable-track {
        width: 100%;
        height: 5px;
        cursor: pointer;
        animate: 0.2s;
        box-shadow: 0px 0px 0px #000000;
        background: color('100', 'gray');
        border-radius: 1px;
        border: 0px solid #000000;
    }
    input[type='range']::-webkit-slider-thumb {
        box-shadow: 0px 0px 2px color('600', 'gray');
        border: 2px solid #ffffff;
        height: 18px;
        width: 18px;
        border-radius: 25px;
        background: color('600', 'green');
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -6.5px;
    }
    input[type='range']:focus::-webkit-slider-runnable-track {
        background: color('100', 'gray');
    }
    input[type='range']::-moz-range-track {
        width: 100%;
        height: 5px;
        cursor: pointer;
        animate: 0.2s;
        box-shadow: 0px 0px 0px #000000;
        background: color('100', 'gray');
        border-radius: 1px;
        border: 0px solid #000000;
    }
    input[type='range']::-moz-range-thumb {
        box-shadow: 0px 0px 2px color('600', 'gray');
        border: 2px solid #ffffff;
        height: 18px;
        width: 18px;
        border-radius: 25px;
        background: color('600', 'green');
        cursor: pointer;
    }
    input[type='range']::-ms-track {
        width: 100%;
        height: 5px;
        cursor: pointer;
        animate: 0.2s;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }
    input[type='range']::-ms-fill-lower {
        background: color('100', 'gray');
        border-radius: 2px;
        box-shadow: 0px 0px 0px #000000;
    }
    input[type='range']::-ms-fill-upper {
        background: color('100', 'gray');
        border-radius: 2px;
        box-shadow: 0px 0px 0px #000000;
    }
    input[type='range']::-ms-thumb {
        margin-top: 1px;
        box-shadow: 0px 0px 2px color('600', 'gray');
        border: 2px solid #ffffff;
        height: 18px;
        width: 18px;
        border-radius: 25px;
        background: color('600', 'green');
        cursor: pointer;
    }
    input[type='range']:focus::-ms-fill-lower {
        background: color('100', 'gray');
    }
    input[type='range']:focus::-ms-fill-upper {
        background: color('100', 'gray');
    }
}

// Eliminar cuando tengamos los iconos actualizados
.icon--flip.flip-vertical {
    transform: rotate(90deg);
}
.icon--rotate.rotate--left {
    transform: scaleX(-1);
}
