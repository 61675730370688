.modal-attribution-new {
    overflow-y: auto;
    z-index: 305;

    .modal__container {
        padding: 0;
    }

    .modal__close {
        top: 15px;
        right: 15px;

        .icon--cross {
            color: color('800', 'gray');
            font-size: 20px;
        }
    }

    .content {
        padding: 0;

        &-header {
            padding: 30px 20px 0 20px;
            text-align: center;

            &--title {
                font-size: 34px;
                font-weight: $font-weight-semibold;
                color: color('800', 'gray');

                @include breakpoint('md') {
                    font-size: 26px;
                }
            }

            &--text {
                font-size: 18px;
                max-width: 766px;
                margin: auto;
                margin-bottom: 40px;
                color: color('700', 'gray');

                @include breakpoint('md') {
                    font-size: 16px;
                }
            }
        }

        &-body {
            .accordion2__menu {
                width: 100%;
                border-bottom: 1px solid color('100', 'gray');
                overflow-x: auto;

                .accordion2__menu__box {
                    display: flex;
                    max-width: 700px;
                    margin: auto;
                }

                &--item {
                    height: 44px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: color('600', 'gray');
                    margin: 0;
                    padding: 0 30px;
                    font-weight: $font-weight-semibold;
                    cursor: pointer;
                    white-space: nowrap;

                    &.active {
                        color: color('800', 'gray');
                        border-bottom: 1px solid color('800', 'gray');
                    }
                }
            }

            .accordion2__container {
                padding: 0 20px;

                .accordion2__content {
                    display: none;

                    p {
                        font-size: 14px;
                        text-align: center;

                        span {
                            color: color('800', 'gray');
                            font-weight: $font-weight-semibold;
                        }
                    }

                    p:nth-of-type(1) {
                        color: color('800', 'gray');
                        font-weight: $font-weight-semibold;
                        margin: 40px 0 20px 0;
                    }

                    p:nth-of-type(2) {
                        max-width: 766px;
                        margin: auto;
                    }

                    .copy-box {
                        max-width: 768px;
                    }

                    &.active {
                        display: block;
                    }
                }
            }
        }
    }
    .social-media-buttons {
        .bj-button {
            margin: 5px;

            @include breakpoint('sm') {
                width: 100%;
                margin: 5px 0;
            }
        }
    }
}

.modal__banner-footer {
    padding: 45px 20px;
    text-align: center;
    color: $color-light;
    background: url('~Media/modal-attribution-bg.png') center no-repeat;
    background-size: cover;

    @include breakpoint('md') {
        background: color('800', 'blue');
    }

    &--title {
        font-size: 26px;
        font-weight: $font-weight-semibold;

        @include breakpoint('md') {
            font-size: 22px;
        }
    }

    &--text {
        font-size: 18px;
        max-width: 600px;
        margin: auto;

        @include breakpoint('md') {
            font-size: 16px;
        }

        span {
            color: color('500', 'yellow');
            font-weight: $font-weight-bold;
        }

        a {
            color: color('500', 'yellow');
            font-weight: $font-weight-semibold;
        }
    }
}

.social-media-buttons {
    display: flex;
    justify-content: center;
    margin: 30px 0;
    flex-wrap: wrap;

    .bj-button {
        margin: 0 5px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
            > span {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        @include breakpoint('sm') {
            width: 100%;
        }
    }

    .copy__text,
    .attribution__text {
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        margin-top: -100%;
    }

    .copy__trigger span {
        font-size: 14px;
        display: none;

        &.active {
            display: block;
        }
    }
}

.copy-box {
    display: flex;
    align-items: center;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;

    @include breakpoint('xs') {
        flex-direction: column;
    }

    .copy__text {
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        margin-top: -100%;
    }

    .attribution__text {
        width: 100%;
        height: 44px;
        line-height: 44px;
        margin-bottom: 0;
        margin-right: 10px;
        padding: 0 10px;
        background: color('50', 'gray');
        border: 1px solid color('100', 'gray');
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: color('500', 'gray');
        font-size: 14px;

        @include breakpoint('xs') {
            margin-bottom: 10px;
            margin-right: 0;
        }
    }

    .copy__trigger {
        @include breakpoint('xs') {
            width: 100%;
        }

        span {
            display: none;

            &.active {
                display: block;
            }
        }
    }
}

// Este css debe aplicarse solo a la vista de detalle de Iconos y Stickers

#modal-attribution.modal-attribution-detail.active {
    position: absolute;
    width: 100%;
    height: auto;
    min-height: 100%;
    padding: $base-spacing;
    background-color: color('900', 'blue', 0.85);
    flex-direction: column;
    display: flex;
    justify-content: center;
    .modal-attribution__wrapper {
        flex-direction: column;
        justify-content: center;
        flex: 1;
        height: 100%;
        display: flex;
    }
    .attribution {
        *:not(span) {
            color: white;
        }
        h6 {
            line-height: $line-height-150;
        }
        > .row {
            max-width: max-content;
            margin: 0 auto;
        }
        .social-media-buttons button:last-child {
            flex-grow: 3;
        }
    }
    .modal__container {
        background-color: transparent;
        margin: 0;
        max-width: unset;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        i.icon--cross {
            color: white;
        }
    }
    @include breakpoint('xl') {
        .attribution {
            padding-top: $large-spacing;
            text-align: center;
            .attribution__video,
            h6 {
                display: none;
            }
            .download-problems-box {
                justify-content: center;
            }
        }
    }
    @include breakpoint('lg') {
        .modal__container {
            .attribution {
                .content {
                    margin: 0 auto;
                }
            }
            vertical-align: top;
        }
    }
    @include breakpoint('md') {
        .attribution {
            .social-media-buttons {
                .bj-button {
                    margin: 0 5px;
                }
                button:last-child {
                    flex-grow: 1;
                }
            }
        }
    }
    @include breakpoint('sm') {
        .attribution {
            .social-media-buttons {
                flex-direction: row;
                align-items: flex-start;
                flex-wrap: nowrap;
            }
        }
    }
}
