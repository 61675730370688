$animation-speed: 400ms;
$base-border-radius: 6px;

%main {
    @include position(absolute, 50% null null 50%);

    @include transition(all 500ms ease-in-out);

    height: 256px;
    margin: -178px 0 0 -128px;
    width: 256px;

    svg,
    img {
        height: 100%;
        width: 100%;
    }
}

%prev-icon {
    left: 0%;

    img {
        height: auto;
        width: 100%;
    }
}

%prev-icon2 {
    left: calc(0% - 100px);
}

%next-icon {
    left: 100%;
    margin-left: -68px;

    img {
        height: auto;
        width: 100%;
    }
}

%next-icon2 {
    left: calc(100% + 100px);
}

.notouch {
    .author {
        a:hover {
            color: #4c5b5c;
        }
    }

    .detail .detail-content .prev:hover .flaticon-arrow_left {
        padding-left: 0;
    }

    .detail .detail-content .next:hover .flaticon-arrow_right {
        padding-left: 24px;
    }
}

.detail {
    margin: 0 auto;

    &[data-icon_type='animated-icon'] {
        #download-free {
            width: 30%;
        }
    }

    .detail-header {
        .text-link {
            border: none;
        }

        .container {
            padding: 0 12px;
        }

        h1 {
            font-size: 18px;

            margin: 0;
        }

        .detail-header--right {
            margin-left: auto;

            .group {
                margin-left: $small-spacing;
            }
        }

        .btn-share {
            height: 42px;
        }
    }

    .detail-content {
        box-sizing: inherit;

        margin: ($base-spacing * 2) 1em $small-spacing 1em;

        .container {
            overflow: hidden;
        }

        .gallery {
            position: relative;

            min-height: 350px;
        }

        .svg-version,
        .img-version {
            filter: alpha(opacity = 100);
            opacity: 1;

            @include transition(opacity 500ms ease-in-out);
        }

        .svg-version {
            filter: alpha(opacity = 0);
            opacity: 0;

            &.active {
                filter: alpha(opacity = 100);
                opacity: 1;

                & + .img-version {
                    opacity: 0;
                    filter: alpha(opacity = 0);
                    visibility: hidden;
                }
            }

            &.light-icons {
                svg {
                    background: darken(color('800', 'blue'), 10);
                    border-radius: 0.7em;
                }
            }
        }

        .main-icon {
            @extend %main;

            &.mainPrev {
                @extend %icon;

                @extend %prev-icon;
            }

            &.mainNext {
                @extend %icon;

                @extend %next-icon;
            }
        }

        .prev-icon,
        .next-icon {
            @extend %icon;

            filter: alpha(opacity = 0);
            opacity: 0;
        }

        .prev-icon {
            @extend %prev-icon;

            &-2 {
                @extend %prev-icon2;

                &.prevIn {
                    @extend %prev-icon;
                }

                &.nextPos {
                    @extend %next-icon2;

                    @include transition-duration(0ms);
                }
            }

            &.prevMain {
                @extend %main;
            }

            &.prevOut {
                @extend %prev-icon2;
            }
        }

        .next-icon {
            @extend %next-icon;

            &-2 {
                @extend %next-icon2;

                &.nextIn {
                    @extend %next-icon;
                }

                &.prevPos {
                    @extend %prev-icon2;

                    @include transition-duration(0ms);
                }
            }

            &.nextMain {
                @extend %main;
            }

            &.nextOut {
                @extend %next-icon2;
            }
        }

        .prev,
        .next {
            @include position(absolute, 50% null null null);

            @include transform(translate(-0%, -50%));

            width: 136px;
            height: 68px;
            margin-top: -30px;
            z-index: 1;
            color: color('500', 'gray');

            i {
                opacity: 0;
                filter: alpha(opacity = 0);
                float: left;
                width: 68px;
                height: 68px;
                font-size: 3em;

                @include transition(all 0.3s ease-in-out);
            }

            span {
                display: block;
                float: left;
                width: 68px;
                height: 68px;
                border: color('100', 'gray') 2px solid;
                border-radius: 0.5em;
            }
        }

        .prev {
            left: 0%;

            i {
                padding-left: 24px;
            }
        }

        .next {
            right: 0%;

            i {
                padding-left: 0px;
            }
        }

        .img-loader {
            @include position(absolute, 50% null null null);

            @include transform(translate(-0%, -50%));

            opacity: 1;
            filter: alpha(opacity = 100);
            margin-top: -30px;
            margin-left: 10px;

            &:nth-child(1) {
                left: 10px;
            }

            &:nth-child(2) {
                right: 10px;
            }
        }

        .icon-actions {
            @include position(absolute, null null 0 0);
            width: 100%;
            text-align: center;

            > div {
                margin-top: $base-spacing;
            }

            .btn-square {
                width: 38px;
                height: 38px;

                position: relative;
                top: 0;
                left: 0;

                display: inline-block;

                margin-left: 4px;

                box-shadow: 0 0 0 2px color('100', 'gray');
            }
        }

        @media (max-width: 640px) {
            width: calc(100% + 176px);
            margin-left: -88px;
        }
    }

    @media (max-width: 1200px) {
        width: 100%;

        .detail-header {
            .group-pack,
            .group-category {
                display: none;
            }
        }
    }

    .detail-footer {
        min-height: 83px;
        h2 {
            font-size: $font-size-lg;
        }

        .tags {
            @include display(flex);
            @include flex-wrap(wrap);
            @include align-items(flex-start);
            @include justify-content(center);
            margin-top: 24px;

            li {
                display: block;
                margin: 0 2px 4px;
            }
        }
    }

    @media (max-width: 768px) {
        overflow: hidden;
    }

    @media (max-width: 480px) {
        .detail-header {
            padding: 6px 0 0;

            .row {
                @include justify-content(center);
            }
        }

        .detail-header--right {
            width: 100%;
        }

        .detail-footer {
            .tags {
                font-size: 0.9em;
            }
        }
    }

    @media (max-width: 320px) {
        .detail-content {
            .prev i,
            .next i {
                font-size: 2em;
            }
        }

        .detail-footer .download {
            @include clearfix;

            li {
                @include flex-basis(100%);

                &.fake {
                    display: none !important;
                }
            }
        }
    }
}

.related {
    #sp-freepik {
        height: auto;
    }
}

@media (max-width: 1344px) {
    .collections-visible .detail {
        width: 100%;
    }
}

.loaded {
    .detail-content {
        .prev-icon,
        .next-icon {
            opacity: 1;
            filter: alpha(opacity = 0);
        }

        .prev,
        .next {
            i {
                opacity: 1;
            }
        }

        .img-loader {
            opacity: 0;
            filter: alpha(opacity = 100);
        }
    }
}

/**
* Modal
*/

#detail-overlay {
    $btn-width: 64px;
    $btn-height: $btn-width;
    $btn-width-mobile: $base-element-height;
    $btn-height-mobile: $btn-width-mobile;
    @include position(fixed, 0 null null 0);
    overflow: hidden;
    display: none;
    width: 100%;
    height: 0%;
    background: color('900', 'blue', 0.9);
    z-index: 100;

    @media (max-width: 1200px) {
        #detail-navbar {
            display: none;
        }
    }

    &.premium-lock {
        .detail .detail-content {
            min-height: 234px;

            padding-bottom: 0;
        }
    }

    &.loading {
        display: block;

        height: 100%;
    }

    &.ready {
        .detail__inner {
            opacity: 1;

            @media (max-width: 1200px) {
                margin-top: 0;
                border-radius: 0;
            }
        }

        &.loading .circle-spinner {
            display: none;
        }
    }

    #detail-wrapper {
        position: relative;
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        width: 100%;
        height: 100%;
        background: transparent;
        z-index: 2;

        @media (max-width: 1200px) {
            padding-top: 0;
        }
    }

    #detail-content {
        padding: 0 72px;

        @media (max-width: 1200px) {
            padding: 0;
        }
    }

    #detail {
        @media (max-width: 1120px) {
            padding-bottom: 85px;
        }
    }

    .detail__inner {
        $content-width: 1400px !global;
        @include transition(opacity 250ms linear);
        opacity: 0;
        background: white;
        position: relative;
        overflow: hidden;
        .detail-footer {
            min-height: 83px;
            h2 {
                font-size: $font-size-lg;
            }
        }

        #fi-premium-s-p {
            border-radius: 0 0 10px 10px;
            overflow: hidden;
        }

        @media (min-width: 1025px) {
            margin: $small-spacing auto;
            border-radius: 10px;
        }

        @media (min-width: 1200px) {
            width: 100%;
            max-width: $content-width;
        }

        @media (max-width: 1200px) {
            .detail-header {
                .group-pack,
                .group-category {
                    display: none;
                }
            }
        }

        @media (max-width: 1200px) {
            max-width: none;
        }
    }

    #detail-close {
        position: fixed;
        top: 10px;
        left: 50%;
        margin-left: $content-width / 2;

        width: $btn-width;
        height: $btn-height;
        font-weight: $font-weight-regular;

        z-index: 3;

        @media (max-width: 1544px) {
            position: absolute;
            top: 20px;
            right: 20px;
            left: initial;
            margin-left: 0;

            width: $btn-width-mobile;
            height: $btn-height-mobile;
        }

        &::after {
            @include position(absolute, 50% null null 50%);
            @include transform(translate(-50%, -50%));

            color: white;
            font-size: $base-element-height;

            content: '\d7';
        }
    }

    .detail-nav {
        width: $btn-width;
        height: $btn-height;

        color: white;
        font-size: $font-size-3xl;
        text-align: center;
        line-height: $btn-height;

        cursor: pointer;

        z-index: 3;

        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(0, -50%);

        &#detail-prev {
            margin-left: -($content-width / 2) - $btn-width;
        }

        &#detail-next {
            margin-left: $content-width / 2;
        }

        @media (max-width: 1544px) {
            &#detail-prev {
                left: 10px;
                margin-left: 0;
            }

            &#detail-next {
                left: initial;
                right: 10px;
            }

            &:before {
                line-height: $btn-height-mobile;
            }
        }
    }

    .circle-spinner {
        border-color: #ffffff;
        border-right-color: transparent;
        width: 120px;
        height: 120px;
        border-width: 4px;

        @include position(fixed, 50% null null 50%);

        margin-left: -60px;
        margin-top: -60px;

        z-index: 1;

        &.related {
            display: block !important;
            border: 2px solid color('900', 'gray');
            border-right-color: transparent;
            top: 60%;
            width: 35px;
            height: 35px;
            position: relative;
            margin: 0 auto;
            left: unset;
        }
    }
}
/**
* Modal end
*/

@media (max-width: 940px) {
    .collections-visible {
        .detail {
            .detail-content {
                .prev-icon,
                .next-icon {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 960px) {
    .collections-visible {
        .detail {
            .detail-content {
                width: calc(100% + 176px);
                margin-left: -88px;
            }
        }
    }
}

@media (max-width: 800px) {
    .collections-visible .detail .detail-footer .tags {
        font-size: 0.9em;
    }
}

body.isIE .detail .detail-content {
    .next,
    .prev,
    .img-loader {
        display: none !important;
    }
}

.detail__content {
    .button--pinterest {
        height: $small-element-height;
        line-height: $small-element-height;
        padding: 0 $base-spacing;
        font-size: $font-size-sm;
        z-index: 1;
    }

    .btn-edit-icon {
        z-index: 1;
        height: 36px;
        padding: 0 $base-spacing;
        margin-left: auto;

        @media (max-width: 1200px) {
            margin-left: $small-spacing;
            padding: 0 $small-spacing;

            i {
                margin: auto;
            }

            span {
                display: none;
            }
        }
    }

    .detail__icon__holder {
        @include flex(1);
        @include display(flex);
        @include flex-direction(column);
        position: relative;

        .close-detail {
            display: none;
            width: 34px;
            height: 34px;
            line-height: 34px;
            font-size: $font-size-xl;
            color: color('800', 'gray');
            margin-left: auto;

            @media (max-width: 1200px) {
                display: block;
            }
        }

        .detail__nav--prev,
        .detail__nav--next {
            display: none;
            align-items: center;
            justify-content: center;
            position: absolute;
            width: 40px;
            height: 40px;
            font-size: 22px;
            color: color('800', 'gray');

            @media (max-width: 1200px) {
                display: flex;
                position: absolute;
                z-index: 1;

                &.detail__nav--prev {
                    top: 50%;
                    left: 0;
                    margin-top: -20px;
                }

                &.detail__nav--next {
                    top: 50%;
                    right: 0;
                    margin-top: -20px;
                }
            }
        }
    }

    .detail__icon__inner {
        touch-action: auto !important;
        @include flex(1);
        img {
            height: auto;
        }

        .animated-icon {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .prev,
    .next {
        color: color('900', 'blue');
        font-size: $font-size-3xl;
    }

    .main-icon {
        img {
            display: block;
            margin: auto;
        }
    }
    @include breakpoint('lg') {
        margin: 20px 20px 0;
    }
}

.premium-user {
    .download {
        .btn {
            background-color: color('500', 'yellow');
            color: #fff;

            &:hover {
                background-color: color('600', 'yellow');
            }

            &:active,
            &.active {
                background-color: color('700', 'yellow');
            }

            &.btn-outline {
                box-shadow: inset 0px 0px 0px 2px color('500', 'yellow');
                color: color('500', 'yellow');

                background: none;

                &:hover {
                    background-color: color('500', 'yellow');
                    color: #fff;
                }

                &:active,
                &.active {
                    background-color: color('600', 'yellow');
                }
            }
        }

        .btn-size {
            .popover-button {
                box-shadow: none;
                color: #fff;
                background: color('600', 'yellow');
            }
        }
    }
}

// Cuando se afiance la ff_raw_svg este código debe ser eliminado
.download {
    .btn-size {
        @include flex(1.5);

        .btn {
            padding: 0.75em 0;
            border-radius: $base-border-radius 0 0 $base-border-radius;
        }
        .popover-button {
            font-size: 10px;
            padding: 0 3px;
            border-radius: 0 $base-border-radius $base-border-radius 0;
            box-shadow: none;
            height: $base-element-height;
            background: color('600', 'green');
            color: #fff;
            min-width: 44px;
        }

        .popover-content {
            width: 64px;
            z-index: 5;

            a {
                display: block;
                padding: 5px;
            }
        }

        span {
            height: $base-element-height;
            line-height: $base-element-height;
            display: inline-block;
            vertical-align: bottom;
        }

        i {
            height: $base-element-height;
            line-height: $base-element-height;
            display: inline-block;
        }
    }
    .btn-svg {
        @include flex(1.5);

        .btn[data-format='raw-svg'] {
            border-radius: $base-border-radius 0 0 $base-border-radius;
        }

        .btn {
            padding: 0.75em 0;
            border-radius: $base-border-radius;
            &.bj-button--primary + .popover .popover-button {
                background-color: color('600', 'green');
            }
            &.btn-warning + .popover .popover-button {
                background-color: color('500', 'yellow');
            }
        }
        .popover-button {
            font-size: 10px;
            padding: 0 3px;
            border-radius: 0 $base-border-radius $base-border-radius 0;
            box-shadow: none;
            height: $base-element-height;
            color: #fff;
        }

        .popover-content {
            width: 282px;
            z-index: 5;
            padding: 0;
            color: color('400', 'gray');
            &::before {
                right: 11px;
            }
            &::after {
                right: 9px;
            }

            li {
                a {
                    display: flex;
                    align-items: flex-start;
                    gap: 10px;
                    svg {
                        width: 25px;
                    }
                    div {
                        flex: 1;
                        color: color('800', 'gray');
                        span {
                            height: unset;
                            line-height: unset;
                            vertical-align: baseline;
                        }
                        .badge {
                            background-color: color('400', 'green');
                            color: white;
                            border-radius: $base-border-radius;
                            line-height: 1.1;
                            padding: 4px;
                        }
                        p {
                            font-weight: $font-weight-regular;
                        }
                    }
                }
                &:hover {
                    background-color: color('75', 'gray');
                    svg {
                        path:first-child {
                            fill: white;
                        }
                    }
                }
            }
        }

        span {
            height: $base-element-height;
            line-height: $base-element-height;
            display: inline-block;
            vertical-align: bottom;
        }
    }

    .popover--download {
        .popover-button {
            padding: 0.75em 0;
        }

        .popover-content {
            width: 140px;
            right: -12px;
        }

        a {
            display: block;
        }
    }
}
// FIN -- Cuando se afiance la ff_raw_svg este código debe ser eliminado

.download.svg-editable {
    .btn-size,
    .btn-svg {
        @include flex(1.5);

        .btn {
            padding: 0.75em 0;
            border-radius: $base-border-radius 0 0 $base-border-radius;
            white-space: normal;
        }
        .popover-button {
            font-size: 10px;
            padding: 0 3px;
            border-radius: 0 3px 3px 0;
            box-shadow: none;
            height: $base-element-height;
            background: color('600', 'green');
            color: #fff;
            &.btn-warning {
                background: color('500', 'yellow');
            }
        }

        .popover-content {
            width: auto;
            padding: $base-spacing 0 $small-spacing;
            p {
                color: color('500', 'gray');
                line-height: 1.4;
            }
            > p {
                margin-bottom: $extra-small-spacing;
                padding: 0 $base-spacing;
            }
            > a {
                padding: $small-spacing $base-spacing;
                display: block;
                background-color: transparent;
                border-radius: 0;
            }
            > a:hover {
                background-color: color('50', 'gray');
            }
            .size {
                display: flex;
                padding: 0 $base-spacing;
                li {
                    a {
                        display: block;
                        padding: $extra-small-spacing $small-spacing;
                        &.active {
                            background-color: color('75', 'gray');
                            border-radius: $base-border-radius;
                        }
                        &:hover {
                            background-color: color('50', 'gray');
                        }
                    }
                }
            }
        }

        span {
            height: $base-element-height;
            line-height: $base-element-height;
            display: inline-block;
            vertical-align: bottom;
            &.badge {
                padding: $extra-small-spacing;
                background-color: color('500', 'green');
                border-radius: $base-border-radius;
                padding: 0 5px;
                font-size: 12px;
                height: 20px;
                line-height: 20px;
                vertical-align: top;
                color: white;
            }
        }

        i {
            height: $base-element-height;
            line-height: $base-element-height;
            display: inline-block;
        }
    }

    .btn-size {
        .popover-content {
            left: -135px;
            width: fit-content;
            padding: 20px 0;
            &::before {
                left: 135px;
            }
            &::after {
                left: 133px;
            }
        }
    }

    .btn-svg {
        .btn {
            > i::before {
                margin-top: -2px;
            }
        }
        .popover-content {
            width: 350px;
            &::before {
                right: 1px;
            }
            &::after {
                right: -1px;
            }
            .font-md {
                color: color('800', 'gray');
            }
        }
        i {
            line-height: unset;
            height: unset;
        }
        i::before {
            margin-top: -9px;
        }
    }

    .popover--download {
        .popover-button {
            padding: 0.75em 0;
        }

        .popover-content {
            width: auto;
            right: 0px;
            padding: $base-spacing 0 $small-spacing;
            > ul {
                > p {
                    padding: 0 $base-spacing;
                    margin-bottom: $extra-small-spacing;
                }

                li {
                    a {
                        padding: $small-spacing $base-spacing;
                    }
                    a:hover {
                        background-color: color('50', 'gray');
                    }
                }
            }

            &::before {
                right: 12px;
            }
            &::after {
                right: 10px;
            }
            p {
                color: color('500', 'gray');
            }
            ul > p {
            }
        }

        a {
            display: flex;
            &.btn {
                padding: 0;

                background-color: transparent;
                i::before {
                    vertical-align: baseline;
                    margin-top: -1px;
                }
                p {
                    color: color('800', 'gray');
                    span {
                        vertical-align: top;
                        color: color('700', 'gray');
                    }
                }
            }
        }
    }
}

.detail__sidebar {
    &__pack {
        max-width: 470px;
        margin: auto;
        border-bottom: 1px solid color('100', 'gray');

        .pack-box {
            flex-wrap: nowrap;

            .pack-holder {
                width: 150px;
                height: 0;
                padding-bottom: 100px;
                overflow: hidden;
                margin-right: 10px;

                @media (max-width: 450px) {
                    height: 135px;

                    img {
                        width: 215px;
                        height: auto;
                        max-width: inherit;
                    }
                }
            }

            .pack-register {
                flex: 1;
            }

            .bj-button {
                background-color: color('800', 'blue');
                padding: 0 10px;

                &:hover {
                    background-color: color('900', 'blue');
                }

                &.mobile {
                    display: none !important;
                }

                @media (max-width: 360px) {
                    &.normal {
                        display: none !important;
                    }

                    &.mobile {
                        display: inline-flex !important;
                    }
                }
            }
        }
    }

    &__banner-premium {
        &.test--a {
            padding: 20px 20px 0;

            a {
                color: color('800', 'blue');
                padding: 10px 20px 10px 10px;
                background-color: color('500', 'yellow', 0.1);
                border-radius: $base-border-radius;
                display: block;
            }
        }

        &.test--b {
            a {
                color: color('600', 'gray');
                display: block;
                padding: $base-spacing;
                border-bottom: 1px solid color('100', 'gray');

                strong {
                    color: color('800', 'gray');
                }

                span {
                    color: color('700', 'yellow');
                }
            }
        }

        &.cart {
            padding: 20px 20px 0;

            a {
                color: color('800', 'blue');
                padding: 10px 20px 10px 10px;
                background-color: color('500', 'yellow', 0.1);
                border-radius: $base-border-radius;
                display: block;
            }
        }

        i {
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background-color: #ffb229;
            color: #fff;
            border-radius: 100%;

            &:before {
                content: '';
                display: block;
                background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23FF7E00' d='M4.3 6.594L0 3.682l1.464 8.37h12.914l1.48-8.386-4.3 2.928L7.914.892z'/%3E%3Cpath fill='%23FFF' d='M4.3 6.595L0 3.682l1.464 8.37h12.915l1.48-8.386-4.301 2.929L7.913.892z'/%3E%3Cpath fill='%23FF9200' d='M1.563 15.89h12.732v-2.425H1.563z'/%3E%3Cpath fill='%23FFF' d='M1.563 15.89h12.732v-2.425H1.563z'/%3E%3C/g%3E%3C/svg%3E");
                background-size: 100%;
                width: 16px;
                height: 16px;
                margin: auto;
                position: relative;
                top: 10px;
            }
        }

        @media (max-width: 420px) {
            text-align: center;

            i {
                display: none;
            }

            .col {
                flex: none;
                width: 100%;
            }

            span {
                margin-top: 10px;
                background-color: #ffb229;
                color: #fff;
                height: 32px;
                line-height: 32px;
                border-radius: $base-border-radius;
                padding: 0 10px;
                margin: 10px auto 0;
            }

            &.test--b {
                a span {
                    color: #fff;
                }
            }
        }
    }

    @media (max-width: 992px) {
        width: 100%;
        body[data-section='icon-detail'] & {
            padding: 20px 0;
        }
    }

    .add-to-collection {
        &.btn-danger {
            box-shadow: inset 0px 0px 0px 2px color('500', 'red');
            color: color('500', 'red');
            background: none;

            &:hover {
                background-color: color('500', 'red', 0.1);
            }

            &:active,
            &.active,
            &:focus {
                background-color: color('500', 'red', 0.2);
            }
        }
    }

    .btn-square {
        width: $base-element-height;
        height: $base-element-height;

        position: relative;
        top: 0;
        left: 0;

        display: inline-block;

        padding: 0;

        box-shadow: inset 0 0 0 2px color('100', 'gray');
    }

    .list--check {
        padding-left: $base-spacing;
    }

    .author__holder {
        border-top: 1px solid color('100', 'gray');
        padding: $small-spacing 0;
    }

    .more-icons {
        border-top: 1px solid color('100', 'gray');

        p:last-child {
            margin-bottom: 0;
        }

        a {
            text-transform: capitalize;
        }
    }

    .fi-not-premium-icon {
        width: 100%;
    }

    .attr-required {
        color: color('800', 'gray');
    }

    .license {
        .editorial-license {
            text-align: left;
            line-height: 1.4;
            padding: $small-spacing;
            background: rgba(74, 210, 149, 0.1);
            border-radius: $base-border-radius;
            h6 {
                margin: 0 0 $extra-small-spacing;
            }
            .link--text {
                color: color('800', 'green');
            }
        }
    }
}

.collections-visible {
    @media (max-width: 1312px) {
        .detail__top {
            display: flex;
            flex-direction: column;
        }

        .detail__sidebar {
            width: 100%;
        }
    }
}

.btn-upgrade {
    padding: 20px 30px !important;
    white-space: normal;
    width: 100%;
    height: auto !important;
    line-height: normal !important;
    font-size: 14px;
    display: block;

    .mobile-copy {
        display: none;
    }

    span {
        display: block;
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 5px;
    }
    i {
        vertical-align: text-top;
        font-weight: $font-weight-regular;
    }
}

.ssm_adunit_container {
    position: relative;
}
