.modal-report {

    .modal-inner {
      max-width: 600px;
  
      button {
        margin-left: $small-spacing;
      }
  
      .radio-holder {
        @include clearfix;
  
        margin-bottom: $base-spacing;
  
        .radio-group {
          margin-bottom: $small-spacing;
        }
  
        .textarea-group textarea {
          height: 80px;
        }
  
        @include breakpoint ('sm') {
          .col-6 {
            width: 100%;
            float: none;
          }
        }
      }
    }
  }
  