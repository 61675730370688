.popover {
    display: inline-block;

    .popover-container {
        position: relative;
        display: inline-block;
    }

    .popover-content {
        position: absolute;
        z-index: 1;
        top: $extra-large-spacing;
        right: 0;
        display: none;
        overflow: visible;
        width: 228px;
        margin: 0;
        padding: 8px;
        border: 1px solid color('100', 'gray');
        border-radius: $base-border-radius;
        background: #ffffff;
        box-shadow: 0 0 60px rgba(14, 42, 71, 0.25);
        animation: popover 0.2s ease-in-out;

        &:before {
            font-size: 1.4em;
            display: block;
            width: 0;
            height: 0;
            content: '';
            pointer-events: none;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #999;
            border-left: 10px solid transparent;
            position: absolute;
            top: -0.45em;
            right: 0.97em;
        }

        &:after {
            font-size: 1.4em;
            display: block;
            width: 0;
            height: 0;
            content: '';
            pointer-events: none;
            border-right: 12px solid transparent;
            border-bottom: 12px solid #ffffff;
            border-left: 12px solid transparent;
            position: absolute;
            top: -0.45em;
            right: 0.9em;
        }
    }

    .show-menu {
        display: block;
    }

    &-top {
        .popover-content {
            top: auto;
            bottom: $extra-large-spacing;

            &:before {
                border-top: 10px solid #000;
                border-bottom: none;
                position: absolute;
                top: 1em;
                right: -0.45em;
            }
            &:after {
                border-top: 12px solid #ffffff;
                border-bottom: none;
                position: absolute;
                top: 0.9em;
                right: -0.45em;
            }
        }
    }

    &-right {
        .popover-content {
            right: auto;
            left: 0;

            &:before {
                position: absolute;
                left: 0.95em;
            }
            &:after {
                position: absolute;
                left: 0.85em;
            }
        }
    }

    &-hover {
        .popover-button:hover {
            ~ .popover-content {
                display: block;
            }
        }
    }
}

@keyframes popover {
    0% {
        opacity: 0;
        transform: translateY(-30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
