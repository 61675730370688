.landing--register {
    height: 100vh;

    .row--main {
        height: 100%;
    }

    .left {
        height: 100%;
        max-height: 100vh;
        min-width: 40%;
        transition: width 600ms cubic-bezier(0.165, 0.84, 0.44, 1);

        &__content {
            max-width: 680px;
            margin: auto;
            padding: 30px 80px;
            height: 100%;
        }
    }

    .right {
        background-color: gray;
        height: 100%;
        flex: 1;
        position: relative;
    }

    .form-holder {
        max-width: 340px;
        margin: auto;
        position: relative;
        z-index: 2;

        .section-switch {
            display: none;
            appearance: none;
            -webkit-appearance: none;
        }
    }

    .social-holder {
        a {
            padding: 0;
        }
    }

    .separator {
        @extend .row;
        margin: $small-spacing 0;

        &:before,
        &:after {
            content: '';
            flex: 1;
            height: 1px;
            background-color: color('300', 'gray');
            align-self: center;
        }

        span {
            display: block;
            width: 36px;
        }
    }

    .bj-button--google {
        background-color: #fff;
        box-shadow: inset 0 0 0 1px color('300', 'gray');

        &:hover {
            background-color: color('50', 'gray');
        }
    }

    .icon--google::before {
        content: '';
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBkPSJNMTEzLjQ3IDMwOS40MDhMOTUuNjQ4IDM3NS45NGwtNjUuMTM5IDEuMzc4QzExLjA0MiAzNDEuMjExIDAgMjk5LjkgMCAyNTZjMC00Mi40NTEgMTAuMzI0LTgyLjQ4MyAyOC42MjQtMTE3LjczMmguMDE0TDg2LjYzIDE0OC45bDI1LjQwNCA1Ny42NDRjLTUuMzE3IDE1LjUwMS04LjIxNSAzMi4xNDEtOC4yMTUgNDkuNDU2LjAwMiAxOC43OTIgMy40MDYgMzYuNzk3IDkuNjUxIDUzLjQwOHoiIGZpbGw9IiNmYmJiMDAiLz48cGF0aCBkPSJNNTA3LjUyNyAyMDguMTc2QzUxMC40NjcgMjIzLjY2MiA1MTIgMjM5LjY1NSA1MTIgMjU2YzAgMTguMzI4LTEuOTI3IDM2LjIwNi01LjU5OCA1My40NTEtMTIuNDYyIDU4LjY4My00NS4wMjUgMTA5LjkyNS05MC4xMzQgMTQ2LjE4N2wtLjAxNC0uMDE0LTczLjA0NC0zLjcyNy0xMC4zMzgtNjQuNTM1YzI5LjkzMi0xNy41NTQgNTMuMzI0LTQ1LjAyNSA2NS42NDYtNzcuOTExaC0xMzYuODlWMjA4LjE3NmgyNDUuODk5eiIgZmlsbD0iIzUxOGVmOCIvPjxwYXRoIGQ9Ik00MTYuMjUzIDQ1NS42MjRsLjAxNC4wMTRDMzcyLjM5NiA0OTAuOTAxIDMxNi42NjYgNTEyIDI1NiA1MTJjLTk3LjQ5MSAwLTE4Mi4yNTItNTQuNDkxLTIyNS40OTEtMTM0LjY4MWw4Mi45NjEtNjcuOTFjMjEuNjE5IDU3LjY5OCA3Ny4yNzggOTguNzcxIDE0Mi41MyA5OC43NzEgMjguMDQ3IDAgNTQuMzIzLTcuNTgyIDc2Ljg3LTIwLjgxOGw4My4zODMgNjguMjYyeiIgZmlsbD0iIzI4YjQ0NiIvPjxwYXRoIGQ9Ik00MTkuNDA0IDU4LjkzNmwtODIuOTMzIDY3Ljg5NkMzMTMuMTM2IDExMi4yNDYgMjg1LjU1MiAxMDMuODIgMjU2IDEwMy44MmMtNjYuNzI5IDAtMTIzLjQyOSA0Mi45NTctMTQzLjk2NSAxMDIuNzI0bC04My4zOTctNjguMjc2aC0uMDE0QzcxLjIzIDU2LjEyMyAxNTcuMDYgMCAyNTYgMGM2Mi4xMTUgMCAxMTkuMDY4IDIyLjEyNiAxNjMuNDA0IDU4LjkzNnoiIGZpbGw9IiNmMTQzMzYiLz48L3N2Zz4=);
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 100%;
        display: block;
        width: 16px;
        height: 16px;
    }

    .welcome {
        display: none;
        padding: $base-spacing;
        height: 100%;

        .img-welcome {
            margin: 70px auto 60px;
            display: block;
        }

        &__text {
            max-width: 340px;
            margin: auto;
        }
    }

    &.finished {
        .left {
            width: 100%;
            height: 100%;

            &__content {
                display: none;
            }
        }

        .welcome {
            display: block;
        }
    }

    @include breakpoint('xl') {
        .left {
            &__content {
                max-width: 540px;
                padding: 30px;
            }
        }
    }

    @include breakpoint('lg') {
        .row--main {
            flex-direction: column-reverse;
        }

        .left {
            height: auto;
            width: 100%;

            &__content {
                padding-bottom: 0;
            }
        }

        .right {
            width: 100%;
            height: auto;

            &:before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: color('900', 'blue');
                opacity: 0.1;
                z-index: 1;
            }
        }

        header {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding: 50px 30px;
            z-index: 1;
            color: color('text-inverted', 'general');
            z-index: 2;

            p {
                color: color('text-inverted', 'general');
            }
        }

        .profile-login {
            display: none;
        }

        .form-holder {
            input:checked {
                + .social-holder {
                    display: none;
                }
                ~ .profile-login {
                    display: block;
                }
                ~ label {
                    display: none !important;
                }
                ~ .already {
                    display: none;
                }
            }
        }
    }

    @include breakpoint('md') {
        header {
            padding: 30px;
        }

        .right {
            &:before {
                opacity: 0.5;
            }
        }
    }

    @include breakpoint('sm') {
        .logo {
            width: 120px;
            margin: auto;
        }

        .left {
            .left__content {
                padding: 20px 30px 0;
            }
        }
    }
}

.profile-login {
    position: relative;

    label[for='email-signup'] {
        position: absolute;
        top: 0;
        left: 0;
        margin: $extra-small-spacing;
    }

    .group {
        @extend .row;
        overflow: visible;
        background-color: #fff;
        margin: 0 0 10px;
        position: relative;
        border-radius: $base-border-radius;

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            border-radius: $base-border-radius;
            box-shadow: inset 0 0 0 1px color('200', 'gray');
            content: '';
            z-index: 2;
        }

        > * {
            border-right: 1px solid color('200', 'gray');
            border-color: color('300', 'gray');
            align-self: stretch;
        }

        &__context {
            padding: 0;
        }

        &__input input {
            flex: 1;
            height: $base-element-height;
            margin: 0;
            border: none;
            padding: 0 10px;
            line-height: $base-element-height;
        }

        .password {
            flex: 1;
        }

        .password__toggle {
            right: 0;
        }

        > input {
            flex: 1;
            height: $base-element-height;
            margin: 0;
            border: none;
            padding: 0 10px;
            line-height: $base-element-height;
        }

        > *:last-child {
            border: none;
            border-top-right-radius: $base-border-radius;
            border-bottom-right-radius: $base-border-radius;
        }
    }
}

.register-slider {
    height: 100%;

    li {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        pointer-events: none;
        transition: opacity 600ms cubic-bezier(0.165, 0.84, 0.44, 1);

        &.active {
            opacity: 1;
            pointer-events: all;
        }
    }

    &__img {
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            pointer-events: none;
        }
    }

    &__text {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 70px 60px 90px;
        color: color('text-inverted', 'general');
        background-image: linear-gradient(to bottom, transparent 0%, color('900', 'blue') 100%);
    }

    &__nav {
        position: absolute;
        left: 60px;
        bottom: 50px;

        li {
            margin-right: $base-spacing;

            &:last-child {
                margin-right: 0;
            }

            button {
                display: block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: rgba(255, 255, 255, 0.6);

                &:hover {
                    background-color: rgba(255, 255, 255, 0.8);
                }
            }

            &.active {
                button {
                    background-color: color('600', 'green');
                }
            }
        }
    }

    &__arrows {
        position: absolute;
        right: 60px;
        bottom: 50px;
        border: 1px solid #fff;

        button {
            border-radius: 0;

            &:hover {
                background-color: color('light', 'background', 0.2);
            }

            &:first-child {
                border-right: 1px solid #fff;
            }
        }
    }

    @include breakpoint('lg') {
        &__text {
            display: none;
        }

        &__nav {
            left: 50%;
            bottom: 20px;
            transform: translateX(-50%);
            z-index: 2;
        }
    }
}
