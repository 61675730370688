aside.filters {
    ::-webkit-scrollbar {
        width: 3px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: color('400', 'gray');
        border-radius: 20px;
        border: 3px solid transparent;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
    }

    #form_filters {
        height: 100%;
    }

    margin-right: 40px;
    border-right: 1px solid color('100', 'gray');
    @include breakpoint('lg') {
        margin-right: 0;
        border-right: none;
    }

    .search-view-filters-aside {
        position: sticky;
        top: 118px;
        display: block;
        font-size: $font-size-sm;
        margin-bottom: 10px;
        width: 300px;

        .col {
            flex: unset;
            padding: 0;
            margin: 0;
            &.sort {
                margin-left: auto;
            }
        }
        .circle-spinner {
            position: absolute;
            left: 45%;
            top: 50%;
        }
        .container-filters {
            display: flex;
            flex-wrap: wrap;

            .filters-body {
                width: 100%;
                height: auto;
                padding-bottom: 0;
                top: 0;
            }
        }

        @include breakpoint('xs') {
            width: 100%;
        }

        .popover {
            .popover-button {
                height: $small-element-height;
                line-height: $small-element-height;
                font-weight: $font-weight-regular;
                padding: 0 $base-spacing;
                position: relative;
                color: color('800', 'gray');
                background-color: color('100', 'gray');
                flex-wrap: nowrap;
                &:hover {
                    background-color: color('200', 'gray');
                    color: color('800', 'gray');
                }

                &:focus {
                    color: color('800', 'gray');
                }
                &.active {
                    background-color: color('100', 'gray');
                    color: color('800', 'gray');
                }
            }
            .popover-content {
                right: 0;
                min-width: 100%;
                padding: 0 8px;
                width: unset;
                .radio-group {
                    margin: $small-spacing 0;
                    flex-wrap: nowrap;
                    label {
                        white-space: nowrap;
                    }
                }

                .switch {
                    line-height: $base-element-height;
                    flex-wrap: nowrap;
                }
                .switch__link {
                    white-space: nowrap;
                }
                &::before,
                &::after {
                    right: unset;
                }
                &::before {
                    display: none;
                }
            }
        }
        &__group {
            .item {
                padding: 0;
                min-height: $small-element-height;
                align-items: stretch;
                display: flex;
                border-radius: $base-border-radius;
                margin: 0;
                text-transform: none;
                font-weight: inherit;
                white-space: nowrap;
                position: relative;

                &.disabled {
                    pointer-events: auto !important;
                    cursor: not-allowed !important;
                    @include breakpoint('xs') {
                        pointer-events: none !important;
                    }
                }
            }
            .item__link {
                border: 1px solid color('100', 'gray');
                height: unset;
                display: flex;
                align-items: center;
                padding: 0 $base-spacing;
                color: color('800', 'gray');
                min-height: 30px;
                cursor: pointer;
                border-radius: 8px;
            }
            .item__link:hover {
                border: 1px solid color('200', 'gray');
            }
            input[type='radio'],
            input[type='checkbox'] {
                display: none;
            }
            input[type='radio']:checked ~ .item__link,
            input[type='checkbox']:checked ~ .item__link,
            label[for^='switch_search_by'].active span {
                color: color('800', 'gray');
                border-radius: 8px;
                border: 1px solid color('500', 'green');
            }
            input[type='radio']:checked ~ .item__link:hover {
                cursor: default;
            }
        }
        .popover.sort {
            .popover-content {
                right: 0;
                width: unset;
                &::after {
                    right: 0.9em;
                }
            }
        }
        .flaticon-choice,
        .flaticon-craft,
        .raw-filter {
            margin-bottom: $base-spacing;
            .checkbox--xs {
                padding: 9px 0;
            }
            .checkbox input[type='checkbox']:checked ~ .checkbox__indicator {
                box-shadow: inset 0 0 0 64px color('600', 'green');
                i::before {
                    line-height: 1.1;
                }
            }
            .badge {
                border-radius: $base-border-radius;
                color: white;
                line-height: 1.1;
                padding: 4px;
            }
            .tooltip {
                position: static;
                .tooltip__content {
                    padding-bottom: 9px;
                    bottom: 95%;

                    .content {
                        border-radius: $base-border-radius;
                        background-color: white;
                        color: color('700', 'gray');
                        font-weight: $font-weight-regular;
                        white-space: normal;
                        width: 240px;
                        display: flex;
                        gap: 10px;
                        padding: 20px;
                        word-break: break-word;
                        box-shadow: 0 2px 30px rgba(29, 38, 45, 0.2);
                        div {
                            flex: 1;
                        }
                        a {
                            color: color('800', 'green');
                        }
                    }
                }
                i {
                    cursor: pointer;
                    &::after {
                        border-top: 6px solid white;
                        z-index: 335;
                        bottom: 83%;
                    }
                }
            }
        }

        .item-plain-color {
            &-selected {
                .item__link {
                    color: color('800', 'gray');
                    border-radius: 8px;
                    border: 1px solid color('500', 'green');
                }
            }

            &-opened {
                ~ .plain-colors {
                    display: flex;
                }
            }
        }

        .plain-colors {
            gap: 10px;
            flex-wrap: wrap;
            padding: 0 40px 0 5px;
            display: none;

            $filter-colors: (
                'red': '#FF0000',
                'blue': '#0000FF',
                'green': '#00FF00',
                'yellow': '#FFFF00',
                'magenta': '#FF00FF',
                'cyan': '#00FFFF',
                'rose': '#FF007F',
                'violet': '#7F00FF',
                'azure': '#007FFF',
                'spring-green': '#00FF7F',
                'chartreuse': '#DFFF00',
                'orange': '#FFA500',
                'black': '#000000',
                'gray': '#808080',
                'white': '#FFFFFF',
            );

            $filter-colors-check-color: (
                'white': color('800', 'gray'),
                'chartreuse': color('800', 'gray'),
            );

            &-item {
                display: inline;
                position: relative;
                width: 20px;
                height: 20px;
                border-radius: 100%;
                border: 1px solid color('200', 'gray');
                cursor: pointer;

                @each $name, $color in $filter-colors {
                    &-#{$name} {
                        background-color: #{$color};
                        span {
                            @if map-has-key($filter-colors-check-color, $name) {
                                border-color: map-get($filter-colors-check-color, $name);
                            } @else {
                                border-color: white;
                            }
                        }
                    }
                }

                span {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    display: inline-block;
                    font-size: 0;
                    width: 7px;
                    height: 11px;
                }

                input:checked ~ span {
                    border-style: solid;
                    border-width: 0 2px 2px 0;
                    transform: translate(-4px, -7px) rotate(45deg);
                }
            }
        }
    }

    .search-view-filters-aside {
        > .container-filters {
            &.scroll {
                overflow: auto;
                padding-top: 10px;

                // si NO se ha hecho scroll
                max-height: calc(100vh - 402px);
                min-height: 300px;

                // si se ha hecho scroll
                #viewport.has-scrolled & {
                    max-height: calc(100vh - 180px);
                }
                // si estamos en la vista de pack search, no tenemos adobe banner
                body[data-section='pack-search'] &,
                body[data-section='style-search'] &,
                body[data-section='404'] & {
                    max-height: calc(100vh - 180px);
                }
                @include breakpoint('lg') {
                    max-height: calc(100vh - 72px);
                    padding-right: 10px;
                    #viewport.has-scrolled & {
                        max-height: calc(100vh - 72px);
                    }
                }
            }
        }

        .close {
            display: flex;
            align-items: center;
            color: color('900', 'gray');
            border-bottom: 1px solid color('100', 'gray');
            margin-right: 20px;
            svg {
                margin-right: 10px;
            }

            .icon {
                color: color('900', 'gray');
                margin-left: auto;
                padding: 5px 0;
            }
        }

        .filters-body {
            .filters-body__container {
                position: relative;
                margin-right: 20px;

                .filters-body__link,
                .filters-body__content {
                    padding: 0;
                }
                .filters-body__link {
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;

                    svg {
                        margin-right: 10px;
                    }
                    p {
                        margin: 0;
                        + span {
                            color: color('500', 'green');
                        }
                    }
                    i {
                        margin-left: auto;
                    }
                    > i {
                        margin-left: unset;
                        &::before {
                            color: color('800', 'gray');
                        }
                    }
                    .tooltip i {
                        color: color('400', 'gray');
                    }
                    &.active {
                        + .filters-body__content {
                            margin-bottom: 30px;
                        }
                    }
                }
                .title {
                    display: inline;
                }

                @include breakpoint('lg') {
                    margin-right: 0;
                }
            }
            .flaticon-craft,
            .raw-filter {
                .search-view-filters-aside__group {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                }
            }
            .filters-applied {
                margin-right: 20px;
                &__header {
                    display: flex;
                    justify-content: space-between;
                    .clear-all {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                    }
                }
                @include breakpoint('lg') {
                    margin-right: 0;
                }
            }
            .labels {
                display: flex;
                flex-wrap: wrap;
                .filterLabels {
                    max-width: 1050px;
                    text-align: left;
                    display: flex;
                    align-items: baseline;
                    flex-wrap: wrap;
                    gap: 10px;
                    justify-content: flex-start;
                    .filterLabel-tag {
                        padding: 0 $small-spacing;
                        background-color: color('500', 'green');
                        border-radius: $base-border-radius;
                        color: white;
                        cursor: pointer;
                        i {
                            display: inline-block;
                            width: unset;
                            height: unset;
                            line-height: 17px;
                            &::before {
                                font-weight: $font-weight-bold;
                                vertical-align: text-bottom;
                                margin-left: $extra-small-spacing;
                                font-size: 12px;
                                color: white;
                            }
                        }
                    }
                }
            }
            hr {
                margin-top: 10px;
            }
        }
        .search-view-filters-aside__group {
            .item {
                line-height: 1;
            }
            .item__link {
                padding: 0 15px;
                min-height: 34px;
                font-weight: $font-weight-regular;
                &.active {
                    background-color: #fff;
                    color: color('800', 'gray');
                    border: 1px solid color('500', 'green');
                }
            }
        }
        > :not(.search-view-filters-aside__group) {
            .container-filters {
                gap: 10px;
            }
        }

        .filters-applied {
            margin-right: 20px;
            &__header {
                display: flex;
                justify-content: space-between;
                .clear-all {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                }
            }
            @include breakpoint('lg') {
                margin-right: 0;
            }
        }
        .labels {
            display: flex;
            flex-wrap: wrap;
            .filterLabels {
                max-width: 1050px;
                text-align: left;
                display: flex;
                align-items: baseline;
                flex-wrap: wrap;
                gap: 10px;
                justify-content: flex-start;
                .filterLabel-tag {
                    padding: 0 $small-spacing;
                    background-color: color('500', 'green');
                    border-radius: $base-border-radius;
                    color: white;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    i {
                        display: inline-block;
                        width: unset;
                        height: unset;
                        line-height: 17px;
                        &::before {
                            font-weight: $font-weight-bold;
                            vertical-align: text-bottom;
                            margin-left: $extra-small-spacing;
                            font-size: 12px;
                            color: white;
                        }
                    }
                }
            }
        }
        hr {
            margin-top: 10px;
        }
    }

    .collapsed-filters {
        display: none;
        width: 80px;
        position: sticky;
        top: 118px;
        height: fit-content;
        justify-content: center;
        flex-direction: column;
        &__close {
            display: flex;
            flex-direction: column;
            gap: 20px;
            > i {
                color: color('800', 'gray');
                &::after {
                    display: none;
                }
                &.active {
                    position: relative;
                    &::after {
                        display: block;
                        position: absolute;
                        content: '';
                        width: 6px;
                        height: 6px;
                        background-color: color('500', 'green');
                        top: 2px;
                        right: 5px;
                        border-radius: 100%;
                    }
                }
            }
        }
        &__item {
            width: 40px;
            height: 40px;
            display: grid;
            justify-items: center;
            align-items: center;
            border-radius: 10px;
            border: 1px solid color('200', 'gray');
            &.editable_stroke {
                svg {
                    fill: color('100', 'gray');
                }
            }
            svg,
            i {
                fill: color('900', 'gray');
                color: color('900', 'gray');
            }
            &.active {
                border: 1px solid color('500', 'green');
            }
        }
    }

    input[type='checkbox']#search__filters ~ .search__filters--overlay {
        display: none;
    }
    input[type='checkbox']#search__filters:checked ~ .search__filters--overlay {
        display: block;
        width: 100vw;
        height: 100vh;
        background: color('800', 'blue', 0.9);
        position: fixed;
        top: 0px;
        z-index: 25;
        animation: popover 0.2s ease-in-out;
        @include breakpoint('lg', 'min') {
            display: none;
        }
    }

    input[type='checkbox']#search__filters:not(:checked) {
        ~ #form_filters {
            width: 0;
            height: 0;
            overflow: hidden;
            transition: width 150ms linear;
            .search-view-filters-aside {
                transition: width 250ms linear;
                transition: opacity 150ms linear;
                width: 0;
                opacity: 0;
            }
        }
        ~ .collapsed-filters {
            display: flex;
        }
    }
    input[type='checkbox']#search__filters:checked {
        ~ #form_filters {
            display: block;
            width: 300px;
            height: 100%;
            overflow: unset;
            transition: width 150ms linear;
            .search-view-filters-aside {
                transition: width 150ms linear;
                transition: opacity 250ms linear;
                width: 300px;
                opacity: 1;
            }
        }
        ~ .collapsed-filters {
            display: none;
        }
    }

    @include breakpoint('lg') {
        input[type='checkbox']#search__filters:checked {
            ~ #form_filters {
                display: none;
            }
            ~ .search__filters--overlay {
                display: none;
            }
        }

        input[type='checkbox']#search__filters:checked.show-filters-in-mobile {
            ~ #form_filters {
                display: block;
                width: 0;
            }
            ~ .search__filters--overlay {
                display: block;
            }
            ~ #form_filters .search-view-filters-aside {
                position: fixed;
                top: 0;
                left: 0;
                z-index: 30;
                background-color: white;
                width: 300px;
                animation: popover 0.2s ease-in-out;
                padding: 10px 0 0 20px;
                height: 100vh;
            }
        }
    }

    @include breakpoint('sm') {
        input[type='checkbox']#search__filters:checked.show-filters-in-mobile {
            ~ #form_filters .search-view-filters-aside {
                width: 100%;
            }
        }
    }
}
