$base-delay: 300ms;

.landing--icons-editor {
    #header {
        background: none;
        border-bottom: 1px solid color('body', 'background', 0.15);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        padding: $base-spacing;
    }

    .hero {
        background-size: 800% 800%;
        padding-top: 180px;
        position: relative;

        .gradient {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
        }

        .gradient--green {
            background: linear-gradient(270deg, #8df690, color('600', 'green'));
        }

        .gradient--purple {
            animation: editorHero 20s ease infinite;
            background: linear-gradient(270deg, color('400', 'purple'), color('800', 'purple'));
        }

        div {
            position: relative;
            z-index: 1;
        }

        a {
            box-shadow: inset 0px 0px 0px 100px color('800', 'blue');

            &:hover {
                box-shadow: inset 0px 0px 0px 100px color('800', 'blue');
            }
            &:focus,
            &:active {
                box-shadow: inset 0px 0px 0px 100px color('700', 'blue');
            }
        }

        img {
            max-width: 1000px;
            width: 100%;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: -1px;
            height: 325px;
            width: 100%;
            background: url(~Media/landing-editor/mask.svg) repeat-x -175px center;
        }
    }

    .steps {
        margin-bottom: 200px;
        padding-top: 100px;

        ol {
            counter-reset: steps-counter;

            li {
                counter-increment: steps-counter;
                position: relative;
                padding-left: 70px;
                margin-bottom: $extra-large-spacing;

                &:before {
                    content: counter(steps-counter);
                    color: color('75', 'gray');
                    font-weight: $font-weight-bold;
                    font-size: 40px;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    border: 1px solid color('75', 'gray');
                    display: block;
                    text-align: center;
                    line-height: 50px;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    font-family: $title-font-family;
                }
            }
        }
    }

    .example {
        margin-bottom: 100px;

        input {
            display: none;
            appearance: none;

            &:nth-of-type(1):checked {
                ~ .slider {
                    li {
                        &:nth-child(1) img {
                            opacity: 1;
                        }
                    }
                }
                ~ .slider__controls {
                    li {
                        &:nth-child(1) label {
                            box-shadow: inset 0px 0px 0px 2px color('800', 'gray');
                        }
                    }
                }
            }
            &:nth-of-type(2):checked {
                ~ .slider {
                    li {
                        &:nth-child(2) img {
                            opacity: 1;
                        }
                    }
                }
                ~ .slider__controls {
                    li {
                        &:nth-child(2) label {
                            box-shadow: inset 0px 0px 0px 2px color('800', 'gray');
                        }
                    }
                }
            }
            &:nth-of-type(3):checked {
                ~ .slider {
                    li {
                        &:nth-child(3) img {
                            opacity: 1;
                        }
                    }
                }
                ~ .slider__controls {
                    li {
                        &:nth-child(3) label {
                            box-shadow: inset 0px 0px 0px 2px color('800', 'gray');
                        }
                    }
                }
            }
        }
    }

    .slider {
        padding: 0px 0px 0px 0px;

        li {
            position: absolute;
            top: 0;
            left: 0;
            padding: 72px 52px;
            width: 100%;
        }

        img {
            display: block;
            box-shadow: 0 0 10px color('700', 'blue', 0.15);
            margin: auto;
            opacity: 0;
            transition: opacity 300ms ease-in;
        }

        &__controls {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);

            label {
                display: block;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                margin: $base-spacing 0;
                cursor: pointer;

                &[for='slider--green'] {
                    background-color: color('300', 'green');
                }
                &[for='slider--purple'] {
                    background-color: color('200', 'purple');
                }
                &[for='slider--yellow'] {
                    background-color: color('200', 'yellow');
                }
            }
        }
    }

    .selector {
        margin: -36px auto 0;
        display: block;
    }

    #footer {
        background: none;
        min-height: 185px;
        border: none;
        position: relative;
        margin: 0;
        padding: 0;

        div {
            @include position(absolute, null null 0 0);

            width: 100%;
        }

        .editor-screen {
            position: relative;
            z-index: 2;
            max-width: 100%;
        }

        .footer--back {
            background: url(~Media/landing-editor/footer-back.svg) no-repeat bottom center;
            z-index: 1;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
        }
        .footer--front {
            background: url(~Media/landing-editor/footer-front.svg) no-repeat bottom center;
            z-index: 3;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
        }

        .footer--text {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            z-index: 4;
            padding: $base-spacing $base-spacing $small-spacing;
        }

        ul {
            margin-top: $small-spacing;
        }
        li {
            display: inline-block;
        }
        li a {
            color: color('link-inverted', 'general');
            font-weight: $font-weight-regular;
        }
        li span {
            display: inline-block;
            margin: 0 10px;
        }
    }
    @include breakpoint('xl') {
        .hero,
        .steps {
            img {
                height: auto;
            }
        }
    }

    @include breakpoint('md') {
        #header {
            position: absolute;
        }

        h4 {
            text-align: center;
        }

        ol {
            padding: 0 $extra-large-spacing;
        }

        .steps {
            padding-top: 25px;
            margin-bottom: 50px;

            img {
                max-width: 100%;
            }
        }

        .example {
            .example__text {
                order: 0;
                text-align: center;
                margin-bottom: $extra-large-spacing;
            }
            .example__slider {
                order: 1;
            }
        }
    }

    @include breakpoint('sm') {
        .hero {
            padding-top: 120px;
        }

        ol {
            padding: 0;
        }

        .footer--text {
            font-size: $font-size-xs;
        }
    }

    @include breakpoint('xs') {
        .logo {
            width: auto;
        }
    }
}

section {
    .easeInBottom {
        opacity: 0;
        transform: translateY(100px);
        h2 {
            font-size: $font-size-6xl;
            @include breakpoint('md') {
                font-size: $font-size-4xl;
            }
        }
    }

    &.active {
        .easeInBottom {
            animation: easeInBottom 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;

            &.delay--1 {
                animation-delay: $base-delay;
            }

            &.delay--2 {
                animation-delay: $base-delay * 1.5;
            }

            &.delay--3 {
                animation-delay: $base-delay * 2;
            }
        }
    }
}

@keyframes editorHero {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
