.row-cards {
    margin-bottom: $extra-large-spacing + $small-spacing;
    body.home & {
        margin-bottom: 50px;
    }

    &__list {
        margin-bottom: $base-spacing * 2;

        .box-container {
            height: 260px;
            overflow: hidden;
            // width: calc(100% + 20px);
            // margin-left: -10px;
            .box-premium {
                .box__inner {
                    .badge-license {
                        left: $base-spacing;
                        bottom: $base-spacing;
                    }
                    footer {
                        .name {
                            margin-left: $base-spacing;
                        }
                    }
                }
            }
            .box__inner {
                a {
                    border-radius: 8px;
                }
                .img-holder {
                    img {
                        margin: $extra-small-spacing auto;
                    }
                }
                footer {
                    height: unset;
                    padding: $extra-small-spacing $base-spacing;
                    font-size: 13px;
                    > .row {
                        margin: 0;
                        .col:first-child {
                            padding-right: $extra-small-spacing;
                        }
                    }
                }
            }
        }
    }

    &__header {
        padding: 0 $small-spacing 0 0;
        align-items: flex-start;
        p {
            font-weight: 600;
            color: color('900', 'blue');
        }
        h4 {
            color: color('900', 'blue');
            line-height: $line-height-125;
        }
        .link--arrow {
            height: 35px;
            margin-top: 20px;
        }
    }

    @include breakpoint('lg') {
        &__list {
            .box-container {
                width: calc(100% + 10px);
                margin-left: -5px;
                margin-bottom: 22px;
                height: auto;
                .box:nth-child(n + 3) {
                    display: none;
                }
            }
        }
    }

    @include breakpoint('sm') {
        &__list {
            margin-bottom: $base-spacing + $small-spacing;
            .box-container {
                .box-premium {
                    .box__inner {
                        .badge-license {
                            left: $small-spacing;
                            bottom: $small-spacing + $extra-small-spacing;
                        }
                        footer {
                            .name {
                                margin-left: $small-spacing + $extra-small-spacing;
                            }
                        }
                    }
                }
                .box__inner {
                    footer {
                        padding: 0 $small-spacing;
                    }
                }
            }
        }
    }
    @include breakpoint('xs') {
        &__list {
            .box-container {
                .box-premium {
                    .box__inner {
                        .badge-license {
                            left: $small-spacing;
                            bottom: $small-spacing;
                        }
                    }
                }
            }
        }
    }
}
