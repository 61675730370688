#cancellation {
    background-color: color('50', 'gray');
    min-height: 650px;
}

.cancellation {
    background-color: color('50', 'gray');
    padding: 0 $base-spacing;
    overflow: hidden;

    .profile-menu {
        border-bottom: 1px solid color('100', 'gray');
        padding-top: $base-spacing;
        text-align: center;
        margin-bottom: $large-spacing;

        li {
            margin: 0 $base-spacing;

            &.active {
                border-bottom: 1px solid color('600', 'gray');
            }
        }

        a {
            padding: $small-spacing;
            display: block;
            color: color('heading', 'general');
        }

        i {
            display: inline-block;
            color: color('heading', 'general');
            margin-right: $extra-small-spacing;
        }
    }

    &__steps {
        width: 300px;
        margin: $extra-large-spacing auto 0;

        ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        li {
            flex: 1;
            position: relative;
            margin: 0;
            padding: $extra-small-spacing;
            color: color('500', 'gray');
            font-size: $font-size-sm;
            text-align: center;

            &.active {
                color: color('800', 'gray');
            }
        }

        .progress {
            overflow: hidden;
            position: relative;
            width: 100%;
            height: 3px;
            border-radius: $base-border-radius;

            &::after,
            &__bar {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            &::after {
                z-index: 1;
                background-color: color('200', 'gray');
                content: '';
            }

            &__bar {
                z-index: 2;
                background-image: linear-gradient(to right, $color-main, rgba($color-main, 0.5));
            }
        }
    }

    &__form {
        max-width: 680px;
        margin: 0 auto;
        padding: $extra-large-spacing 0;
        text-align: center;
        position: relative;

        &:after {
            content: '';
            display: block;
            width: 203px;
            height: 271px;
            background: url('~Media/cancellation/piki-sad.svg') no-repeat;
            position: absolute;
            bottom: 100px;
            right: -150px;
        }

        label {
            display: block;
            cursor: pointer;
        }

        @include breakpoint('md') {
            &:after {
                display: none;
            }
        }
    }

    .options {
        text-align: left;
        max-width: 450px;
        margin: 0 auto $large-spacing;
    }

    .radio-group {
        font-size: $font-size-md;
        color: color('heading', 'general');
        cursor: pointer;
        padding-left: $large-spacing;
        margin-bottom: $base-spacing;

        &:hover {
            label.fake-radio:before {
                box-shadow: inset 0 0 0 1px color('400', 'gray');
            }
        }

        label.fake-radio {
            width: 22px;
            margin-right: $extra-small-spacing;

            &:before {
                width: 22px;
                height: 22px;
                box-shadow: inset 0 0 0 1px color('300', 'gray');
            }
        }

        input[type='radio']:checked + label.fake-radio {
            &:before {
                box-shadow: inset 0 0 0 7px color('green', 'state');
            }
        }
    }

    &__banner {
        max-width: 800px;
        width: 100%;
        height: 300px;
        background: url('~Media/cancellation/banner-desktop.jpg') no-repeat;
        background-size: cover;
        margin: 0 auto $large-spacing;
        display: block;
        padding: $base-spacing;

        @include breakpoint('md') {
            max-width: 320px;
            background: url('~Media/cancellation/banner-mobile.jpg') no-repeat;

            p {
                font-size: $font-size-lg;
            }
        }
    }

    &__options__footer {
        width: 1230px;
        height: 525px;
        background: url('~Media/cancellation/resources-desktop.png') no-repeat;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        @include breakpoint('xs') {
            width: 522px;
            height: 416px;
            background: url('~Media/cancellation/resources-mobile.png') no-repeat;
        }
    }

    &__buttons {
        margin: 0 0 $extra-large-spacing 0;

        @include breakpoint('xs') {
            div {
                width: 100%;
                margin: $small-spacing 0;
                text-align: center;
            }

            .bj-button {
                min-width: 180px;
            }
        }
    }

    &__form__support__holder {
        margin-top: $extra-large-spacing;
    }

    &__form__support {
        input[type='text'],
        input[type='email'] {
            height: 44px;
            line-height: 44px;
            padding: 0 10px;
        }

        textarea {
            padding: 10px;
            height: 100px;
        }

        input[type='text'],
        input[type='email'],
        textarea {
            border: 1px solid color('300', 'gray');
            border-radius: 2px;

            &:hover {
                border-color: color('500', 'gray');
            }
        }

        @include breakpoint('xs') {
            .col {
                width: 100%;
                padding: 0 !important;
                flex: none;
            }
        }
    }

    &__features {
        margin-top: $extra-large-spacing;

        .container {
            padding: 0;
            max-width: 1200px;
        }

        @include breakpoint('lg') {
            .feature {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                margin-bottom: $base-spacing;
            }

            .text-holder {
                text-align: left;
                flex: 1;
                padding-right: 0;
            }
        }
    }

    &__thanks {
        padding-top: $extra-large-spacing * 2;
    }

    .quality--img {
        max-width: 1259px;
        position: relative;
        left: 50%;
        margin-left: -630px;
        display: block;

        @include breakpoint('sm') {
            max-width: 522px;
            margin-left: -261px;
        }
    }
}
