.tags-page {
    .tags-letter {
        margin-bottom: $extra-large-spacing;

        li {
            margin: 0 $small-spacing $small-spacing 0;

            &:last-child {
                margin-right: 0;
            }

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: color('100', 'gray');
                border-radius: $base-border-radius;
                border: 1px solid color('200', 'gray');
                color: color('900', 'blue');
                font-size: $font-size-sm;
                height: $small-element-height;
                width: $small-element-height;
                line-height: $small-element-height;
                text-align: center;

                &:hover {
                    background-color: color('200', 'gray');
                }
            }

            &.active {
                a {
                    background-color: color('800', 'blue');
                    color: #fff;
                    border-color: color('800', 'blue');
                    cursor: default;
                }
            }
        }
    }

    ul.tags {
        @include columns(3);
        @include column-gap(4em);
        @include column-rule(1px dotted color('100', 'gray'));
        margin-bottom: $extra-large-spacing;

        li {
            margin-bottom: $small-spacing;
            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid;

            a,
            span {
                display: inline-block;
                border-radius: $base-border-radius;
                color: #fff;
                padding: 0 $small-spacing;
                font-size: $font-size-sm;
                height: $extra-small-element-height;
                line-height: $extra-small-element-height;
            }

            a {
                background-color: color('900', 'blue');
            }

            span {
                background-color: color('100', 'gray');
                margin-left: auto;
                color: color('900', 'blue');
            }
        }
    }

    .pagination {
        margin-bottom: $extra-large-spacing;
    }

    @include breakpoint('lg') {
        ul.tags {
            @include columns(2);
        }
    }

    @include breakpoint('sm') {
        ul.tags {
            @include columns(1);
            @include column-gap(0);
            @include column-rule(none);
        }
    }
}
