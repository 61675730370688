.alert {
    border-radius: 4px;
    clear: both;
    color: #fff;
    font-size: $font-size-sm;
    position: relative;
    width: 100%;
    background-color: color('800', 'blue');
    color: #fff;

    &.success {
        background-color: color('600', 'green');
        color: color('800', 'blue');
    }

    &.danger {
        background-color: color('600', 'red');
        color: #fff;
    }

    &.warning {
        background-color: color('500', 'yellow');
        color: #fff;
    }

    i {
        @include position(absolute, 50% null null 0);

        @include transform(translateY(-50%));

        padding: 0 $small-spacing + $extra-small-spacing;
        text-align: center;
        height: 100%;

        &:before {
            font-size: $font-size-md;
            position: relative;
            top: 50%;

            @include transform(translateY(-50%));

            display: block;
        }
    }

    a {
        color: #fff;
        text-decoration: underline;
        font-weight: $font-weight-bold;
    }

    p {
        margin: 0;
        display: inline-block;
        vertical-align: middle;
        padding: ($small-spacing + $extra-small-spacing) ($extra-large-spacing + $base-spacing)
            ($small-spacing + $extra-small-spacing) ($large-spacing + $small-spacing);
        text-align: left;
        width: 100%;
    }

    .close-alert {
        color: #fff;
        font-size: $font-size-lg;
        padding: $extra-small-spacing ($small-spacing + $extra-small-spacing);
        border-radius: 0 4px 4px 0;
        text-decoration: none;

        @include position(absolute, 50% 0 null null);

        @include transform(translateY(-50%));

        @include transition(all 0.2s ease-in-out);

        background-color: rgba(#000, 0.15);
        height: 100%;

        &:hover {
            background-color: rgba(#000, 0.1);
            color: #fff;
        }

        span {
            display: block;
            position: relative;
            top: 50%;

            @include transform(translateY(-50%));
        }

        // & ~ p {
        // padding-right: $small-spacing+$extra-small-spacing;
        // }
    }
}
