.edge-content-iframe {
    display: block;
    padding: 1em;
    box-sizing: inherit;
    background: color('100', 'gray');
}

.edge-content {
    @include position(absolute, null null null 0);
    min-height: 90px;
    visibility: hidden;
    overflow: hidden;
    width: 100%;
    margin: $large-spacing 0;
    background: color('100', 'gray');

    &.bn-space {
        min-height: 300px;
    }

    &.fi-premium-edge-content-first {
        padding: 0;
    }
    &.last-edge-content {
        position: relative;
        margin: 0;
        border-top: darken(color('100', 'gray'), 10) 1px solid;
    }

    &.show {
        visibility: visible;
    }

    &.bn-space-aside {
        min-height: 300px;
    }

    .edge-content-iframe {
        padding: 1em 0;
    }

    header {
        margin-bottom: 1em;

        @include clearfix;

        .sponsor {
            float: left;
            font-size: 0.8em;
            font-weight: $font-weight-bold;
            color: color('900', 'blue');
            margin: 7px 7px 0 7px;
        }

        .coupon {
            font-size: 0.8em;
            font-weight: $font-weight-bold;
            color: color('900', 'blue');
            float: right;

            .promocode {
                float: none;
                display: inline-block;
                padding: 5px 20px;
                color: #4c4c4c;
                border: 1px dashed #c3c3c3;
                background-color: #fff;
                font-size: 14px;
                font-weight: $font-weight-bold;
                margin: 0 0 0 1em;
                border-radius: $base-border-radius;

                .code {
                    color: #e38888;
                }
            }
        }
    }

    .slides {
        @include clearfix;

        .slide {
            overflow: hidden;
            position: relative;
            float: left;
            margin: 0 1em 10em 0;
            background-color: #ffffff;
            width: 150px;
            height: 150px;

            a {
                width: 100%;
                height: 100%;
                display: block;

                .overflow-imagen,
                .overflow-imagen_large {
                    overflow: hidden;
                    position: relative;

                    img {
                        max-width: initial;
                    }
                }
            }
        }
    }
}

.bn-space-gads.bn-detail {
    min-height: 110px;
    background: #f0f0f0;
    text-align: center;
    margin: 10px 0;
    border-radius: 8px;
    display: flex;
    align-items: center;
}

/* CUPON SHUTTER */
.couponbox.shutterstock {
    right: 0;
}
.couponbox.shutterstock .cbox .input-code a {
    line-height: 17px;
}

.couponbox {
    // right: 0 !important;
    @media (max-width: 450px) {
        .cshadow {
            display: none !important;
        }
        .cbox {
            width: 95% !important;
            margin: auto;
            padding: 15px !important;
            img {
                width: 120px !important;
            }
            .couponbox {
                .close {
                    top: 14px;
                    right: 46px !important;
                }
            }
            .tt1 {
                font-size: 22px !important;
                line-height: 22px !important;
            }
            .tt2 {
                font-size: 16px !important;
            }
        }
    }
}
.es {
    .couponbox {
        .cbox {
            .tt1 {
                font-size: $font-size-3xl !important;
                line-height: 38px !important;
                @media (max-width: 450px) {
                    font-size: 22px !important;
                    line-height: 22px !important;
                }
            }
        }
    }
}

.collections-visible {
    @media (max-width: 1344px) {
        .a-wrapper {
            display: none !important;
        }
    }
}

/* BANNER SEARCH */
.search-result,
.detail--v2 {
    .a-wrapper {
        background: color('75', 'gray');
        text-align: center;
        margin: $small-spacing 0;
        border-radius: 8px;

        div {
            margin: auto;
        }

        .edge-content-iframe {
            padding: 0;
            background: none;
            //height: 92px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;

            &.ads-slidesgo {
                height: auto !important;
                min-height: 92px;
            }
        }
    }
}

// .detail--v2 {
//   .a-wrapper {
//     border-bottom: 1px solid color('100', 'gray')
//   }
// }

#sp-freepik {
    height: 300px;

    .edge-content-iframe {
        display: block;
        padding: 1em;
        box-sizing: inherit;
        background: color('100', 'gray');
        height: 182px !important;
    }
}

.spirit-a {
    position: relative;
    height: 291px;
    &.soul-p-nsba {
        height: 202px;
        margin-top: 20px;

        .preload {
            border-radius: 8px;
        }
    }
    .preload {
        background-color: color('75', 'gray');
    }
}

#banner-autopromo {
    &.active {
        position: relative;
        height: 291px;
        .preload {
            background-color: color('75', 'gray');
        }
    }
}

body[data-section='404'] .spirit-a .preload {
    background-color: transparent;
}

body[data-section='404'] #banner-autopromo .preload {
    background-color: transparent;
}

@media screen and (max-height: 768px) {
    .spirit-a {
        height: 164px;
    }
}

@media screen and (max-height: 768px) {
    #banner-autopromo {
        &.active {
            height: 164px;
        }
    }
}

@media (max-width: 380px) {
    .spirit-a,
    #banner-autopromo {
        height: auto;
    }
}
