#notification-cart {
    right: 0;
    width: 480px;
    height: 276px;
    background: url('~Media/notify-cart/cart-desktop.jpg') no-repeat center;
    background-size: cover;
    box-shadow: 0 0 60px rgba(14, 42, 71, 0.25);

    @include breakpoint('sm') {
        max-width: 100%;
        background: url('~Media/notify-cart/cart-mobile.jpg') no-repeat center;
        background-size: cover;
    }

    .notification__close {
        color: color('800', 'blue');
        margin: 0;

        i {
            @extend .icon;
        }
    }

    .notification__content {
        height: 235px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            width: 143px;
            height: auto;

            @include breakpoint('sm') {
                width: 120px;
            }
        }

        p {
            text-align: center;
            margin: 0;

            &:first-of-type {
                color: color('700', 'gray');
                margin: 10px 0 5px 0;

                span {
                    color: color('500', 'yellow');
                    font-weight: $font-weight-semibold;

                    i {
                        margin-right: 5px;
                        color: inherit;
                        &::before {
                            vertical-align: bottom;
                        }
                    }
                }
            }

            &:nth-last-of-type(2) {
                color: color('800', 'blue');
                font-weight: $font-weight-semibold;
                font-size: $font-size-xl;

                @include breakpoint('xs') {
                    font-size: 18px;
                }

                br {
                    display: none;
                }
            }

            &:last-of-type {
                color: color('800', 'blue');
                font-size: $font-size-7xl;
                font-weight: $font-weight-bold;
                line-height: 63px;
                margin-bottom: 15px;
                font-family: $title-font-family;

                @include breakpoint('xs') {
                    font-size: 34px;
                    line-height: 34px;
                    height: 42px;
                }
            }
        }
        .countdown-promo {
            height: 63px;
        }

        a {
            background: color('600', 'green');
            color: $color-light;

            &:hover {
                text-decoration: none;
                background: color('700', 'green');
            }
        }
    }
}

.autopromo-coupon-container {
    @include breakpoint('sm') {
        width: 100%;
        display: flex;
        justify-content: center;
        right: 0;
    }
}
