.landing--collections {
    main {
        overflow: hidden;
    }

    #header {
        padding: $base-spacing 0;
        border-bottom: 1px solid color('100', 'gray');
        margin-bottom: $base-spacing;
    }

    section {
        position: relative;
    }

    .hero {
        background: none;
        text-align: left;
        padding: 80px;
        margin: 0;
        color: color('text', 'general');

        h2 {
            max-width: 400px;
            @include breakpoint('md', 'min') {
                font-size: $font-size-6xl;
            }
        }

        p {
            max-width: 470px;
        }
    }

    .features {
        margin-bottom: 100px;

        .container {
            position: relative;
            padding: 0 40px;
        }
    }

    .feature {
        border-radius: 8px;
        box-shadow: 0 0 10px color('500', 'blue', 0.2);
        text-align: center;
        background-color: color('body', 'background');
    }

    .form {
        position: absolute;
        pointer-events: none;

        &--left {
            left: -845px;
            top: -479px;
        }
        &--right {
            right: -615px;
            top: -320px;
        }
    }

    .features__text {
        p {
            max-width: 585px;
        }
    }

    .examples {
        margin-bottom: 60px;
        position: relative;
        z-index: 2;

        .col__lg--6:first-child {
            padding-right: 50px;
        }
        .col__lg--6:last-child {
            padding-left: 50px;
        }

        p {
            max-width: 430px;
        }
    }

    .example-iconfont {
        width: 70%;
        margin: -7% 21% 0;
    }

    .example-svg-sprite {
        width: 52%;
        margin: -7% 17% 0;
    }

    .icon-example--iconfont {
        margin: -12px 12px 0;
    }

    .icon-example--svg-sprite {
        position: absolute;
        bottom: -4%;
        right: 5%;
    }

    .icon-example {
        padding: $base-spacing;
        background-color: color('body', 'background');
        border-radius: 4px;
        box-shadow: 0 0 10px color('500', 'blue', 0.2);
        text-align: center;
        font-family: Consolas, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono,
            Courier New, monospace;
        display: inline-block;
        font-size: 14px;
    }

    .try {
        background-image: linear-gradient(-84deg, #82f08f 0%, #1ebe83 100%);
        position: relative;
        padding-top: 500px;
        margin-top: -500px;

        &:before {
            content: '';
            background: url(~Media/landing/collections/mask.svg) no-repeat bottom center;
            display: block;
            width: 100%;
            height: 326px;
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
        }

        .container {
            position: relative;
            z-index: 1;
        }

        p {
            max-width: 500px;
            margin: 0 auto 40px;
        }

        .button {
            box-shadow: inset 0px 0px 0px 100px color('800', 'blue');

            &:hover {
                box-shadow: inset 0px 0px 0px 100px color('800', 'blue');
                color: color('text-inverted', 'general');
            }
        }
    }

    video {
        width: 100%;
        height: auto;
        max-width: 970px;
        display: block;
        margin: auto;
    }

    #footer {
        border: none;
        position: relative;
        background: none;
        min-height: 0;
        margin: 0;

        &:before {
            content: '';
            background: url(~Media/landing/collections/footer.svg) no-repeat bottom center;
            display: block;
            width: 100%;
            height: 652px;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
        }

        div {
            position: relative;
        }

        li {
            display: inline-block;
            a {
                color: color('link-inverted', 'general');
                font-weight: $font-weight-regular;
            }
            span {
                display: inline-block;
                margin: 0 10px;
            }
        }
    }

    @include breakpoint('lg') {
        .features {
            margin-bottom: 40px;

            .container {
                padding: 0 80px;
            }

            .col.col--stretch {
                width: 100%;
                flex: none;
                margin-bottom: $large-spacing;
            }

            img {
                margin: 0;
            }

            .feature {
                display: flex;

                &__text {
                    text-align: left;
                    margin-left: $large-spacing;
                }
            }
        }

        .features__text {
            padding: 0 80px;
        }

        .examples {
            padding: 0 80px;

            .col__lg--6:first-child {
                padding: 0;
                margin-bottom: $large-spacing;
            }
            .col__lg--6:last-child {
                padding: 0;
            }
        }
    }

    @include breakpoint('md') {
        .hero {
            text-align: center;
            padding: 0;

            h2,
            p {
                margin-left: auto;
                margin-right: auto;
            }
        }

        .features {
            .container {
                padding: 0;
            }
        }

        .feature__holder {
            padding: 0 $base-spacing;
        }

        .features__text {
            padding: 0;
        }

        .examples {
            padding: 0;
        }

        .example-svg-sprite {
            width: 90%;
            margin: -15px auto;
        }

        .icon-example--iconfont {
            margin: -12px -91px 0;
            left: 50%;
            position: relative;
        }

        .icon-example--svg-sprite {
            position: relative;
            margin: -10px -70px 0;
            left: 50%;
        }
    }

    @include breakpoint('sm') {
        .hero {
            p {
                display: block;
            }
        }

        .features img {
            width: 114px;
        }
    }

    @include breakpoint('xs') {
        .logo {
            width: auto;
        }
    }
}
