.header-premium {
    background-size: cover;
    background-position: center center;
    height: 100%;

    .content {
        max-width: map-get($breakpoints, 'md');
        height: 100%;
        margin: 0 auto;
        padding: 0 $base-spacing;
        text-align: center;
    }

    &--bg2 {
        overflow: hidden;

        background-color: color('green', 'state');
        background-image: url('~Media/autopromo/banner-search-premium/bg.svg');

        .content {
            position: relative;

            &::before {
                position: absolute;
                top: 50%;
                right: 100%;

                width: 388px;
                height: 283px;

                transform: translateY(-50%);

                background-image: url('~Media/autopromo/banner-search-premium/bg-layer.svg');
                background-repeat: no-repeat;
                content: '';
            }
        }
    }

    &--slim {
        @include breakpoint('lg', 'min') {
            .content {
                max-width: 880px;

                text-align: left;
            }

            .col {
                padding: 0 $base-spacing;
            }

            span.font-h3 {
                width: 100%;
                max-width: 192px;

                margin: 0 $extra-small-spacing 0 0 !important;
                padding: 0 ($base-spacing + $extra-small-spacing) 0 0 !important;

                border-right: $base-border;
                border-color: color('border', 'general', 0.15);

                line-height: 1.2;
                text-align: right;
            }

            p {
                line-height: 2;
            }

            &.header-premium--bg3 {
                span.font-h3 {
                    border-color: color('border', 'general');
                }
            }
        }

        @include breakpoint('lg') {
            .content {
                max-width: 600px;
                top: 50%;
                transform: translateY(-50%);
                position: relative;
                height: auto;
            }

            .row {
                display: block;
            }

            p {
                margin: 0 0 $base-spacing !important;
            }
        }
    }
}
