//Modal
.modal-essentials {
    z-index: 101;

    .modal__container {
        max-width: 640px;
    }

    .modal__container.extra {
        max-width: 560px;
    }

    &__header {
        background-color: color('600', 'red');
        color: #fff;
    }

    &__headerv2 {
        h6,
        i,
        button {
            color: color('600', 'red');
        }

        i.icon--cross {
            color: color('900', 'blue');
        }
    }

    &__content {
        color: color('900', 'blue');
        h6,
        i,
        h6 span {
            color: color('600', 'red');
        }
        a {
            line-height: 1.2;
        }
        a.link-contact-unpaid-teams {
            color: color('600', 'green');
        }
    }
    .modal-essentials__payment-step {
        i.icon--spinner {
            width: 100%;
            &::after {
                animation: rotate 600ms linear infinite;
                display: block !important;
                overflow: hidden;
                opacity: 1;
                width: calc((32px) * 0.75);
                height: calc((32px) * 0.75);
                margin: calc(((32px) * 0.25) / 2) auto;
                border-radius: 50%;
                border-width: 2px;
                border-color: #fff rgba(255, 255, 255, 0.3) rgba(255, 255, 255, 0.3) rgba(255, 255, 255, 0.3);
                border-style: solid;
                box-sizing: border-box;
                content: '';
                position: relative;
                top: -6px;
            }
            &::before {
                display: none;
            }
        }
    }

    .countdown {
        color: color('900', 'blue');
        margin-bottom: $small-spacing;

        &__description {
            display: none;
        }
    }
}

header > #essentials {
    button {
        background-color: color('600', 'red');
    }
    a.my-subscription {
        background-color: color('600', 'blue');
    }
    .close-being-processed {
        background-color: transparent;
        i::before {
            color: white;
        }
    }
}
