html,
body {
    height: 100%;
    display: block;
}

body {
    overflow-y: scroll;

    &.overflow-hidden #viewport {
        @include filter(blur(5px));
    }

    &.overflow {
        overflow: hidden;
    }

    &.overflow--mobile {
        @include breakpoint('md') {
            overflow: hidden;
        }
    }
}

@include selection {
    background-color: rgba(color('400', 'green'), 0.5);
}

img {
    max-width: 100%;
}

#ot-sdk-btn-floating {
    display: none;
}

/* performance CMP */
// @media (max-width: 768px) {
#onetrust-banner-sdk.otFlat {
    transform: translateY(100%);
    transition: transform 600ms ease-in;

    &.active {
        transform: translateY(0%);
    }
}
#cky-consent {
    transform: translateY(100%);
    transition: transform 600ms ease-in;

    &.active {
        transform: translateY(0%);
    }
}
// }
