#footer {
    background-color: color('900', 'blue');
    color: color('500', 'gray');
    padding: $extra-large-spacing 0 0;
    font-size: $font-size-sm;
    margin: 0;
    border: none;

    a:not(.btn):not(.bj-button),
    #ot-sdk-btn {
        color: white;
    }
    #ot-sdk-btn {
        text-align: left;
        border: none;
        background: none;
        font-size: 13px;
        vertical-align: inherit;
        margin: 0;
        padding: 0;
    }

    .footer__column {
        width: 20%;

        ul {
            margin-bottom: $small-spacing;

            li {
                margin-bottom: $extra-small-spacing;
                vertical-align: top;
            }
        }

        input {
            display: none;
            appearance: none;
            -webkit-appearance: none;
        }
    }

    .footer__column.stickers {
        margin-bottom: $large-spacing;
    }

    .footer__title {
        label {
            color: #fff;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            margin-bottom: $extra-small-spacing;
            display: block;

            i {
                color: #fff;
                transform: rotate(90deg);
            }
        }
    }

    .footer__legal {
        a {
            color: color('500', 'gray');
        }
    }

    .share {
        margin-bottom: $base-spacing;

        li {
            float: none;

            display: inline-block;

            a:not(.btn):not(.bj-button) {
                color: color('text-inverted', 'general');
                border-radius: $base-border-radius;
                width: $small-element-height;
                height: $small-element-height;
                line-height: $small-element-height;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .copyright {
        padding: $base-spacing 0;

        border-top: 1px solid color('800', 'gray');

        text-align: center;

        margin-top: $base-spacing;
    }

    .language-selector {
        .popover-button {
            color: color('text-inverted', 'general');

            i {
                color: $color-light !important;
            }
        }

        .popover-content {
            width: 160px;
            padding: 0;

            &:before,
            &:after {
                top: 100%;
                right: 15px;
            }
        }

        ul {
            margin: 0;
        }

        a:not(.btn):not(.bj-button) {
            display: block;
            padding: 5px;
            color: color('500', 'gray');

            &:hover {
                background-color: color('75', 'gray');
            }
        }
    }

    .footer__social {
        text-align: right;
    }

    .footer__projects {
        background-color: rgba(66, 66, 66, 0.5);

        padding: $base-spacing $small-spacing;

        strong {
            color: color('600', 'gray');
        }

        ul {
            float: right;

            font-weight: $font-weight-bold;

            margin: 0;

            li {
                display: inline-block;

                margin-left: $base-spacing;
            }

            a {
                font-weight: $font-weight-bold;
            }
        }
    }

    @include breakpoint('lg') {
        .footer__column {
            width: 33.333%;
        }

        .footer__social {
            text-align: left;
        }
    }

    @include breakpoint('sm') {
        padding: $large-spacing 0 0;

        .footer__column {
            width: 100%;
            // margin-bottom: $large-spacing;

            ul:not(.share) {
                display: none;
            }

            input:checked + ul {
                display: block;
                margin-bottom: $large-spacing;
            }

            ul li {
                margin-bottom: $small-spacing;
            }
            ul.share li {
                margin: 0 $extra-small-spacing $small-spacing;
            }
            &.stickers {
                margin-bottom: 0;
            }
        }

        // .container {
        //     padding: 0 $base-spacing;
        // }

        .footer__top {
            a {
                width: 100%;
                text-align: center;
                margin: 0 auto 10px;
                max-width: 300px;
                line-height: 1.2;
                &.logo-flaticon {
                    margin-bottom: 20px;
                }
            }
        }

        .footer__buttons {
            margin: 0;
            width: 100%;
        }

        .footer__social {
            text-align: center;
        }

        .footer__legal {
            text-align: center;
        }

        .copyright {
            .row {
                display: block;
                text-align: center;
            }

            .popover {
                margin-top: $base-spacing;
                width: 100%;

                div,
                button {
                    width: 100%;
                    max-width: 300px;
                }
            }
        }

        .footer__projects {
            text-align: center;

            ul {
                float: none;
                margin-top: $base-spacing;

                li:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}

.collections-visible {
    #footer {
        @media (max-width: 1312px) {
            .footer__column {
                width: 33.333%;
            }

            .footer__social {
                text-align: left;
            }
        }
    }
}
