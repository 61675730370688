.error302 {
    padding: 100px $base-spacing;
    max-width: 850px;
    margin: auto;

    .img--icon {
        box-shadow: 10px 10px 20px 0 color('900', 'blue', 0.1);
        border-radius: 10px;
        width: 180px;
        height: 180px;
        text-align: center;
        padding: 20px;

        img {
            position: relative;
            top: 50%;
            margin-top: -64px;
        }
    }

    .img--pack {
        max-width: 320px;
        box-shadow: 0 0 0 1px color('100', 'gray');
        overflow: hidden;
        padding: 10px;
        height: 205px;
        margin: auto;
    }

    .col__text {
        width: 100%;
        max-width: 400px;
        margin-left: 80px;
    }

    button .icon:before {
        vertical-align: middle;
    }

    @include breakpoint('md') {
        padding: 40px 20px;
        margin-bottom: 20px;

        .col__img {
            width: 100%;
            text-align: center;
        }

        .img--icon {
            margin: auto;
        }

        .col__text {
            margin: $base-spacing auto 0;
            flex: none;
        }
    }
}
