.icons-search-result {
    visibility: hidden;
}
.search-result {
    position: relative;
    margin-bottom: 20px;
    margin-top: 20px;

    .personal-icon {
        border: 2px dashed color('100', 'gray');
        pointer-events: none;
        &:hover {
            .overlay {
                display: none;
            }
        }
    }
    .stickers-404 {
        width: 100%;
        h6 {
            flex: 1;
        }
    }
}

@include breakpoint('xs') {
    .search-result {
        .stickers-404 {
            flex-wrap: wrap;
            h6 {
                width: 100%;
                flex: unset;
            }
            > a {
                margin-left: $small-spacing;
            }
        }
    }
}
.search-view__top-stickers-searches {
    > .container {
        max-width: unset;
    }
}

.icons-search-empty-result {
    visibility: hidden;
}

#didyoumean {
    margin-top: 6em;
}
.search-data + #didyoumean {
    margin-top: 12px;
}
.search-pack-result-text,
.search-pack-result-icons {
    margin: 1em;
    text-align: center;
    display: block;
}
.search-pack-result {
    position: relative;

    height: 206px;

    overflow: hidden;

    @media (max-width: 1024px) {
        margin-top: 0;
    }

    &:empty {
        display: none;
    }

    &:not(.similar-packs) {
        .box {
            a {
                overflow: hidden;

                footer {
                    @include position(absolute, null null 0 0);
                    width: 100%;

                    display: none;
                }
            }
            &:hover {
                a {
                    footer {
                        display: block;
                    }
                }
            }
        }
    }

    &.similar-packs {
        height: auto;
        overflow: hidden;
        background-color: color('50', 'gray');
        border-top: 1px solid color('100', 'gray');
        padding: 20px 15px;

        p {
            margin-bottom: 5px;
            margin: 0px;
            padding: 16px 0 5px 0;
        }

        .box .overlay-more {
            top: 10px;
            left: 10px;
            width: calc(100% - 20px);
            height: calc(100% - 20px);

            @media (max-width: 768px) {
                top: 5px;
                left: 5px;
                width: calc(100% - 10px);
                height: calc(100% - 10px);
            }
        }

        footer {
            @include clearfix;
        }

        @media (max-width: 480px) {
            overflow-y: hidden;
        }
    }

    > p {
        width: 100%;
        margin: 1em 0 0;
        text-align: center;
        a {
            text-decoration: underline;
        }
    }

    .box-scroll {
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
    }

    .box {
        position: relative;
        max-width: 400px;
        .overlay-more {
            @include position(absolute, 0 null null 0);
            width: 100%;
            height: 100%;
            background-color: color('800', 'blue', 0.7);
            color: #fff;
            z-index: 2;
            text-align: center;
            font-size: 1.4em;
            border: none;

            &:hover {
                background-color: color('800', 'blue', 0.6);

                span {
                    // border-bottom: 1px solid;
                }
            }

            span {
                @include position(absolute, 50% null null 0);
                @include transform(translateY(-50%));

                width: 100%;
                height: 24px;

                font-size: 18px;
                text-align: center;
                line-height: 24px;
            }
        }
    }
}

.premium-autop {
    position: relative;
    color: #fff;
    height: 300px;
    background: url('~Media/autopromo/banner-search-premium/bg-dark.svg') no-repeat center;
    background-size: cover;

    &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 450px;
        height: 297px;
        transform: translateY(-50%);
        margin-left: -670px;
        background-image: url('~Media/autopromo/banner-search-premium/computer.svg');
        background-repeat: no-repeat;
        content: '';
    }
    .premium {
        display: inline-flex;
        align-items: baseline;
        gap: 5px;
        i {
            color: inherit;
        }
    }
}
