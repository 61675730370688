#{$all-buttons},
.btn {
    background-color: color('500', 'green');
    &:hover {
        background-color: color('600', 'green');
    }
    &:active,
    &.active {
        background-color: color('600', 'green');
    }

    &.btn-gray {
        background-color: color('100', 'gray');
        color: color('800', 'gray');

        &:hover {
            background-color: color('200', 'gray');
            color: color('800', 'gray');
        }

        &:active,
        &.active,
        &:focus {
            background-color: color('200', 'gray');
            color: color('800', 'gray');
        }

        &.btn-outline {
            box-shadow: inset 0px 0px 0px 2px color('100', 'gray');
            color: color('800', 'gray');

            &:hover {
                background-color: rgba(color('200', 'gray'), 0.1);
                color: color('800', 'gray');
            }

            &:active,
            &.active,
            &:focus {
                background-color: rgba(color('200', 'gray'), 0.2);
                color: color('800', 'gray');
            }
        }
    }

    &.btn-success {
        background-color: color('500', 'green');

        &:hover {
            background-color: color('600', 'green');
        }

        &:focus {
            background-color: color('600', 'green');
        }

        &.btn-outline {
            box-shadow: inset 0px 0px 0px 2px color('500', 'green');
            color: color('500', 'green');

            &:hover {
                background-color: rgba(color('500', 'green'), 0.1);
                color: color('500', 'green');
            }

            &:active,
            &.active {
                background-color: rgba(color('500', 'green'), 0.2);
            }
        }
    }

    &.btn-warning {
        background-color: color('500', 'yellow');

        &:hover {
            background-color: color('600', 'yellow');
        }

        &:active,
        &.active {
            background-color: color('600', 'yellow');
        }

        &.btn-outline {
            box-shadow: inset 0px 0px 0px 2px color('500', 'yellow');
            color: color('500', 'yellow');

            &:hover {
                background-color: color('500', 'yellow', 0.1);
                color: color('500', 'yellow');
            }

            &:active,
            &.active {
                background-color: color('600', 'yellow');
            }
        }
    }

    &.btn-danger {
        background-color: color('500', 'red');
        color: #fff;

        &:hover {
            background-color: color('600', 'red');
        }

        &:active,
        &.active {
            background-color: color('600', 'red');
        }

        &.btn-outline {
            box-shadow: inset 0px 0px 0px 2px color('500', 'red');
            color: color('500', 'red');

            &:hover {
                background-color: rgba(color('500', 'red'), 0.1);
                color: #fff;
            }

            &:active,
            &.active,
            &:focus {
                background-color: color('600', 'red');
                background-color: rgba(color('500', 'red'), 0.2);
            }
        }
    }

    &.btn-outline {
        background: none;
        color: color('500', 'green');
        box-shadow: inset 0px 0px 0px 2px color('500', 'green');

        &:hover {
            background-color: rgba(color('500', 'green'), 0.1);
            color: color('500', 'green');
        }

        &:active,
        &.active,
        &:focus {
            background-color: rgba(color('500', 'green'), 0.2);
            color: color('500', 'green');
        }
    }

    &.nostyle {
        &:hover,
        &:active,
        &.active {
            background: none;
        }
    }
}

#{$all-buttons},
.btn {
    appearance: none;
    background-color: color('500', 'green');
    border: none;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    // display: inline-block;
    font-family: $base-font-family;
    font-size: $font-size-md;
    -webkit-font-smoothing: antialiased;
    font-weight: $font-weight-semibold;
    line-height: 1;
    padding: 0.75em 1em;
    text-decoration: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    outline: none;

    // @include transition(all 0.1s $ease-out-quart);

    &:hover {
        color: #fff;
    }

    &:focus {
        color: #fff;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    &.btn-small {
        font-size: 0.8em;
        padding: 0.5em 0.75em;
    }

    &.btn-pill {
        border-radius: 2em;
        padding: 0.75em 1.5em;
    }

    &.nostyle {
        padding: 0;
        background: none;
    }

    span,
    i:before {
        vertical-align: middle;
    }
}

button {
    .icon {
        &:before {
            vertical-align: unset;
        }
    }
}
