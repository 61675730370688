.landing-whats-new {
    overflow: unset;
    .main {
        overflow: unset;
    }
    .container {
        max-width: 1025px;
        > hr {
            width: 100%;
        }
    }

    &__menu-toggle {
        width: 265px;
        position: sticky;
        position: -webkit-sticky;
        margin-right: $extra-large-spacing;
        top: 120px;

        label {
            display: none;
            align-items: center;
        }
        input {
            display: none;
        }
    }

    &__sidebar {
        background-color: #fff;
        padding: $small-spacing 0;
        border-radius: $base-border-radius;
        margin: 0;

        a {
            height: $small-element-height;
            line-height: $small-element-height;
            padding: 0 $base-spacing;
            font-size: $font-size-sm;
            position: relative;
            display: block;
            font-weight: $font-weight-regular;

            &.active {
                color: color('600', 'green');
                font-weight: $font-weight-bold;
            }
        }
        li:first-child span {
            font-weight: $font-weight-bold;
            padding: 0 $base-spacing;
            color: color('800', 'gray');
            line-height: $small-element-height;
        }
    }
    &__text {
        width: 100%;
        .popover-button {
            padding-left: 0;
        }
    }
    &__article {
        padding: $small-spacing 0 $large-spacing 0;
        margin-bottom: $large-spacing;
        img {
            width: 100%;
            border-radius: 8px;
            object-fit: cover;
        }
        footer {
            .share {
                li {
                    display: inline-block;
                }
                li:not(:first-child) a {
                    padding: 0 $base-spacing 0 $base-spacing;
                    color: color('800', 'gray');
                }
            }
        }
        border-bottom: 1px solid color('100', 'gray');
    }
    &__article:last-child {
        border-bottom: none;
    }

    @include breakpoint('lg') {
        .container {
            max-width: 750px;
        }
        &__menu-toggle {
            width: 100%;
            position: relative;
            top: unset;
            margin-bottom: $base-spacing;

            label {
                display: flex;
                align-items: center;
                cursor: pointer;
                width: fit-content;
            }
            .landing-whats-new__sidebar__wrapper {
                display: none;
            }
            .landing-whats-new__sidebar__wrapper::before {
                font-size: 1.4em;
                display: block;
                width: 0;
                height: 0;
                content: '';
                pointer-events: none;
                position: absolute;
                top: -0.45em;
                border-right: 10px solid transparent;
                border-bottom: 10px solid #fff;
                border-left: 10px solid transparent;
            }
            input:checked + .landing-whats-new__sidebar__wrapper {
                display: block;
                position: absolute;
                width: unset;
                border-radius: $base-border-radius;
                z-index: 1;
                top: 45px;
                left: 0;
                overflow: visible;
                margin: 0;
                padding: 8px;
                background: #fff;
                box-shadow: 0 0 60px rgba(14, 42, 71, 0.25);
                animation: popover 0.2s ease-in-out;
            }
        }
        &__article {
            img {
                height: auto;
            }
        }
    }
    @include breakpoint('sm') {
        &__article {
            img {
                border-radius: $base-border-radius;
            }
        }
    }
}
