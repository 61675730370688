$box-pricing-width: 300px;
$a-header-black: #27343e;
$a-header-hover: #0f73ee;

%position-absolute {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
}

$box-shadow: 0 0 10px 0 rgba(95, 125, 149, 0.3);

.page-pricing {
    padding-top: 0px !important;

    #viewport {
        padding-top: $header--menu-height;
    }

    #header {
        z-index: 2;
        position: absolute;
        margin: 0;
        padding: 0;
        border: none;

        .row {
            padding: 0;
        }

        .menu-holder > li:not(.language-selector) {
            display: none;

            @include breakpoint('lg') {
                display: block;
            }
        }

        .popover-tools {
            .popover-button {
                padding: 0 $large-spacing;
            }
        }
    }

    .header--menu {
        margin: 0;
    }

    .header--search {
        display: none;
    }

    .header.fixed .header--search {
        display: none;
    }

    // Recuperar en caso de poner Premium Plus

    // .discount {
    //     padding: $base-spacing;
    //     border-radius: $base-border-radius;
    //     background: linear-gradient(270deg, color("4", "blue") 0%, color("3", "blue") 100%);
    //     box-shadow: $box-shadow;

    //     p {
    //         margin: 0;
    //         color: color("text-inverted", "general");
    //         text-align: center;
    //     }

    //     &__price {
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         margin: 0 ($small-spacing * -1);

    //         p {
    //             padding: 0 $small-spacing;
    //         }

    //         p.strike {
    //             border-right: 2px solid color("hades", "gray");
    //             color: color("heather", "gray");
    //             font-size: $font-size-lg;
    //         }

    //         p:last-child {
    //             $first-letter-font-size: 56px;

    //             position: relative;
    //             font-weight: $font-weight-bold;
    //             line-height: 0.7;

    //             b:nth-child(1) {
    //                 font-size: $first-letter-font-size;
    //                 vertical-align: text-top;
    //             }

    //             b:nth-child(2) {
    //                 position: relative;
    //                 margin-left: $extra-small-spacing;
    //                 span {
    //                     position: absolute;
    //                     top: 100%;
    //                     left: 0;
    //                     padding-top: 4px;
    //                     color: color("bali", "gray");
    //                     font-weight: $font-weight-regular;
    //                 }
    //             }
    //         }
    //     }
    // }

    // section section {
    //     margin: 0;
    // }

    // .container--xs {
    //     max-width: 1024px;
    //     margin: 0 auto;
    //     padding: 0 20px;
    // }

    // .button--outline {
    //     &.button--yellow {
    //         &:hover {
    //             background: rgba(252, 161, 32, 0.1);
    //             box-shadow: inset 0px 0px 0px 1px #ffb229;
    //             color: #ffb229;
    //         }
    //     }
    //     &.button--inverted {
    //         &:hover {
    //             background: rgba(252, 161, 32, 0.1);
    //             box-shadow: inset 0px 0px 0px 1px #fff;
    //             color: #fff;
    //         }
    //     }
    // }

    // .tooltip {
    //     .tooltip__trigger {
    //         &:after {
    //             border-top-color: #fff;
    //         }
    //     }

    //     .tooltip__content {
    //         .content {
    //             width: 240px;
    //             background-color: #fff;
    //             white-space: normal;
    //         }
    //     }
    // }

    // &__benefits {
    //     article {
    //         display: flex;
    //         align-items: flex-start;

    //         &:not(:last-child) {
    //             margin: 0 0 $base-spacing;
    //         }

    //         #{$all-headings} {
    //             margin: 0;
    //             font-size: $font-size-md;
    //         }

    //         .content {
    //             flex: 1;
    //         }

    //         .tooltip {
    //             display: inline;
    //         }

    //         img {
    //             width: $base-element-height;
    //             height: auto;
    //             margin: 0 $small-spacing $small-spacing 0;
    //         }

    //         .icon,
    //         p,
    //         a {
    //             margin: 0;
    //             color: color("hoki", "gray");
    //             font-size: $font-size-sm;
    //         }
    //     }
    // }

    // &__plans {
    //     .discount ~ section {
    //         transform: translateY(($base-spacing / 2) * -1);
    //     }

    //     .info {
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         margin: 0 0 $base-spacing;

    //         i {
    //             margin: 0 0 0 $extra-small-spacing;
    //             color: color("hoki", "gray");
    //         }

    //         p {
    //             margin: 0;
    //         }
    //     }

    //     article {
    //         position: relative;
    //         z-index: 2;
    //         min-width: 260px;
    //         max-width: 320px;
    //         margin: 0 auto;
    //         padding: $large-spacing $base-spacing;
    //         border-radius: $base-border-radius;
    //         background-color: color("light", "background");
    //         box-shadow: $box-shadow;
    //         text-align: center;

    //         &:not(:last-child) {
    //             margin-bottom: $base-spacing;
    //         }

    //         #{$all-headings} {
    //             margin: 0 0 $base-spacing;
    //             font-size: $font-size-md;
    //             text-transform: uppercase;
    //             line-height: 1;
    //         }

    //         .ribbon-wrapper {
    //             @extend %position-absolute;
    //             overflow: hidden;
    //             width: 100%;
    //             height: 100%;
    //             transform: translate(6px, -6px);
    //             pointer-events: none;
    //         }

    //         .ribbon {
    //             top: 8px;
    //             right: 8px;
    //             left: auto;
    //             width: 82px;
    //             height: 82px;
    //             position: absolute;

    //             .content {
    //                 position: absolute;
    //                 top: 50%;
    //                 left: 50%;
    //                 width: 200%;
    //                 padding: $extra-small-spacing $small-spacing;
    //                 background: color("5", "yellow");
    //                 box-shadow: 0 3px 10px -5px black;
    //                 box-shadow: none;
    //                 transform: translate(-50%, -50%) rotate(45deg);
    //                 color: color("text-inverted", "general");
    //                 font-size: 11px;
    //                 font-weight: $font-weight-bold;
    //                 text-transform: uppercase;

    //                 &::before,
    //                 &::after {
    //                     position: absolute;
    //                     top: 100%;
    //                     z-index: -1;
    //                     border-bottom: $base-border-radius solid transparent;
    //                     content: "";
    //                 }

    //                 &::before {
    //                     left: 0;
    //                     border-top: $base-border-radius solid color("7", "yellow");
    //                     border-right: $base-border-radius solid transparent;
    //                     border-left: $base-border-radius solid color("7", "yellow");
    //                 }

    //                 &::after {
    //                     right: 0;
    //                     border-top: $base-border-radius solid color("7", "yellow");
    //                     border-right: $base-border-radius solid color("7", "yellow");
    //                     border-left: $base-border-radius solid transparent;
    //                 }
    //             }
    //         }

    //         p {
    //             color: color("oxford", "gray");
    //         }

    //         p.font-sm {
    //             color: color("hoki", "gray");
    //         }
    //     }
    // }

    // &__plus {
    //     overflow: hidden;
    //     position: relative;
    //     padding: ($extra-large-spacing * 2) 0;
    //     background: url("~Media/pricing/premium-plus/background.svg") center center no-repeat;
    //     background-size: cover;
    //     color: color("text-inverted", "general");
    //     text-align: center;

    //     &::before,
    //     &::after {
    //         @extend %position-absolute;
    //         background-size: contain;
    //         background-repeat: no-repeat;
    //         content: "";
    //     }

    //     &::before {
    //         top: auto;
    //         bottom: -90px;
    //         width: 220px;
    //         height: 300px;
    //         margin-left: -80px;
    //         background-image: url("~Media/pricing/premium-plus/freepik.png");
    //     }

    //     &::after {
    //         top: -120px;
    //         right: 0;
    //         left: auto;
    //         width: 298px;
    //         height: 310px;
    //         margin-right: -170px;
    //         background-image: url("~Media/pricing/premium-plus/flaticon.png");
    //     }

    //     #{$all-headings} {
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         margin: 0 0 $base-spacing;
    //         color: color("heading-inverted", "general");
    //         font-weight: $font-weight-bold;
    //         line-height: 1.2;

    //         .icon--premium::before {
    //             content: "";
    //             background: url("~Media/pricing/premium-plus/premium_plus_crown.svg") 50% no-repeat;
    //             display: block;
    //             width: 30px;
    //             height: 30px;
    //         }

    //         span {
    //             color: color("1", "blue");
    //         }

    //         i {
    //             margin: 0 $small-spacing;
    //         }
    //     }

    //     p {
    //         margin: 0 0 $small-spacing;
    //         padding: 0 $extra-large-spacing;
    //     }
    // }

    // &__twitter {
    //     .avatar {
    //         width: $base-element-height;
    //         height: $base-element-height;
    //         margin: 0 $small-spacing 0 0;
    //         img {
    //             width: auto;
    //             height: 100%;
    //             object-fit: cover;
    //         }
    //     }

    //     .name {
    //         margin: 0 0 $extra-small-spacing;
    //         color: color("oxford", "gray");
    //         font-weight: $font-weight-semibold;
    //     }

    //     .username {
    //         color: color("bali", "gray");
    //     }

    //     .likes {
    //         position: relative;
    //         margin: 0 $large-spacing 0 0;
    //         padding-left: $extra-small-icon-width + $extra-small-spacing;

    //         i {
    //             position: absolute;
    //             top: 50%;
    //             left: 0;
    //             transform: translateY(-50%);
    //         }
    //     }

    //     .container {
    //         text-align: center;
    //     }

    //     article {
    //         .content {
    //             position: relative;
    //             padding: $base-spacing;
    //             padding-bottom: $base-element-height + $base-spacing;
    //             border-radius: $base-border-radius;
    //             background-color: color("light", "background");
    //             box-shadow: $box-shadow;
    //             text-align: left;
    //         }

    //         header,
    //         section,
    //         footer {
    //             display: flex;
    //             align-items: center;
    //             font-size: $font-size-sm;

    //             p {
    //                 margin: 0;
    //             }

    //             &:not(footer) {
    //                 margin: 0 0 $base-spacing;
    //             }
    //         }

    //         header {
    //             span {
    //                 display: block;
    //             }

    //             p {
    //                 margin: 0;
    //                 line-height: 1;
    //             }
    //         }

    //         section {
    //             color: color("oxford", "gray");
    //             font-weight: $font-weight-semibold;
    //         }

    //         footer {
    //             position: absolute;
    //             bottom: 0;
    //             left: 0;
    //             height: $base-element-height;
    //             padding: $base-spacing;
    //             color: color("cadet", "gray");
    //             line-height: $base-element-height;
    //         }
    //     }
    // }

    // &__swiper {
    //     overflow: hidden;
    //     margin: 0 $small-spacing * -1;

    //     article {
    //         padding: $small-spacing;
    //     }

    //     & > div {
    //         display: flex;
    //         flex-wrap: nowrap;

    //         article,
    //         article .content {
    //             height: 100%;
    //         }
    //     }

    //     & ~ .swiper__navigation {
    //         display: block;
    //     }
    // }

    // &__faq {
    //     .container {
    //         > div {
    //             background-color: #fff;
    //             margin: 0 0 10px;
    //             border-radius: $base-border-radius;
    //             box-shadow: 0 0 10px 0 rgba(95, 125, 149, 0.3);
    //             background-color: #fff;
    //             padding: 30px;

    //             &.active {
    //                 > * {
    //                     display: block;
    //                 }

    //                 h3:first-child {
    //                     margin-bottom: 30px;

    //                     &:after {
    //                         content: "-";
    //                     }
    //                 }
    //             }

    //             > * {
    //                 display: none;
    //             }

    //             h3:first-child {
    //                 display: block;
    //                 font-size: 18px;
    //                 padding-right: 76px;
    //                 margin: 0;
    //                 cursor: pointer;
    //                 position: relative;

    //                 &:after {
    //                     content: "+";
    //                     position: absolute;
    //                     top: 0;
    //                     right: 0;
    //                     color: color("green", "state");
    //                     font-size: 18px;
    //                     width: 20px;
    //                     height: 26px;
    //                     font-size: 34px;
    //                     line-height: 26px;
    //                     display: block;
    //                     font-weight: $font-weight-regular;
    //                     text-align: center;
    //                 }
    //             }
    //         }
    //     }

    //     .accordion {
    //         background: none;
    //         border: none;
    //     }

    //     .accordion__link {
    //         padding: $small-spacing;
    //         padding-right: $base-spacing + $base-icon-width;

    //         &.active {
    //             padding-bottom: 0;
    //         }

    //         .icon {
    //             position: absolute;
    //             right: $small-spacing;
    //             top: $extra-small-spacing;
    //             color: color("5", "green");
    //         }
    //     }

    //     .accordion__container {
    //         margin: 0 0 $small-spacing;
    //         border-radius: $base-border-radius;
    //         box-shadow: $box-shadow;
    //         background-color: color("light", "background");
    //         font-size: $font-size-sm;
    //     }

    //     .accordion__content {
    //         padding: $small-spacing $large-spacing $large-spacing;

    //         p {
    //             padding-right: 70px;
    //         }
    //     }
    // }

    // @include breakpoint("sm", "min") {
    //     &__benefits {
    //         section {
    //             display: grid;
    //             column-gap: $base-spacing;
    //             grid-template-columns: repeat(2, auto);
    //         }
    //     }

    //     &__plans {
    //         article {
    //             min-width: 280px;
    //             margin-bottom: 0;
    //             margin-left: $small-spacing;
    //             margin-right: $small-spacing;
    //         }

    //         section {
    //             display: flex;
    //             align-items: flex-start;
    //             justify-content: center;
    //         }

    //         .discount {
    //             font-size: $font-size-lg;
    //         }
    //     }

    //     &__twitter {
    //         article {
    //             section {
    //                 font-size: $font-size-lg;
    //             }
    //         }
    //     }

    //     &__faq {
    //         .accordion__container {
    //             font-size: $font-size-lg;
    //         }

    //         .accordion__link {
    //             padding: $large-spacing;
    //             padding-right: ($large-spacing * 2) + $base-icon-width;

    //             .icon {
    //                 right: $base-spacing;
    //                 top: $large-spacing;
    //             }
    //         }
    //     }
    // }

    // @include breakpoint("md", "min") {
    //     &__benefits {
    //         section {
    //             grid-template-columns: repeat(4, auto);
    //         }

    //         article {
    //             display: block;
    //             text-align: center;

    //             img {
    //                 width: 64px;
    //                 height: 64px;
    //             }
    //         }

    //         p.paragraph-readable--xs {
    //             max-width: 648px;
    //             font-size: $font-size-xl;
    //         }
    //     }

    //     &__plans {
    //         .discount ~ section {
    //             transform: translateY(($large-spacing / 2) * -1);
    //         }

    //         .discount {
    //             padding: $large-spacing;
    //         }
    //     }

    //     &__plus {
    //         padding-left: 250px;
    //         padding-right: 250px;
    //         font-size: $font-size-xl;

    //         &::before,
    //         &::after {
    //             top: 50%;
    //             transform: translateY(-50%);
    //             background-size: cover;
    //         }

    //         &::before {
    //             width: 370px;
    //             height: 574px;
    //             margin-left: -100px;
    //             background-size: 370px auto;
    //         }

    //         &::after {
    //             width: 451px;
    //             height: 609px;
    //             margin-right: -200px;
    //             background-size: 451px auto;
    //         }
    //     }

    //     &__swiper {
    //         div div {
    //             min-width: 380px;
    //         }
    //     }

    //     .title {
    //         font-size: $font-size-xl;
    //     }
    // }

    // .gallery {
    //     @extend %position-absolute;
    //     overflow: hidden;
    //     z-index: 9;

    //     img {
    //         float: right;
    //         height: 70px;
    //         margin: 0 $extra-small-spacing $extra-small-spacing 0;
    //         border-radius: 5px;
    //     }

    //     @include breakpoint("sm", "min") {
    //         img {
    //             height: 140px;
    //         }
    //     }
    // }
}

.pricing {
    &__hero {
        overflow: hidden;
        position: relative;
        z-index: 1;
        background: url('~Media/pricing/hero.svg');
        background-size: cover;
        color: #fff;

        .content {
            position: relative;
            padding: $extra-large-spacing $base-spacing;
            max-width: 768px;
            margin: auto;
            text-align: center;

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                right: 100%;
                width: 320px;
                height: 283px;
                transform: translateY(-50%);
                background-image: url('~Media/pricing/computer.svg');
            }
        }

        h3 {
            font-weight: $font-weight-regular;
            margin-bottom: $small-spacing;
            color: #fff;
        }

        p {
            margin: 0;
            font-size: $font-size-lg;
        }

        .premium {
            white-space: nowrap;
        }
        &.free-png {
            background: color('800', 'blue');
            min-height: 219px;
            display: flex;
            align-items: center;
            justify-content: center;

            h5 {
                padding: 0 $base-spacing;
                margin: 0 auto $base-spacing;
            }
            p {
                padding: 0 $large-spacing;
                span {
                    color: color('500', 'yellow');
                    display: inline-flex;
                    gap: 5px;
                    align-items: baseline;
                    i {
                        display: inline;
                        color: inherit;
                    }
                }
            }

            .image-left,
            .image-right {
                position: absolute;
                top: 0;
            }
            .image-left {
                left: 0;
            }
            .image-right {
                right: 0;
            }
            .ball-blue,
            .ball-green {
                position: absolute;
                bottom: -10px;
            }
            .ball-blue {
                left: 0;
            }
            .ball-green {
                right: 0;
            }
            @media (max-width: 1200px) {
                .image-left {
                    transform: translateX(-50%);
                }
                .image-right {
                    transform: translateX(50%);
                }
                .ball-blue {
                    transform: translateX(-20%);
                }
                .ball-green {
                    transform: translateX(20%);
                }
            }
            @include breakpoint('md') {
                .image-left,
                .image-right {
                    display: none;
                }
            }
        }
    }

    &__pack {
        margin-top: $base-spacing;

        h6 {
            color: color('800', 'blue');
        }

        &--left {
            position: relative;
            top: 10px;

            img {
                max-width: 200px;
                margin-right: $base-spacing;
                box-shadow: 0 1px 5px color('300', 'gray');
            }
        }

        &--right {
            height: 160px;
            margin-top: -10px;
        }

        @include breakpoint('md') {
            h6 {
                margin: 0;
            }

            &--left {
                top: 0;

                img {
                    max-width: 100px;
                }
            }
        }
    }

    .container {
        max-width: 1200px;
    }

    &__tables {
        background-color: color('75', 'gray');

        .container {
            max-width: 1200px;
            padding: $base-spacing;

            .col__table {
                padding-right: $small-spacing;
            }

            .col__box {
                padding-left: $small-spacing;
            }
        }

        .table {
            box-shadow: 0 1px 5px color('300', 'gray');
            border: 1px solid color('100', 'gray');
            border-radius: $base-border-radius;
            background-color: #fff;

            thead {
                border-bottom: 1px solid color('100', 'gray');
                font-size: $font-size-xs;
            }

            th,
            td {
                height: $base-element-height;
                padding: 0 0 0 $base-spacing;

                &:nth-child(2),
                &:nth-child(3) {
                    text-align: center;
                }
            }

            td {
                color: color('800', 'gray');
                font-size: $font-size-sm;
            }
        }

        .plans {
            border: 1px solid color('100', 'gray');
            box-shadow: 0 1px 5px color('300', 'gray');
            border-radius: $base-border-radius;
            background: #fff;
            margin: 0 0 $base-spacing;
            cursor: pointer;

            &:hover {
                border-color: color('200', 'gray');
            }

            &:last-child {
                margin: 0;
            }

            .ribbon {
                position: relative;
                display: inline-block;
                min-width: 200px;
                height: 24px;
                line-height: 24px;
                margin-top: $base-spacing;
                padding-left: $base-spacing;
                background: color('700', 'green');
                font-size: $font-size-xs;
                color: #fff;

                &:after {
                    position: absolute;
                    top: 50%;
                    right: -12px;
                    transform: translateY(-50%) rotate(45deg);
                    width: 24px;
                    height: 24px;
                    background: #fff;
                    content: '';
                }

                + .content {
                    padding-top: $small-spacing;
                }
            }

            .content {
                position: relative;
                padding: $base-spacing;
                padding-right: 190px;
            }

            span {
                display: block;
            }

            .title {
                font-size: 1em;
                font-weight: $font-weight-bold;
                text-transform: uppercase;
                color: color('900', 'blue');
            }

            .price {
                strong {
                    color: color('600', 'green');
                }

                small {
                    font-size: $font-size-sm;
                    color: color('900', 'blue');
                    font-weight: $font-weight-regular;
                }
            }

            .info {
                font-size: $font-size-xs;
                color: color('600', 'gray');
                line-height: $line-height-175;

                b {
                    color: color('900', 'blue');
                }
            }

            .vat {
                color: color('900', 'blue');
                font-size: $font-size-2xs;
                line-height: $line-height-175;
            }

            .bj-button {
                position: absolute;
                top: 50%;
                right: 1.5em;
                transform: translateY(-50%);
                cursor: pointer;
            }
        }

        @include breakpoint('md') {
            .container {
                .col__table {
                    padding: 0 $extra-small-spacing;
                    margin-bottom: $base-spacing;

                    img {
                        width: 20px;
                        height: 20px;
                    }
                }

                .col__box {
                    padding: 0 $extra-small-spacing;
                }
            }

            .table {
                th {
                    &:nth-child(2),
                    &:nth-child(3) {
                        font-size: 11px;
                        padding: 0 $extra-small-spacing;
                    }
                }

                td {
                    padding: 0;

                    &:nth-child(1) {
                        padding: 0 $small-spacing;
                    }
                }
            }
        }

        @include breakpoint('sm') {
            .plans {
                overflow: hidden;
                position: relative;

                .content {
                    padding: $base-spacing;
                    text-align: center;

                    .bj-button {
                        position: relative;
                        transform: none;
                        top: inherit;
                        right: inherit;
                        margin-top: $small-spacing;
                    }
                }

                .ribbon {
                    transform: rotate(-45deg);
                    text-align: center;
                    padding: 0;
                    position: absolute;
                    left: -65px;
                    top: 0px;
                }
            }
        }
    }

    &__advantages {
        text-align: center;
        margin: $extra-large-spacing 0 $base-spacing;

        img {
            max-width: 96px;
            height: 96px;
        }

        @include breakpoint('sm') {
            text-align: left;

            img {
                max-width: 100%;
                height: auto;
            }

            h6 {
                font-size: 18px;
                margin: 0 0 $small-spacing;
            }
        }
    }

    &__twitter {
        background-color: color('75', 'gray');
        padding: $extra-large-spacing 0;

        .avatar {
            width: $base-element-height;
            height: $base-element-height;
            margin: 0 $small-spacing 0 0;
            img {
                width: auto;
                height: 100%;
                object-fit: cover;
            }
        }

        .name {
            margin: 0 0 $extra-small-spacing;
            color: color('800', 'gray');
            font-weight: $font-weight-semibold;
        }

        .username {
            color: color('500', 'gray');
        }

        .likes {
            position: relative;
            margin: 0 $large-spacing 0 0;
            padding-left: $extra-small-icon-width + $extra-small-spacing;

            i {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }

        .container {
            text-align: center;
        }

        article {
            .content {
                position: relative;
                padding: $base-spacing;
                padding-bottom: $base-element-height + $base-spacing;
                border-radius: $base-border-radius;
                background-color: color('light', 'background');
                box-shadow: $box-shadow;
                text-align: left;
            }

            header,
            section,
            footer {
                display: flex;
                align-items: center;
                // font-size: $font-size-sm;

                p {
                    margin: 0;
                }

                &:not(footer) {
                    margin: 0 0 $base-spacing;
                }
            }

            header {
                span {
                    display: block;
                }

                p {
                    margin: 0;
                    line-height: 1;
                }
            }

            section {
                color: color('800', 'gray');
                font-weight: $font-weight-semibold;
            }

            footer {
                position: absolute;
                bottom: 0;
                left: 0;
                height: $base-element-height;
                padding: $base-spacing;
                color: color('400', 'gray');
                line-height: $base-element-height;
            }
        }
    }

    &__swiper {
        overflow: hidden;
        margin: 0 $small-spacing * -1;

        article {
            padding: $small-spacing;
        }

        & > div {
            display: flex;
            flex-wrap: nowrap;

            article,
            article .content {
                height: 100%;
            }
        }

        & ~ .swiper__navigation {
            display: block;
        }
    }
}

.premium__plans {
    text-align: center;
    margin: $extra-large-spacing 0;

    // &.not-premiumplus {
    //     margin-top: -$large-spacing;
    // }

    &__box-holder {
        &:first-child {
            margin: 0 $base-spacing 0 auto;

            .premium__plans__box {
                margin-left: auto;
            }
        }
        &:last-child {
            margin: 0 auto 0 $base-spacing;
        }
    }

    &__box {
        width: $box-pricing-width;

        &__inner {
            background-color: #fff;
            box-shadow: 0 0 10px rgba(95, 125, 149, 0.3);
            border-radius: $base-border-radius;
            padding: $extra-large-spacing $base-spacing;
            height: 100%;
            position: relative;

            .uppercase {
                letter-spacing: 1px;
            }
        }

        &__price {
            font-size: 56px;
            line-height: 56px;
            text-align: left;

            &__info-wrap {
                font-size: 16px;
                line-height: normal;
                margin-left: $extra-small-spacing;
            }
        }
    }

    .price-original + .premium__plans__box__price {
        border-left: 1px solid color('100', 'gray');
        padding-left: $small-spacing;
    }

    .ribbon {
        width: 125px;
        height: 98px;
        overflow: hidden;
        position: absolute;
        top: -5px;
        right: -5px;

        &:before,
        &:after {
            content: '';
            position: absolute;
            display: block;
            border: 2px solid color('800', 'yellow');
            border-top-color: transparent;
            border-right-color: transparent;
        }
        &:before {
            top: 0px;
            left: 27px;
        }
        &:after {
            bottom: 0;
            right: 0;
        }
        span {
            position: absolute;
            display: block;
            width: 226px;
            padding: 4px 0;
            background-color: color('500', 'yellow');
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
            color: #fff;
            text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
            text-transform: uppercase;
            text-align: center;
            left: -25px;
            top: 30px;
            font-size: 13px;
            transform: rotate(45deg);
            line-height: 18px;
            font-weight: $font-weight-bold;
        }
    }

    .tooltip {
        &__content {
            width: 260px;
            white-space: normal;
        }
    }

    .vat {
        font-size: 11px;
    }

    &.premium__plans--two-products {
        @include breakpoint('md') {
            .premium__plans__box-holder {
                margin: 0;

                &:first-child .premium__plans__box {
                    margin: 0 $small-spacing 0 auto;
                }
                &:last-child .premium__plans__box {
                    margin: 0 auto 0 $small-spacing;
                }
            }
        }

        @include breakpoint('sm') {
            .premium__plans__box-holder {
                width: 100%;
                @include flex(none);

                &:first-child {
                    margin: 0;

                    .premium__plans__box {
                        margin: 0 auto;
                    }
                }
                &:last-child {
                    margin: 0 0 $base-spacing;

                    .premium__plans__box {
                        margin: 0 auto;
                    }
                }
            }

            .premium__plans__box--monthly {
                @include order(1);
            }
            .premium__plans__box--annual {
                @include order(0);
            }
        }
    }

    &.premium__plans--three-products {
        .premium__plans__box {
            margin: 0 $small-spacing;
        }

        @include breakpoint('xl') {
            .premium__plans__box {
                margin: 0 $extra-small-spacing;
            }
        }

        @include breakpoint('lg') {
            .premium__plans__box-holder {
                width: 100%;
                @include flex(none);
                margin: 0 0 $base-spacing;

                .premium__plans__box {
                    margin: 0 auto;
                }
            }
        }
    }
}
