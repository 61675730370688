.tooltip {
    $tooltip-gutter: $small-spacing;
    $tooltip-triangle: 12px;
    $tooltip-box-width: 300px;

    $small-spacing: 12px;

    position: relative;

    &:hover {
        .tooltip__trigger--always,
        .tooltip__trigger--enabled:not(disabled),
        .tooltip__trigger--disabled[disabled] {
            &::after {
                display: block;
            }

            & ~ .tooltip__content {
                display: block;

                &:not(.tooltip__content--permanent):hover {
                    display: none;
                }
            }
        }
    }

    &__content {
        @include position(absolute, null null null null);

        display: none;
        color: white;
        font-size: $font-size-xs;
        text-align: center;
        white-space: nowrap;
        z-index: 333;

        .content {
            padding: ($small-spacing / 2) $small-spacing;

            border-radius: $base-border-radius;

            background-color: color('900', 'gray');

            box-shadow: 0 2px 60px color('900', 'gray', 0.2);
            p {
                margin-bottom: 4px;
            }
        }
    }

    &__trigger {
        position: relative;

        &::after {
            display: none;

            content: '';

            z-index: 323;
        }
    }

    &--box {
        .tooltip__content {
            width: $tooltip-box-width;

            color: color('900', 'gray');
            white-space: inherit;

            .content {
                background-color: white;
            }
        }

        &.tooltip--top .tooltip__trigger::after,
        &.tooltip--top-left .tooltip__trigger::after,
        &.tooltip--top-right .tooltip__trigger::after {
            border-top-color: white;
        }

        &.tooltip--bottom .tooltip__trigger::after,
        &.tooltip--bottom-left .tooltip__trigger::after,
        &.tooltip--bottom-right .tooltip__trigger::after {
            border-bottom-color: white;
        }
    }

    // Position
    &--left,
    &--right {
        .tooltip__content {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &--left {
        .tooltip__content {
            right: 100%;
            padding-right: $tooltip-gutter;
            padding-right: 10px;
        }
        .tooltip__trigger::after {
            @include position(absolute, 50% 100% null null);
            @include triangle($tooltip-triangle, color('900', 'gray'), right);
            @include transform(translateY(-50%));

            margin: 0 ($small-spacing / 3) 0 0;
        }
    }

    &--right {
        .tooltip__content {
            left: 100%;
            padding-left: $tooltip-gutter;
            padding-left: 10px;
        }
        .tooltip__trigger::after {
            @include position(absolute, 50% null null 100%);
            @include triangle($tooltip-triangle, color('900', 'gray'), left);
            @include transform(translateY(-50%));

            margin: 0 0 0 ($small-spacing / 3);
        }
    }

    &--top,
    &--top-left,
    &--top-right {
        .tooltip__content {
            bottom: 100%;

            padding: 0 0 $tooltip-gutter;
        }

        .tooltip__trigger::after {
            @include position(absolute, null null 100% 50%);
            @include triangle($tooltip-triangle, color('900', 'gray'), down);
            @include transform(translate(-50%));

            margin: 0 0 ($small-spacing - ($tooltip-triangle / 2)) 0;
        }
    }

    &--top {
        .tooltip__content {
            @include transform(translateX(-50%));

            left: 50%;
        }
    }

    &--top-left {
        .tooltip__content {
            left: 0;
        }
    }

    &--top-right {
        .tooltip__content {
            right: 0;
        }
    }

    &--bottom,
    &--bottom-left,
    &--bottom-right {
        .tooltip__content {
            top: 100%;

            padding: $tooltip-gutter 0 0;
        }

        .tooltip__trigger::after {
            @include position(absolute, 100% null null 50%);
            @include triangle($tooltip-triangle, color('900', 'gray'), up);
            @include transform(translate(-50%));

            margin: ($small-spacing - ($tooltip-triangle / 2)) 0 0 0;
        }
    }

    &--bottom {
        .tooltip__content {
            @include transform(translateX(-50%));

            left: 50%;
        }
    }

    &--bottom-left {
        .tooltip__content {
            left: 0;
        }
    }

    &--bottom-right {
        .tooltip__content {
            right: 0;
        }
    }
}
