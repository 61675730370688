.code-browser {
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 0 20px color('700', 'blue', 0.3);

    &__header {
        background-color: color('75', 'gray');
        height: 24px;

        span {
            background-color: color('200', 'gray');
            border-radius: 50%;
            margin: 8px 0px 0px 10px;
            width: 9px;
            height: 9px;
            display: block;
            float: left;
        }
    }

    &__content {
        background-color: color('900', 'gray');
        padding: $base-spacing $small-spacing;
    }
}

code {
    font-family: Consolas, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono,
        Courier New, monospace;
    color: color('text-inverted', 'general');
    white-space: pre-line;
    word-break: break-word;
    font-weight: $font-weight-bold;

    .red {
        color: color('red', 'state');
    }
    .green {
        color: color('green', 'state');
    }
    .yellow {
        color: color('100', 'yellow');
    }
    .blue {
        color: color('100', 'blue');
    }
    .purple {
        color: color('200', 'purple');
    }
}

.mono {
    font-family: Consolas, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono,
        Courier New, monospace;
}
