#notification-cookie {
    background-color: color('900', 'gray');

    color: color('text-inverted', 'general');

    .button {
        box-shadow: inset 0 0 0 100px color('800', 'gray');
        background-color: transparent;

        &:hover {
            box-shadow: inset 0 0 0 100px color('600', 'gray');
        }
    }

    .icon {
        margin-bottom: 2px;
    }

    .row {
        @include prefixer(justify-content, flex-start, spec ms);

        padding: $small-spacing;
        padding-right: ($base-spacing + $small-element-height);
    }
}
