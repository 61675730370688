.uicons__related {
    margin-bottom: 70px;
    .container {
        padding: 0 30px;
    }
    &--header {
        .link--arrow.ui-most {
            padding: 0;
        }
        .link--arrow.ui-most:hover {
            background: none;
        }
    }

    @include breakpoint('md') {
        .container {
            padding: 0 $base-spacing;
            .uicons__icons {
                width: calc(100% + 20px);
                margin-left: -10px;
            }
        }
    }
}
.icon__font__in__action {
    h2.font-h6 {
        font-size: $font-size-lg;
    }
    > p {
        color: color('700', 'gray');
    }
    &--examples {
        width: calc(100% + 20px);
        margin-left: -10px;
        > div {
            height: $base-spacing;
            margin: $small-spacing;
            border-radius: 12px;
            height: 230px;
            width: calc(33% - 20px);
            overflow: hidden;
        }
        > div:nth-child(1) {
            background: linear-gradient(115.08deg, rgba(199, 235, 255, 0.5) 0%, rgba(94, 178, 252, 0.5) 98.5%);
            .phone-example {
                width: 284px;
                border: 8px solid color('800', 'gray');
                box-shadow: 0px 42px 109px rgba(4, 69, 143, 0.16), 0px 9.38125px 24.3466px rgba(4, 69, 143, 0.0953772),
                    0px 2.79304px 7.24861px rgba(4, 69, 143, 0.0646228);
                border-radius: 32px;
                height: 241px;
                margin: -40px auto 0;
                background-color: color('100', 'gray');
                position: relative;
                overflow: hidden;
                &--text {
                    height: 75px;
                    background-color: color('50', 'gray');
                    position: absolute;
                    bottom: 58px;
                    width: 100%;
                    p {
                        padding: $small-spacing 30px;
                    }
                }
                &--buttons {
                    position: absolute;
                    padding: 0 30px;
                    background-color: white;
                    bottom: 0;
                    height: 58px;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    > span {
                        width: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &:hover {
                            background-color: color('50', 'gray');
                            transition: all ease-in 0.2s;
                            box-shadow: inset 0 7px 1px -7px rgba(0, 0, 0, 0.4);
                        }
                    }
                    span:nth-child(1) {
                        box-shadow: 0 2px 0 0 color('500', 'blue') inset;
                        img {
                            filter: invert(41%) sepia(50%) saturate(6906%) hue-rotate(203deg) brightness(96%)
                                contrast(92%);
                        }
                    }
                }
            }
        }
        > div:nth-child(2) {
            background: linear-gradient(114.93deg, #ececff 2.23%, color('100', 'purple') 98.59%);
            .notifications-example {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                height: 100%;
                > div {
                    width: 235px;
                    border-radius: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    text-align: left;
                }
                &--1 {
                    background: color('400', 'purple');
                    box-shadow: 0px 1px 2px rgba(32, 32, 149, 0.1), 0px 2px 5px rgba(32, 32, 149, 0.15);
                    margin-bottom: $base-spacing;
                    color: white;
                    transition: all ease-in 0.2s;
                    &:hover {
                        transform: scale(1.02);
                    }
                    img {
                        filter: invert(100%);
                    }
                }
                &--2 {
                    background: white;
                    box-shadow: 0px 1px 2px rgba(32, 32, 149, 0.1), 0px 2px 5px rgba(32, 32, 149, 0.15);
                    color: color('500', 'purple');
                    transition: all ease-in 0.2s;
                    &:hover {
                        transform: scale(1.02);
                    }
                    img {
                        filter: invert(29%) sepia(97%) saturate(4852%) hue-rotate(238deg) brightness(95%) contrast(89%);
                    }
                }
            }
        }
        > div:nth-child(3) {
            background: linear-gradient(114.93deg, #d9ffed 2.23%, #b4ffdb 98.59%);
            display: flex;
            align-items: center;
            justify-content: center;
            .menu-example {
                width: 225px;
                background-color: color('800', 'gray');
                box-shadow: 0px 42px 109px rgba(0, 139, 113, 0.16), 0px 9.38125px 24.3466px rgba(0, 139, 113, 0.0953772),
                    0px 2.79304px 7.24861px rgba(0, 139, 113, 0.0646228);
                border-radius: 18px;
                padding: $base-spacing;
                > div {
                    padding: $small-spacing $base-spacing;
                    color: color('300', 'gray');
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    border-radius: 12px;
                    transition: all ease-in 0.2s;
                    &:hover {
                        background: rgba(29, 38, 45, 0.2);
                    }
                }
                > div:nth-child(1) {
                    background: rgba(29, 38, 45, 0.5);
                    color: white;

                    img {
                        filter: invert(100%);
                    }
                }
            }
        }
        > div:nth-child(4) {
            background: linear-gradient(115.08deg, rgba(255, 222, 204, 0.5) 0%, rgba(255, 200, 175, 0.5) 98.5%);
            width: calc(40% - 20px);
            display: flex;
            align-items: center;
            justify-content: center;
            .sizes-example {
                width: 340px;
                box-shadow: 0px 42px 109px rgba(164, 25, 25, 0.16), 0px 9.38125px 24.3466px rgba(164, 25, 25, 0.0953772),
                    0px 2.79304px 7.24861px rgba(164, 25, 25, 0.0646228);
                border-radius: 18px;
                overflow: hidden;
                > div {
                    height: 85px;
                    width: 100%;
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                }
                &--white {
                    background-color: white;
                    img {
                        filter: invert(23%) sepia(48%) saturate(302%) hue-rotate(164deg) brightness(96%) contrast(88%);
                        transition: all ease-in 0.2s;
                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }
                &--black {
                    background-color: color('800', 'gray');
                    img {
                        filter: invert(100%);
                        transition: all ease-in 0.2s;
                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
        > div:nth-child(5) {
            background: linear-gradient(97.41deg, #fff3cd 0%, #ffe28f 99.65%);
            width: calc(59% - 20px);
            padding: 40px 50px;
            overflow: hidden;
            .tablet-example {
                width: 700px;
                border: 8px solid color('800', 'gray');
                border-radius: 16px;
                height: 300px;
                overflow: hidden;
                background-color: color('50', 'gray');
                box-shadow: 0px 42px 109px rgba(109, 82, 19, 0.16), 0px 9.38125px 24.3466px rgba(109, 82, 19, 0.0953772),
                    0px 2.79304px 7.24861px rgba(109, 82, 19, 0.0646228);
                display: flex;
                &--sidebar {
                    width: 68px;
                    height: 100%;
                    background-color: color('75', 'gray');
                    padding-top: 40px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    img {
                        margin-bottom: 22px;
                    }
                }
                &--body {
                    height: 100%;
                    flex: 1;
                    padding: 30px;
                    > p {
                        font-size: 20px;
                    }
                    span {
                        margin-bottom: 15px;
                        color: color('800', 'gray');
                        display: block;
                    }
                    &--frame {
                        width: 144px;
                        height: 140px;
                        background-color: color('75', 'gray');
                        display: inline-block;
                        vertical-align: middle;
                        border-radius: 18px;
                        margin-right: $small-spacing;
                        padding: 16px;
                        transition: all ease-in 0.2s;
                        &:hover {
                            margin-top: -5px;
                        }
                        > div {
                            background-color: color('100', 'gray');
                            border-radius: 50px;
                            width: 48px;
                            height: 48px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                    &--frame.yellow {
                        background-color: color('200', 'yellow');
                        > div {
                            background-color: color('100', 'yellow');
                            img {
                                filter: invert(24%) sepia(36%) saturate(382%) hue-rotate(163deg) brightness(97%)
                                    contrast(89%);
                            }
                        }
                    }
                }
            }
        }
    }

    @include breakpoint('lg') {
        &--examples {
            > div:nth-child(1) {
                width: calc(50% - 20px);
            }
            > div:nth-child(2) {
                width: calc(50% - 20px);
            }
            > div:nth-child(3) {
                width: calc(40% - 20px);
            }
            > div:nth-child(4) {
                width: calc(60% - 20px);
            }
            > div:nth-child(5) {
                display: none;
            }
        }
    }
    @include breakpoint('md') {
        margin-bottom: 40px;
        .container {
            padding: 0 $base-spacing;
        }
        &--examples {
            > div:nth-child(1) {
                width: calc(100% - 20px);
            }
            > div:nth-child(2) {
                width: calc(100% - 20px);
            }
            > div:nth-child(3) {
                width: calc(100% - 20px);
            }
            > div:nth-child(4) {
                width: calc(100% - 20px);
            }
        }
    }

    @include breakpoint('sm') {
        &--examples {
            > div:nth-child(4) {
                display: none;
            }
        }
    }
}
.find-best-icon-font {
    max-width: calc(1050px + 60px);
    margin: 0 auto;
    color: color('800', 'gray');
    margin-bottom: 70px;
    .container {
        padding: 0 30px;
    }
    &--content {
        background: linear-gradient(
            98.75deg,
            rgba(179, 179, 252, 0.25) 2.58%,
            rgba(199, 235, 255, 0.25) 47.57%,
            rgba(199, 255, 199, 0.25) 98.55%
        );
        border-radius: 12px;
        padding: 40px 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        a {
            line-height: 1.2;
        }
    }
    @include breakpoint('lg') {
        &--content {
            flex-direction: column;
            &--text {
                text-align: center;
                margin-bottom: 30px;
            }
        }
    }
    @include breakpoint('md') {
        margin-bottom: 40px;
        .container {
            padding: 0 $base-spacing;
        }
    }
}
.doubts-support {
    max-width: calc(1050px + 60px);
    margin: 0 auto;
    color: color('700', 'gray');
    .container {
        padding: 0 30px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 90px;
    }

    a {
        color: color('800', 'green');
    }
    &--doubts,
    &--support {
        width: 40%;
    }
    @include breakpoint('md') {
        .container {
            margin-bottom: 40px;
        }
        &--doubts,
        &--support {
            width: 100%;
        }
    }
}
