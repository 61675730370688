.search-data {
    // Relativo a un autor dentro de autores
    /* Relativo a las cabeceras de:
       - Autores
       - Top icons
       - New icons
    */
    &.no-results {
        display: flex;
        justify-content: flex-end;

        .popover-share {
            margin: 20px 10px;
        }
    }

    // Relativo a resultado de búsqueda de packs
    // Relativo a los packs desde la home
    &.packs-data {
        // ANCHOR: COMENTADO
        // display: flex;

        .packs-data--box {
            display: flex;
            // padding-top: 20px;

            .search-data--box {
                display: flex;

                .total-search-result {
                    display: flex;
                    align-items: center;
                    margin-right: 20px;
                    align-items: baseline;
                    flex-wrap: wrap;
                    // ANCHOR: COMENTADO
                    // margin: 20px 10px 20px 0;

                    // ANCHOR: COMENTADO
                    // @include breakpoint('md') {
                    // margin: 20px 0 0 0;
                    // }

                    h1 {
                        margin: 0;
                        margin-bottom: 10px;
                    }

                    &.icons-result {
                        // ANCHOR: COMENTADO
                        // flex-direction: column;
                        // margin-right: 20px;

                        > div {
                            display: flex;
                            align-items: center;
                        }
                    }
                }

                .link--tag-stickers {
                    i {
                        color: color('800', 'green');
                    }
                }

                .tags {
                    // ANCHOR: COMENTADO
                    // margin: 20px 0;

                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;

                    @include breakpoint('md') {
                        // ANCHOR: COMENTADO
                        // margin: 10px 0 20px 0;
                    }

                    li {
                        display: inline-flex;
                        align-items: center;
                        background: color('75', 'gray');
                        color: color('700', 'gray');
                        font-size: 14px;
                        // height: 30px;
                        border-radius: $base-border-radius;
                        margin: 0 5px 0 0;
                        padding: 5px;

                        @include breakpoint('md') {
                            margin: 8px 5px 0 0;
                        }

                        .filters_delete_tag {
                            color: color('800', 'gray');
                            margin-left: 5px;
                            font-size: 16px;
                            font-weight: $font-weight-bold;
                        }

                        &.delete-all {
                            background: none;
                            padding: 0;

                            @include breakpoint('xs') {
                                display: none;
                            }

                            .filters_delete_all {
                                width: auto;
                                font-weight: $font-weight-regular;
                                font-size: 14px;
                                color: color('700', 'gray');
                                text-decoration: underline;

                                &:hover {
                                    color: color('600', 'red');
                                }
                            }
                        }
                    }
                }

                .popover-share {
                    position: static;
                    // ANCHOR: COMENTADO
                    // margin: 20px 0;
                }

                &:first-child {
                    flex: 1;

                    @include breakpoint('md') {
                        // flex-direction: column;
                        align-items: flex-start;
                    }
                }

                // + .search-data--box {
                //     justify-content: flex-end;
                // }
            }

            .popover-share {
                .popover-content {
                    z-index: 10;
                }
            }
        }

        .origin-search--notice,
        .original-search {
            margin-top: 5px;
            font-size: 14px;
        }

        .origin-search--notice {
            display: flex;
            flex-wrap: wrap;
            background-color: rgba(252, 161, 32, 0.1);
            width: fit-content;
            padding: $extra-small-spacing $small-spacing;
            border-radius: $base-border-radius;
            font-weight: $font-weight-bold;

            p {
                margin-bottom: 0;
                margin-right: 5px;
                color: color('700', 'gray');
            }
            a {
                color: color('800', 'yellow');
            }
        }
    }
}
