#tooltip-popper {
    background: #1F262C;
    color: white;
    padding: 12px 15px;
    font-size: 13px;
    border-radius: 2px;
    z-index: 30;
    display: none;

    &[data-show] {
        display: block;
        }
    &[data-popper-placement^='top'] > #arrow-popper {
        bottom: -4px;
    }
    
    &[data-popper-placement^='bottom'] > #arrow-popper {
        top: -4px;
    }
    
    &[data-popper-placement^='left'] > #arrow-popper {
        right: -4px;
    }
    
    &[data-popper-placement^='right'] > #arrow-popper {
        left: -4px;
    }

    #arrow-popper{
        position: absolute;
        width: 8px;
        height: 8px;
        background: inherit;
        visibility: hidden;

        &::before{
            position: absolute;
            width: 8px;
            height: 8px;
            background: inherit;
            visibility: visible;
            content: '';
            transform: rotate(45deg);
        }

    }
  }


