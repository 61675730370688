$base-duration: 0ms;
$base-timing: cubic-bezier(1.000, 0.000, 0.000, 1.000);
$ui-duration: 0ms;
$ui-timing: cubic-bezier(1.000, 0.000, 0.000, 1.000);
$feedback-duration: 150ms;
$feedback-timing: cubic-bezier(1.000, 0.000, 0.000, 1.000);
$breakpoints: (
    xs: 480px,
    sm: 640px,
    md: 768px,
    lg: 992px,
    xl: 1400px
);
$grid-columns-spacing: 5px;
$base-spacing: 20px;
$small-spacing: 10px;
$extra-small-spacing: 5px;
$flaticon: (
    0: (
    name: midnight-blue,
    background: #222,
    text: #fff
    ),
    1: (
    name: emerland,
    background: #91dc5a,
    text: #fff
    ),
    2: (
    name: sun-flower,
    background: #ffda44,
    text: #222
    ),
    3: (
    name: amethyst,
    background: #933ec5,
    text: #fff
    ),
    4: (
    name: alizarin,
    background: #d80027,
    text: #fff
    ),
    5: (
    name: peter-river,
    background: #006df0,
    text: #fff
    ),
    6: (
    name: clouds,
    background: #fff,
    text: #222
    )
);