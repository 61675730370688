.collections__tour {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 999;
    color: color('text', 'general');
    overflow: auto;
    border-left: 1px solid color('75', 'gray');

    .button__close {
        position: absolute;
        top: 0;
        right: 0;
        width: $base-element-height;
        height: $base-element-height;
        line-height: $base-element-height;

        i {
            display: inline-block;
            color: color('800', 'gray');
        }
    }

    &__content {
        width: 100%;
        max-width: 280px;
        margin: 0 auto;
        margin-top: 40px;
    }

    &__item {
        font-size: $font-size-sm;
        border-bottom: 1px solid color('75', 'gray');
        padding: $small-spacing 0;

        &:last-child {
            border-bottom: none;
        }

        input[type='checkbox'] {
            display: none;
            appearance: none;
            -webkit-appearance: none;

            &:checked {
                + label {
                    .icon--plus {
                        display: none;
                    }
                    .icon--minus {
                        display: block;
                    }
                }

                ~ div {
                    max-height: 500px;
                    transition: max-height 500ms ease-in;

                    p {
                        transform: none;
                        opacity: 1;
                        transition: transform 300ms ease-in, opacity 300ms ease-in;
                    }
                }
            }
        }

        label {
            cursor: pointer;
            display: block;
            position: relative;

            .icon--plus,
            .icon--minus {
                position: absolute;
                top: 0;
                right: 0;
                line-height: 24px;
                color: color('600', 'gray');
            }

            .icon--plus {
                display: block;
            }
            .icon--minus {
                display: none;
            }
        }

        i {
            display: inline-block;
            color: color('800', 'green');
            vertical-align: middle;
            margin-right: $extra-small-spacing;
        }

        span {
            vertical-align: middle;
        }

        div {
            max-height: 0;
            overflow: hidden;
        }

        p {
            margin: 0;
            transform: translateY(10px);
            opacity: 0;
        }
    }
}
