.color-palette {
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 34px;
        > i {
            vertical-align: -1px;
        }
        .popover {
            .popover-content {
                border-radius: 8px;
                width: unset;
                min-width: 95px;
                padding: 10px 15px;
                &::after {
                    right: 5px;
                }
                &::before {
                    right: 7px;
                }
                li {
                    label {
                        cursor: pointer;
                        white-space: nowrap;
                    }
                    &:nth-child(2) {
                        span {
                            color: color('600', 'red');
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }

    div[class^='color-palette-wrapper'] {
        position: relative;
        min-height: 34px;
        .tooltip__content {
            left: 0;
            transform: unset;
        }

        .swatches {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;

            .swatch {
                display: inline-block;

                .color {
                    position: relative;
                    display: block;
                    width: 34px;
                    height: 34px;
                    border-radius: 4px;
                    box-shadow: inset 0px 0px 0px 1px rgba(29, 38, 45, 0.1);
                    cursor: pointer;
                    i {
                        position: absolute;
                        color: color('800', 'gray');
                        background-color: white;
                        box-shadow: inset 0px 0px 0px 1px color('400', 'gray');
                        border-radius: 100%;
                        right: -5px;
                        top: -5px;
                        &:hover {
                            background-color: color('500', 'red');
                            color: white;
                            box-shadow: inset 0px 0px 0px 1px rgba(29, 38, 45, 0.1);
                        }
                    }
                }

                &:nth-child(6n) {
                    margin-right: 0;
                }
            }

            @include breakpoint('md') {
                .swatch:nth-child(6n) {
                    margin-right: 10px;
                }
            }
        }
        .palette-edit-color-picker {
            display: inline;
            vertical-align: top;
            margin-right: 10px;
            float: left;
            button::after {
                // border-left: 6px solid transparent;
                // border-right: 6px solid transparent;
                border-top: 6px solid color('800', 'blue');
            }
            .tooltip__content {
                .content {
                    background-color: color('800', 'blue');
                    width: 240px;
                    span {
                        white-space: break-spaces;
                    }
                }
            }
        }

        .pcr-app[data-theme='classic'] {
            // para móvil
            // top: -20px !important;
            // width: calc(100% + 40px);
            // left: -20px !important;
            // fin para móvil

            top: 36px !important;
        }
        .pcr-app .pcr-interaction {
            margin: 0;

            .pcr-result {
                width: 100%;
                text-align: center;
                margin-left: 0;
            }
        }
    }
    .save-cancel-buttons {
        display: flex;

        .save-palette,
        .cancel-edition {
            &:hover,
            &:active {
                text-decoration: none;
            }
        }
        .cancel-edition {
            color: color('800', 'gray');
        }
    }
}

.detail__editor__options.detail__editor__colors {
    position: relative;
}
