.top-stickers-searches {
    margin-bottom: $large-spacing + $extra-small-spacing;
    > h2 {
        color: color('900', 'blue');
    }
    &__content {
        display: flex;
        width: calc(100% + 20px);
        margin-left: -$small-spacing;
        flex-wrap: wrap;
        .mask {
            margin-left: 22px;
            margin-right: 22px;
        }
    }
    &__block {
        width: 20%;
        display: flex;
        flex-wrap: wrap;
    }
    &__item {
        padding: $small-spacing;
        .content {
            position: relative;
            overflow: hidden;
            border-radius: 8px;
            height: 100%;
            text-align: center;
            img {
                margin-top: $large-spacing;
                transition: all ease-in-out 0.2s;
            }
            > p {
                padding: $base-spacing;
                margin: 0;
                text-align: left;
            }
        }
        .content:hover img {
            transform: scale(1.05);
        }
    }
    &__block:nth-child(1) {
        width: 40%;
        > div {
            width: 50%;
        }
        > div:last-child {
            width: 100%;
        }
        .top-stickers-searches__item:nth-child(1) {
            .content {
                background-color: color('100', 'yellow');
            }
        }
        .top-stickers-searches__item:nth-child(2) {
            .content {
                background-color: color('100', 'blue');
            }
        }
        .top-stickers-searches__item:nth-child(3) {
            .content {
                background-color: color('100', 'purple');
            }
        }
    }
    &__block:nth-child(2) {
        width: 20%;
        div {
            width: 100%;
        }
        .top-stickers-searches__item:nth-child(1) {
            .content {
                background-color: color('500', 'green', 0.2);
            }
        }
        .top-stickers-searches__item:nth-child(2) {
            .content {
                background-color: color('100', 'red');
            }
        }
    }
    &__block:nth-child(3) {
        width: 40%;
        div {
            width: 100%;
        }
        .top-stickers-searches__item {
            .content {
                text-align: left;
                padding: 60px 50px;
                background-color: color('75', 'gray');
                img {
                    position: absolute;
                    bottom: $extra-large-spacing;
                    right: $large-spacing;
                }
                p {
                    padding: 0;
                    margin-bottom: $base-spacing;
                    color: color('800', 'gray');
                }
                ul {
                    display: inline-block;
                    li {
                        padding: $small-spacing 0;
                        text-align: left;
                        a {
                            color: color('800', 'gray');
                        }
                    }
                }
                ul:last-child {
                    display: none;
                }
            }
        }
    }

    @include breakpoint('xl') {
        &__content {
            img {
                height: auto;
            }

            .top-stickers-searches__block:nth-child(1) {
                width: 66.6%;
                // .top-stickers-searches__item{
                //   width: 50%;
                // }
            }
            .top-stickers-searches__block:nth-child(2) {
                width: 33.3%;
                // .top-stickers-searches__item{
                //   width: 50%;
                // }
            }
            .top-stickers-searches__block:nth-child(3) {
                width: 100%;
                .content {
                    ul:last-child {
                        display: inline-block;
                        margin-left: $extra-large-spacing * 2;
                    }
                }
            }
        }
    }
    @include breakpoint('lg') {
        &__content {
            .top-stickers-searches__block:nth-child(1) {
                width: 66.6%;
                // .top-stickers-searches__item{
                //   width: 50%;
                // }
            }
            .top-stickers-searches__block:nth-child(2) {
                width: 33.3%;
                // .top-stickers-searches__item{
                //   width: 50%;
                // }
            }
            .top-stickers-searches__block:nth-child(3) {
                .content {
                    ul:last-child {
                        display: none;
                    }
                }
            }
        }
    }
    @include breakpoint('md') {
        &__content {
            .top-stickers-searches__block:nth-child(1),
            .top-stickers-searches__block:nth-child(2) {
                width: 100%;
            }
        }
    }
    @include breakpoint('sm') {
        &__content {
            .top-stickers-searches__block:nth-child(1) {
                .top-stickers-searches__item {
                    width: 100%;
                }
                .mask {
                    overflow: hidden;
                    width: 165px;
                    margin-left: unset;
                    margin-right: unset;

                    margin: auto;
                    img {
                        max-width: unset;
                        transform-origin: 82px 82px;
                    }
                }
            }
            .top-stickers-searches__block:nth-child(3) {
                .content {
                    img {
                        display: none;
                    }
                }
            }
        }
    }
}

.collections-visible {
    .top-stickers-searches {
        @media (max-width: 1760px) {
            &__content {
                img {
                    height: auto;
                }

                .top-stickers-searches__block:nth-child(1) {
                    width: 66.6%;
                }
                .top-stickers-searches__block:nth-child(2) {
                    width: 33.3%;
                }
                .top-stickers-searches__block:nth-child(3) {
                    width: 100%;
                    .content {
                        ul:last-child {
                            display: inline-block;
                            margin-left: $extra-large-spacing * 2;
                        }
                    }
                }
            }
        }
    }
}
