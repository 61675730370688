.pagination-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 93px;

    &__item {
        flex: 1;

        &:nth-of-type(2) {
            flex: 2;
        }

        @include breakpoint('md') {
            &:nth-of-type(1),
            &:nth-of-type(3) {
                display: none;
            }
        }

        .pagination {
            text-align: center;
            margin: 10px 5px 40px;

            a {
                margin: $extra-small-spacing $extra-small-spacing;
            }

            @include breakpoint('sm') {
                margin: 10px 5px 30px;
                a {
                    @include prefixer(flex, 1, spec ms);
                    padding: 0 $base-spacing;
                }
            }
        }

        .pagination-right-buttons {
            justify-content: flex-end;
        }

        .pagination-center-buttons {
            justify-content: center;
        }

        .pagination-right-buttons,
        .pagination-center-buttons {
            display: flex;
            align-items: center;
            padding-right: 10px;

            .form-box {
                display: flex;
                align-items: center;
                margin: 20px 0 40px;

                [name='pagination-page'] {
                    border: 1px solid color('200', 'gray');
                    width: 50px;
                    height: 33px;
                    text-align: center;
                    padding: 0;
                    margin: 0;
                    margin-right: 10px;
                }

                .pagination-buttons {
                    display: flex;
                    margin-left: 10px;

                    .pagination-prev,
                    .pagination-next {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 33px;
                        width: 28px;
                        background: color('100', 'gray');
                        color: color('800', 'gray');

                        .flaticon-prev,
                        .flaticon-next {
                            position: relative;
                            top: 2px;
                        }
                    }
                }
            }
        }
    }
    @include breakpoint('sm') {
        &.container {
            padding: 0px 10px;
        }
    }
}
