.section__faq {
    padding: $large-spacing 0;

    li {
        border-radius: 4px;
        border: 1px solid color('200', 'gray');
        position: relative;
        padding: $extra-large-spacing $large-spacing;
        margin-bottom: $base-spacing;
    }

    &__row {
        padding-left: 140px;
        position: relative;

        > strong {
            color: color('700', 'green');
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 120px;
            font-size: $font-size-sm;
        }

        p {
            margin: 0;
        }
    }

    @include breakpoint('md') {
        li {
            padding: $base-spacing;
        }

        &__row {
            padding-left: 0;

            > strong {
                width: auto;
                position: relative;
            }
        }
    }
}
