.landing-api {
    &.overflow-hidden #viewport {
        filter: none;
        -webkit-filter: none;
    }

    #viewport {
        padding-top: 0;
        background: color('900', 'blue');
    }

    .header {
        height: 56px;
        line-height: 56px;
        padding: 0 $base-spacing;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
    }

    .navigation {
        ul {
            li {
                display: inline-block;
                margin-left: $large-spacing;
            }

            a {
                color: color('50', 'gray');
                &:hover {
                    color: color('300', 'gray');
                }
            }
        }

        .user-info {
            font-size: 20px;
            .icon {
                color: color('green', 'state');
            }
        }
    }

    .trigger__button,
    .trigger__close {
        display: none;
    }

    .trigger__close {
        position: absolute;
        top: 0;
        right: 0;
        width: $base-element-height;
        height: $base-element-height;
        line-height: $base-element-height;
    }

    .avatar {
        display: block;
        width: 42px;
        height: 42px;
    }

    .hero {
        text-align: left;
        background: url(~Media/landing/api/bg-api.svg) no-repeat top right;
        padding: 160px 0;
        .links-content {
            display: inline-block;
            &__buttons {
                display: flex;
                a {
                    display: block;
                    line-height: 1.2;
                }
                .documentation-v2:hover {
                    color: color('300', 'gray');
                }
            }
        }
    }

    .screen-holder {
        position: relative;
        background-color: color('75', 'gray');
        border-radius: 4px;
        padding: 0;
    }

    .code-browser {
        position: absolute;
        left: -44px;
        bottom: -60px;
        max-width: 450px;
        font-size: $font-size-sm;

        &__header {
            background-color: color('700', 'blue');

            span {
                background-color: color('800', 'blue');
            }
        }

        &__content {
            text-align: left;
            background-color: color('900', 'blue');
        }

        code {
            white-space: pre;
        }
    }

    .screen {
        width: 74%;
        border-radius: $base-border-radius;
    }

    .sidebar-holder {
        img {
            width: 24.2%;
            position: absolute;
            top: 0;
            right: 1%;
            transition: opacity 0ms ease-in, transform 300ms ease-in;
            transform: translateY(20px);
            opacity: 0;

            &.active {
                transform: none;
                opacity: 1;
            }
        }
    }

    .mobile-screens {
        position: relative;
        width: 60%;
        margin: -50px 20px 100px auto;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            transition: opacity 0ms ease-in, transform 300ms ease-in;
            transform: translateY(20px);
            opacity: 0;
            border-radius: 4px;
            box-shadow: 0 0 10px color('700', 'blue', 0.2);

            &.active {
                transform: none;
                opacity: 1;
            }
        }
    }

    .features {
        padding: 0 $base-spacing;
        color: white;

        .features__inner {
            padding: 50px 60px;
            max-width: 1365px;
            margin: 0 auto;
            border-radius: 16px;
            background-color: color('800', 'blue');
            position: relative;

            i {
                @include font-size($h5-font-size);
                line-height: $base-element-height;
                width: 30px;
                height: 30px;
            }
            b {
                color: color('400', 'green');
            }
            span {
                color: color('400', 'green');
                font-size: 65px;
                font-family: $title-font-family;
            }
        }
    }

    .examples {
        padding: 100px 0;

        .container {
            max-width: 900px;
        }

        .examples__nav {
            text-align: center;
            margin-bottom: $large-spacing;
            display: inline-block;

            li {
                display: inline-block;
                padding: 0 $base-spacing;

                label {
                    display: block;
                    cursor: pointer;
                    font-weight: $font-weight-semibold;
                    height: $small-element-height;
                    line-height: $small-element-height;
                    padding: 0 $small-spacing;
                }
            }
            hr {
                width: 100%;
                margin-top: -1px;
                background-color: color('800', 'blue');
            }
        }

        input {
            display: none;
            appearance: none;

            &:nth-of-type(1):checked {
                ~ .examples__nav {
                    li {
                        &:nth-child(1) {
                            border-bottom: 1px solid color('green', 'state');
                            color: color('400', 'green');
                        }
                    }
                }
                ~ .examples__content {
                    li {
                        &:nth-child(1) {
                            display: block;
                        }
                    }
                }
            }
            &:nth-of-type(2):checked {
                ~ .examples__nav {
                    li {
                        &:nth-child(2) {
                            border-bottom: 1px solid color('green', 'state');
                            color: color('400', 'green');
                        }
                    }
                }
                ~ .examples__content {
                    li {
                        &:nth-child(2) {
                            display: block;
                        }
                    }
                }
            }
            &:nth-of-type(3):checked {
                ~ .examples__nav {
                    li {
                        &:nth-child(3) {
                            border-bottom: 1px solid color('green', 'state');
                            color: color('400', 'green');
                        }
                    }
                }
                ~ .examples__content {
                    li {
                        &:nth-child(3) {
                            display: block;
                        }
                    }
                }
            }
        }

        .code-holder {
            background-color: color('800', 'blue');

            pre {
                padding: $large-spacing;
                text-align: left;
            }
        }

        .examples__content {
            border-radius: 4px;
            box-shadow: 0 0 10px color('700', 'blue', 0.2);
            overflow: hidden;

            li {
                display: none;
            }
        }

        .example-result {
            background-color: color('body', 'background');
        }
    }

    .pricing {
        padding: 100px 0;
        .container {
            max-width: 1400px;
        }
        &__block {
            border-radius: 16px;
            padding: 40px;
            margin: 0 25px 20px;

            ul {
                text-align: left;
            }
            > a {
                width: 100%;
            }
            &:nth-child(1) {
                margin: 0;
                padding: 0;
            }
            &:nth-child(2),
            &:nth-child(3) {
                display: flex;
                flex-direction: column;
                ul {
                    flex: 1;
                }
                > a {
                    align-self: flex-end;
                }
            }
            &:nth-child(2) {
                background-color: color('800', 'blue');
                max-width: 370px;
            }
            &:nth-child(3) {
                background-color: color('500', 'green');
                max-width: 370px;
                > a {
                    background-color: color('800', 'blue');
                }
            }
        }
        @include breakpoint('lg') {
            &__block {
                flex: unset;
                &:nth-child(1) {
                    width: 100%;
                    text-align: center;
                    margin-bottom: $large-spacing;
                }
                &:nth-child(2),
                &:nth-child(3) {
                    margin: 0 $small-spacing 20px;
                    width: calc(50% - 20px);
                }
            }
        }
        @include breakpoint('lg') {
            &__block {
                &:nth-child(2),
                &:nth-child(3) {
                    margin: 0 $small-spacing 20px;
                    width: calc(100% - 20px);
                }
            }
        }
    }

    .modal-api-key {
        .content {
            padding: $extra-large-spacing;
        }

        @include breakpoint('sm') {
            .content {
                padding: $extra-large-spacing $base-spacing;
            }
        }
    }

    .api-key {
        margin: 50px auto 20px;
        max-width: 550px;

        &__box {
            background-color: color('800', 'blue');
            border-radius: 4px;
            height: 68px;
            line-height: 68px;
            overflow: hidden;
        }

        &__key {
            color: color('200', 'gray');
            font-size: $font-size-lg;
        }

        button {
            height: 68px;
            line-height: 68px;
            width: 32px;
        }

        @include breakpoint('sm') {
            &__key {
                font-size: $font-size-sm;
            }
        }
    }

    #footer {
        min-height: 0;
        margin: 0;
        padding: 0;

        div {
            position: relative;
        }

        li {
            display: inline-block;
            a {
                color: color('heading', 'general');
                font-weight: $font-weight-regular;
            }
            span {
                display: inline-block;
                margin: 0 10px;
            }
        }
    }
    @include breakpoint('xl') {
        .features__inner {
            text-align: center;
            .row {
                div:first-child {
                    margin-bottom: $large-spacing;
                }
            }
        }
    }

    @include breakpoint('lg') {
        .hero {
            text-align: center;

            .container {
                max-width: 670px;
            }
        }

        .hero__text {
            max-width: 530px;
            margin: 0 auto 80px;
            .documentation-v2 {
                text-align: left;
            }
        }
        .examples {
            padding: 60px 0;
        }
    }

    @include breakpoint('md') {
        .trigger__button,
        .trigger__close {
            display: block;
        }
        .hero {
            background-position-x: -35%;
        }
        #menu-api {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: color('900', 'blue');
            z-index: 1;

            &.active {
                display: block;
            }

            ul {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }

            li {
                display: block;
                text-align: center;
                margin: 0;
            }
        }

        .code-browser {
            position: relative;
            left: 0;
            bottom: 0;
            max-width: initial;
        }

        .screen-holder {
            background: none;
        }

        .features {
            // padding: 0;

            .features__inner {
                box-shadow: none;
                margin: 0;
                padding: 40px 20px;

                i {
                    display: none;
                }

                span {
                    font-size: 42px;
                    & + p {
                        font-size: 14px;
                    }
                }
            }
        }

        .what-is {
            margin: 60px 0;
        }

        .pricing {
            padding: 60px 0;
        }
    }
    @include breakpoint('sm') {
        .hero {
            .links-content {
                flex-wrap: wrap;
                &__buttons {
                    width: 100%;
                    flex-wrap: wrap;
                    justify-content: center;
                    .container-left {
                        width: 100%;
                    }
                    a {
                        margin-right: 0;
                    }
                    .documentation-v2 {
                        text-align: center;
                        margin-bottom: $base-spacing;
                    }
                }
            }
        }
    }

    @include breakpoint('xs') {
        .logo {
            width: auto;
        }
    }
}

body[data-trigger='menu-api'] {
    overflow: hidden;
}

/* Cursor */
.typed-cursor {
    opacity: 1;
    margin-bottom: -2px;
    height: 15px;
    border-left: 7px solid white;
    animation: blinker 0.9s steps(2, start) infinite;
}

@keyframes typed-cursor {
    to {
        visibility: hidden;
    }
}
