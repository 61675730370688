@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.animation-spinner {
    animation: spin 600ms linear infinite;
}
