.star-rating {
    position: relative;
    display: inline-block;
    overflow: hidden;

    i {
        display: inline-block;
        margin-right: $extra-small-spacing;

        &:last-child {
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .bg {
        i {
            color: color('300', 'gray');
        }
    }

    .active {
        position: absolute;
        top: 0;
        left: 0;
        white-space: nowrap;
        overflow: hidden;

        i {
            color: color('yellow', 'state');
        }
    }
}
