.search-data {
    // @include clearfix;
    // padding: 6px 1em;
    position: relative;
    margin-top: $small-spacing;
    // margin-top: 106px;

    &--packs {
        margin-top: 0px;
    }

    h1,
    #total_icon_badge {
        margin-right: 6px;
        padding-right: 0;
        font-size: $font-size-xl;
        margin-bottom: 0;
        font-family: $base-font-family;
        color: color('800', 'gray');
        align-self: baseline;
    }
    strong {
        color: color('900', 'blue');
    }
}

.list-header {
    @include clearfix;
    padding: 20px;
    position: relative;
    background-color: color('800', 'blue');
    color: #fff;

    &.collection-header {
        background-color: #fff;
    }

    .share {
        @include position(absolute, 24px 24px null null);

        .popover-content i:before {
            vertical-align: middle;
        }
    }

    .list-header-title {
        .tooltip__content {
            padding-left: 0;
        }

        h1 {
            display: inline;
            font-size: 26px;
            line-height: 34px;
            margin-right: 10px;
            padding-right: 0;
            color: color('900', 'blue');

            @media (max-width: 480px) {
                margin-right: 5px;
            }

            i {
                font-size: 20px;

                &:before {
                    width: 26px;
                    height: 26px;
                }
            }
        }

        h2 {
            margin: 0 6px 0 0;
            display: inline-block;
            font-size: 22px;
            line-height: unset;

            .badge {
                color: color('600', 'gray');
                background-color: unset;
                font-weight: $font-weight-regular;
            }
        }

        .flaticon-premium {
            display: flex;
            margin-right: 5px;

            &:before {
                width: 20px;
                height: 20px;

                @media (max-width: 480px) {
                    width: 18px;
                    height: 18px;
                }
            }
        }

        &__box {
            display: flex;
            align-items: center;

            .badge_type {
                margin-right: 12px;
                font-size: 26px;
                text-transform: capitalize;

                @media (max-width: 480px) {
                    font-size: 1.4em;
                }
            }
        }

        .btn-like {
            height: 26px;
            width: 26px;

            margin-bottom: 12px;
            padding: 0;

            background: #fff;

            position: relative;

            display: inline-block;
        }
    }

    .fetch-collection-wrapper {
        margin-top: 2em;
        float: right;
    }

    h1 {
        margin: 0;
        b {
            color: color('600', 'green');
        }

        .badge {
            font-size: 0.5em;
        }
    }

    .formats {
        clear: both;
        float: left;
        margin-top: 0.5em;
    }

    .back {
        @include position(absolute, null 2em 2em null);
    }

    .tagline {
        clear: both;
        margin-top: 24px;
    }

    .author {
        position: relative;
        clear: both;
        float: left;
        // margin-top: 1em;

        @include clearfix;
        .image-profile {
            margin-right: $small-spacing;
        }
        .text-profile {
            p {
                margin: 0;
                display: inline;
                color: color('800', 'gray');
            }

            b {
                color: color('800', 'gray');
            }

            a {
                color: color('800', 'green');
            }

            &.license {
                font-size: 0.9em;
            }
        }
    }

    &.top-icons-header {
        background-color: color('600', 'red');
        color: #fff;

        h1 {
            color: #fff;
        }
    }

    &.latest-icons-header {
        background-color: color('500', 'blue');
        color: #fff;

        h1 {
            color: #fff;
        }
    }

    @media (max-width: 1024px) {
        .list-header--wrapper {
            .list-header-title {
                @include justify-content(center);
            }
        }

        h1,
        h2 {
            font-size: $line-height-160;
            text-align: center;
            float: none;
            padding-right: 0;
        }

        .formats {
            float: none;
            text-align: center;
        }

        .share {
            margin: 0;

            li {
                float: none;
                display: inline-block;

                a {
                    font-size: 0.8em;
                    line-height: 2.3em;
                }
            }

            &:before {
                position: relative;
                left: -5px;
                top: 3px;
            }
        }

        .tagline {
            text-align: center;
            margin-top: 1em;
        }

        .author {
            .avatar {
                display: inline-block;
                vertical-align: middle;
                position: relative;
            }

            p {
                padding: 0;
                font-size: 0.9em;
            }
        }

        &.author-header {
            .author {
                .author-info {
                    padding: 0;

                    ul.author-links {
                        text-align: center;

                        li {
                            float: none;
                            display: inline-block;
                            margin-left: -4px;
                        }
                    }
                }
            }
        }
        &.pack-header {
            .author {
                padding-right: 0;
            }
            .back {
                right: 50%;
                transform: translateX(50%);
                bottom: 1em;
            }
        }
    }

    @include breakpoint('sm') {
        &.collection-header {
            padding-bottom: 2em;
        }
        .fetch-collection-wrapper {
            float: none;
        }
        .author {
            float: none;
        }
    }

    @include breakpoint('xs') {
        padding: 1em;

        .list-header-title {
            h1,
            h2 {
                font-size: 1.4em;
            }
        }

        .tagline,
        .formats {
            font-size: 0.9em;
        }
    }
}

.list-options {
    $list-height: 50px !global;
    $list-item-height: 36px !global;
    @include clearfix;
    position: relative;
    width: 100%;
    height: $list-height;
    padding: 0 $small-spacing;
    background-color: color('75', 'gray');

    &.in-search {
        display: none;
    }

    .btn-share {
        line-height: 0;
    }

    .tooltip.hidden {
        display: flex !important;

        &:hover {
            .tooltip__trigger:after {
                display: none;
            }

            .tooltip__content {
                display: none;
            }
        }
    }

    .tooltip__content .content {
        padding: 12px;
    }

    > form {
        display: block;
        float: left;

        & > *:not(.popover),
        .popover-button {
            line-height: $list-item-height;
        }

        & > * {
            float: left;

            height: $list-item-height;
        }
    }

    #popover-equivalents {
        right: initial;
        left: 0;
        z-index: 3;

        &:before {
            left: 10px;
        }
        &:after {
            left: 8px;
        }

        a {
            color: color('900', 'blue');
        }
    }

    .premium-advise {
        position: absolute;
        left: 1em;
        font-size: 0.8em;
        width: 240px;
        margin-top: 2.5em;
        line-height: 1.2em;
        color: #fff;
        background: color('900', 'blue');
        padding: 0.5em 1.5em 0.5em 0.5em;
        border-radius: $base-border-radius;
        text-align: center;
        z-index: 3;

        &:before {
            content: '';
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 6px solid #333;
            position: absolute;
            top: -6px;
            left: 0.5em;
        }

        button {
            color: #fff;
            position: absolute;
            right: 0;
            top: 50%;

            @include transform(translateY(-50%));

            padding: 0.5em;
            height: 100%;
        }
    }

    .color-selector-wrapper {
        @include clearfix;

        display: none;

        height: $list-item-height;

        margin-right: 2em;

        font-size: 0;
        line-height: $list-item-height;

        .color-selector {
            $color-width: 24px;
            $color-height: $color-width;

            @include transition(opacity 0.1s linear, transform 0.1s linear);

            position: relative;

            display: inline-block;

            width: $color-width;
            height: $color-height;

            margin: 0 6px 0 0;
            padding: 2px;

            border-radius: 25px;

            vertical-align: middle;

            cursor: pointer;

            &::before {
                $selected-width: 10px;
                $selected-height: $selected-width;

                @include position(absolute, 0 null null 0);
                @include size($selected-width $selected-height);
                @include transition(250ms linear);

                opacity: 0;

                display: block;

                margin: (($color-height - $selected-height) / 2) (($color-width - $selected-width) / 2);

                border-radius: 50%;

                background-color: white;

                content: '';
            }

            &.active::before {
                opacity: 1;
            }

            &:hover::before {
                opacity: 0.6;
            }
        }

        .color-selector[data-type='multicolor'] {
            background-image: url('~Media/colorWheel.png');
            background-size: cover;
        }

        .color-selector[data-type='monocolor'] {
            background-color: #000;
        }

        @media (min-width: 1024px) {
            display: inline-block;
        }
    }

    .label-switch {
        margin: 0;
        vertical-align: sub;
        &[for='selection'] {
            margin-right: 1em;
        }
    }

    .infinite_scroll {
        margin-right: 1em;
    }

    .popover-color {
        margin-right: 2em;
    }

    .popover-filter {
        .color_filter {
            display: none;

            @media (max-width: 1024px) {
                display: block;
            }
        }
    }

    // .search_in_pack_wrapper {
    //     display: inline-block;
    //     margin-right: 1em;

    //     span.input-group {
    //         .input-addon {
    //             &.flaticon-magnifier {
    //                 padding: 0;
    //                 left: 8px;
    //                 font-size: 13px;
    //                 width: auto;
    //             }
    //         }

    //         .awesomplete {
    //             margin: 0;
    //             li {
    //                 padding-left: 1em;
    //             }
    //             mark {
    //                 background-color: #a9fd00;
    //             }
    //         }
    //         div:nth-child(2) {
    //             margin: 0;
    //         }
    //         input {
    //             width: 200px;
    //             display: inline-block;
    //             height: 20px;
    //             padding-left: 2em;
    //             border-radius: 20px;
    //             border: none;
    //             font-size: 0.9em;
    //         }

    //         .reset-input {
    //             right: 0.5em;
    //             opacity: 1;
    //         }
    //     }
    // }

    @media (max-width: 1024px) {
        font-size: 0.9em;

        .label-switch {
            vertical-align: bottom;
            &[for='selection'] {
                margin-right: 1em;
            }
        }
    }
    @media (max-width: 480px) {
        .infinite_scroll {
            margin-right: 0;
        }
        .page-counter {
            display: none;
        }

        .search_in_pack_wrapper {
            margin: 0;

            span.input-group {
                input {
                    width: 180px;
                }
            }
        }
    }

    @media (max-height: 800px) {
        &.in-search {
            .tooltip__trigger:after {
                visibility: hidden;
            }

            .tooltip__content {
                visibility: hidden;
            }
        }
    }
}

.grid_selector {
    .radio-group {
        display: inline-block;

        margin: 0;
        padding: 0px;

        label {
            text-align: center;
            display: block;
            color: color('100', 'gray');

            i {
                width: 26px;
                height: 26px;
                line-height: 26px;
            }
        }

        input {
            display: none;

            &:checked ~ label {
                border-radius: $base-border-radius;

                background-color: color('100', 'gray');

                color: color('800', 'gray');
            }
        }
    }
}

.popover-equivalents {
    .popover-button {
        color: color('600', 'gray');
    }

    > span {
        display: none;
    }
}

.user-bar {
    background-color: color('100', 'gray');
    padding: 0.5em;
    border-bottom: 1px solid color('100', 'gray');
    text-align: center;

    .wrapper {
        margin: 0 auto;
        position: relative;

        @include clearfix;
    }

    .badges {
        vertical-align: middle;

        li {
            margin: 0 0.2em;

            img {
                max-width: 38px;
                max-height: 38px;
                vertical-align: middle;
            }
        }
    }

    .btn-follow {
        position: absolute;
        right: 0;
        top: 0;
        width: 180px;

        &.active {
            span:nth-child(2) {
                display: none;
            }

            &:hover {
                background-color: color('600', 'red');
                border-color: color('700', 'red');
                color: #fff;

                span:nth-child(1) {
                    display: none;
                }

                span:nth-child(2) {
                    display: block;
                }
            }
        }
    }

    @media (max-width: 1024px) {
        .btn-follow {
            position: relative;
            right: auto;
            margin-top: 1em;
            clear: both;
        }
    }

    @media (max-width: 480px) {
        .btn-follow {
            width: 100%;
        }
    }
}

.loader-overlay {
    width: 100%;
    height: 100%;

    @include position(absolute, 0 null null 0);

    background-color: rgba(#ffffff, 0.7);
    z-index: 2;
    display: none;
}

#viewport:not(.collections-visible) {
    .list-options {
        @media (min-width: 1024px) {
            .fake-popover {
                margin: 0;
                .popover-container {
                    height: $list-height;

                    .popover-button {
                        display: none;
                    }

                    .popover-content {
                        animation: none;
                        position: relative;
                        top: 0;
                        right: 0;
                        display: block;
                        width: auto;
                        padding: 0;
                        border: none;
                        background: none;
                        box-shadow: none;
                        text-align: center;

                        &:before,
                        &:after {
                            display: none;
                        }
                        .radio-group {
                            display: inline-block;
                            font-size: 1em;
                            padding-left: 0;
                            label {
                                display: block;
                                margin: 0;
                                padding: 0 0.5em;
                                line-height: $list-height;
                                position: relative;

                                &:hover {
                                    color: lighten(color('800', 'blue'), 10);
                                }
                            }
                            .fake-radio {
                                display: none;
                            }
                            input[type='radio']:checked + label + label:before {
                                content: '';
                                display: block;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                width: 100%;
                                height: 3px;
                                background: color('900', 'blue');
                            }
                        }
                    }

                    > * {
                        height: $list-height;
                    }
                }

                &.popover-equivalents {
                    > span {
                        display: inline-block;
                    }
                }
            }
        }
    }
}

.collections-visible {
    .list-header {
        @media (max-width: 1344px) {
            .list-header-title {
                float: none;
                padding-right: 0;

                @include justify-content(center);
            }

            .formats {
                float: none;
                text-align: center;
            }

            .tagline {
                text-align: center;
                margin-top: 1em;
            }

            .download-pack {
                float: none;
                margin-top: 1em;
            }

            &.author-header {
                .author {
                    .author-info {
                        padding: 0;

                        ul.author-links {
                            text-align: center;

                            li {
                                float: none;
                                display: inline-block;
                                margin-left: -4px;
                            }
                        }
                    }
                }
            }
            &.pack-header {
                .author {
                    padding-right: 0;
                }
                .back {
                    right: 50%;
                    transform: translateX(50%);
                    bottom: 1em;
                }
            }
        }

        @media (max-width: 800px) {
            padding: 1em;

            h1,
            h2 {
                font-size: 1.2em;
            }

            .author {
                margin-top: 1em;
            }
        }
    }

    .list-options {
        @media (max-width: 1344px) {
            font-size: 0.9em;
            padding: 0 1em;
            height: auto;

            .label-switch {
                margin-right: 1em;
                vertical-align: bottom;
            }
        }

        @media (min-width: 1360px) {
            .fake-popover {
                margin: 0;

                .popover-container {
                    height: $list-height;

                    .popover-button {
                        display: none;
                    }

                    .popover-content {
                        animation: none;
                        position: relative;
                        top: 0;
                        right: 0;
                        display: block;
                        width: auto;
                        padding: 0;
                        border: none;
                        background: none;
                        box-shadow: none;
                        text-align: center;

                        &:before,
                        &:after {
                            display: none;
                        }
                        .radio-group {
                            display: inline-block;
                            font-size: 1em;
                            padding-left: 0;

                            label {
                                display: block;

                                margin: 0;
                                padding: 0 0.5em;

                                line-height: $list-height;
                                position: relative;
                            }
                            .fake-radio {
                                display: none;
                            }
                            input[type='radio']:checked + label + label:before {
                                content: '';
                                display: block;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                width: 100%;
                                height: 3px;
                                background: color('800', 'blue');
                            }
                        }
                    }

                    > * {
                        height: $list-height;
                    }
                }

                &.popover-equivalents {
                    > span {
                        display: inline-block;
                    }
                }
            }
        }
    }

    .related-search {
        @media (max-width: 1344px) {
            display: none;
        }
    }

    .search-data {
        ul.tags {
            @media (max-width: 1344px) {
                li {
                    display: none;
                    &.delete-all {
                        display: inline-block;
                    }
                }
            }
        }
    }
}
