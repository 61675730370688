@keyframes sonarWarning {
    0% {
        box-shadow: 0 0 0 0 color('yellow', 'state');
    }

    100% {
        box-shadow: 0 0 0 20px color('yellow', 'state', 0);
    }
}

@keyframes sonarDanger {
    0% {
        box-shadow: 0 0 0 0 color('600', 'red');
    }

    100% {
        box-shadow: 0 0 0 20px color('600', 'red', 0);
    }
}

@keyframes sonarSuccess {
    0% {
        box-shadow: 0 0 0 0 color('600', 'green');
    }

    100% {
        box-shadow: 0 0 0 20px color('600', 'green', 0);
    }
}
