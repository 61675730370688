$max-collections-width: 960px;
$max-collections-height: 650px;

.max-collections {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 0 $base-spacing;
    display: none;

    &.visible {
        display: block;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: color('800', 'blue', 0.9);
        z-index: 100;
    }

    h5 {
        max-width: 550px;
    }

    input[name='max-collections__section-trigger'] {
        display: none;
        appearance: none;
        -webkit-appearance: none;

        &:checked + .max-collections__wrapper {
            transform: translateX(-50%);
        }
    }

    &__container {
        position: relative;
        width: 100%;
        max-width: $max-collections-width;
        height: $max-collections-height;
        background: color('light', 'background');
        z-index: 100;
        border-radius: 10px;
        margin: $base-spacing auto;
        top: 50%;
        margin-top: -$max-collections-height / 2;
        overflow: hidden;
    }

    &__wrapper {
        width: 200%;
        white-space: nowrap;
        height: $max-collections-height;
        // transform: translateX(-50%);
        transition: transform 300ms ease-in;
    }

    &__step {
        height: 100%;
        @include display(flex);
        @include flex-direction(column);
        width: 50%;
        float: left;
        overflow: hidden;
        white-space: normal;
    }

    &__second-step {
        .max-collections__actions {
            border-top: 1px solid color('100', 'gray');
        }
    }

    &__content {
        @include flex(1);
        padding: 0 $extra-large-spacing + $large-spacing;
    }

    &__header {
        padding: $large-spacing $extra-large-spacing + $large-spacing;

        .row {
            position: relative;
        }

        .link--arrow {
            color: color('600', 'gray');
            position: absolute;
            top: 50%;
            left: 0;
            margin: 0;
            transform: translateY(-50%);
            z-index: 1;

            i {
                color: color('600', 'gray');
            }

            &:hover {
                color: color('600', 'gray');
            }
        }
    }

    &__nav {
        color: color('300', 'gray');
    }

    &__actions {
        padding: $large-spacing $extra-large-spacing + $large-spacing;
    }

    &__holder {
        border: 1px solid color('100', 'gray');
        border-radius: 2px;
    }

    &__item {
        height: $large-element-height;
        line-height: $large-element-height;
        border-bottom: 1px solid color('100', 'gray');
        overflow: hidden;

        &:last-child {
            border: none;
        }

        &:hover {
            background-color: color('50', 'gray');
        }

        &__name {
            width: 140px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &__number {
            width: 130px;
            text-align: center;
        }

        .checkbox-group {
            line-height: 18px;
            width: 18px;
            > i {
                color: transparent;
                box-shadow: 0px 0px 0px 1px color('200', 'gray') inset;
                border-radius: 2px;
            }
            input[type='checkbox']:checked + i:before {
                color: white;
                background-color: color('500', 'green');
                border-radius: 2px;
            }
        }

        ul {
            height: 32px;

            li {
                display: inline-block;
                margin-right: 14px;
            }
        }

        img {
            width: 32px;
            display: block;
        }
    }

    .list {
        list-style-type: disc;
        padding-left: $base-spacing;
        margin-bottom: $large-spacing;

        li {
            margin-bottom: $small-spacing;
        }
    }

    .discount {
        border-radius: 14px;
        background-image: linear-gradient(to right, color('800', 'blue'), color('800', 'purple'));
        color: color('text-inverted', 'general');
        padding: $large-spacing;

        p {
            max-width: 400px;
        }
    }

    label {
        cursor: pointer;
    }

    @include breakpoint('md') {
        &__header {
            padding: $large-spacing $large-spacing * 2;
        }

        &__content {
            padding: 0 $large-spacing * 2;
        }

        &__actions {
            padding: $large-spacing $large-spacing * 2;
        }
    }

    @include breakpoint('sm') {
        padding: 0;

        &__container {
            border-radius: 0;
            height: 100%;
            margin: 0 !important;
            top: 0;
        }

        &__wrapper {
            transition-duration: 150ms;
            height: 100%;
        }

        &__header {
            padding: $large-spacing $base-spacing;
        }

        &__content {
            padding: 0 $base-spacing;
        }

        &__actions {
            padding: $small-spacing $base-spacing;
            border-top: 1px solid color('100', 'gray');
        }

        &__item {
            &__checkbox {
                @include order(2);
            }

            &__data {
                @include order(1);
                @include flex(1);
                line-height: $large-element-height / 2;
                display: block !important;
                height: $large-element-height;
            }

            &__number {
                text-align: left;
            }

            &__icons {
                @include order(0);
                @include flex(none);
                padding: 0 $small-spacing 0 $base-spacing;
            }

            ul {
                li {
                    &:nth-child(n + 2) {
                        display: none;
                    }
                }
            }
        }

        h5 {
            margin-top: 0;
        }

        .discount {
            padding: $base-spacing;

            .font-xl {
                font-size: $font-size-md;
                margin-bottom: $small-spacing;
            }

            .col {
                flex: none;
                width: 100%;
            }
        }

        .price-holder {
            display: none;
        }

        &__second-step {
            .max-collections__actions {
                padding: $base-spacing;

                .bj-button {
                    width: 100%;
                    margin: 0;

                    &--gray {
                        @include order(1);
                        margin: $small-spacing 0 0 0;
                    }
                }
            }
        }
    }

    @media (max-height: 680px) {
        &__container {
            top: 0;
            margin: $base-spacing auto;
        }
    }
}
