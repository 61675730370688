@include keyframes(alien) {
    0% {
        @include transform(rotate(0deg));
    }
    50% {
        @include transform(rotate(10deg));
    }
    100% {
        @include transform(rotate(0deg));
    }
}