.detail {
    &.new-detail {
        max-width: 1440px;
        padding: 0 60px;
        margin: 0 auto;
        .detail__inner {
            .detail__top {
                padding-top: 30px;
                border-bottom: none;

                .detail__content {
                    border-radius: 12px;
                    background-color: color('50', 'gray');
                    text-align: center;
                    &.bg-white {
                        background-color: white;

                        @include breakpoint('lg') {
                            border-right: none;
                            border-bottom: 1px solid color('100', 'gray');
                            border-radius: 0;
                        }
                    }

                    &__inner {
                        .detail__icon__holder {
                            .main-icon-without-slide {
                                padding: 90px;
                                @include breakpoint('md') {
                                    padding: 30px;
                                }
                            }

                            .sticker--shadow {
                                img {
                                    filter: drop-shadow(0px 0px 2px rgba(55, 73, 87, 0.15))
                                        drop-shadow(0px 2px 5px rgba(55, 73, 87, 0.2));
                                }
                            }
                            .icon-mp4-container {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 286px;
                                height: 286px;
                                background-color: white;
                                border: 1px solid transparent;
                                border-radius: 8px;
                                margin: $large-spacing auto;
                            }
                        }

                        .detail-styles {
                            position: absolute;
                            height: 80%;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 30px;
                            $button-width: 62px;
                            $button-height: $button-width;
                            $icon-width: 46px;
                            $icon-height: 46px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                            img {
                                display: block;
                                width: unset;
                                height: unset;
                                pointer-events: none;
                            }

                            ul {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                padding-right: 10px;
                                overflow: auto;
                                white-space: nowrap;
                                border-radius: $base-border-radius;
                                &::-webkit-scrollbar {
                                    width: 5px;
                                    height: 5px;
                                }
                                &::-webkit-scrollbar-track {
                                    border-radius: 10px;
                                }
                                &::-webkit-scrollbar-thumb {
                                    background: color('100', 'gray');
                                    border-radius: 10px;
                                    height: 30px;
                                }
                                &::-webkit-scrollbar-thumb:hover {
                                    background: color('200', 'gray');
                                }

                                @include breakpoint('sm') {
                                    flex-direction: row;
                                }
                            }

                            li {
                                display: inline-block;
                                position: relative;
                                width: $button-width;
                                margin-bottom: 5px;

                                &:last-child {
                                    border: none;
                                }
                            }

                            a {
                                display: block;

                                width: $button-width;
                                height: $button-height;

                                padding: 15px;
                                border-radius: 6px;

                                &:hover {
                                    background-color: color('100', 'gray', 0.65);
                                }

                                &.active {
                                    overflow: hidden;

                                    box-shadow: inset 0 0 0px 1px color('500', 'green');
                                    border-bottom: unset;
                                }
                            }

                            @media (max-width: 480px) {
                                margin-bottom: $small-spacing;
                            }
                        }
                    }
                    .detail__content__inner {
                        height: 100%;
                        width: 100%;
                        @include flex-direction(column);
                        .edit-animated-icon {
                            margin-left: auto;
                            display: flex;
                            align-items: center;
                            color: color('800', 'gray');
                            .icon--edit-alt:before {
                                margin-top: 7px;
                            }
                            b {
                                color: color('500', 'green');
                            }
                            @media (max-width: 1200px) {
                                margin-left: $small-spacing;
                                padding: 0 $small-spacing;

                                span {
                                    display: none;
                                }
                            }
                        }
                        .search-style-word {
                            width: 100%;
                            padding: $small-spacing 0;
                            background: white;
                            .search-holder {
                                .fake-search {
                                    height: 34px;
                                    line-height: 34px;

                                    .popover-button {
                                        height: 34px;
                                        line-height: 34px;
                                        padding: 0 $small-spacing;
                                        span {
                                            min-width: unset;
                                            padding: 0 $extra-small-spacing 0 $small-spacing;
                                            @include breakpoint('xs') {
                                                max-width: 90px;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                            }
                                        }
                                    }

                                    .popover-content {
                                        border: 1px solid color('100', 'gray');
                                        color: color('600', 'gray');
                                        padding: $small-spacing;
                                        border-radius: $base-border-radius;
                                        z-index: 10;
                                        ul {
                                            max-height: 275px;
                                            overflow-y: scroll;
                                            li {
                                                height: 42px;
                                                padding: 0 $small-spacing;
                                                display: flex;
                                                align-items: center;
                                                cursor: pointer;
                                                &:hover {
                                                    background-color: color('75', 'gray');
                                                }
                                                span {
                                                    display: none;
                                                }
                                            }
                                            li.active {
                                                background: rgba(74, 210, 149, 0.1);
                                                color: color('800', 'green');
                                            }
                                            li.active {
                                                span {
                                                    display: inline;

                                                    &::before {
                                                        color: color('800', 'green');
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    input {
                                        padding: 0 $small-spacing;
                                        height: 34px;
                                        line-height: 34px;
                                        text-overflow: ellipsis;
                                    }
                                    .search__button,
                                    .clean-search__button {
                                        width: 34px;
                                        margin: unset;
                                        height: 100%;
                                        i::before {
                                            color: color('800', 'gray');
                                        }
                                    }

                                    .awesomplete {
                                        .text {
                                            font-size: 14px;
                                        }
                                    }

                                    &.ios {
                                        .awesomplete {
                                            height: 34px;
                                            display: flex;
                                            align-items: center;
                                            padding: $small-spacing + $extra-small-spacing 0;
                                            cursor: text;

                                            input {
                                                height: 100%;
                                                line-height: initial;
                                            }

                                            ul {
                                                top: 34px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .detail__sidebar {
                    margin-left: 70px;
                    width: 441px;
                    position: relative;
                    .collections-visible & {
                        @media (max-width: 1312px) {
                            margin-left: 0;
                            width: 100%;
                            .download-action {
                                .copypng--button,
                                .popover-container,
                                .popover-button {
                                    width: 100%;
                                }
                            }
                        }
                    }
                    h1 {
                        font-family: $base-font-family;
                        font-size: $font-size-md;
                    }
                    .banner-craft {
                        margin-bottom: 20px;
                        &__container {
                            max-width: 470px;

                            background-color: color('800', 'blue');
                            border-radius: 8px;
                            padding: 10px;
                            display: flex;
                            align-items: center;
                        }
                        &__image {
                            width: 140px;
                            height: 140px;
                            flex-shrink: 0;
                            margin-right: 10px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-size: cover;
                            background-position-x: center;
                            background-repeat: no-repeat;
                            border-radius: 5px;
                            img {
                                mix-blend-mode: multiply;
                            }
                        }
                        &__text {
                            padding: $small-spacing;
                            p {
                                color: color('50', 'gray');
                                .badge {
                                    border-radius: $base-border-radius;
                                    background: color('500', 'green');
                                    padding: 0 5px;
                                    font-size: $font-size-2xs;
                                    height: 18px;
                                    color: color('800', 'blue');
                                    display: inline-flex;
                                    justify-content: center;
                                    align-items: center;
                                }
                                > a {
                                    color: color('400', 'green');
                                }
                            }
                            > a,
                            button {
                                width: 100%;
                                line-height: 1.2;
                            }
                        }
                        &__spinner.circle-spinner {
                            margin: 0 10px;
                        }

                        @include breakpoint('lg') {
                            &__container {
                                margin: 20px auto;
                            }
                        }
                        @include breakpoint('xs') {
                            &__container {
                                flex-direction: column;
                                align-items: center;
                                text-align: center;
                            }
                            &__image {
                                margin-bottom: $base-spacing;
                                height: 130px;
                            }
                        }
                    }
                    .detail__mobile-holder {
                        padding: 10px 0 20px;
                    }
                    .author__holder {
                        padding: 10px 0;
                        .author {
                            > .row {
                                margin: 0;
                            }
                        }
                    }
                }
            }

            .search-other-categories {
                color: color('800', 'gray');
                font-size: $font-size-lg;
                h2 {
                    font-size: $font-size-lg;
                }
            }
            .related {
                .related-header {
                    h2,
                    p {
                        color: color('800', 'gray');
                        text-align: left;
                        font-weight: $font-weight-bold;
                        font-size: $font-size-lg;
                    }
                    .link--arrow.ai-most {
                        padding: 0;
                    }
                    .link--arrow.ai-most:hover {
                        background: none;
                    }
                }
            }
            .detail-footer {
                .tags {
                    margin-top: 12px;
                }
            }

            .form-feedback {
                margin: 0 0 24px;
            }

            .detail-footer {
                > p {
                    color: color('800', 'gray');
                    font-weight: $font-weight-bold;
                }
                .tags {
                    justify-content: start;
                    li {
                        margin: 0 4px 4px 0;
                    }
                }
            }
        }
        &.uicon {
            .detail__inner {
                .detail__top {
                    .detail__content {
                        transition: all 0.3s ease-in-out;
                        margin: 0;

                        .uicon-edit-color-picker {
                            position: relative;
                            margin-left: auto;
                            .color-picker-wrapper {
                                width: 20px;
                                margin-bottom: 0;
                                margin-left: 20px;
                            }
                            .pcr-app[data-theme='classic'],
                            .pcr-app[data-theme='nano'] {
                                left: unset !important;
                            }
                            .pcr-app[data-theme='classic']:after,
                            .pcr-app[data-theme='nano']:after {
                                left: 16px;
                            }
                            .pcr-app[data-theme='classic']:before,
                            .pcr-app[data-theme='nano']:before {
                                left: 18px;
                            }
                            .pcr-swatches button {
                                cursor: default;
                                &:disabled,
                                &[disabled] {
                                    opacity: 1 !important;
                                }
                            }
                        }
                        .detail__icon__inner {
                            min-height: 480px;
                            .icon-png-container {
                                padding-bottom: 80px;
                                img {
                                    filter: invert(22%) sepia(14%) saturate(1042%) hue-rotate(164deg) brightness(102%)
                                        contrast(85%);
                                }
                            }
                            @include breakpoint('sm') {
                                min-height: unset;
                                flex-direction: column;
                                .icon-png-container {
                                    padding-bottom: 0;
                                }
                            }
                        }
                        .detail_equivalent_icons a {
                            background-color: color('50', 'gray');
                            img {
                                filter: invert(22%) sepia(14%) saturate(1042%) hue-rotate(164deg) brightness(102%)
                                    contrast(85%);
                            }
                        }
                    }

                    .detail__sidebar {
                        .uicon-detail {
                            &__header {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                &--logo {
                                    display: flex;
                                    align-items: center;
                                    .tooltip {
                                        &__trigger::after {
                                            border-bottom: 6px solid white !important;
                                            z-index: 400;
                                        }
                                        &__content {
                                            .content {
                                                padding: 20px;
                                                width: 280px;
                                                background-color: white;
                                                color: color('600', 'gray');
                                                white-space: normal;
                                            }
                                            &:hover {
                                                display: block;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .uicon-detail-download {
                            .copy-class {
                                .group {
                                    background-color: white;
                                    flex-wrap: nowrap;
                                    &__input {
                                        border: none;
                                        align-self: center;
                                        width: calc(100% - 54px);
                                    }

                                    &__context {
                                        .icon--check {
                                            display: none;
                                            &::before {
                                                font-size: 22px;
                                            }
                                        }
                                    }

                                    &__context.copied {
                                        background-color: color('500', 'green');
                                        border-radius: $base-border-radius;
                                        i {
                                            color: white !important;
                                        }
                                        .icon--duplicate {
                                            display: none;
                                        }
                                        .icon--check {
                                            display: block;
                                        }
                                    }
                                    button.bj-button--ghost:hover {
                                        color: color('500', 'green');
                                        background-color: transparent;
                                    }
                                    button:focus {
                                        color: color('800', 'gray');
                                    }

                                    code {
                                        color: color('800', 'gray');
                                        font-size: $font-size-sm;
                                        font-weight: $font-weight-regular;
                                        padding: 10px 15px;
                                        display: flex;
                                        overflow: hidden;
                                        span {
                                            display: inline-block;
                                            white-space: nowrap;
                                        }

                                        .purple {
                                            color: color('800', 'purple');
                                        }

                                        .yellow {
                                            color: color('400', 'yellow');
                                        }
                                        .green {
                                            color: color('800', 'green');
                                        }
                                    }
                                }
                                .group::before {
                                    box-shadow: inset 0 0 0 1px color('100', 'gray');
                                }
                            }
                            .copy-share {
                                display: grid;
                                gap: 10px;
                                grid-template-columns: repeat(2, 1fr);
                                grid-template-areas: 'copy share';
                                .btn-copy {
                                    grid-area: copy;
                                }
                                .btn-share {
                                    grid-area: share;
                                }
                                @include breakpoint('xs') {
                                    button {
                                        padding: 0px;
                                    }
                                }
                            }
                            .download-action {
                                display: grid;
                                gap: 10px;
                                grid-template-columns: repeat(4, 1fr);
                                grid-template-areas: 'png svg android ios';

                                .row {
                                    margin: 0;
                                }
                                .btn-png {
                                    grid-area: png;
                                }
                                .btn-svg {
                                    grid-area: svg;
                                }
                                .btn-android {
                                    grid-area: android;
                                }
                                .btn-ios {
                                    grid-area: ios;
                                }
                                @include breakpoint('xs') {
                                    > [class^='btn-'] {
                                        .row {
                                            flex-wrap: nowrap;
                                        }
                                    }
                                }
                                @include breakpoint('xs') {
                                    .btn {
                                        font-size: 14px;
                                        padding: 5px;
                                    }
                                }
                            }

                            .accordion {
                                &__container {
                                    .download-cdn,
                                    .package-manager {
                                        .group--inputs-cdn,
                                        .group--inputs-npm {
                                            max-width: 85%;
                                            background-color: white;
                                            .group__input {
                                                border-right: none;

                                                input {
                                                    overflow: hidden;
                                                    text-overflow: ellipsis;
                                                    white-space: nowrap;
                                                }
                                            }
                                        }
                                    }
                                }
                                &__link {
                                    padding: 10px 0 10px;
                                    border-bottom: 1px solid color('200', 'gray');
                                }
                                &__content {
                                    padding: 10px 0 10px;
                                }
                            }
                            .no-registered {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                gap: 20px;
                                border-radius: 8px;
                                padding: 40px;
                                background: rgb(213, 242, 235);
                                background: linear-gradient(
                                    120deg,
                                    rgba(213, 242, 235, 1) 0%,
                                    rgba(219, 238, 250, 1) 50%,
                                    rgba(238, 247, 254, 1) 100%
                                );
                                p {
                                    color: color('800', 'gray');
                                }
                            }
                        }
                        .uicon-version {
                            font-size: 10px;
                            padding: 5px 0 10px;
                        }
                    }
                }
                > section {
                    max-width: unset;
                    margin-bottom: 70px;
                    > .container {
                        padding: 0 20px;
                    }
                    &.related.related-tags {
                        .uicons__related {
                            margin-bottom: 70px;

                            &--header {
                                max-width: unset;
                                margin: unset;
                                h2 {
                                    color: color('800', 'gray');
                                    display: block;
                                    margin-right: $small-spacing;
                                }
                                .link--arrow.ui-most {
                                    padding: 0;
                                }
                                .link--arrow.ui-most:hover {
                                    background: none;
                                }
                            }
                        }

                        .icons.uicons-list {
                            display: grid;
                            justify-items: center;
                            grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
                            grid-auto-flow: row dense;
                            grid-gap: 18px;
                            margin-bottom: 20px;

                            > li:not(.icon__detail--container) {
                                min-width: 100%;
                                position: relative;
                                overflow: hidden;
                                transition: transform 200ms ease, box-shadow 200ms ease;

                                @include breakpoint('md', 'min') {
                                    &:not(.active):not(.icon__detail--container):hover {
                                        transform: translateY(-2px);
                                        box-shadow: 0px 6px 18px 0px rgba(32, 32, 149, 0.1);
                                        z-index: 1;
                                    }
                                }

                                &.active {
                                    a:before {
                                        transform: scale(2);
                                    }
                                }

                                a {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    display: block;

                                    &:before {
                                        content: '';
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                        background: color('50', 'gray');
                                        border-radius: 100%;
                                        transform: scale(0);
                                        transition: transform 300ms ease;
                                    }
                                }

                                img {
                                    margin: auto;
                                    top: 50%;
                                    position: relative;
                                    margin-top: -13px;
                                    width: 26px;
                                    height: 26px;
                                    left: unset;
                                    transform: translateY(-50%);
                                    filter: invert(22%) sepia(14%) saturate(1042%) hue-rotate(164deg) brightness(102%)
                                        contrast(85%);
                                }
                            }

                            .circle-spinner {
                                grid-column-start: 1;
                                grid-column-end: five;
                                width: 35px;
                                height: 35px;
                            }
                        }
                    }
                }
            }
            ~ .container {
                max-width: 1440px;
                .uicons__related {
                    &--header {
                        max-width: unset;
                    }
                    .uicons__icons {
                        display: grid;
                        justify-items: center;
                        grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
                        grid-auto-flow: row dense;
                        grid-gap: 18px;
                        margin-bottom: 20px;

                        > li:not(.icon__detail--container) {
                            min-width: 100%;
                            position: relative;
                            overflow: hidden;
                            transition: transform 200ms ease, box-shadow 200ms ease;

                            @include breakpoint('md', 'min') {
                                &:not(.active):not(.icon__detail--container):hover {
                                    transform: translateY(-2px);
                                    box-shadow: 0px 6px 18px 0px rgba(32, 32, 149, 0.1);
                                    z-index: 1;
                                }
                            }

                            &:before {
                                content: '';
                                display: block;
                                width: 100%;
                                padding: 0 0 100%;
                            }

                            &.active {
                                a:before {
                                    transform: scale(2);
                                }
                            }

                            a {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                display: block;

                                &:before {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    right: 0;
                                    bottom: 0;
                                    background: color('50', 'gray');
                                    border-radius: 100%;
                                    transform: scale(0);
                                    transition: transform 300ms ease;
                                }
                            }

                            img {
                                margin: auto;
                                top: 50%;
                                position: relative;
                                margin-top: -13px;
                                filter: invert(22%) sepia(14%) saturate(1042%) hue-rotate(164deg) brightness(102%)
                                    contrast(85%);
                            }
                        }

                        .circle-spinner {
                            grid-column-start: 1;
                            grid-column-end: five;
                            width: 35px;
                            height: 35px;
                        }
                    }
                    @include breakpoint('md') {
                        .container {
                            padding: 0 $base-spacing;
                            .uicons__icons {
                                width: calc(100% + 20px);
                                margin-left: -10px;
                            }
                        }
                    }
                }
                .icon__font__in__action,
                .find-best-icon-font,
                .doubts-support {
                    max-width: unset;
                }
                .doubts-support {
                    .container {
                        padding: 0;
                    }
                }
            }
        }
        @include breakpoint('lg') {
            &:not(.uicon) {
                .detail__inner {
                    .detail__top {
                        .detail__sidebar {
                            .detail__mobile-holder {
                                .download {
                                    .btn-size {
                                        .popover-bottom {
                                            .popover-content {
                                                bottom: 50px;
                                                top: unset;
                                                @keyframes popover-inverse {
                                                    0% {
                                                        opacity: 0;
                                                        transform: translateY(30px);
                                                    }
                                                    100% {
                                                        opacity: 1;
                                                        transform: translateY(0px);
                                                    }
                                                }

                                                animation: popover-inverse 0.2s ease-in-out;
                                                &::after,
                                                &::before {
                                                    bottom: -0.45em;
                                                    top: unset;
                                                    border-bottom: unset;
                                                    border-top: 12px solid #ffffff;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.uicon {
                .detail__inner {
                    .detail__top {
                        .detail__content {
                            &__inner {
                                min-height: 500px;
                            }
                        }

                        .detail__sidebar {
                            margin-left: 0;
                            padding: 40px 0 0;
                        }
                    }
                }
            }
            padding: 0 40px;
            .detail__inner {
                .detail__top {
                    display: block;
                    .detail__content {
                        margin-bottom: 40px;
                    }
                    .detail__sidebar {
                        margin: 0 auto;
                        width: 100%;

                        .detail__mobile-holder {
                            padding: 20px;
                        }
                    }
                }
            }
        }
        @include breakpoint('sm') {
            padding: 0 20px;

            &.uicon {
                .detail__inner {
                    .detail__top {
                        .detail__content {
                            &__inner {
                                min-height: unset;
                                .detail-styles {
                                    -ms-overflow-style: none; /* Internet Explorer, Edge */
                                    scrollbar-width: none; /* Firefox */
                                    transform: unset;
                                    overflow-x: auto;
                                    width: 100%;
                                    &::-webkit-scrollbar {
                                        display: none;
                                    }
                                    ul {
                                        padding: 0 30px;
                                        overflow: auto;
                                        width: 80%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .detail__inner {
                .detail__top {
                    .detail__content {
                        margin-bottom: 40px;
                        &__inner {
                            .detail-styles {
                                position: static;
                                height: unset;
                            }
                        }
                    }
                }
            }
        }

        @include breakpoint('xs') {
            &:not(.uicon) {
                .detail__inner {
                    .detail__top {
                        .detail__sidebar {
                            .detail__mobile-holder {
                                .download {
                                    .btn-size {
                                        .popover-button {
                                            min-width: 34px;
                                            > span {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .related-tags-styles {
            margin-bottom: 45px;
            header {
                p {
                    color: color('800', 'gray');
                    font-weight: $font-weight-bold;
                }
            }
        }

        .cards-products-home.circles {
            width: fit-content;
            margin: 40px auto 60px;
            display: grid;
            grid-template-columns: repeat(4, minmax(80px, 200px));
            gap: 30px;
            text-align: center;
            a {
                &:hover {
                    .cards-products-home__item {
                        .cards-products-home__item__image {
                            img,
                            video {
                                transform: scale(1.05);
                            }
                        }
                    }
                }
            }

            .cards-products-home__item {
                .cards-products-home__item__image {
                    .circle {
                        width: 120px;
                        height: 120px;
                        background: linear-gradient(
                            218.58deg,
                            color('500', 'green') 11.5%,
                            color('100', 'green') 87.95%
                        );
                        border-radius: 50%;
                        margin: 0 auto;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        .image {
                            width: 114px;
                            height: 114px;
                            border: 4px solid white;
                            border-radius: 50%;
                            margin: 0 auto;
                            z-index: 5;
                            overflow: hidden;
                            background: linear-gradient(
                                110.49deg,
                                color('75', 'gray') 26.28%,
                                color('100', 'purple') 94.17%
                            );
                        }
                    }
                    img,
                    video {
                        transition: all 0.2s ease-in-out;
                        width: 100%;
                        height: auto;
                        display: block;
                    }
                }
                .cards-products-home__item__text {
                    color: color('800', 'gray');
                    h6 {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 5px;
                        flex-wrap: wrap;
                    }
                    p {
                        @supports (-webkit-line-clamp: 2) {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: initial;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
            }
            @include breakpoint('lg') {
                grid-template-columns: unset;
                grid-template-areas:
                    'icons-circle icons-circle uicons-circle uicons-circle '
                    'aicons-circle aicons-circle stickers-circle stickers-circle';
                .icons-circle {
                    grid-area: icons-circle;
                }
                .uicons-circle {
                    grid-area: uicons-circle;
                }
                .aicons-circle {
                    grid-area: aicons-circle;
                }
                .stickers-circle {
                    grid-area: stickers-circle;
                }
            }
            @include breakpoint('sm') {
                .icons-circle,
                .uicons-circle,
                .aicons-circle,
                .stickers-circle {
                    max-width: 80px;
                }
                .cards-products-home__item {
                    h6 {
                        font-size: 13px;
                    }
                    .cards-products-home__item__image {
                        .circle {
                            width: 64px;
                            height: 64px;
                            .image {
                                width: 60px;
                                height: 60px;
                                border: 2px solid white;
                            }
                        }
                    }
                    .cards-products-home__item__text {
                        p {
                            display: none;
                        }
                    }
                }
            }
        }

        // Solución con aside Colecciones abierto

        @include breakpoint('xl') {
            .collections.visible {
                ~ .container .cards-products-home__item {
                    h6 {
                        font-size: 13px;
                    }
                    .cards-products-home__item__image {
                        .circle {
                            width: 64px;
                            height: 64px;
                            .image {
                                width: 60px;
                                height: 60px;
                                border: 2px solid white;
                            }
                        }
                    }
                    .cards-products-home__item__text {
                        p {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .spirit-a.bg-aqua {
        // background-color: color('50', 'gray');
        border-radius: 12px;
        height: unset;
        #unicorn {
            .unicorn {
                section {
                    .unicorn__row {
                        justify-content: space-between;
                    }
                }
            }
            header {
                background: none;
                justify-content: flex-start;
            }
        }
        @include breakpoint('sm') {
            display: none;
        }
    }
}

// vista hbs

.content.detail {
    .detail.new-detail {
        padding: 0;
        .detail__top {
            padding: 20px 20px 0;
        }
        .related-tags-styles {
            min-height: 490px;
        }
    }
}
