#gr_user_menu {
    @include breakpoint('lg', 'min') {
        height: $avatar-width-xs;
    }
    min-width: 60px;

    #gr_connected {
        display: none;
        height: $avatar-width-xs;

        .popover-user {
            .popover-container {
                position: relative;

                padding-right: 14px;

                &::before {
                    @include position(absolute, 50% 2px null null);
                    @include transform(translateY(-50%));
                    @include triangle(8px, white, down);

                    content: '';
                }
            }
            .popover-content {
                padding: 0;
                width: 360px;

                @include breakpoint('xs') {
                    width: 320px;
                }

                .popover-user-top {
                    padding: 16px;
                    border-bottom: 1px solid color('200', 'gray');
                    @include clearfix;

                    .avatar-holder {
                        float: left;
                        margin-right: 10px;
                        display: block;
                        width: 64px;
                        height: 64px;
                        border-radius: 100%;
                        overflow: hidden;
                    }
                    .user-data {
                        float: left;
                        width: 180px;
                        span {
                            @include ellipsis();
                            display: block;
                            font-size: 16px;
                            margin-bottom: 10px;
                            font-weight: $font-weight-bold;
                            color: #4a4a4a;
                        }
                        .btn {
                            color: #fff;
                        }
                    }
                }

                .user__links {
                    li {
                        position: relative;

                        > a,
                        button {
                            display: flex;
                            align-items: center;
                            border: none;
                            height: 36px;
                            padding: 0 20px;
                            font-size: 14px;
                            color: color('900', 'blue');
                        }
                    }

                    i {
                        color: color('800', 'gray');
                        margin-right: 10px;
                    }
                }

                .tooltip i {
                    color: color('300', 'gray');
                }

                .user__subscription {
                    padding: 0 20px 10px;
                }

                .subscription-type {
                    color: color('600', 'gray');
                    margin-left: 10px;
                }

                hr {
                    background-color: color('100', 'gray');
                    margin: 0;
                    width: 100%;
                }

                .downloads__number {
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 100%;
                    .badge {
                        padding: 0 5px;
                    }
                }
            }
        }
    }
}
