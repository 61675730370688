.landing--coronavirus {
    .button {
        &:hover,
        &:focus {
            color: #fff !important;
        }
    }

    #header {
        border: none;
        background: none;
        padding: $base-spacing;
        margin: 0 0 $extra-large-spacing;
        position: relative;

        @include breakpoint('md') {
            text-align: center;
            margin: 0;
        }
    }

    .logo {
        display: inline-block;

        @include breakpoint('xs') {
            width: auto;
        }
    }

    section {
        margin: 0;
    }

    .container {
        max-width: 1120px;
        position: relative;
        z-index: 1;
    }

    .hero {
        position: relative;
        margin-bottom: $large-spacing;

        &:before {
            content: '';
            display: block;
            width: 100%;
            height: 80%;
            position: absolute;
            top: 0;
            left: 0;
            background-image: linear-gradient(to bottom, color('body', 'background'), color('75', 'gray'));
        }

        img {
            max-width: 530px;
            width: 100%;
            margin: auto;
        }

        @include breakpoint('md') {
            text-align: center;

            h2 {
                margin-top: 0;
            }
        }
    }

    .coronavirus {
        &__icons {
            margin-bottom: $extra-large-spacing;
        }

        &__title {
            color: color('400', 'purple');
        }

        &__icon {
            padding: $small-spacing;
            max-width: 180px;
            min-width: 180px;

            a {
                text-align: center;
                display: block;
                border-radius: 24px;
                background-color: color('body', 'background');
                transition: box-shadow 100ms ease-in-out;
                padding: $large-spacing $small-spacing;

                &:hover {
                    // transform: translateY(-10px);
                    box-shadow: 5px 5px 20px 0 color('700', 'blue', 0.1);
                }
            }

            &__img {
                width: 50px;
                height: 50px;
                margin: 0 auto $base-spacing;

                img {
                    display: block;
                    margin: auto;
                }
            }
        }

        @include breakpoint('md') {
            &__icons {
                margin-bottom: 40px;
            }

            &__icon {
                max-width: 160px;
                min-width: 160px;
            }
        }
    }

    .prevent {
        padding: 80px 0;
        position: relative;
        overflow: hidden;

        &:before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: color('50', 'gray');
        }

        .use-icons {
            margin-top: $extra-large-spacing;

            p,
            h3 {
                max-width: 460px;
            }
        }

        @include breakpoint('md') {
            padding: 40px 0 0;

            .use-icons {
                margin-top: 0;
            }
        }
    }

    .banner {
        margin-bottom: 100px;
        position: relative;

        &:before {
            content: '';
            display: block;
            width: 100%;
            height: 50%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: color('50', 'gray');
        }

        .container {
            background: url(~Media/landing/coronavirus/banner.svg) no-repeat;
            background-size: cover;
            padding: $extra-large-spacing $extra-large-spacing 0;
            border-radius: 10px;
        }

        &__img {
            padding-right: $extra-large-spacing;
            position: relative;

            img {
                position: absolute;
                bottom: 0;
                left: 0;
                width: calc(100% - 30px);
            }
        }

        .button {
            background-color: color('800', 'blue');
            margin-bottom: $extra-large-spacing;

            &:hover {
                background-color: color('800', 'blue');
            }
        }

        @include breakpoint('lg') {
            text-align: center;
            padding: 0 $base-spacing;

            &__img {
                @include order(1);
                padding-right: 0;

                img {
                    position: relative;
                    width: 100%;
                }
            }

            &__text {
                @include order(0);
            }
        }
    }

    #footer {
        background: color('900', 'blue');
        color: #fff;
        min-height: auto;
        margin: 0;
        border: none;

        p {
            font-size: $font-size-md;
        }

        div {
            position: relative;
        }

        li {
            display: inline-block;

            span {
                margin: 0 $extra-small-spacing;
            }
        }
    }
}
