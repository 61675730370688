#popup-collections {
    width: 405px;
    background-color: color('body', 'background');
    border-radius: 8px;
    position: absolute !important;
    right: $small-spacing;
    top: 56px;
    height: auto;
    margin: 0;
    box-shadow: 0 0 10px color('700', 'blue', 0.3);

    &:before {
        content: '';
        position: absolute;
        bottom: 100%;
        right: 11px;
        height: 0;
        width: 0;
        border-bottom: 6px solid #fff;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        transform: translate(-50%);
        margin: 6px 0 0 0;
    }

    .popup-close {
        position: absolute;
        top: 0;
        right: 0;

        .icon {
            margin: 0;
            width: 46px;
            height: 36px;
            line-height: 36px;
            font-size: 26px;
            color: color('heading', 'general');
        }
    }

    .popup-collections__text {
        padding: $base-spacing;
    }

    .btn {
        height: 34px;
        line-height: 34px;
        padding: 0 1em;
        font-size: 14px;
        color: color('text-inverted', 'general');
    }

    @include breakpoint('sm') {
        display: none;
    }
}
