$base-delay: 300ms;

.landing-follow {
    #header {
        padding: $base-spacing 0;
        border-bottom: 1px solid color('100', 'gray');
        margin-bottom: $base-spacing;
    }

    main {
        background: url('~Media/landing-follow/form3.svg') no-repeat bottom center;
        overflow: hidden;
    }

    section {
        .easeInBottom {
            opacity: 0;

            transform: translateY(100px);
        }
    }

    #animation--intro {
        position: absolute;
        top: 50%;
        left: 0;

        width: 820px;
        height: 625px;

        transform: translateY(-50%);
    }

    #animation--features {
        position: absolute;
        top: 50%;
        left: 0;

        width: 675px;
        height: 564px;

        transform: translateY(-50%);
    }

    #animation--footer {
        width: 1319px;
        height: 580px;

        position: relative;
        left: 50%;

        transform: translateX(-50%);
    }

    .footer__image {
        position: relative;
        z-index: 1;
    }

    #footer {
        min-height: 185px;
        background: url('~Media/footer.svg') no-repeat bottom center;
        border: none;
        position: relative;

        div {
            @include position(absolute, null null 0 0);
            width: 100%;
            padding: $base-spacing $base-spacing $small-spacing;
        }

        ul {
            margin-top: $small-spacing;
        }
        li {
            display: inline-block;
        }
        li a {
            color: color('500', 'gray');
            font-weight: $font-weight-regular;
            &:not(.btn):not(.bj-button) {
                color: color('500', 'gray');
                &:hover {
                    color: color('600', 'gray');
                }
            }
        }
        li span {
            display: inline-block;
            margin: 0 10px;
        }
    }

    @include breakpoint('md', 'min') {
        .section--full {
            height: 100vh;
            min-height: 800px;
        }

        .features__form {
            position: relative;
        }

        .features__form--inner {
            position: absolute;
            top: 50%;
            right: 0;

            transform: translateY(-50%);
        }
    }

    @include breakpoint('md') {
        main {
            background-size: 4600px;

            text-align: center;
        }

        .features__form {
            @include order(1);
        }

        #animation--intro,
        #animation--features {
            width: 100%;
            top: 0;
            height: auto;
            transform: none;
        }

        #animation--footer {
            width: 600px;
            height: auto;
        }
    }
}
