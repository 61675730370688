$icon-size: 64px;
$icon-size-big: 128px;
$btn-size: 40px;

.notouch {
    .icons {
        small {
            font-size: 10px;
        }

        .icon--item {
            &:hover {
                .overlay,
                .icon-options {
                    display: block;
                }

                &.locked:hover,
                &.personal-icon:hover {
                    .overlay {
                        .view {
                            display: none;
                        }
                    }

                    .flaticon-lock {
                        display: none;
                    }
                }
            }

            .flaticon-selection:hover {
                opacity: 1;
            }
        }
    }
}

.icons {
    .icon--holder {
        width: 100%;
        height: 100%;
        border: 1px solid #fff;
        position: relative;
    }

    .icon--item {
        &.hover {
            .overlay,
            .icon-options {
                display: block;
            }

            &.locked:hover,
            &.personal-icon:hover {
                .overlay {
                    .view {
                        display: none;
                    }
                }

                .flaticon-lock {
                    display: none;
                }
            }
        }

        .overlay {
            .icon--item__context-menu {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                li {
                    margin: 5px;

                    &:nth-child(2) {
                        margin-top: 0;
                        margin-bottom: 0;
                        &.copy-png-svg-list {
                            .tooltip__content {
                                white-space: normal;
                                width: 230px;
                                text-align: left;
                                .content {
                                    display: flex;
                                    i {
                                        color: white;
                                        line-height: 1.7;
                                    }
                                    span {
                                        font-size: 12px;
                                    }
                                }
                            }

                            .popover-container {
                                .popover-button {
                                    &.active {
                                        &::after {
                                            display: none;
                                        }
                                    }

                                    &.active ~ .tooltip__content {
                                        display: none;
                                    }
                                }
                            }
                            .popover-content {
                                width: max-content;
                                top: 35px;
                                z-index: 4444;
                                pointer-events: all;
                                &::after {
                                    right: 0.2em;
                                }
                                &::before {
                                    right: 0.25em;
                                }
                                > div {
                                    display: flex;
                                    flex-direction: column;
                                    pointer-events: all;
                                    button {
                                        width: 100%;
                                    }
                                    span {
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                    &:nth-child(3) {
                        &.download-png-svg-list {
                            .tooltip__content {
                                white-space: normal;
                                text-align: left;
                                .content {
                                    display: flex;
                                    i {
                                        color: white;
                                        line-height: 1.7;
                                    }
                                    span {
                                        font-size: 12px;
                                    }
                                }
                            }
                            .popover-container {
                                .popover-button {
                                    &.active {
                                        &::after {
                                            display: none;
                                        }
                                    }
                                    &.active ~ .tooltip__content {
                                        display: none;
                                    }
                                }
                            }
                            .popover-content {
                                width: max-content;
                                top: 35px;
                                z-index: 4444;
                                pointer-events: all;
                                &::after {
                                    right: 0.2em;
                                }
                                &::before {
                                    right: 0.25em;
                                }
                                > div {
                                    display: flex;
                                    flex-direction: column;
                                    pointer-events: all;
                                    span {
                                        font-size: 12px;
                                    }
                                }
                                ul {
                                    li {
                                        margin: 0;
                                    }
                                }

                                button {
                                    width: 100%;
                                }
                            }
                        }
                    }

                    button {
                        width: $small-element-height;
                        height: $small-element-height;
                        pointer-events: all;
                    }
                }
            }
        }

        .flaticon-selection:hover {
            opacity: 1;
        }
        &.sticker--shadow {
            img.lazyload--done {
                filter: drop-shadow(0px 0px 2px rgba(55, 73, 87, 0.15)) drop-shadow(0px 2px 5px rgba(55, 73, 87, 0.2));
            }
        }
    }
}

.search-result {
    .icons {
        .icon--item {
            &.whitebg {
                background: color('800', 'blue');

                &.selected {
                    border-color: #fff;
                }
            }
            &[data-icon_type='uicon'] {
                min-width: 100%;
                img {
                    width: 32px;
                    height: 32px;
                    transform: translate(-50%, -50%);
                    margin: unset;
                    filter: invert(22%) sepia(14%) saturate(1042%) hue-rotate(164deg) brightness(102%) contrast(85%);
                }
            }
        }
        &.uicons-list {
            display: grid;
            justify-items: center;
            grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
            grid-auto-flow: row dense;
            grid-gap: 18px;
        }
    }
}

.icons {
    @include display(flex);
    @include flex-wrap(wrap);
    @include align-items(flex-start);
    @include align-content(start);
    width: 100%;
    gap: 5px;

    &.big {
        .icon--item {
            min-width: 160px;

            img,
            svg {
                width: $icon-size-big;
                height: $icon-size-big;
                margin: (-$icon-size-big / 2) 0 0 (-$icon-size-big / 2);
            }
        }
    }

    .icon--item {
        min-width: 140px;
        max-width: 100%;
        flex: 1;
        position: relative;

        .icon--holder:after {
            content: '';
            padding-bottom: 100%;
            display: block;
        }

        img,
        svg {
            width: $icon-size;
            height: $icon-size;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: (-$icon-size / 2) 0 0 (-$icon-size / 2);
        }

        .tooltip {
            &.icon--item__premium-badge {
                position: absolute;
                bottom: $extra-small-spacing;
                left: 50%;
                @include transform(translateX(-50%));
                z-index: 3;
            }
        }

        .overlay {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 3;
            display: none;
            background-color: color('100', 'gray', 0.8);
            pointer-events: none;
        }

        .btn {
            width: $btn-size;
            height: $btn-size;
            line-height: $btn-size;
            padding: 0;
            background-color: rgba(255, 255, 255, 0.7);
            color: color('900', 'gray');

            &:hover {
                background-color: #fff;
            }
        }

        i {
            color: color('900', 'gray');
        }

        .icon-options {
            @include position(absolute, ($btn-size + 12px) 6px null null);
            display: none;
            z-index: 4;

            .btn {
                border: none;
            }

            .popover-content {
                padding: 8px 0;
                top: 35px;

                li {
                    > a,
                    > button {
                        font-size: 13px;
                        padding: 0 8px;
                        line-height: 28px;
                        display: block;
                        color: color('900', 'blue');
                        width: 100%;
                        text-align: left;

                        &:hover {
                            background-color: color('75', 'gray');
                        }
                    }
                }

                i {
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: $extra-small-spacing;
                }

                hr {
                    margin: 8px 0;
                    width: 100%;
                }
            }

            &.popover-left {
                .popover-content {
                    &:before {
                        right: 9px;
                    }

                    &:after {
                        right: 7px;
                    }
                }
            }

            &.popover-right {
                .popover-content {
                    &:before {
                        left: 9px;
                    }

                    &:after {
                        left: 7px;
                    }
                }
            }
        }

        .view {
            color: #fff;
            font-size: 24px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            text-align: center;
            height: 100%;
        }

        .flaticon-selection,
        .icon--premium {
            font-size: 13px;

            &:before {
                width: 14px;
                height: 14px;
            }
        }

        .flaticon-selection {
            opacity: 0.5;
            color: color('900', 'gray');
            font-weight: $font-weight-bold;
        }

        .icon-name {
            margin-top: 1em;
            font-size: 0.8em;
            @include ellipsis;
        }

        &.selected {
            .icon--holder {
                background-color: color('75', 'gray');
                box-shadow: inset 0 0 0 1px color('200', 'gray');
            }

            .overlay {
                .add-remove {
                    background-color: color('600', 'red', 0.7);
                    color: #fff;

                    &:hover {
                        background-color: color('600', 'red');
                    }
                }
            }
        }

        &.error {
            border: 2px dashed color('600', 'red');
            color: color('600', 'red');
        }

        &.uploaded {
            .overlay {
                .add-remove {
                    background-color: color('600', 'red', 0.8);
                    color: #fff;
                    height: 100%;
                }
            }

            &.remove {
                @include animation(removeIcon 0.5s $ease-out-cubic forwards);
            }

            .icon-name {
                position: absolute;
                left: 0;
                bottom: -2em;
                width: 100%;
            }
        }

        &.icon-fill {
            height: 0;
        }

        .add-more {
            padding: 1em;

            button {
                width: 6em;
                height: 6em;
                border-radius: 100%;
                position: relative;

                span {
                    @include position(absolute, null null $line-height-160 0);

                    width: 100%;
                    font-size: 0.8em;
                }

                &:before {
                    @include position(absolute, 0.5em null 0 null);

                    font-size: 2em;
                    left: 0;
                    width: 100%;
                }
            }
        }
    }

    .animated--icon {
        display: flex;
        transition: box-shadow 0.2s ease-in-out;
        .link-icon-detail {
            display: flex;
            justify-content: center;

            img {
                padding: 7.5px;

                &.pd-none {
                    padding: 0 !important;
                }
            }
        }

        i {
            position: absolute;
            color: color('600', 'gray');
            top: 15px;
            left: 15px;
            transition: opacity 0.2s ease-in-out;
        }

        @include breakpoint('lg', 'min') {
            &:hover {
                box-shadow: 0px 1px 4px rgba(55, 73, 87, 0.1), 0px 5px 10px rgba(55, 73, 87, 0.15);
                z-index: 2;
                i {
                    opacity: 0;
                }
            }
        }
    }
}

@media (min-width: 1121px) {
    #viewport:not(.collections-visible) .col--icons {
        max-width: calc(100% - 470px);
    }
}

@media (max-width: 480px) {
    .col--icons {
        padding: 0;
    }

    .icons .icon--item {
        min-width: 33.33%;
    }
}

@media (max-width: 360px) {
    .icons .icon--item img {
        width: 54px;
        height: 54px;
        margin: -27px 0 0 -27px;
    }
}

@keyframes removeIcon {
    0% {
        transform: scale(1);
    }

    20% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes iconUp {
    from {
        transform: translateY(30px);

        opacity: 0;
    }

    to {
        transform: translateY(0px);

        opacity: 1;
    }
}
