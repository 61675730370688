.following {
    background-color: color('50', 'gray');

    padding-bottom: $base-spacing * 2;

    .list-options {
        .popover-filters,
        .label-switch {
            display: none;
        }
    }

    .following__list {
        margin: $base-spacing 0;
        min-height: 380px;
    }

    .following__block {
        max-width: 1024px;

        margin: $small-spacing auto;

        border: 1px solid color('100', 'gray');

        background-color: #fff;

        padding: $base-spacing;
    }

    .avatarAndName {
        width: 100%;
        a {
            display: flex;
            align-items: center;
            .avatar {
                width: 32px;
                height: 32px;
            }
        }
        p {
            margin: 0;
        }
    }

    p {
        margin-bottom: 6px;

        a {
            color: color('900', 'gray');
        }
    }

    .followers__data {
        font-size: 14px;
    }

    .followers__number {
        font-size: 14px;

        margin-left: $small-spacing;
    }

    .following__data {
        margin-right: $base-spacing;
        width: 260px;
        .row {
            margin: 0;
        }
    }

    .following__packs {
        height: 93px;
        overflow: hidden;
        margin: 0;

        a {
            max-width: 128px;

            border: 1px solid color('100', 'gray');

            margin: 0 6px 100%;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .pagination {
        margin: $base-spacing 0 0;
    }

    @include breakpoint('md') {
        .following__data {
            margin-bottom: $base-spacing;
            margin-right: 0;
            width: 100%;
        }
    }

    @include breakpoint('xs') {
        .following__packs {
            height: auto;

            a {
                margin-bottom: 0;

                max-width: none;
                width: 30%;

                &:nth-child(n + 4) {
                    display: none;
                }

                &:nth-child(3) {
                    margin-right: 0;
                }
            }
        }
    }

    .empty-search {
        .paragraph-readable {
            max-width: 400px;

            a {
                color: color('600', 'green');
                font-weight: $font-weight-semibold;
            }
        }
    }
}

.best-authors {
    &__box-holder {
        max-width: 260px;
        min-width: 190px;
        padding: 0 10px;
    }

    &__box {
        background-color: #fff;
        box-shadow: 0 0 9px color('100', 'gray');

        &__bottom {
            padding: 35px 20px 20px;
            position: relative;
            .row {
                .col {
                    p {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
        }

        .avatar {
            width: 50px;
            height: 50px;
            position: absolute;
            left: 50%;
            margin-left: -25px;
            top: -25px;

            img {
                width: 50px;
                height: 50px;
            }
        }
    }
}

.collections-visible {
    @media (max-width: 1344px) {
        .following .list-options {
            height: 50px;
        }
    }
}
