.user-alert-container {
    position: fixed;
    bottom: $base-spacing;
    left: $base-spacing;
    z-index: 500;
    padding-right: $base-spacing;

    .user-alert {
        z-index: 500;
        padding-right: $base-spacing;
        margin-top: $large-spacing;
        position: relative;

        &.warning {
            i {
                background-color: color('yellow', 'state');
                animation: sonarWarning 1.8s linear infinite;
            }
            p {
                background-color: color('yellow', 'state');
                color: #333;
            }
        }
        &.danger {
            i {
                background-color: color('red', 'state');
                animation: sonarDanger 1.8s linear infinite;
            }
            p {
                background-color: color('red', 'state');
                color: #fff;
            }
        }
        &.success {
            i {
                background-color: color('green', 'state');
                animation: sonarSuccess 1.8s linear infinite;
            }
            p {
                background-color: color('green', 'state');
                color: #333;
            }
        }
        i {
            display: inline-block;
            border-radius: 100%;
            font-size: $font-size-xl;
            line-height: $base-element-height;
            width: $base-element-height;
            height: $base-element-height;
            text-align: center;
            color: rgba(#000, 0.4);
        }
        p {
            display: inline-block;
            border-radius: $base-border-radius;
            padding: $extra-small-spacing $small-spacing;
            font-size: $font-size-sm;
            text-align: center;
            margin: 0;
            cursor: default;
            position: relative;

            b {
                color: color('800', 'blue');
                color: rgba(0, 0, 0, 0.5);
            }

            a {
                color: rgba(0, 0, 0, 0.5);
                text-decoration: underline;
            }

            button {
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                padding: $small-spacing;
                background: rgba(0, 0, 0, 0.3);
                color: #fff;
                border-radius: 0 $base-border-radius $base-border-radius 0;

                &:hover {
                    background: rgba(0, 0, 0, 0.5);
                }
            }
        }
    }
}
