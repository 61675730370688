.unscrollable {
    overflow: hidden;
}

.disabled {
    opacity: 0.6;

    cursor: none !important;
    pointer-events: none;
}

.text-link {
    color: color('900', 'gray');
    border-bottom: 1px solid color('600', 'green');
    font-weight: $font-weight-semibold;

    &:hover {
        color: color('800', 'blue');
        cursor: pointer;
    }
}

.hidden {
    display: none !important;
}

.error {
    color: color('600', 'red');
}

.danger {
    color: color('600', 'red');
}

.premium {
    color: color('500', 'yellow');
    text-transform: none;
}

.bg-medium-gray {
    h1 {
        color: #fff;
    }
}

.google {
    color: color('google', 'social');
}

.facebook {
    color: color('facebook', 'social');
}

.twitter {
    color: color('twitter', 'social');
}

.pinterest {
    color: color('pinterest', 'social');
}

.bg-facebook {
    background-color: color('facebook', 'social') !important;
}

.bg-twitter {
    background-color: color('twitter', 'social') !important;
}

.bg-pinterest {
    background-color: color('pinterest', 'social');
}

.bg-instagram {
    background-color: color('instagram', 'social');
}

.bg-youtube {
    background-color: color('youtube', 'social');
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.fullwidth {
    width: 100%;
}

.block {
    display: block;
}

.avatar {
    display: block;
    width: 110px;
    height: 110px;
    border-radius: 100%;
    background-size: 100%;

    img {
        border-radius: 100%;
        width: 100%;
        height: auto;
    }

    &.avatar-80 {
        width: 70px;
        height: 70px;
    }

    &.avatar-60 {
        width: 54px;
        height: 54px;
    }

    &.avatar-40 {
        display: block;
        width: 44px;
        height: 44px;
        border-radius: 100%;
        background-size: 100%;
    }
}
