.badge {
    background-color: color('800', 'blue');
    border-radius: 20px;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    font-weight: $font-weight-bold;
    height: 20px;
    line-height: 20px;
    min-width: 20px;
    text-align: center;

    &.badge-gray {
        background-color: color('100', 'gray');
        color: color('900', 'gray');
    }

    &.badge-white {
        background-color: #fff;
        color: color('900', 'gray');
    }

    &.badge-error {
        background-color: color('600', 'red');
    }

    &.badge-warning {
        background-color: color('500', 'yellow');
    }

    &.badge-success {
        background-color: color('600', 'green');
        color: color('900', 'gray');
    }
}
