//Topbar
.notification--topbar {
    display: none;
    background-color: color('600', 'red');
    color: #fff;
    padding: 13px 20px;
    z-index: 6;
    &.winback.being-processed {
        background-color: color('500', 'blue');
    }
    &.active {
        display: block;
    }

    &.winback {
        background-color: color('500', 'red');
        > .row {
            i {
                line-height: 2;
                align-self: flex-start;
            }
            i.icon--cross {
                line-height: 1.2;
            }
            p {
                margin-right: 10px;
            }
            .link-contact-unpaid-teams {
                color: white;
            }
        }
    }

    @include breakpoint('sm') {
        font-size: $font-size-sm;

        .row {
            display: block;
        }

        &.winback.being-processed > .row {
            padding-top: 30px;
        }

        &.winback > .row {
            display: flex;
            align-items: flex-start;

            .close-being-processed {
                position: absolute;
                right: 10px;
                top: 0;
            }

            p {
                margin-right: 0;
            }
        }

        p {
            margin-bottom: $small-spacing;
        }

        .bj-button {
            height: $extra-small-element-height;
            line-height: $extra-small-element-height;
        }

        .bj-button.update-payment,
        .my-subscription {
            width: 100%;
            height: 34px;
            line-height: 34px;
        }
    }
}

//Cuando hacemos scroll hacia arriba, mostramos el topbar por encima de la barra de búsqueda
#header {
    &.fixed {
        .notification--topbar {
            position: relative;
        }
    }
}

body.topbar-active {
    #viewport {
        padding-top: 172px;
    }
    &.home--anonymous {
        #viewport {
            padding-top: 108px;
        }
    }
}
