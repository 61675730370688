.copy-holder {
    margin: 0 0 $small-spacing;

    input {
        position: absolute;
        top: 0;
        left: 0;
        margin-top: -100%;
        opacity: 0;
        pointer-events: none;
    }

    .attribution_text {
        flex: 1;
        display: block;
        height: $base-element-height;
        line-height: $base-element-height;
        font-size: $font-size-sm;
        border-radius: 3px 0 0 3px;
        background-color: color('50', 'gray');
        color: color('500', 'gray');
        border: 1px solid color('100', 'gray');
        padding: 0 1em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    button {
        border-radius: 0 3px 3px 0;
        height: $base-element-height;
        line-height: $base-element-height;
        padding: 0 $base-spacing;
        font-size: $font-size-sm;

        > span {
            display: none;

            &.active {
                display: block;
            }
        }

        i {
            height: $base-element-height;
            line-height: $base-element-height;
        }
    }
}
