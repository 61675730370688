.slider {
    .slider__nav {
        text-align: center;

        li {
            max-width: 25%;
            padding: 12px;
        }

        a {
            padding: 12px;
            background-color: #fff;
            color: color('900', 'gray');

            &:hover,
            &.active {
                box-shadow: 0 0 20px rgba(95, 125, 149, 0.3);
                color: color('900', 'gray');
            }
        }

        p {
            margin: 0;
        }
    }

    .slider__items {
        position: relative;

        li {
            display: none;
            padding: 24px 0;
            @include transition(opacity 300ms ease-in-out);
            &.active {
                display: block;
            }
        }
    }

    .laptop {
        min-height: initial;
    }
}
