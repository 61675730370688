.modal-confirm-copy-content {
    .modal__container {
        .content {
            padding: 50px;
        }
    }
    .modal-actions {
        display: flex;
        justify-content: space-between;
    }

    .progress-bar__title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .completed.progress-bar__title {
        color: color('500', 'green');
        i::before {
            color: color('500', 'green');
        }
    }

    .completed.progress-bar__value {
        width: 100%;
        background-color: color('500', 'green');
        animation-name: width;
        animation-duration: 0.5s;
    }

    @keyframes width {
        from {
            width: 0%;
        }
        to {
            width: 100%;
        }
    }

    @include breakpoint('lg') {
        .modal-actions {
            flex-wrap: wrap;
            justify-content: center;
            .button__cancel {
                margin-bottom: 10px;
            }
            #confirm-download-button {
                width: 100%;
            }
        }
    }
}
