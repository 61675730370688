$list-height: 50px !global;

.popover {
    // Necesario por especificidad
    &.popover-filters {
        position: relative;
        margin-right: $small-spacing;

        .popover-button {
            .button--text {
                margin: 0 $extra-small-spacing 0 0;
            }

            .badge {
                height: $base-spacing;
                line-height: $base-spacing;
                padding: 0;
                background-color: color('300', 'gray');
                color: color('900', 'gray');
                font-size: 11px;
                min-width: $base-spacing;
                margin: 0 $extra-small-spacing 0 0;
                z-index: 1;
            }
        }

        .popover-content {
            width: 300px;
            padding: 0;
            overflow-y: auto;
            max-height: calc(100% - #{$header-height});
            position: fixed;
            top: $header-height;
            left: $small-spacing;

            &:before {
                left: 16px;
            }

            &:after {
                left: 14px;
            }
        }

        .tag {
            height: 22px;
            line-height: 22px;
            padding: 0 5px;
            box-shadow: 0 0 0 1px color('green', 'state');
            border-radius: 2px;
            color: color('900', 'blue');
            font-weight: $font-weight-bold;
            font-size: 12px;
        }

        .tooltip__trigger::after {
            opacity: 0;
        }

        .popover-filters__apply {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 250px;
            padding: $small-spacing;
            background-color: #fff;
            border-top: 1px solid color('100', 'gray');
            display: none;
        }

        @include breakpoint('lg') {
            .popover-content {
                left: 0;
            }
        }
    }

    .in-search {
        .popover-filters {
            .popover-content {
                $search-header-height: $header-height + $list-height;
                top: $search-header-height;
                height: calc(100% - #{$search-header-height});
                position: fixed;
                left: 0;
                overflow: auto;
                border-radius: 0;
                border: none;
                animation: none;
            }
        }
    }
}

.view--pack .popover.popover-filters .popover-content {
    height: auto;
}
