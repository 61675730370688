div.awesomplete {
    & > input {
        display: block;
    }

    & > ul {
        position: absolute;
        left: 0;
        z-index: 99;
        min-width: 100%;
        border-radius: $base-border-radius;
        background: #fff;
        box-shadow: 0 $extra-small-spacing $small-spacing color('900', 'gray', 0.2);
        color: color('900', 'gray');
        text-align: left;
        padding: $small-spacing 0;

        &[hidden],
        &:empty {
            display: none;
        }

        > li {
            position: relative;
            height: $small-element-height;
            line-height: $small-element-height;
            cursor: pointer;
            padding: 0 $base-spacing;
            color: color('600', 'gray');

            &:hover {
                background: color('100', 'gray');
            }

            &[aria-selected='true'] {
                background: color('100', 'gray');
            }

            mark {
                background: none;
                font-weight: $font-weight-bold;
                color: color('900', 'gray');
            }
        }
    }
}
