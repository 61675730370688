/* CUSTOM CHECKBOX */
.checkbox-group {
    @include display(flex);
    @include flex-wrap(wrap);
    @include align-items(flex-start);

    input[type='checkbox'] {
        display: none;
        @include appearance(none);

        &:checked + .fake-checkbox {
            &:before {
                box-shadow: inset 0 0 0 64px color('500', 'green');
            }

            &:after {
                content: '\f121';
                font-family: 'bobjoll';
                position: absolute;
                top: 0;
                left: 0;
                color: #fff;
                width: 22px;
                height: 22px;
                line-height: 22px;
                text-align: center;
            }
        }
    }

    label {
        cursor: pointer;
        margin: 0;
    }

    .fake-checkbox {
        position: relative;
        margin-right: 5px;
        padding: 0;

        &:before {
            content: '';
            border-radius: 2px;
            width: 22px;
            height: 22px;
            display: inline-block;
            box-shadow: inset 0 0 0 1px color('200', 'gray');
        }
    }
}

/* CUSTOM RADIO BUTTONS */
.radio-group {
    @include display(flex);
    @include flex-wrap(wrap);
    @include align-items(flex-start);

    input[type='radio'] {
        display: none;
        @include appearance(none);

        &:checked + label.fake-radio:before {
            box-shadow: inset 0 0 0 7px color('500', 'green');
        }
    }

    label {
        cursor: pointer;
        margin: 0;
    }

    .fake-radio {
        cursor: pointer;
        position: relative;
        margin-right: 5px;
        padding: 0;

        &:before {
            content: '';
            border-radius: 50%;
            width: 22px;
            height: 22px;
            display: inline-block;
            box-shadow: inset 0 0 0 1px color('200', 'gray');
        }
    }
}

/* ERROR */
form,
.form {
    span.error {
        @include display(inline-block);

        @include transform(translateX(-50%) translateY(-22px));

        position: relative;
        color: color('600', 'red');
        font-size: 0.8em;
        padding: 0 0.3em;
        opacity: 0;
        background: #fff;
        left: 50%;
        max-width: 95%;
        margin-bottom: -19px;
        text-align: center;

        input {
            border-color: color('600', 'red');
        }
    }
}
