.home--anonymous {
    #viewport {
        padding-top: $header--menu-height;

        &.collections-visible {
            .header {
                .header--search {
                    width: 100%;
                }
            }
        }
    }

    .header {
        .header--search {
            background: none;
            box-shadow: none;
            position: absolute;
            width: 100%;

            .logo,
            .search-holder {
                display: none;
            }
        }

        #collections-toggle {
            margin-left: auto;
            color: #fff;
            box-shadow: inset 0 0 0 2px #fff;
            padding: 0 5px;
            background-color: color('900', 'blue');
        }
    }

    .logo-positive {
        display: none;
    }

    &.hero--white {
        .header #collections-toggle {
            color: color('900', 'blue');
            background: none;
            box-shadow: inset 0 0 0 2px color('900', 'blue');
        }

        .hero.hero--home {
            .content .search-holder .fake-search {
                background-color: rgb(239, 243, 246);

                input:placeholder {
                    color: color('800', 'gray');
                }

                button[type='submit'] {
                    background-color: color('400', 'green');
                    color: #fff;
                }
            }
        }

        .search-holder .fake-search button.clean-search__button:after {
            display: none;
        }

        .home__tags a {
            color: color('800', 'gray');
        }
    }
}
.home {
    .packs-new h3 {
        color: color('900', 'blue');
    }

    @include breakpoint('md') {
        section {
            &.container {
                padding: 0 $base-spacing;
            }
            &.home-section {
                > .container {
                    padding: 0 $base-spacing;
                }
            }
        }
    }

    .premium-packs {
        h3 i:before {
            width: 18px;
            height: 18px;
        }

        header {
            a {
                color: $color-light;

                &:hover {
                    color: $color-light;
                }
            }
        }
    }
}

section.blog {
    img {
        display: block;

        @supports (object-fit: cover) {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
