//Reemplazar este fichero cuando se integre el nuevo editor

.color-selector {
    @include clearfix;

    text-align: center;
    margin-left: -3px;
    width: calc(100% + 6px);

    li {
        display: inline-block;
        padding: 3px;

        button {
            padding: 0;
            width: 20px;
            height: 20px;
            border: none;
            border-radius: 100%;
            background: none;

            @include transition(all 0.3s ease-in-out);

            &.green {
                background-color: rgb(145, 220, 90);
            }

            &.blue {
                background-color: rgb(0, 109, 240);
            }

            &.purple {
                background-color: rgb(147, 62, 197);
            }

            &.yellow {
                background-color: rgb(255, 218, 68);
            }

            &.red {
                background-color: rgb(216, 0, 39);
            }

            &.black {
                background-color: #000000;
                box-shadow: 0 0 0 1px #5f5f5f;
            }

            &.white {
                background-color: #ffffff;
                box-shadow: 0 0 1px color('800', 'blue');
            }

            &.selected {
                &:after {
                    content: '';
                    width: 10px;
                    height: 10px;
                    display: block;
                    background-color: #fff;
                    border-radius: 50%;
                    margin: 5px;
                }
                &.white:after {
                    background-color: color('900', 'gray');
                }
            }
        }
    }
}
