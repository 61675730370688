.search-holder {
    .fake-search {
        background-color: color('75', 'gray');
        height: $base-element-height;
        line-height: $base-element-height;
        border-radius: $base-border-radius;
        margin: 0;

        &.focus {
            box-shadow: inset 0 0 0 2px color('75', 'gray');
        }

        .popover {
            &-button {
                height: $base-element-height;
                line-height: $base-element-height;
                font-weight: $font-weight-regular;
                padding: 0 $base-spacing;
                position: relative;
                color: color('heading', 'general');

                &:after {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    width: 1px;
                    height: 60%;
                    transform: translateY(-50%);
                    background-color: color('200', 'gray');
                    content: '';
                }

                span {
                    min-width: 60px;
                }
            }

            &-content {
                width: max-content;
                left: 0;
                top: 100%;
                border-radius: 0 0 $base-border-radius $base-border-radius;
                padding: $small-spacing;
                min-width: 100%;
                color: color('text', 'general');

                &:before,
                &:after {
                    display: none;
                }
            }
        }

        button[type='submit'] {
            border: none;
            width: $base-element-height;
            height: $base-element-height;
            color: color('heading', 'general');
            box-shadow: none;

            &:focus {
                background: none;
            }
        }

        button {
            &.clean-search__button {
                position: relative;
                margin: auto 0;
                background: transparent;
                height: 100%;
                padding: 0;
                width: $base-element-height;
                opacity: 0;
                transition: 0.3s ease all;

                .icon {
                    color: color('800', 'gray');
                    margin: auto;
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 0;
                    width: 1px;
                    height: 60%;
                    transform: translateY(-50%);
                    background-color: color('200', 'gray');
                }

                &.show-btn {
                    opacity: 1;
                }
            }
        }

        @include breakpoint('sm') {
            // .popover,
            .tag-field__items {
                display: none;
            }
            .popover-button {
                padding: 0 5px 0 10px;
            }

            input[type='search'] {
                padding-left: $small-spacing;
            }

            button[type='submit'] {
                padding: 0;
            }
        }
    }

    input[type='search'] {
        margin: 0;
        padding: 0;
        border: none;
        background: none;
        white-space: nowrap;
        @include flex(1);
        height: $base-element-height;
        line-height: $base-element-height;
        position: relative;
        z-index: auto;
    }

    .awesomplete {
        @include flex(1);
    }

    form {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        height: 100%;
    }

    .tag-field {
        position: relative;
        line-height: $extra-small-element-height;
        height: 100%;

        &__item {
            box-shadow: 0 0 0 1px color('300', 'gray');
            border-radius: 2px;
            padding-left: 4px;
            margin: ($base-element-height - $small-element-height) $extra-small-spacing
                ($base-element-height - $small-element-height) 0;
            font-size: $font-size-sm;

            .remove {
                display: inline-block;
                width: $extra-small-element-height - $extra-small-spacing;
                height: $extra-small-element-height;
                line-height: $extra-small-element-height;
                text-align: center;
                font-weight: $font-weight-bold;
                cursor: pointer;
            }
        }

        &__content {
            position: absolute;
            top: 100%;
            left: 0;
            visibility: hidden;
            width: 100%;
            height: 100%;
            pointer-events: none !important;
            font-size: 15px;

            .autocomplete {
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 1;
                width: calc(100% + 30px);
                margin: 0 -15px;
            }
        }
    }

    .fake-input {
        @include flex(1);
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        outline: none;
        width: 200px;
        br {
            display: none;
        }
        * {
            display: inline;
            white-space: nowrap;
        }
        .search-tag {
            background-color: color('green', 'state', 0.7);
            color: color('heading', 'general');
            padding: 0 4px;
        }
    }
}
