.pickr {
    position: relative;
    overflow: visible;
    transform: translateY(0);
}
.pickr * {
    box-sizing: border-box;
    outline: none;
    border: none;
    -webkit-appearance: none;
}
.pickr .pcr-button {
    position: relative;
    height: 2em;
    width: 2em;
    padding: 0.5em;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue', Arial, sans-serif;
    border-radius: 0.15em;
    background: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" stroke="%2342445A" stroke-width="5px" stroke-linecap="round"><path d="M45,45L5,5"></path><path d="M45,5L5,45"></path></svg>')
        no-repeat center;
    background-size: 0;
    transition: all 0.3s;
}
.pickr .pcr-button::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path fill="white" d="M1,0H2V1H1V0ZM0,1H1V2H0V1Z"/><path fill="gray" d="M0,0H1V1H0V0ZM1,1H2V2H1V1Z"/></svg>');
    background-size: 0.5em;
    border-radius: 0.15em;
    z-index: -1;
}
.pickr .pcr-button::before {
    z-index: initial;
}
.pickr .pcr-button::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: background 0.3s;
    background: currentColor;
    border-radius: 0.15em;
}
.pickr .pcr-button.clear {
    background-size: 70%;
}
.pickr .pcr-button.clear::before {
    opacity: 0;
}
.pickr .pcr-button.clear:focus {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.85), 0 0 0 3px currentColor;
}
.pickr .pcr-button.disabled {
    cursor: not-allowed;
}
.pickr *,
.pcr-app * {
    box-sizing: border-box;
    outline: none;
    border: none;
    -webkit-appearance: none;
}
.pickr input:focus,
.pickr input.pcr-active,
.pickr button:focus,
.pickr button.pcr-active,
.pcr-app input:focus,
.pcr-app input.pcr-active,
.pcr-app button:focus,
.pcr-app button.pcr-active {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.85), 0 0 0 3px currentColor;
}
.pickr .pcr-palette,
.pickr .pcr-slider,
.pcr-app .pcr-palette,
.pcr-app .pcr-slider {
    transition: box-shadow 0.3s;
}
.pickr .pcr-palette:focus,
.pickr .pcr-slider:focus,
.pcr-app .pcr-palette:focus,
.pcr-app .pcr-slider:focus {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.85), 0 0 0 3px rgba(0, 0, 0, 0.25);
}
.pcr-app {
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 10000;
    border-radius: 0.1em;
    background: #fff;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0s 0.3s;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue', Arial, sans-serif;
    box-shadow: 0 0.15em 1.5em 0 rgba(0, 0, 0, 0.1), 0 0 1em 0 rgba(0, 0, 0, 0.03);
    left: 0;
    top: 0;
}
.pcr-app.visible {
    transition: opacity 0.3s;
    visibility: visible;
    opacity: 1;
}
.pcr-app .pcr-swatches {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.75em;
}
.pcr-app .pcr-swatches.pcr-last {
    margin: 0;
}
@supports (display: grid) {
    .pcr-app .pcr-swatches {
        display: grid;
        align-items: center;
        grid-template-columns: repeat(auto-fit, 1.75em);
    }
}
.pcr-app .pcr-swatches > button {
    font-size: 1em;
    position: relative;
    width: calc(1.75em - 5px);
    height: calc(1.75em - 5px);
    border-radius: 0.15em;
    cursor: pointer;
    margin: 2.5px;
    flex-shrink: 0;
    justify-self: center;
    transition: all 0.15s;
    overflow: hidden;
    background: transparent;
    z-index: 1;
}
.pcr-app .pcr-swatches > button::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path fill="white" d="M1,0H2V1H1V0ZM0,1H1V2H0V1Z"/><path fill="gray" d="M0,0H1V1H0V0ZM1,1H2V2H1V1Z"/></svg>');
    background-size: 6px;
    border-radius: 0.15em;
    z-index: -1;
}
.pcr-app .pcr-swatches > button::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: currentColor;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.15em;
    box-sizing: border-box;
}
.pcr-app .pcr-swatches > button:hover {
    filter: brightness(1.05);
}
.pcr-app .pcr-interaction {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -0.2em 0 -0.2em;
}
.pcr-app .pcr-interaction > * {
    margin: 0 0.2em;
}
.pcr-app .pcr-interaction input {
    letter-spacing: 0.07em;
    font-size: 0.75em;
    text-align: center;
    cursor: pointer;
    color: #75797e;
    background: #f1f3f4;
    border-radius: 0.15em;
    transition: all 0.15s;
    padding: 0.45em 0.5em;
    margin-top: 0.75em;
}
.pcr-app .pcr-interaction input:hover {
    filter: brightness(0.975);
}
.pcr-app .pcr-interaction input:focus {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.85), 0 0 0 3px rgba(66, 133, 244, 0.75);
}
.pcr-app .pcr-interaction .pcr-result {
    color: #75797e;
    text-align: right;
    flex: 1 1 8em;
    width: 170px;
    min-width: 8em;
    transition: all 0.2s;
    border-radius: 0.15em;
    background: #f1f3f4;
    cursor: text;
}
.pcr-app .pcr-interaction .pcr-result::selection {
    background: #4285f4;
    color: #fff;
}
.pcr-app .pcr-interaction .pcr-type.active {
    color: #fff;
    background: #4285f4;
}
.pcr-app .pcr-interaction .pcr-save,
.pcr-app .pcr-interaction .pcr-cancel,
.pcr-app .pcr-interaction .pcr-clear {
    color: #fff;
    width: auto;
}
.pcr-app .pcr-interaction .pcr-save,
.pcr-app .pcr-interaction .pcr-cancel,
.pcr-app .pcr-interaction .pcr-clear {
    color: #fff;
}
.pcr-app .pcr-interaction .pcr-save:hover,
.pcr-app .pcr-interaction .pcr-cancel:hover,
.pcr-app .pcr-interaction .pcr-clear:hover {
    filter: brightness(0.925);
}
.pcr-app .pcr-interaction .pcr-save {
    background: #4285f4;
}
.pcr-app .pcr-interaction .pcr-clear,
.pcr-app .pcr-interaction .pcr-cancel {
    background: #f44250;
}
.pcr-app .pcr-interaction .pcr-clear:focus,
.pcr-app .pcr-interaction .pcr-cancel:focus {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.85), 0 0 0 3px rgba(244, 66, 80, 0.75);
}
.pcr-app .pcr-selection .pcr-picker {
    position: absolute;
    height: 18px;
    width: 18px;
    border: 2px solid #fff;
    border-radius: 100%;
    user-select: none;
}
.pcr-app .pcr-selection .pcr-color-palette,
.pcr-app .pcr-selection .pcr-color-chooser,
.pcr-app .pcr-selection .pcr-color-opacity {
    position: relative;
    user-select: none;
    display: flex;
    flex-direction: column;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}
.pcr-app .pcr-selection .pcr-color-palette:active,
.pcr-app .pcr-selection .pcr-color-chooser:active,
.pcr-app .pcr-selection .pcr-color-opacity:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}
.pcr-app[data-theme='nano'] {
    width: 14.25em;
    width: 100%;
    max-width: 95vw;
}
.pcr-app[data-theme='nano'] .pcr-swatches {
    margin-top: 0.6em;
    padding: 0 0.6em;
}
.pcr-app[data-theme='nano'] .pcr-interaction {
    padding: 0 0.6em 0.6em 0.6em;
}
.pcr-app[data-theme='nano'] .pcr-selection {
    display: grid;
    grid-gap: 0.6em;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: 5fr auto auto;
    align-items: center;
    height: 10.5em;
    width: 100%;
    align-self: flex-start;
}
.pcr-app[data-theme='nano'] .pcr-selection .pcr-color-preview {
    grid-area: 2 / 1 / 4 / 1;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 0.6em;
}
.pcr-app[data-theme='nano'] .pcr-selection .pcr-color-preview .pcr-last-color {
    display: none;
}
.pcr-app[data-theme='nano'] .pcr-selection .pcr-color-preview .pcr-current-color {
    position: relative;
    background: currentColor;
    width: 2em;
    height: 2em;
    border-radius: 50em;
    overflow: hidden;
}
.pcr-app[data-theme='nano'] .pcr-selection .pcr-color-preview .pcr-current-color::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path fill="white" d="M1,0H2V1H1V0ZM0,1H1V2H0V1Z"/><path fill="gray" d="M0,0H1V1H0V0ZM1,1H2V2H1V1Z"/></svg>');
    background-size: 0.5em;
    border-radius: 0.15em;
    z-index: -1;
}
.pcr-app[data-theme='nano'] .pcr-selection .pcr-color-palette {
    grid-area: 1 / 1 / 2 / 3;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.pcr-app[data-theme='nano'] .pcr-selection .pcr-color-palette .pcr-palette {
    border-radius: 0.15em;
    width: 100%;
    height: 100%;
}
.pcr-app[data-theme='nano'] .pcr-selection .pcr-color-palette .pcr-palette::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path fill="white" d="M1,0H2V1H1V0ZM0,1H1V2H0V1Z"/><path fill="gray" d="M0,0H1V1H0V0ZM1,1H2V2H1V1Z"/></svg>');
    background-size: 0.5em;
    border-radius: 0.15em;
    z-index: -1;
}
.pcr-app[data-theme='nano'] .pcr-selection .pcr-color-chooser {
    grid-area: 2 / 2 / 2 / 2;
}
.pcr-app[data-theme='nano'] .pcr-selection .pcr-color-opacity {
    grid-area: 3 / 2 / 3 / 2;
}
.pcr-app[data-theme='nano'] .pcr-selection .pcr-color-chooser,
.pcr-app[data-theme='nano'] .pcr-selection .pcr-color-opacity {
    height: 0.5em;
    margin: 0 0.6em;
}
.pcr-app[data-theme='nano'] .pcr-selection .pcr-color-chooser .pcr-picker,
.pcr-app[data-theme='nano'] .pcr-selection .pcr-color-opacity .pcr-picker {
    top: 50%;
    transform: translateY(-50%);
}
.pcr-app[data-theme='nano'] .pcr-selection .pcr-color-chooser .pcr-slider,
.pcr-app[data-theme='nano'] .pcr-selection .pcr-color-opacity .pcr-slider {
    flex-grow: 1;
    border-radius: 50em;
}
.pcr-app[data-theme='nano'] .pcr-selection .pcr-color-chooser .pcr-slider {
    background: linear-gradient(to right, red, #ff0, lime, cyan, blue, #f0f, red);
}
.pcr-app[data-theme='nano'] .pcr-selection .pcr-color-opacity .pcr-slider {
    background: linear-gradient(to right, transparent, black),
        url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path fill="white" d="M1,0H2V1H1V0ZM0,1H1V2H0V1Z"/><path fill="gray" d="M0,0H1V1H0V0ZM1,1H2V2H1V1Z"/></svg>');
    background-size: 100%, 0.25em;
}
.pcr-app.pcr-app {
    box-shadow: none;
}
.pcr-app.pcr-app .pcr-selection .pcr-picker {
    height: 18px;
    width: 18px;
}
.pcr-app.pcr-app .pcr-selection .pcr-color-preview {
    margin-left: 0;
}
.pcr-app.pcr-app .pcr-selection .pcr-color-preview .pcr-current-color {
    border-radius: 0.15em;
    width: 100%;
}
.pcr-app.pcr-app .pcr-selection .pcr-color-chooser,
.pcr-app.pcr-app .pcr-selection .pcr-color-opacity {
    // margin-left: 0;
}
.pcr-app.pcr-app .pcr-interaction {
    padding: 0;
    margin-bottom: 0;
}
.gpickr {
    display: flex;
    flex-direction: column-reverse;
    // box-shadow: 0 0.15em 1.5em 0 rgba(0, 0, 0, 0.1), 0 0 1em 0 rgba(0, 0, 0, 0.03);
    // border-radius: 0.15em;
    // overflow: hidden;
    // padding: 0.5em;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue', Arial, sans-serif;
    user-select: none;
    max-width: 100vw;
    margin-bottom: $base-spacing;
}
.gpickr * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
.gpickr button {
    border: none;
    outline: none;
    background: initial;
    cursor: pointer;
}
.gpickr .gpcr-interaction {
    flex-direction: column;
    display: flex;
    margin-left: 0.5em;
    width: 12.5em;
    width: 100%;
    z-index: 1;
    margin: 0 0 15px;
}
.gpickr .gpcr-interaction .gpcr-result {
    display: none;
    height: 7.9em;
    width: 100%;
    position: relative;
    border-radius: 0.15em;
    margin-bottom: 0.5em;
    flex-grow: 1;
}
.gpickr .gpcr-interaction .gpcr-result::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path fill="white" d="M1,0H2V1H1V0ZM0,1H1V2H0V1Z"/><path fill="gray" d="M0,0H1V1H0V0ZM1,1H2V2H1V1Z"/></svg>');
    background-size: 8px;
    border-radius: 0.15em;
    z-index: -1;
}
.gpickr .gpcr-interaction .gpcr-result .gpcr-pos,
.gpickr .gpcr-interaction .gpcr-result .gpcr-angle {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: all 0.3s;
    position: absolute;
    margin: auto;
    opacity: 0.25;
}
.gpickr .gpcr-interaction .gpcr-result .gpcr-angle {
    height: 0.35em;
    width: 0.35em;
    background: white;
    border-radius: 100%;
}
.gpickr .gpcr-interaction .gpcr-result .gpcr-angle > div {
    height: 2px;
    width: 2em;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    position: absolute;
    background: white;
    border-radius: 1em;
    margin: auto 0;
    transform-origin: left;
}
.gpickr .gpcr-interaction .gpcr-result .gpcr-angle.gpcr-active {
    opacity: 1;
}
.gpickr .gpcr-interaction .gpcr-result .gpcr-pos {
    height: 5em;
    width: 5em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    opacity: 1;
}
.gpickr .gpcr-interaction .gpcr-result .gpcr-pos > div {
    height: 15px;
    width: 15px;
    border: 2px solid transparent;
    position: relative;
    margin: auto;
    transition: all 0.3s;
}
.gpickr .gpcr-interaction .gpcr-result .gpcr-pos > div:not(.gpcr-active) {
    cursor: pointer;
}
.gpickr .gpcr-interaction .gpcr-result .gpcr-pos > div::before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 5px;
    width: 5px;
    border-radius: 100%;
    background: white;
    transition: all 0.3s;
    opacity: 0.25;
    margin: auto;
}
.gpickr .gpcr-interaction .gpcr-result .gpcr-pos > div:hover::before {
    opacity: 1;
}
.gpickr .gpcr-interaction .gpcr-result .gpcr-pos > div.gpcr-active {
    border-color: white;
    border-radius: 100%;
}
.gpickr .gpcr-interaction .gpcr-result .gpcr-pos > div.gpcr-active::before {
    opacity: 1;
}
.gpickr .gpcr-interaction .gpcr-result:hover .gpcr-angle {
    opacity: 1;
}
.gpickr .gpcr-interaction .gpcr-mode {
    height: 1.5em;
    width: 1.5em;
    position: relative;
    top: 0.15em;
    left: 0.15em;
    border: 2px solid white;
    border-radius: 0.15em;
    cursor: pointer;
    opacity: 0.25;
    transition: all 0.3s;
}
.gpickr .gpcr-interaction .gpcr-mode::before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    transition: all 0.3s;
}
.gpickr .gpcr-interaction .gpcr-mode[data-mode='linear']::before {
    height: 2px;
    width: 70%;
    background: white;
    transform: rotate(45deg);
    border-radius: 50em;
}
.gpickr .gpcr-interaction .gpcr-mode[data-mode='radial']::before {
    height: 50%;
    width: 50%;
    border-radius: 100%;
    border: 2px solid white;
}
.gpickr .gpcr-interaction .gpcr-mode[data-mode='conic']::before {
    height: 0;
    width: 0;
    border: 5px solid transparent;
    border-color: white white transparent transparent;
}
.gpickr .gpcr-interaction .gpcr-mode:hover {
    opacity: 1;
}
.gpickr .gpcr-stops {
    margin-bottom: 0.75em;
}
.gpickr .gpcr-stops .gpcr-stop-preview {
    height: 2em;
    width: 100%;
    position: relative;
    border-radius: 0.15em;
    overflow: hidden;
    cursor: pointer;
}
.gpickr .gpcr-stops .gpcr-stop-preview::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path fill="white" d="M1,0H2V1H1V0ZM0,1H1V2H0V1Z"/><path fill="gray" d="M0,0H1V1H0V0ZM1,1H2V2H1V1Z"/></svg>');
    background-size: 8px;
    border-radius: 0.15em;
    z-index: -1;
}
.gpickr .gpcr-stops .gpcr-stop-marker {
    position: relative;
    z-index: 1;
}
.gpickr .gpcr-stops .gpcr-stop-marker .gpcr-marker {
    height: 12px;
    width: 12px;
    position: absolute;
    background: currentColor;
    margin: 0.15em 0 0 -5px;
    border-radius: 100%;
    border: 2px solid white;
    box-shadow: 0 0.05em 0.2em rgba(0, 0, 0, 0.15);
    transition: opacity 0.15s;
    cursor: grab;
    cursor: webkit-grab;
}
.gpickr .gpcr-stops .gpcr-stop-marker .gpcr-marker::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2 2"><path fill="white" d="M1,0H2V1H1V0ZM0,1H1V2H0V1Z"/><path fill="gray" d="M0,0H1V1H0V0ZM1,1H2V2H1V1Z"/></svg>');
    background-size: 4px;
    border-radius: 0.15em;
    z-index: -1;
}
.gpickr .gpcr-stops .gpcr-stop-marker .gpcr-marker::before {
    border-radius: 100%;
}
