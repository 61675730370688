.group-styles {
    border-bottom: 1px solid color('200', 'gray');
    padding-bottom: 10px;
    margin-top: 50px;

    &:first-child {
        margin-top: 0;
    }

    &__header {
        display: flex;
        align-items: center;
        gap: 5px;
        flex-wrap: wrap;
        h2 {
            display: inline-block;
            font-family: $base-font-family;
        }
    }
    &__info {
        width: 100%;
        @media (max-width: 1407px) {
            span {
                display: none;
            }
        }
    }
    &__body {
        display: flex;
        align-items: center;
        gap: 10px;

        > .icons {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(138px, 1fr));
            grid-auto-flow: row dense;
            align-items: end;

            .icon--item.icon-fill {
                display: none;
            }

            .icon--holder {
                max-width: 190px;
                margin: 0 auto;
            }

            &:not(.big) {
                min-width: unset;
                height: 152px;
                overflow: hidden;
                @include breakpoint('lg') {
                    height: 285px;
                }
                @include breakpoint('xs') {
                    // height: 431px;
                    grid-template-columns: repeat(auto-fill, minmax(85px, 1fr));
                }
                .icon--item {
                    height: 140px;
                }
            }

            &.big {
                grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
                height: 185px;
                overflow: hidden;
                @include breakpoint('lg') {
                    height: 376px;
                }
                // @include breakpoint('xs') {
                //     height: 566px;
                // }
                .icon--item {
                    height: 185px;
                }
            }
        }

        > .bj-button {
            min-width: 145px;
            @include breakpoint('md') {
                flex-grow: 0;
                margin-left: auto;
            }
        }

        @include breakpoint('md') {
            flex-wrap: wrap;
            > .bj-button {
                margin-bottom: $base-spacing;
            }
        }
    }
}

.collections-visible {
    .group-styles__body > .icons {
        height: 125px;
        overflow: hidden;
    }
}
