.notify-wrapper {
    .button {
        display: inline-block;

        position: relative;

        height: $base-element-height;

        padding: 0 1.4em;

        border-radius: $base-border-radius;

        box-shadow: inset 0px 0px 0px 0 color('default', 'buttons');

        color: color('default', 'buttons-text');
        font-size: $font-size-md;
        font-weight: $font-weight-semibold;
        line-height: $base-element-height;

        cursor: pointer;

        &.active,
        &:hover {
            box-shadow: inset 0px 0px 0px 0 color('default', 'buttons', 1, 5);

            color: color('default', 'buttons-text');

            .icon {
                color: color('default', 'buttons-text');
            }
        }

        &.active,
        &:active {
            box-shadow: inset 0px 0px 0px 0 color('default', 'buttons-text', 1, 12);
        }

        .icon {
            color: color('default', 'buttons-text');
        }

        /**
        * Modifier
        */
        &--outline {
            --color: color('default-outline', 'buttons-text');

            box-shadow: inset 0px 0px 0px $base-border-width color('default', 'buttons');

            color: color('default-outline', 'buttons-text');

            .icon {
                color: color('default-outline', 'buttons-text');
            }

            &.active,
            &:hover {
                box-shadow: inset 0px 0px 0px (map-get($button-sizes, map-get-last($button-sizes)) / 2)
                    color('default', 'buttons');

                color: color('default', 'buttons-text');

                .icon {
                    color: color('default', 'buttons-text');
                }
            }
        }

        &--flat {
            box-shadow: inset 0px 0px 0px 100px color('default', 'buttons');

            &.active,
            &:hover {
                box-shadow: inset 0px 0px 0px 100px color('default', 'buttons', 1, 5);

                color: color('default', 'buttons-text');

                .icon {
                    color: color('default', 'buttons-text');
                }
            }

            &.active,
            &:active {
                box-shadow: inset 0px 0px 0px 100px color('default', 'buttons', 1, 12);
            }
        }

        &--shadow {
            &::before {
                position: absolute;
                top: 0;
                left: 0;

                width: 100%;
                height: 100%;

                border-radius: $base-border-radius;

                box-shadow: $small-box-shadow;

                content: '';
            }
        }

        &--state {
            &.active:not(:disabled) {
                .state--inactive {
                    display: none;
                }

                .state--active {
                    display: block;
                }
            }

            &:not(.active) {
                .state--inactive {
                    display: block;
                }

                .state--active {
                    display: none;
                }
            }
        }

        &--pill {
            border-radius: $base-element-height;
        }

        &--icon {
            @extend %row-flexbox;
            @extend .row--vertical-center;

            &:not(.button--icon--only) {
                .icon {
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            span,
            i {
                margin-left: auto;
            }

            span ~ i,
            i ~ span {
                margin-left: $extra-small-spacing !important;
                margin-right: auto;
            }

            &--only {
                width: $base-element-height;

                padding: 0 !important;
            }
        }

        &--auto {
            box-shadow: inset 0px 0px 0px 100px color('default', 'buttons');

            height: auto !important;

            span {
                line-height: $line-height-160;
            }
        }

        &--loading {
            $size: map-get($button-sizes, map-get-last($button-sizes)) / 2;

            box-shadow: inset 0px 0px 0px ($size) color('gray', 'buttons') !important;

            pointer-events: none !important;

            &::after {
                animation: rotate 600ms linear infinite;

                display: block !important;

                overflow: hidden;

                opacity: 1;

                width: calc((#{$size}) * 0.75);
                height: calc((#{$size}) * 0.75);

                margin: calc(((#{$size}) * 0.25) / 2) auto;

                border-radius: 50%;
                border-width: 2px;
                border-color: color('border-inverted', 'general') color('border-inverted', 'general', 0.3)
                    color('border-inverted', 'general', 0.3) color('border-inverted', 'general', 0.3);
                border-style: solid;

                box-sizing: border-box;

                content: '';
            }

            & > * {
                display: none !important;
            }
        }

        /**
        * Sizes
        */
        @each $key, $value in $button-sizes {
            &--#{$key} {
                font-size: map-get($font-sizes, $key);

                height: $value;

                line-height: $value;

                &.button--icon {
                    &--only {
                        width: $value;
                        height: $value;
                    }
                }
            }
        }

        /**
        * Colors
        */
        @each $color, $hex in map-get($colors, 'buttons') {
            &--#{$color} {
                --color: color($color, 'buttons-text');

                color: color($color, 'buttons-text');

                .icon {
                    color: color($color, 'buttons-text');
                }

                &.button--outline {
                    box-shadow: inset 0px 0px 0px $base-border-width $hex;

                    color: color(#{$color}-outline, 'buttons-text');

                    .icon {
                        color: color(#{$color}-outline, 'buttons-text');
                    }

                    &:hover {
                        box-shadow: inset 0px 0px 0px 100px color($color, 'buttons-hover');
                    }

                    &.active,
                    &:active {
                        color: color($color, 'buttons-text');

                        box-shadow: inset 0px 0px 0px 100px color($color, 'buttons-hover');

                        .icon {
                            color: color($color, 'buttons-text');
                        }
                    }
                }

                &.button--flat {
                    box-shadow: inset 0px 0px 0px 100px $hex;
                }

                &:hover {
                    box-shadow: inset 0px 0px 0px 100px color($color, 'buttons-hover');

                    color: color($color, 'buttons-text');

                    .icon {
                        color: color($color, 'buttons-text');
                    }
                }

                &.active,
                &:active {
                    box-shadow: inset 0px 0px 0px 100px color($color, 'buttons-active');
                }
            }

            &--#{$color}--hover {
                &.active,
                &:hover {
                    box-shadow: inset 0px 0px 0px 100px color($color, 'buttons-hover') !important;

                    color: color($color, 'buttons-text') !important;

                    .icon {
                        color: color($color, 'buttons-text') !important;
                    }
                }

                &.active,
                &:active {
                    box-shadow: inset 0px 0px 0px 100px color($color, 'buttons-active') !important;
                }
            }
        }

        /**
        * Social
        */
        @each $color, $hex in map-get($colors, 'social') {
            &--#{$color} {
                box-shadow: inset 0px 0px 0px 0 $hex;

                color: color('inverted', 'buttons');

                .icon {
                    color: color('inverted', 'buttons');
                }

                &.button--flat.button--inverted {
                    box-shadow: inset 0px 0px 0px 100px color('inverted', 'buttons');

                    color: color($color, 'social');

                    .icon {
                        color: color($color, 'social');
                    }

                    &.active,
                    &:hover {
                        box-shadow: inset 0px 0px 0px 100px color($color, 'social', 1, 5);

                        color: color('inverted', 'buttons');

                        .icon {
                            color: color('inverted', 'buttons');
                        }
                    }

                    &.active,
                    &:active {
                        box-shadow: inset 0px 0px 0px 100px color($color, 'social', 1, 7);
                    }
                }

                &.button--outline {
                    box-shadow: inset 0px 0px 0px $base-border-width $hex;

                    color: color($color, 'social');

                    .icon {
                        color: color($color, 'social');
                    }

                    &.active,
                    &:hover {
                        box-shadow: inset 0px 0px 0px 100px color($color, 'social', 1, 5);

                        color: color('inverted', 'buttons');

                        .icon {
                            color: color('inverted', 'buttons');
                        }
                    }

                    &.active,
                    &:active {
                        box-shadow: inset 0px 0px 0px 100px color($color, 'social', 1, 7);
                    }
                }

                &.button--flat {
                    box-shadow: inset 0px 0px 0px 100px $hex;
                }

                &.active,
                &:hover {
                    box-shadow: inset 0px 0px 0px 100px color($color, 'social', 1, 5);

                    color: color('inverted', 'buttons');

                    .icon {
                        color: color('inverted', 'buttons');
                    }
                }

                &.active,
                &:active {
                    box-shadow: inset 0px 0px 0px 100px color($color, 'social', 1, 7);
                }
            }
        }
    }

    .notify--banner .button--icon--only.notification__close {
        width: 32px;
        height: 32px;
    }
}

.button--premium.button--flat {
    box-shadow: inset 0 0 0 100px color('500', 'yellow');
    display: inline-block;

    position: relative;

    height: $base-element-height;

    padding: 0 1.4em;

    border-radius: $base-border-radius;

    color: color('default', 'buttons-text');
    font-size: $font-size-md;
    font-weight: $font-weight-semibold;
    line-height: $base-element-height;
}
.button--premium {
    --color: color($color, 'buttons-text');
    color: #fff;
}
.button--md {
    font-size: 16px;
    height: 44px;
    line-height: 44px;
}

.button--premium.button--flat:hover {
    text-decoration: none !important;
    box-shadow: inset 0 0 0 100px color('400', 'yellow');
    color: #fff;
}

button {
    &.btn--follow {
        // border: 2px solid color('blue', 'state');
        box-shadow: inset 0 0 0 2px color('blue', 'state');
        color: color('blue', 'state');
        background: none;

        &.btn-small {
            font-size: 14px;
            height: 34px;
            height: $small-element-height;
            padding: 0 20px;
        }

        &:hover {
            background-color: color('blue', 'state', 0.1);
            color: color('blue', 'state');
        }

        &:focus {
            background-color: color('blue', 'state', 0.2);
        }

        .state--active {
            display: none;
        }

        &.active {
            box-shadow: inset 0 0 0 2px color('200', 'gray');
            color: color('800', 'gray');
            background: none;

            .hover {
                display: none;
            }

            &:hover {
                // border-color: color('500', 'red');
                box-shadow: inset 0 0 0 2px color('500', 'red');
                background-color: color('500', 'red', 0.1);
                color: color('500', 'red');

                .normal {
                    display: none;
                }

                .hover {
                    display: block;
                }
            }

            &:focus {
                .normal {
                    display: initial;
                }

                .hover {
                    display: none;
                }
            }

            .state--active {
                display: block;
            }

            .state--inactive {
                display: none;
            }
        }

        &--inverted {
            box-shadow: inset 0 0 0 2px color('text-inverted', 'general');
            color: color('text-inverted', 'general');

            &:hover {
                color: color('text-inverted', 'general');
                background-color: color('text-inverted', 'general', 0.1);
            }

            &:focus {
                color: color('text-inverted', 'general');
                background-color: color('text-inverted', 'general', 0.2);
            }

            &.active {
                box-shadow: inset 0 0 0 2px color('600', 'gray');
                color: color('text-inverted', 'general');

                &:hover {
                    color: color('text-inverted', 'general');
                }
            }
        }

        &--inverted-alt {
            box-shadow: inset 0 0 0 2px color('blue', 'state');
            background-color: color('blue', 'state');
            color: color('text-inverted', 'general');

            &:hover {
                color: color('text-inverted', 'general');
                background-color: color('600', 'blue');
                box-shadow: inset 0 0 0 2px color('600', 'blue');
            }

            &:focus {
                color: color('text-inverted', 'general');
                background-color: color('700', 'blue');
                box-shadow: inset 0 0 0 2px color('700', 'blue');
            }

            &.active {
                box-shadow: inset 0 0 0 2px color('600', 'gray');
                color: color('text-inverted', 'general');

                &:hover,
                :focus {
                    color: color('text-inverted', 'general');
                    background-color: color('600', 'red');
                    box-shadow: inset 0 0 0 2px color('600', 'red');
                }

                &:active,
                &:focus {
                    box-shadow: inset 0 0 0 2px color('600', 'gray');
                    color: color('text-inverted', 'general');
                    background-color: transparent;
                }
            }
        }
    }

    &.btn--bookmark {
        font-size: 16px;
        display: block;

        &:hover {
            i {
                color: color('900', 'gray');
            }
        }

        i {
            color: color('900', 'gray');
        }

        .state--active {
            display: none;
        }

        + .tooltip__content {
            .state--active {
                display: none;
            }
        }

        &.active {
            .state--active {
                display: block;
            }
            .state--inactive {
                display: none;
            }

            + .tooltip__content {
                .state--active {
                    display: block;
                }
                .state--inactive {
                    display: none;
                }
            }
        }
    }

    &.button--pinterest {
        color: color('text-inverted', 'general');
        @include display(flex);
        @include flex-direction(center);

        &:hover {
            color: color('text-inverted', 'general');
            background-color: color('pinterest', 'social', 0.9);
        }

        &:active,
        &:focus {
            color: color('text-inverted', 'general');
            background-color: darken(color('pinterest', 'social'), 5);
        }

        i {
            font-size: 16px;
            margin-right: 5px;
            line-height: $small-element-height;
        }
    }
}

.bj-button {
    transition: none;

    .icon {
        &::before {
            vertical-align: baseline !important;
            fill: currentColor;
        }
    }

    &--link {
        font-weight: $font-weight-semibold;
        color: color('800', 'green');
        height: auto;
        line-height: inherit;
        font-size: inherit;
        padding: 0;
        vertical-align: unset;
        border-radius: 0;
        min-width: 0;

        &:hover,
        &:focus,
        &:active {
            color: color('800', 'green');
            text-decoration: underline;
            background: none;
        }
    }

    &--white {
        background-color: #fff;
        box-shadow: none;

        &:hover,
        &:focus,
        &:active {
            background-color: color('50', 'gray');
            box-shadow: none;
        }
    }
}

.button--loading-download {
    pointer-events: none !important;
    animation: loadingDownloadFree 2s infinite linear;
    position: relative;

    .text-downloading {
        animation: fade-in 700ms linear forwards;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 16px;
        margin: 0;
    }

    &.bj-button--yellow,
    &.btn-premium {
        animation: loadingDownloadPremium 2s infinite linear;
    }

    &.bj-button--gray {
        animation: loadingDownloadGray 2s infinite linear;
    }

    & > * {
        pointer-events: none !important;
    }

    & > *:not(.text-downloading) {
        opacity: 0;
    }
}

@keyframes loadingDownloadFree {
    0% {
        background: color('500', 'green');
    }

    50% {
        background: color('600', 'green');
    }

    100% {
        background: color('500', 'green');
    }
}

@keyframes loadingDownloadPremium {
    0% {
        background: color('500', 'yellow');
    }

    50% {
        background: color('600', 'yellow');
    }

    100% {
        background: color('500', 'yellow');
    }
}

@keyframes loadingDownloadGray {
    0% {
        background: color('50', 'gray');
    }

    50% {
        background: color('100', 'gray');
    }

    100% {
        background: color('50', 'gray');
    }
}
