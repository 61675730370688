// Override rebranding
@import 'modules/variables/rebranding';

// Load variable dependencies
@import '~bobjoll/scss/modules/bourbon/bourbon';
@import '~bobjoll/scss/modules/mixins/helpers';
@import '~bobjoll/scss/modules/mixins/grid';

// Load configuracion
@import '../../settings.scss';

// External libraries
@import '~@simonwep/pickr/dist/themes/classic.min';
@import '../../ts/library/gpickr/gpickr';

// Override colors
@import 'modules/variables/colors.variables';
@import '~bobjoll/scss/modules/variables/colors';
@import 'modules/variables/colors';

// Override general
@import '~bobjoll/scss/modules/variables/general';
@import 'modules/variables/general';
@import '~bobjoll/scss/modules/variables/general';

// Override partials
@import 'modules/variables/partials';
@import '~bobjoll/scss/modules/variables/partials';

// Override selectors
@import '~bobjoll/scss/modules/variables/selectors';

// Load framework
@import '~bobjoll/scss/base';

// Load project styles and components
@import 'modules/project';
@import 'layout/**/*.scss';
@import 'partials/**/*.scss';
@import 'pages/**/*.scss';
// @import 'modules/mixins/**/*';
// @import 'vendors/**/*';
$prueba-20-08-2020: true;

// Override max-width bobjoll
// se necesita aquí para que cargue el último y sobre escriba al resto
.container,
.container.row {
    max-width: 1520px;
    padding: 0px 20px;
    margin: 0 auto;
}

.row {
    margin: 0;
}
.container.filters-inside-view {
    display: flex;
    &::after {
        display: none;
    }
    .list-content {
        width: 100%;
        @include breakpoint('lg') {
            padding-left: 0;
            border-left: none;
        }
    }
}
// para los filtros de iconos se necesita que el container y los filtros tengan propiedades nuevas
@include breakpoint('lg') {
    input#search__filters:checked ~ .container {
        padding: 0;
        .search-view-filters {
            display: block;
            .item__link {
                img {
                    display: none;
                }
            }
        }
    }
}

// eliminar el ancho máximo en las páginas de búsqueda

body[data-section$='-search'] #viewport > .container,
body[data-section='404'] #viewport > .container {
    max-width: unset;
}

// override botones

$base-border-radius: 6px;

.bj-button {
    border-radius: $base-border-radius;
    i {
        font-size: $font-size-md;
        &::before {
            font-size: $font-size-md;
        }
    }
}

.bj-button--yellow,
.bj-button--primary,
.btn-warning {
    color: color('900', 'gray');
    &:hover,
    &:active,
    &:focus,
    &:visited {
        color: color('900', 'gray');
    }
}

html h6 {
    font-family: $base-font-family;
}

html .font-h1 {
    font-size: $font-size-5xl;
    line-height: $line-height-125;
    font-family: $title-font-family;
    @include breakpoint('md', 'min') {
        font-size: $font-size-7xl;
    }
}

html .font-h2 {
    font-size: $font-size-4xl;
    line-height: $line-height-125;
    font-family: $title-font-family;
    @include breakpoint('md', 'min') {
        font-size: $font-size-6xl;
    }
}

html .font-h3 {
    font-size: $font-size-4xl;
    line-height: $line-height-125;
    font-family: $title-font-family;
    @include breakpoint('md', 'min') {
        font-size: $font-size-5xl;
    }
}

html .font-h4 {
    font-size: $font-size-3xl;
    line-height: $line-height-125;
    font-family: $title-font-family;
    @include breakpoint('sm', 'min') {
        font-size: $font-size-4xl;
    }
}

html .font-h5 {
    font-size: $font-size-2xl;
    line-height: $line-height-125;
    font-family: $base-font-family;
    @include breakpoint('sm', 'min') {
        font-size: $font-size-3xl;
        font-family: $title-font-family;
    }
}

html .font-h6 {
    font-size: $font-size-xl;
    line-height: $line-height-150;
    font-family: $base-font-family;
    @include breakpoint('sm', 'min') {
        font-size: $font-size-2xl;
        line-height: $line-height-125;
    }
}

html .icon {
    font-size: $font-size-md;
}
