.header-author-searcher {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    .author-tabs-component {
        &__tabs {
            overflow: hidden;
            display: flex;
        }
        &__tabs.center {
            justify-content: center;
        }
        &__links {
            padding: 0 $small-spacing 18px $small-spacing;
            color: color('600', 'gray');
            background-color: transparent;
            border-radius: 0;
            border-bottom: 1px solid transparent;

            &:hover {
                background-color: transparent;
                color: color('800', 'gray');
            }
            &.active {
                background-color: transparent;
                border-bottom: 1px solid color('800', 'gray');
                color: color('800', 'gray');
                z-index: 1;
            }
        }
        hr {
            width: 100%;
            position: absolute;
            left: 0;
            margin-top: -1px;
        }
    }
    .search-style-word {
        margin-left: auto;
        margin-top: -20px;
        .search-holder {
            .fake-search {
                .popover-button {
                    height: 34px;
                    line-height: 34px;
                    padding: 0 $small-spacing;
                    span {
                        min-width: unset;
                        padding: 0 $extra-small-spacing 0 $small-spacing;
                    }
                }

                .popover-content {
                    border: 1px solid color('100', 'gray');
                    color: color('600', 'gray');
                    padding: $small-spacing;
                    border-radius: $base-border-radius;
                    z-index: 10;
                    ul {
                        max-height: 275px;
                        overflow-y: scroll;
                        li {
                            height: 42px;
                            padding: 0 $small-spacing;
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            &:hover {
                                background-color: color('75', 'gray');
                            }
                            span {
                                display: none;
                            }
                        }
                        li.active {
                            background: rgba(74, 210, 149, 0.1);
                            color: color('800', 'green');
                        }
                        li.active {
                            span {
                                display: inline;

                                &::before {
                                    color: color('800', 'green');
                                }
                            }
                        }
                    }
                }

                input {
                    padding: 0 $small-spacing;
                }
                .search__button,
                .clean-search__button {
                    width: 34px;
                    margin: unset;
                    height: 100%;
                    i::before {
                        color: color('800', 'gray');
                    }
                }

                .awesomplete {
                    .text {
                        font-size: 14px;
                    }
                }

                &.ios {
                    .awesomplete {
                        height: 34px;
                        display: flex;
                        align-items: center;
                        padding: $small-spacing + $extra-small-spacing 0;
                        cursor: text;

                        input {
                            height: 100%;
                            line-height: initial;
                        }

                        ul {
                            top: 34px;
                        }
                    }
                }
            }
        }
    }
    &.no-tabs {
        margin-top: -40px;
        .search-style-word {
            margin-top: 20px;
            margin-bottom: 20px;
        }
        hr {
            bottom: 0;
        }
    }
    @include breakpoint('sm') {
        .search-style-word {
            .search-holder {
                .fake-search {
                    .popover-button {
                        span {
                            max-width: 100px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }
    @include breakpoint('lg') {
        flex-wrap: wrap;
        .tabs-component {
            width: 100%;
            &__tabs {
                justify-content: center;
            }
            &__links {
                padding: 0 $small-spacing $small-spacing;
            }
        }
        .search-style-word {
            margin: 20px auto 0;
            width: 100%;
        }
        .tabs-component ~ .search-style-word {
            margin: $base-spacing auto;
        }
        &.no-tabs {
            margin-top: 0;
            .search-style-word {
                margin-top: 20px;
                margin-bottom: 20px;
            }
            hr {
                top: 0;
                bottom: unset;
            }
        }
    }
}
