#form_valuation {
    opacity: 1;
    position: fixed;
    left: 0;
    top: 50%;
    text-align: center;
    z-index: 15;
    background-color: #fff;
    width: 320px;
    padding: $base-spacing;
    max-width: calc(100% - 30px);
    transform: translateX(-100%);
    transition: transform 0.2s $ease-out-quart;
    animation-name: npsanimation;
    animation-duration: 1s;

    @keyframes npsanimation {
        0% {
            transform: translateX(-100%);
        }
        25% {
            transform: translateX(calc(-100% - 21px));
        }
        50% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(calc(-100% - 21px));
        }
    }

    &.active {
        transform: translateX(0);
        box-shadow: 0 0 30px rgba(34, 34, 34, 0.25);
    }

    #calification_panel {
        a {
            display: block;
        }
    }

    .faces-survey {
        a {
            transition: transform 100ms ease-in-out;
            margin: 0 $small-spacing;
            &:hover {
                transform: translateY(-5px);
            }
        }
    }

    .nps-survey {
        a {
            flex: 1;
            height: $extra-small-element-height;
            line-height: $extra-small-element-height;
            background: #fff;
            background-color: color('100', 'gray');
            color: color('800', 'gray');
            border-radius: $base-border-radius;
            margin: 0 1px;
            font-size: $font-size-sm;
            &:hover {
                background-color: color('75', 'gray');
            }
        }
    }

    #open_panel {
        position: absolute;
        border-radius: 0 $base-border-radius $base-border-radius 0;
        background-color: color('700', 'blue');
        top: 0;
        left: 100%;
        cursor: pointer;
        height: 100%;
        &:hover {
            background-color: color('800', 'blue');
        }
        #button_panel {
            display: flex;
            align-items: center;
            height: 100%;
            i {
                width: 12px;
                &::before {
                    margin-left: -2px;
                }
            }
        }
    }

    #trustpilotNPS {
        a {
            display: block;
        }
    }

    textarea {
        min-height: 120px;
        font-size: $font-size-sm;
    }
}
