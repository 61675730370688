#subscription-upgrade-error {
    .notification__close {
        margin: 0;
        background: color('600', 'yellow') !important;
        height: 100%;
    }

    .notification__state {
        top: 50%;
        background: none;
        align-self: auto;
    }
}
