$header--menu-height: 54px;
$header--search-height: 64px;
$header-height: $header--menu-height + $header--search-height;
$menu-mobile__width: 320px;

#header {
    background: none;
    margin: 0;
    border: none;
    padding: 0;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 14;
    width: 100%;

    &--menu {
        height: $header--menu-height;
        background-color: color('900', 'blue');
        padding: 0 $base-spacing;

        nav {
            @include flex(1);
            font-size: $font-size-sm;
            padding: 0 $base-spacing;

            a {
                font-weight: $font-weight-semibold;
                color: color('200', 'gray');

                &:hover {
                    color: color('75', 'gray');
                }

                &.text__state--yellow {
                    color: color('500', 'yellow');
                }
            }

            .uicons__header-link {
                .badge {
                    color: #fff;
                }
            }
        }

        &__login {
            min-width: 50px;
            .notification-center-menu-trigger {
                position: relative;
                button {
                    &:hover,
                    &:active,
                    &:focus {
                        text-decoration: none;
                    }
                }
                i {
                    width: 54px;
                    line-height: 38px;
                    text-align: center;
                }
                .badge {
                    position: absolute;
                    top: 0;
                    color: white;
                    height: 16px;
                    min-width: 16px;
                    line-height: 16px;
                }
            }
            #notification-center-menu-side {
                position: fixed;
                bottom: 0;
                top: 54px;
                right: 0px;
                border-top: 0;
                margin: 0;
                padding: 0;
                z-index: 11;
                list-style-type: none;
                width: 100%;
                max-width: 392px;
                background: #ffffff;
                border-radius: 10px 0 0 10px;
                color: color('800', 'gray');
                overflow: hidden;
                transition: all 0.25s ease 0.3s, visibility 0.25s linear 0.25s;
                transform: translateX(100%);
                display: flex;
                flex-direction: column;
                z-index: 75;
                &.active {
                    visibility: visible;
                    transition-delay: 0.3s;
                    transform: translateX(0%);
                    opacity: 1;
                    right: 0;
                    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.4);
                }

                .notification-center-menu-side {
                    &__title-container {
                        background: color('75', 'gray');
                        padding: 16px;
                        font-weight: 600;
                        line-height: 24px;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        i {
                            cursor: pointer;
                        }
                    }
                    &__promo {
                        margin: 16px;
                        border: 1px solid color('100', 'gray');
                        border-radius: 10px;
                        .notification-image {
                            background-image: url('~Media/promo10off.png');
                            height: 114px;
                            background-size: cover;
                            background-position: right;
                            border-radius: 10px;
                            padding: 20px;
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                        }
                        a {
                            padding: 16px;
                            display: block;
                        }
                    }
                    &__empty {
                        margin: 16px;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        .empty-notification-icon-container {
                            background: rgba(43, 196, 138, 0.5);
                            padding: 15px;
                            font-size: 24px;
                            border-radius: 10px;
                            margin: 20px;
                            i::before {
                                color: white;
                            }
                        }
                    }
                }
            }
            #overlay-notifications {
                display: none;
                &.active {
                    display: block;
                    position: absolute;
                    width: 100vw;
                    height: 100vh;
                    background-color: rgba(0, 0, 0, 0.25);
                    left: 0;
                    z-index: 70;
                    top: 0;
                }
            }

            #gr_not-connected {
                .login-register-buttons-container {
                    display: none;
                }
                .login-register-user-button {
                    display: block;
                }
                @include breakpoint('xl', 'min') {
                    .login-register-buttons-container {
                        display: flex;
                    }
                    .login-register-user-button {
                        display: none;
                    }
                }
            }
        }
    }

    &--search {
        box-shadow: 0 0 0 1px color('200', 'gray');
        background-color: color('body', 'background');

        &__inner {
            padding: $small-spacing $base-spacing;

            .bj-button {
                box-shadow: inset 0 0 0 2px color('200', 'gray');
                color: color('800', 'gray');
                box-shadow: none;

                &:hover,
                &:focus {
                    box-shadow: none;
                    color: color('800', 'gray');
                }

                &#button-filters {
                    color: color('800', 'gray') !important;
                    box-shadow: none !important;

                    &:hover,
                    &:focus {
                        box-shadow: none !important;
                        color: color('800', 'gray') !important;
                    }
                }
            }
            .fake-search {
                .popover {
                    //!
                    .popover-content {
                        border: none;
                        color: color('800', 'gray');
                        box-shadow: 0px 2px 8px color('800', 'gray', 0.06), 0px 15px 30px color('800', 'gray', 0.1);
                        padding: 0 $small-spacing;
                        hr {
                            width: 100%;
                            position: absolute;
                            left: 0px;
                        }
                        .switch {
                            line-height: 44px;
                        }
                    }
                    .popover-content.stickers {
                        min-width: 185px;
                    }
                }
            }
            .header--search__filters.checkbox {
                padding: 7px;
                .icon {
                    padding: 0;
                    color: color('800', 'gray');
                }
            }
            @include breakpoint('lg') {
                padding: $small-spacing;
            }
        }

        .logo {
            display: none;
        }

        .radio-group {
            font-size: $font-size-sm;
            line-height: $line-height-160;
            margin: $small-spacing 0;

            .fake-radio:before {
                width: 18px;
                height: 18px;
            }

            input[type='radio']:checked + label.fake-radio:before {
                box-shadow: inset 0 0 0 4px color('600', 'green');
            }
        }

        .checkbox-group {
            font-size: $font-size-sm;

            .fake-checkbox:before {
                width: 18px;
                height: 18px;
            }

            input[type='checkbox']:checked + label.fake-checkbox:after {
                width: 18px;
                height: 18px;
                line-height: 18px;
            }
        }
    }

    .popover-tools {
        .popover-button {
            cursor: pointer;
            font-weight: $font-weight-semibold;
            color: color('200', 'gray');
        }

        .popover-content {
            max-width: 620px;
            min-width: 570px;
            padding: 20px 30px;
            z-index: 5;

            &:before {
                left: 1.8em;
            }

            &:after {
                left: 1.7em;
            }

            @include breakpoint('lg') {
                padding: 10px 20px 20px 36px !important;
                max-width: inherit;
                min-width: inherit;
            }

            .popover-tools-box {
                margin-right: 30px;

                &:last-child {
                    margin-right: 0;
                    max-width: 280px;
                    flex: 1;
                }

                .menu-tools-link {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 10px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    &:hover {
                        span {
                            &:first-child,
                            &:last-child {
                                color: color('900', 'gray');
                            }
                        }
                    }

                    span {
                        &:first-child {
                            color: color('800', 'gray');
                            font-weight: $font-weight-semibold;
                        }

                        &:last-child {
                            color: color('600', 'gray');
                            font-weight: $font-weight-regular;
                        }

                        @include breakpoint('lg') {
                            font-size: 16px;
                        }
                    }
                }

                .text__general--heading {
                    margin-bottom: 15px;

                    &:nth-of-type(2) {
                        margin-top: 20px;
                    }

                    @include breakpoint('lg') {
                        margin-bottom: 0;
                        font-size: 14px;

                        &:nth-of-type(2) {
                            margin-top: 10px;
                        }
                    }
                }

                .link-api {
                    @include breakpoint('lg') {
                        display: none;
                    }
                }
            }
        }
    }

    #gr_user_menu #gr_connected.active {
        .popover-user {
            .popover-container::before {
                border-top: unset;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-bottom: 4px solid white;
            }
        }
    }

    .popover {
        position: relative;
        margin-right: $small-spacing;
        &.type-selector {
            margin-right: $base-spacing;
            @include breakpoint('sm') {
                margin-right: 0;
            }
        }

        .popover-content {
            z-index: 6;
        }

        @media (max-width: 1325px) {
            &--more-btn {
                .popover-content {
                    left: auto;
                    right: 0;

                    &::before,
                    &::after {
                        left: auto;
                        right: 1.8em;
                    }
                }
            }
        }

        @include breakpoint('lg', 'min') {
            &--more-btn {
                &:hover {
                    .popover-bridge {
                        background: transparent;
                        position: absolute;
                        height: 35px;
                        width: 100%;
                        z-index: 6;
                        .popover-content {
                            display: block;
                            margin-top: -30px;
                        }
                        &:hover {
                            .popover-content {
                                display: block;
                                margin-top: -30px;
                            }
                        }
                    }
                }
            }
        }
    }

    .popover--lang {
        .popover-button {
            background: none;

            *:not(img) {
                display: none;
            }

            &:hover,
            &.active {
                background-color: color('800', 'blue');
            }

            img {
                width: 16px;
                height: auto;
            }
        }

        .popover-content {
            padding: 0;
            width: 100%;
            min-width: 110px;
            text-align: left;
            top: 100%;
            border-radius: $base-border-radius;

            &:before,
            &:after {
                display: none;
            }

            a {
                height: 36px;
                line-height: 36px;
                padding: 0 $base-spacing;
                display: block;

                &:hover {
                    background-color: color('75', 'gray');
                }

                &.active {
                    font-weight: $font-weight-bold;
                }
            }
        }
    }

    .avatar {
        position: relative;
        width: $avatar-width-xs;
        height: $avatar-width-xs;

        .premium {
            @include position(absolute, -5px null null -10px);
            display: block;
            width: 20px;
            height: 20px;
            border: 2px solid color('yellow', 'state');
            border-radius: 50%;
            background-color: color('900', 'blue', 0.3);
            font-size: 12px;
            font-weight: $font-weight-regular;
            line-height: 14px;
            text-align: center;

            &:before {
                width: 12px;
            }
        }
    }

    .bj-button--mobile {
        cursor: pointer;
        i {
            width: $header--menu-height;
            text-align: center;
        }
    }

    #main-menu__toggle {
        display: none;
        appearance: none;
        -webkit-appearance: none;
    }

    @include breakpoint('lg', 'min') {
        .scrollable--menu {
            overflow: visible;
        }
    }

    @include breakpoint('lg') {
        &--menu {
            padding: 0;

            .logo {
                @include order(1);
                width: 150px;
                margin-left: auto;
                margin-right: auto;
            }

            nav {
                @include order(0);
                @include flex(none);
                font-size: $font-size-lg;
                padding: 0;
                // z-index: 15;

                a {
                    width: 100%;
                    display: block;
                    margin: 0;
                    color: color('800', 'gray');
                    height: $base-element-height;
                    line-height: $base-element-height;
                    padding: 0 $large-spacing;

                    &:hover {
                        color: color('800', 'gray');
                    }
                }
                .stickers__header-link {
                }

                .link-pricing a {
                    white-space: nowrap;
                }

                .bj-button {
                    height: $large-element-height;
                    line-height: $base-spacing;
                }

                .bj-button--green {
                    color: #fff;

                    &:hover {
                        color: #fff;
                    }
                }

                .bj-button--gray {
                    color: color('900', 'gray');
                    box-shadow: inset 0 0 0 2px color('100', 'gray');
                }
            }

            &__login {
                @include order(2);
                margin-left: 0 !important;
            }
        }

        input[type='checkbox']:checked {
            + .main-menu {
                display: block;
            }
        }

        .bg-toggle-menu {
            display: block;
            position: fixed;
            top: 0;
            left: $menu-mobile__width;
            width: calc(100% - #{$menu-mobile__width});
            height: 100%;
            background-color: color('800', 'blue', 0.9);
            z-index: 5;
        }

        .main-menu {
            position: fixed;
            top: 0;
            left: 0;
            width: $menu-mobile__width;
            height: 100%;
            background-color: color('body', 'background');
            z-index: 16;
            display: none;

            .popover {
                width: 100%;

                * {
                    width: 100%;
                }

                &-button {
                    width: 100%;
                    display: block;
                    margin: 0;
                    color: color('800', 'gray');
                    height: $base-element-height;
                    line-height: $base-element-height;
                    padding: 0 $large-spacing;
                    text-align: left;

                    i {
                        position: absolute;
                        top: 0;
                        height: 100%;
                        line-height: $base-element-height;
                        text-align: right;
                        color: color('800', 'gray');
                        right: $large-spacing;
                    }
                }

                &-content {
                    position: relative;
                    width: 100%;
                    animation: none;
                    top: 0;
                    border-radius: 0;
                    background-color: color('50', 'gray');
                    border: none;
                    box-shadow: none;
                    padding: 20px 30px;

                    &:before,
                    &::after {
                        display: none;
                    }

                    li {
                        margin: 0 0 15px;
                    }

                    p {
                        height: $base-element-height;
                        line-height: $base-element-height;
                        margin: 0;
                    }

                    a {
                        height: auto;
                        line-height: normal;
                        padding: 0;
                        // font-size: 14px;
                    }

                    .row {
                        display: block;
                    }

                    .img-holder {
                        display: none;
                    }
                }

                &--lang {
                    width: 100%;

                    .popover-container {
                        width: 100%;
                    }

                    .popover-button {
                        width: 100%;
                        font-size: $font-size-lg;
                        margin-top: $small-spacing;

                        *:not(img) {
                            display: block;
                        }

                        &:hover,
                        &.active {
                            background: none;
                        }
                    }

                    .popover-content {
                        a {
                            padding-left: 26px;
                        }
                    }

                    img {
                        box-shadow: 0 0 0 1px color('200', 'gray');
                    }
                }
            }
        }

        .menu-holder {
            margin-bottom: $extra-large-spacing * 2;

            li {
                width: 100%;
            }

            .menu--mobile__lv2 {
                @include order(1);
            }

            .menu--mobile__lv3 {
                @include order(2);
            }
        }

        .language-selector {
            margin: $small-spacing 0 0;

            &:before {
                content: '';
                border-top: 1px solid color('100', 'gray');
                display: block;
                width: calc(100% - #{$large-spacing} * 2);
                left: $large-spacing;
                position: relative;
            }
        }

        &--search {
            .bj-button {
                .button--text {
                    display: none;
                }

                .badge {
                    position: absolute;
                    top: -$extra-small-spacing;
                    right: -$extra-small-spacing;
                    margin: 0 !important;
                }
            }

            .popover.popover-filters {
                margin-right: 0;

                .popover-button {
                    padding-left: 0;

                    .badge {
                        right: $extra-small-spacing;
                    }

                    &:focus,
                    &.active {
                        background: none;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}

#collections-toggle {
    padding: 0 $small-spacing;
    margin: 0 0 0 $small-spacing;
    transition: none;

    .button--text {
        margin: 0 $extra-small-spacing 0 0;
    }

    .badge {
        background-color: color('200', 'gray');
        color: color('900', 'gray');
        margin: 0 $extra-small-spacing 0 0;
    }

    &.limit-reached {
        .badge {
            color: #fff;
            background-color: color('red', 'state');

            &.animate {
                @include animation(shake 1s both);
            }
        }
    }

    @include breakpoint('lg') {
        padding: 0 0 0 $small-spacing;
        margin-left: 0;
    }
}

.collections-visible {
    #collections-toggle {
        display: none;
    }
}
