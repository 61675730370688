.modal-premium {
    .modal-inner {
        max-width: 640px;
    } 

    .flaticon-premium:before {
        width: 18px;
        height: 18px;
    } 

    .btn {
        height: 64px;
        line-height: 64px;
        padding: 0 24px;
    }
}