.hero--section {
    position: relative;
    overflow: hidden;
    background-color: color('50', 'gray');
    padding: $base-spacing * 2 0;
    margin-bottom: 45px;
    min-height: 300px;
    .container {
        .content {
            h1 {
                color: color('900', 'blue');
                margin: 0 0 $small-spacing;
            }
            p {
                color: color('700', 'gray');
            }
        }
    }
    &__text {
        max-width: 580px;

        .hero--section__buttons-section a {
            padding: 0 $base-spacing + $extra-small-spacing;
            display: block !important;
            span {
                float: none;
            }
        }
    }
    &__image--icons,
    &__image--stickers {
        position: unset;
        img {
            position: absolute;
            bottom: 0px;
            left: 55%;
        }
    }
    &__image--icons {
        img {
            top: -40%;
            filter: drop-shadow(12px 15px 40px rgba(207, 217, 224, 0.5));
        }
    }
    &__image--stickers {
        img {
            bottom: -30px;
            max-width: unset;
            left: 50%;
        }
    }
    .shadow {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40px;
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 0.01%,
            rgba(0, 0, 0, 0.03) 100%
        );
    }

    @include breakpoint('xl') {
        .container {
            .content {
                padding: 0;
            }
        }
    }

    @include breakpoint('lg') {
        padding: $base-spacing * 2 0;
    }
    @include breakpoint('md') {
        a {
            text-transform: capitalize;
        }
        &__image--icons {
            img {
                top: -13%;
            }
        }
    }
    @include breakpoint('sm') {
        margin-bottom: $large-spacing;
        &__text {
            text-align: center;
            .hero--section__buttons-section {
                justify-content: center;
            }
        }
        &__image--icons,
        &__image--stickers {
            display: none;
        }
    }
}
