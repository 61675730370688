aside.filters-uicons {
    ::-webkit-scrollbar {
        width: 3px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: color('400', 'gray');
        border-radius: 20px;
        border: 3px solid transparent;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
    }
    margin-right: 40px;
    border-right: 1px solid color('100', 'gray');
    @include breakpoint('lg') {
        margin-right: 0;
        border-right: none;
    }

    .uicons-filter {
        position: sticky;
        top: 118px;
        .uicons__selector {
            max-height: calc(100vh - 180px);
            overflow: scroll;

            &--wrapper {
                margin-bottom: $large-spacing;
                width: 300px;
                .close {
                    display: flex;
                    align-items: center;
                    color: color('800', 'gray');
                    border-bottom: 1px solid color('100', 'gray');
                    margin-right: 20px;
                    margin-bottom: 15px;
                    svg {
                        margin-right: 10px;
                    }

                    .icon {
                        color: color('800', 'gray');
                        margin-left: auto;
                        padding: 5px 0;
                    }
                }
            }

            &__group-search {
                display: none;
                box-shadow: inset 0px 0px 0px 1px color('100', 'gray');
                border-radius: 3px 3px 3px 3px;
                position: relative;
                flex: 1;

                .icon--search {
                    width: $base-element-height;
                    height: $base-element-height;
                    line-height: $base-element-height;
                    top: 0;
                    left: 0;
                    color: color('800', 'gray');
                }

                input {
                    margin: 0;
                    padding: 0 0 0 $small-spacing;
                    border: none;
                    background: none;
                    box-sizing: border-box;
                    flex: 1;

                    &:placeholder-shown {
                        + button {
                            visibility: hidden;
                        }
                    }
                }

                &__empty {
                    padding: $base-spacing;

                    i {
                        font-size: 38px;
                        width: 38px;
                        height: 38px;
                        line-height: 38px;
                    }
                }
            }

            &__group-search,
            &__group-weight,
            &__group-corner,
            &__group-brands {
                margin-bottom: 30px;

                .filters-body__link {
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;

                    svg {
                        margin-right: 10px;
                    }
                    p {
                        margin: 0;
                        + span {
                            color: color('500', 'green');
                        }
                    }
                    i {
                        margin-left: auto;
                    }
                }

                ul {
                    display: flex;
                    gap: 10px;
                    flex-wrap: wrap;
                    li {
                        display: inline-block;
                        color: color('800', 'gray');
                        fill: color('800', 'gray');

                        input {
                            display: none;
                        }
                        label {
                            border: 1px solid color('100', 'gray');
                            box-shadow: none;
                            border-radius: 8px;
                            color: color('800', 'gray');
                            font-size: 14px;
                            height: 32px;
                            padding: 0 15px;
                            flex-wrap: nowrap;
                            margin-left: 0;
                            &:hover {
                                border: 1px solid color('200', 'gray');
                                background-color: unset;
                            }
                            i {
                                svg {
                                    width: 100%;
                                    height: 100%;
                                    fill: color('800', 'gray');
                                }
                            }
                        }
                        input[type='checkbox']:checked ~ label {
                            border: 1px solid color('500', 'green');
                            background-color: inherit;
                            color: color('800', 'gray');

                            box-shadow: none;
                            z-index: 2;
                            position: relative;
                            &:hover {
                                i {
                                    svg {
                                        fill: color('800', 'gray');
                                    }
                                }
                            }
                            i {
                                svg {
                                    fill: color('800', 'gray');
                                }
                            }
                        }
                    }
                }
                .search-view-filters-aside__group {
                    display: flex;
                    align-items: flex-start;
                }
            }
            @include breakpoint('lg') {
                max-height: calc(100vh - 72px);
            }
        }

        @include breakpoint('lg') {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 30;
            background-color: white;
            width: 280px;
            animation: popover 0.2s ease-in-out;
            padding: 10px 0 0 20px;
            height: 100vh;
            // max-height: calc(100vh - 72px);
        }
        @include breakpoint('xs') {
            width: 100%;
        }

        .filterLabels {
            text-align: left;
            display: block;
            padding-right: 20px;

            .filterLabels__header {
                display: flex;
                justify-content: space-between;
                .clear-all {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
            .filterLabels__body {
                display: flex;
                gap: 10px;
                flex-wrap: wrap;
                margin-bottom: 10px;

                .filterLabel-tag {
                    padding: 0 $small-spacing;
                    background-color: color('500', 'green');
                    border-radius: $base-border-radius;
                    color: white;
                    cursor: pointer;
                    margin: 0;
                    display: flex;
                    align-items: center;
                    i {
                        display: inline-block;
                        width: unset;
                        height: unset;
                        line-height: 17px;
                        &::before {
                            font-weight: $font-weight-bold;
                            vertical-align: text-bottom;
                            margin-left: $extra-small-spacing;
                            font-size: 12px;
                            color: white;
                        }
                    }
                    &:hover {
                        i {
                            &::before {
                                color: white;
                            }
                        }
                    }
                }
            }
            .uicons__search {
                padding-right: 20px;
            }
        }
    }

    .collapsed-filters {
        display: none;
        width: 80px;
        position: sticky;
        top: 118px;
        height: fit-content;
        justify-content: center;
        flex-direction: column;
        &__close {
            display: flex;
            flex-direction: column;
            gap: 20px;
            > i {
                color: color('800', 'gray');
            }
        }
        &__item {
            width: 40px;
            height: 40px;
            display: grid;
            justify-items: center;
            align-items: center;
            border-radius: 10px;
            border: 1px solid color('200', 'gray');
            &.editable_stroke {
                svg {
                    fill: color('100', 'gray');
                }
            }
            svg,
            i {
                fill: color('900', 'gray');
                color: color('900', 'gray');
            }
            &.active {
                border: 1px solid color('500', 'green');
            }
        }
    }

    @include breakpoint('lg') {
        input#search__filters:checked ~ .uicons-filter {
            display: block;
            position: fixed;
            top: 0px;
            background-color: #fff;
            z-index: 30;
            width: 280px;
            animation: popover 0.2s ease-in-out;
            padding: 10px 0 10px 20px;
            height: 100vh;
            .close {
                .icon {
                    color: color('800', 'gray');
                }
            }
            .headline {
                line-height: 1.9;
                color: color('800', 'gray');
                margin-bottom: 12px;
            }
            hr {
                width: calc(100% + 40px);
                margin-left: 0;
            }
            .uicons__selector {
                margin-right: 0;
            }
        }
    }

    input[type='checkbox']#search__filters ~ .search__filters--overlay {
        display: none;
    }
    input[type='checkbox']#search__filters:checked ~ .search__filters--overlay {
        display: block;
        width: 100vw;
        height: 100vh;
        background: color('800', 'blue', 0.9);
        position: fixed;
        top: 0px;
        z-index: 25;
        animation: popover 0.2s ease-in-out;
        @include breakpoint('lg', 'min') {
            display: none;
        }
    }

    input[type='checkbox']#search__filters:not(:checked) {
        ~ .uicons-filter {
            display: none;
        }
        ~ .collapsed-filters {
            display: flex;
        }
    }
    input[type='checkbox']#search__filters:checked {
        ~ .uicons-filter {
            display: block;
        }
        ~ .collapsed-filters {
            display: none;
        }
    }

    @include breakpoint('lg') {
        input[type='checkbox']#search__filters:checked {
            ~ .uicons-filter {
                display: block;
                .uicons__selector--wrapper {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                }
            }
        }

        input[type='checkbox']#search__filters:checked.show-filters-in-mobile {
            ~ .uicons-filter {
                display: block;
            }
            ~ .search__filters--overlay {
                display: block;
            }
        }
    }

    @include breakpoint('xs') {
        input#search__filters:checked ~ .uicons-filter {
            width: 100%;
        }
    }
}
