.notify-wrapper {
    &--bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 100;

        width: 100%;
    }

    .notify--banner {
        padding: $small-spacing $base-spacing;

        border-radius: 0 !important;

        //background-color: color('blue', 'state');
        background: none;

        color: color('text-inverted', 'general');

        a {
            color: color('text-inverted', 'general') !important;

            &:hover {
                text-decoration: underline;
            }
        }
        
        .notification__close {
            border-radius: 50%;
            top: 5px;
            right: 10px;
            position: absolute;
        }
    }
    

}
