.modal-palette-not-premium-user {
    .modal__container {
        .content {
            border-radius: 16px;
            display: flex;
            padding: 0;
            .image-box {
                width: 50%;
                background-color: yellow;
                background: url('~Media/modals/color-palette/modal-palette-upgrade.png');
                background-size: cover;
            }
            .text-box {
                width: 50%;
                padding: 60px 40px 40px;
                .feature {
                    display: flex;
                    p {
                        color: color('900', 'gray');
                    }
                    i {
                        color: color('500', 'green');
                    }
                }
            }
            @include breakpoint('md') {
                .image-box {
                    display: none;
                }
                .text-box {
                    width: 100%;
                }
            }
        }
    }
}
