.tags-columns {
    color: color('800', 'gray');
    display: flex;
    ul {
        width: 20%;
        z-index: 5;
    }
    ul:last-child {
        display: none;
    }

    a {
        color: color('800', 'gray');
    }
    a:hover {
        color: color('800', 'green');
    }

    @include breakpoint('md') {
        flex-wrap: wrap;
        ul {
            width: 50%;
        }
        ul:last-child {
            display: block;
        }
    }
}
