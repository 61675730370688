.new-headers {
    h1 {
        margin: 0;
        margin-top: 0.67em;
        margin-bottom: 5px;
    }

    @include breakpoint('sm') {
        h1 {
            font-size: 26px;
        }

        p {
            font-size: 14px;
        }
    }
}
