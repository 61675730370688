.countdown {
    ul {
        @extend .row;
        @include justify-content(center);
    }

    li {
        .countdown__value:after {
            content: ':';
            margin: 0 $extra-small-spacing;
        }

        &:last-child {
            .countdown__value:after {
                display: none;
            }
        }
    }

    &__value {
        display: block;
        @include font-size($h6-font-size);
    }
}