.modal-license {
    .list--check {
        li {
            // padding-left: $large-spacing;
            position: relative;
            margin: 0 0 $small-spacing;
            display: flex;
            i {
                line-height: 1.2em;
            }
        }
    }

    &__footer {
        background-color: color('50', 'gray');
        padding: $base-spacing $extra-large-spacing;
    }
}
