.home-icons {
    color: color('900', 'blue');

    .popular-styles {
        .row {
            width: calc(100% + 30px);
            margin-left: -15px;
            justify-content: center;
            > div {
                padding: 0 $small-spacing + $extra-small-spacing;
                margin-bottom: $large-spacing;
            }
        }
        &__item {
            position: relative;
            background: linear-gradient(115.78deg, color('600', 'blue') -7.64%, color('200', 'blue') 104.5%);
            border-radius: 16px;
            overflow: hidden;
            height: 260px;
            box-shadow: 0px 2px 8px rgba(55, 73, 87, 0.1), 0px 15px 30px rgba(55, 73, 87, 0.15);
            transition: all 0.2s ease-in-out;
            p {
                position: absolute;
                padding: ($base-spacing + $extra-small-spacing) $large-spacing $base-spacing;
                color: #fff;
            }
            img {
                max-width: unset;
                position: relative;
                top: 80px;
                left: 40px;
                transform: rotate(-8deg);
                transition: all 0.2s ease-in-out;
            }
        }
        &__item:hover {
            box-shadow: 0px 2px 12px rgba(55, 73, 87, 0.1), 0px 25px 50px rgba(55, 73, 87, 0.15);
            transform: translateY(-2px);
            img {
                max-width: unset;
                position: relative;
                left: 40px;
                transform: rotate(0deg);
            }
        }
        .container .row {
            > div:nth-child(1) .popular-styles__item {
                background: linear-gradient(115.78deg, color('600', 'blue') -7.64%, color('200', 'blue') 104.5%);
            }
            > div:nth-child(2) .popular-styles__item {
                background: linear-gradient(115.74deg, color('600', 'yellow') -6.1%, color('200', 'yellow') 101.85%);
            }
            > div:nth-child(3) .popular-styles__item {
                background: linear-gradient(115.74deg, color('500', 'purple') -6.1%, color('300', 'blue') 101.85%);
            }
            > div:nth-child(4) .popular-styles__item {
                background: linear-gradient(115.74deg, color('600', 'red') -6.1%, color('200', 'red') 101.85%);
            }
            > div:nth-child(5) .popular-styles__item {
                background: linear-gradient(115.74deg, color('600', 'green') -6.1%, #abffad 101.85%);
            }
            > div:nth-child(6) .popular-styles__item {
                background: linear-gradient(115.74deg, color('700', 'blue') -6.1%, color('400', 'purple') 100.57%);
            }
        }
    }
    .emojis__banner {
        overflow: hidden;
        position: relative;
        margin: $extra-large-spacing 0 100px;
        border-radius: 10px;
        .row {
            padding: $extra-large-spacing ($large-spacing * 2);
            background-color: color('50', 'gray');
        }
        &__text {
            width: 45%;
            h5 {
                color: color('900', 'blue');
            }
            p {
                color: color('700', 'gray');
            }
        }
        &__image {
            width: 55%;
            position: unset;
            img {
                display: block;
                height: auto;
                position: absolute;
                bottom: -165px;
                left: 50%;
                max-width: unset;
            }
            .purple {
                top: -165px;
                left: 72%;
            }
            .green {
                left: 45%;
            }
            .yellow {
                left: 67%;
            }
        }
    }
    .section-logos {
        text-align: center;
        margin-bottom: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h5 {
            margin-top: 0;
            color: color('800', 'blue');
        }
        > p {
            margin: 0 auto $large-spacing;
            color: color('700', 'gray');
        }
        .row {
            width: calc(100% + 20px);
            margin-left: -10px;
            margin-bottom: $large-spacing;
            justify-content: center;
            > div {
                padding: $small-spacing;
                width: 12.5%;
                a {
                    background-color: color('50', 'gray');
                    transition: background-color 0.2s ease;
                    display: block;
                    width: 152px;
                    height: 144px;
                    border-radius: 8px;
                    position: relative;
                    img {
                        position: absolute;
                        left: 50%;
                        top: 30%;
                        transform: translateX(-50%) translateY(-20%);
                        pointer-events: none;
                    }
                    p {
                        position: absolute;
                        bottom: $large-spacing;
                        width: 100%;
                        text-align: center;
                        pointer-events: none;
                    }
                }
            }
            a:hover {
                background-color: color('75', 'gray');
            }
        }
    }
    .top-icon-search {
        ul {
            width: 20%;
            margin-bottom: 0;
            li {
                a {
                    color: color('800', 'gray');
                }
                a:hover {
                    color: color('800', 'green');
                }
            }
        }
        ul:last-child {
            display: none;
        }
    }

    @include breakpoint('xl') {
        .popular-styles {
            &__item {
                img {
                    left: 50%;
                    transform: translateX(-50%) rotate(-8deg);
                }
            }
            &__item:hover {
                img {
                    left: 50%;
                    transform: translateX(-50%) rotate(0deg);
                }
            }
        }
        .section-logos {
            .row {
                max-width: 688px;
                > div {
                    width: unset;
                }
            }
        }
    }
    @include breakpoint('lg') {
        .popular-styles {
            &__item {
                img {
                    left: 40px;
                    transform: rotate(-8deg);
                }
            }
            &__item:hover {
                img {
                    left: 40px;
                    transform: rotate(0deg);
                }
            }
        }
        .emojis__banner {
            .row {
                padding: $extra-large-spacing ($base-spacing * 2);
            }
            &__text {
                width: 50%;
            }
            &__image {
                width: 50%;

                img {
                    bottom: -135px;
                    left: 55%;
                }
            }
        }
    }

    @include breakpoint('md') {
        .emojis__banner {
            margin: $large-spacing 0 80px;
            &__image {
                img {
                    bottom: -125px;
                    left: 55%;
                }
            }
        }
        .section-logos {
            .row {
                max-width: 516px;
                justify-content: center;
            }
        }
        .top-icon-search {
            ul {
                width: 50%;
            }

            ul:last-child {
                display: block;
            }
        }
    }

    @include breakpoint('sm') {
        .popular-styles,
        .top-icon-search {
            padding-top: $small-spacing;
            > .container {
                > p {
                    margin-bottom: $base-spacing;
                }
            }
        }
        .emojis__banner {
            margin: $large-spacing 0 ($base-spacing * 2);
            .row {
                padding: $large-spacing;
            }
            &__text {
                width: 100%;
                text-align: center;
                p {
                    font-size: 14px;
                }
            }
            &__image {
                width: 100%;
                height: 200px;
                img {
                    bottom: -405px;
                    left: -50%;
                }
                .purple {
                    top: 95px;
                    left: 65%;
                }
                .green {
                    left: -25%;
                    bottom: -190px;
                }
                .yellow {
                    left: 15%;
                    bottom: -245px;
                }
            }
        }
        .section-logos {
            margin-bottom: $base-spacing * 2;
            > p {
                font-size: 14px;
            }
            .row {
                max-width: 325px;
                > div {
                    padding: 5px;
                    a {
                        display: block;
                        width: 87px;
                        height: 87px;
                        img {
                            width: 24px;
                            height: 24px;
                            top: 25%;
                        }
                        p {
                            font-size: 13px;
                            bottom: $base-spacing;
                        }
                    }
                }
            }
        }
    }

    @include breakpoint('xs') {
        .popular-styles {
            &__item {
                height: 180px;
                p {
                    font-size: 14px;
                }
                img {
                    width: 165px;
                    height: auto;
                }
            }
        }
        .emojis__banner {
            &__image {
                img {
                    bottom: -355px;
                    left: -62%;
                }
            }
        }
    }
}

.collections-visible {
    .section-logos {
        .row {
            max-width: 688px;

            > div {
                width: unset;
            }
        }
    }

    @include breakpoint('xl') {
        .home-icons {
            .emojis__banner {
                &__image {
                    img {
                        bottom: -100px;
                    }
                }
            }
        }
        .section-logos {
            .row {
                max-width: 516px;
                justify-content: center;
                .hide-mobile {
                    display: none;
                }
            }
        }
    }
}
