.modal-download-detail {
    position: absolute;
    left: 0;
    z-index: 5;
    width: 100%;
    transition: bottom 1s;
    padding: $small-spacing 0 $extra-small-spacing;
    opacity: 0;
    transition: opacity 0.2s linear;
    &__content {
        background-color: white;
        border-radius: $base-border-radius;
        box-shadow: 0px 2px 8px rgba(55, 73, 87, 0.1);
        position: relative;
        > a,
        #download-free {
            width: 100%;
            line-height: 1.2;
        }
        > p {
            display: flex;
            align-items: center;
            color: color('700', 'gray');
            &.font-md {
                color: color('800', 'gray');
            }
            .modal__trigger {
                color: color('800', 'gray');
            }
        }
        hr {
            width: 100%;
        }
        .button--close {
            position: absolute;
            top: 0;
            right: 0;
            width: unset;
        }
        .button--close:hover {
            color: unset;
            background-color: unset;
        }
    }

    &.transition--mobile {
        @include breakpoint('lg') {
            bottom: 0;
            left: 0;
        }
    }

    @include breakpoint('lg') {
        padding: 0;
        position: fixed;
        bottom: -500px;
        z-index: 20;
        transition: bottom 1s ease-in-out;
        &__content {
            box-shadow: none;
            border-top: 1px solid color('100', 'gray');
        }
    }
}
