.tabs-sort-by-component {
    margin-top: 13px;
    position: relative;
    &::before {
        border-bottom: 1px solid color('100', 'gray');
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        @include breakpoint('lg') {
            bottom: unset;
            width: 100%;
            top: 46px;
        }
    }
    &__tabs {
        overflow: visible;
        display: flex;
        white-space: nowrap;
        @include breakpoint('lg') {
            flex-wrap: nowrap;
            overflow: auto;
            margin-bottom: 80px;
            .group-by {
                width: 100%;
                justify-content: flex-end;
                display: flex;
                position: absolute;
                bottom: -60px;
            }
        }
    }
    &__tabs.center {
        justify-content: center;
    }
    &__links {
        padding: 0 10px 10px 10px;
        color: color('600', 'gray');
        background-color: transparent;
        border-radius: 0;
        border-bottom: 1px solid transparent;
        line-height: 2.2;
        z-index: 5;
        &:first-child {
            padding-left: 0;
        }
        &:hover {
            background-color: transparent;
            color: color('800', 'gray');
        }
        &.active {
            background-color: transparent;
            border-bottom: 1px solid color('800', 'gray');
            color: color('800', 'gray');
        }
        &:focus {
            color: color('600', 'gray');
        }
    }

    .switch {
        flex-wrap: nowrap;
        margin-top: -10px;
        background-color: transparent;
    }

    .sort-by {
        margin-left: auto;
    }

    .group-by {
        margin-left: auto;
        flex-wrap: nowrap;
        display: flex;
        > span {
            white-space: nowrap;
            line-height: 34px;
            color: color('800', 'gray');
        }
        .popover-container {
            .popover-button {
                background-color: color('500', 'green');
                color: white;
                padding: 10px 20px;
                flex-wrap: nowrap;
                span {
                    display: flex;
                    align-items: center;
                }
                i {
                    color: white;
                }
                &.active {
                    &::after {
                        display: none;
                    }
                }
                &.active ~ .tooltip__content {
                    display: none;
                }
            }
            .tooltip__trigger::after {
                left: unset;
                right: 15px;
            }
            .tooltip__content {
                width: 295px;
                left: unset;
                right: -146px;

                .content {
                    background-color: color('900', 'blue');
                    padding: 25px 30px;

                    p {
                        white-space: normal;
                    }
                }
            }
        }
        .popover-content {
            padding: 0;
            right: 0;
            min-width: 100%;
            width: unset;

            li {
                label {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    i {
                        display: inline-block;
                    }
                    &.active {
                        color: color('500', 'green');
                        i:before {
                            color: color('500', 'green');
                        }
                    }
                }
                &:hover {
                    background-color: color('75', 'gray');
                }
            }
        }
    }
}
