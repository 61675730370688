.landing--affiliate {
    .button--green {
        box-shadow: inset 0px 0px 0px 100px color('600', 'green');

        &:hover {
            box-shadow: inset 0px 0px 0px 100px color('700', 'green');
            color: #fff;
        }
    }

    section {
        position: relative;
    }

    .hero {
        background: url(~Media/landing/affiliate/bg-header.svg) no-repeat center;
        background-size: cover;
        text-align: left;
        padding: 80px;
        margin: 0;

        p {
            max-width: 510px;
            color: color('100', 'gray');
        }
    }

    .logo {
        display: inline-block;
        margin-bottom: 40px;

        @include breakpoint('xs') {
            width: auto;
        }
    }

    .benefits {
        padding: 80px 0;
        margin: 0;

        .container {
            max-width: 1115px;
        }

        &__holder {
            max-width: 570px;
            margin: auto;
        }
    }

    .why-us {
        background-color: color('50', 'gray');
        padding: 40px;
        margin: 0;
    }

    .join {
        background: url(~Media/landing/affiliate/bg-join.svg) no-repeat center;
        background-size: cover;
        padding: 70px 0;
        margin: 0;
    }

    #footer {
        background: color('900', 'blue');
        min-height: 0;
        margin: 0;
        border: none;

        div {
            position: relative;
        }

        li {
            display: inline-block;
            a {
                color: color('link-inverted', 'general');
                font-weight: $font-weight-regular;
            }
            span {
                display: inline-block;
                margin: 0 10px;
            }
        }
    }

    @include breakpoint('lg') {
        text-align: center;

        .hero {
            text-align: center;

            p {
                margin-left: auto;
                margin-right: auto;
            }
        }

        .text__holder {
            order: 1;
        }

        .img__holder {
            order: 0;
        }

        .benefits {
            padding: 60px 0;
        }

        .why-us {
            .container {
                max-width: 600px;
            }
        }
    }

    @include breakpoint('sm') {
        .hero {
            padding: 40px;

            p {
                display: block;
            }
        }

        .benefits {
            padding: 40px 0;

            img {
                max-width: 280px;
            }
        }

        .benefits__holder {
            max-width: 280px;
        }

        .why-us {
            .container {
                text-align: center;
            }
        }

        .join {
            padding: 40px 0;
        }
    }
}
