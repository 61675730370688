.tooltip--bookmarks.new {
    .new-function-popup {
        display: block;
    }
    .tooltip__content,
    .tooltip__trigger:after {
        display: none !important;
    }
}

.new-function-popup {
    width: 360px;
    border-radius: $base-border-radius;
    background: color('body', 'background') url('~Media/bookmarks/bookmark-new.svg') no-repeat;
    background-size: auto 100%;
    box-shadow: 0 0 10px color('900', 'gray', 0.2);
    padding: 20px 20px 20px 140px;
    position: absolute;
    top: 100%;
    right: -20px;
    z-index: 1;
    margin-top: 10px;

    display: none;

    &:before {
        content: '';
        position: absolute;
        bottom: 100%;
        right: 16px;
        height: 0;
        width: 0;
        border-bottom: 6px solid #fff;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        transform: translate(-50%);
        margin: 6px 0 0 0;
    }

    .button {
        box-shadow: inset 0px 0px 0px 100px color('800', 'blue') !important;
        color: color('text-inverted', 'general');

        &:hover {
            box-shadow: inset 0px 0px 0px 100px color('800', 'blue') !important;
        }
    }

    a {
        color: color('text', 'general');
    }
}

@media (max-width: 890px) {
    .new-function-popup {
        left: -20px;

        &:before {
            left: 28px;
            border-bottom-color: #40c585;
        }
    }
}

@media (max-width: 1210px) {
    .collections-visible {
        .new-function-popup {
            left: -20px;

            &:before {
                left: 28px;
                border-bottom-color: #40c585;
            }
        }
    }
}

// Page
.bookmarks {
    background-color: color('50', 'gray');

    .following__list {
        min-height: 380px;
        padding-top: $base-spacing;
    }

    .list-options {
        .popover-filters,
        .label-switch {
            display: none;
        }
    }
}

.list-options .following__links {
    height: 50px;
    line-height: 50px !important;
    float: none;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);

    a {
        padding: 0 10px;
        color: color('text', 'general');

        &.active {
            color: color('800', 'gray');
        }
    }
}
