.author-style {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: flex-end;
    padding: 45px 0 20px;
    border-bottom: 1px solid color('200', 'gray');

    .author-style__info {
        .go-back {
            display: flex;
            color: color('400', 'gray');
            i {
                color: color('400', 'gray');
            }
            &:hover {
                color: color('500', 'gray');
                i {
                    color: color('500', 'gray');
                }
            }
        }
        .author-style__info__headline {
            display: flex;
            flex-wrap: wrap;
            align-items: baseline;
        }
    }
    .author-style__search {
        width: 35%;
        .search-holder .fake-search {
            border-radius: 8px;
        }
        i {
            color: color('800', 'gray') !important;
        }
    }

    @include breakpoint('md') {
        padding: 20px 0;
        flex-wrap: wrap;
        .author-style__search {
            width: 100%;
        }
    }
}

.container.sticky {
    position: sticky;
    top: 119px;
    background-color: white;
    z-index: 10;
    margin-bottom: 20px;
    & + .search-data {
        display: none;
    }
}
