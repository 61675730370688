.downloads-limit {
    padding: 100px $base-spacing;
    max-width: 950px;
    margin: auto;

    .list--check{
        text-align: left;
        li {
            display: flex;
            margin-bottom: 10px;
            i{
                line-height: 1.2em;
            }
        }

    }

    &.limit--registered {
        max-width: 1100px;

        .limit-icon {
            position: relative;
            img {
                max-width: 427px;
                display: block;
                margin: auto;
            }
        }

        .limit-collection {
            img {
                max-width: 404px;
                display: block;
                margin: auto;
            }
        }

        .icon-holder {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 220px;
            height: 220px;
            background-color: #fff;
            box-shadow: 10px 10px 20px 0 rgba(8,25,43,0.10);
            border-radius: 10px;
            padding: 46px;

            img {
                width: 100%;
                height: auto;
            }

            &:before {
                content: "";
                display: inline-block;
                width: 20px;
                height: 20px;
                background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTRweCIgaGVpZ2h0PSIxNHB4IiB2aWV3Qm94PSIwIDAgMTQgMTQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogc2tldGNodG9vbCA0Ni4yICg0NDQ5NikgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+NTk1REVERUQtNEZBOC00Mjk3LUExMEItMjRBNTJCNUZBRTU0PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBza2V0Y2h0b29sLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJzdXBlcmFkby1saW1pdGUtZGUtZGVzY2FyZ2FzLXVzdWFyaW8iIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjcuMDAwMDAwLCAtNTkzLjAwMDAwMCkiPgogICAgICAgICAgICA8ZyBpZD0iR3JvdXAtNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzQ4LjAwMDAwMCwgNTE0LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9ImNvcm9uYSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzc5LjAwMDAwMCwgNzkuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZS0yOSIgZmlsbD0iI0ZBOEMwMCIgeD0iMS41MjU2NDEwMyIgeT0iMTAuNzY5MjMwOCIgd2lkdGg9IjExLjIxNzk0ODciIGhlaWdodD0iMi42MDI1NjQxIj48L3JlY3Q+CiAgICAgICAgICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlBhdGgtMyIgZmlsbD0iI0ZGOEMwMCIgcG9pbnRzPSIxLjM3NjU2OTA2IDguNzA1MTI4MjEgNy4wMTU5NDM1OSAwLjA2MTA0NDA0NTggMTIuNzU3MzYyNCA4LjcwNTEyODIxIj48L3BvbHlnb24+CiAgICAgICAgICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlBhdGgtNCIgZmlsbD0iI0VEQTUwMCIgcG9pbnRzPSIxMi42NzQzOTggMTAuMTAzODQ0IDEzLjk4Njg4MDMgMi40NzAzMjQxNCAyLjgyMjQ4NTA4IDkuODAyMTcxMTEiPjwvcG9seWdvbj4KICAgICAgICAgICAgICAgICAgICA8cG9seWdvbiBpZD0iUGF0aC01IiBmaWxsPSIjRkVDNDAwIiBwb2ludHM9IjEuMzM0NjM2NDMgMTAuMDU3ODE3IDAuMDc1NDk1NDUxNSAyLjQzMjY4MTkzIDEwLjUwMzI4MDcgMTAuMDU3ODE3Ij48L3BvbHlnb24+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==) 50% no-repeat;
                background-color: #fff;
                background-size: 20px;
                padding: 27px;
                border-radius: 8px;
                position: absolute;
                left: -27px;
                top: -27px;
                box-shadow: 5px 5px 10px 0 rgba(8,25,43,0.10);
            }
        }

        @include breakpoint('lg')  {
            .limit-icon {
                img {
                    max-width: 342px;
                }
            }

            .limit-collection {
                img {
                    max-width: 341px;
                }
            }
            
            .icon-holder {
                width: 115px;
                height: 115px;
                padding: $base-spacing;

                &:before {
                    background-size: 14px;
                    padding: 19px;
                    border-radius: 5px;
                    left: -19px;
                    top: -19px;
                }
            }

            .limit__text {
                text-align: center;
                margin-top: $base-spacing;
            }

            ul {
                max-width: 400px;
                margin: auto;
            }

            .button-holder {
                position: fixed;
                bottom: 0;
                left: 0;
                width: 100%;
                padding: $base-spacing;
                box-shadow: 0 0 9px 0 rgba(8,25,43,0.10);
                background-color: #fff;
                z-index: 1;

                a {
                    width: 100%;
                    margin: 0;
                }
            }
        }

        @include breakpoint('md')  {
            padding: $base-spacing;
    
            .limit__image {
                display: none;
            }
        }
    }

    &.limit--guest {
        max-width: 1100px;

        .limit-icon {
            img {
                max-width: 435px;
                display: block;
                margin: auto;
            }
        }

        .limit-collection {
            img {
                max-width: 404px;
                display: block;
                margin: auto;
            }
        }

        .icon-holder {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 200px;
            height: 200px;
            background-color: #fff;
            box-shadow: 10px 10px 20px 0 rgba(8,25,43,0.10);
            border-radius: 10px;
            padding: 40px;

            img {
                width: 100%;
                height: auto;
            }
        }


        @include breakpoint('lg')  {
            .limit-icon {
                img {
                    max-width: 342px;
                }
            }

            .limit-collection {
                img {
                    max-width: 341px;
                }
            }
            
            .icon-holder {
                width: 115px;
                height: 115px;
                padding: $base-spacing;
            }

            .limit__text {
                text-align: center;
                margin-top: $base-spacing;
            }

            ul {
                max-width: 400px;
                margin: auto;
            }

            .button-holder {
                position: fixed;
                bottom: 0;
                left: 0;
                width: 100%;
                padding: $base-spacing;
                box-shadow: 0 0 9px 0 rgba(8,25,43,0.10);
                background-color: #fff;
                z-index: 1;

                a {
                    width: 100%;
                }
            }
        }

        @include breakpoint('md')  {
            padding: $base-spacing;
    
            .limit__image {
                display: none;
            }
        }
    }

    .btn {
        height: 54px;
        line-height: 54px;
        padding: 0 $base-spacing;
    }

    @include breakpoint('md')  {
        padding: 50px $base-spacing;

        .limit__image {
            display: none;
        }
    }
}
