// NUEVOS ESTILOS GUIA FLATICON

// LINKS
.link {
    // Link normal
    &--normal {
        font-weight: $font-weight-semibold;
        color: color('800', 'green');

        &:hover {
            color: color('800', 'green');
            text-decoration: underline;
        }
    }
    // Link con flecha
    &--arrow {
        padding: 0 10px;
        height: $base-element-height;
        display: inline-flex;
        align-items: center;
        font-weight: $font-weight-bold;

        color: color('800', 'green');

        &--sm {
            font-size: $font-size-sm;
            height: $small-element-height;
        }

        &--md {
            font-size: $font-size-md;
        }

        &:hover {
            color: color('800', 'green');
            background: rgba(74, 210, 149, 0.1);

            i {
                color: color('800', 'green');
            }
        }

        span ~ i {
            margin-left: $small-spacing;
        }

        i {
            color: inherit;

            ~ span {
                margin-left: $small-spacing;
            }
        }

        &.special {
            color: $color-light;
            i {
                color: inherit;
            }

            &:hover {
                background: rgba(255, 255, 255, 0.1);
                i {
                    color: inherit;
                }
            }
        }
    }
    // Link cuadrado sin texto
    &--square {
        padding: 0;
        width: 34px;
        height: 34px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: $base-border-radius;
        color: color('800', 'gray');

        &:hover {
            background: color('100', 'gray');
            color: color('800', 'gray');
        }

        &:visited {
            color: color('800', 'gray');
        }

        &[disabled] {
            color: color('300', 'gray');

            &:hover {
                background: none;
                color: color('300', 'gray');
            }
        }

        i {
            margin-bottom: -4px;
        }

        &.special {
            color: $color-light;

            &:hover {
                background: rgba(255, 255, 255, 0.1);
            }

            &:visited {
                color: $color-light;
            }

            &[disabled] {
                color: color('600', 'gray');

                &:hover {
                    background: none;
                    color: color('600', 'gray');
                }
            }
        }
    }
}

// TAGS
.tag {
    // Para filtros
    &--filter {
        height: 31px;
        display: inline-flex;
        align-items: center;
        border-radius: $base-border-radius;
        padding: 0 10px;
        background: color('75', 'gray');

        p {
            margin: 0;
            font-size: 14px;
            color: color('700', 'gray');
        }

        i {
            color: color('700', 'gray');
            font-weight: $font-weight-semibold;
            font-size: 11px;
            margin-left: 5px;
            margin-bottom: -2px;
            cursor: pointer;
        }
    }

    // Relateds
    &--related {
        height: 27px;
        display: inline-flex;
        align-items: center;
        border-radius: $base-border-radius;
        padding: 0 10px;
        color: color('700', 'gray');
        font-size: $font-size-sm;
        border: 1px solid color('300', 'gray');

        &:hover {
            color: color('600', 'gray');
            background: rgba(255, 255, 255, 0.1);
        }
    }
}

// BADGE
.new--badge {
    font-size: $font-size-xs;
    color: color('600', 'gray');
    text-transform: uppercase;
    font-weight: $font-weight-semibold;
}

// V4 BUTTONS MODIFIED
.bj-button {
    // Quitar cuando se puedan usar los iconos V2
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    font-weight: $font-weight-semibold;

    &--primary {
        color: $color-light !important;
        background: color('500', 'green');

        &:hover {
            background: color('600', 'green');
            color: $color-light;
        }

        &:active {
            background: color('800', 'green');
            color: $color-light;
        }

        &.bj-button--outline {
            background: none;
            color: color('500', 'green') !important;
            box-shadow: 0 0 0 2px color('500', 'green') inset;

            &:hover {
                background: rgba(74, 210, 149, 0.1);
                color: color('500', 'green');
                box-shadow: 0 0 0 2px color('500', 'green') inset;
            }

            &:active {
                background: rgba(74, 210, 149, 0.2);
            }
        }
    }

    &--transparent {
        color: color('800', 'gray');
        background: none;

        &:hover,
        &:active,
        &:focus {
            color: color('800', 'gray');
            background: none;
        }
    }

    &--secondary {
        background: color('100', 'gray');
        color: color('800', 'gray') !important;

        &:hover {
            background: color('200', 'gray');
        }

        &.active,
        &:focus {
            background: color('300', 'gray');
        }

        &.bj-button--outline {
            background: none;
            color: color('800', 'gray') !important;
            box-shadow: 0 0 0 2px color('200', 'gray') inset;

            &:hover {
                background: rgba(207, 217, 224, 0.1);
                // color: color('800', 'gray');
            }

            &:active {
                background: rgba(207, 217, 224, 0.2);
            }

            // &:focus {
            //     color: color('800', 'gray');
            // }
        }
    }

    &--square {
        width: 44px;
        height: 44px;
        padding: 0;
    }

    &--pinterest-red {
        background: $social-pinterest;

        &:hover {
            background: rgba(203, 32, 39, 0.9);
        }
    }

    &--facebook {
        background: $social-facebook;

        &:hover {
            background: darken($social-facebook, 5%);
        }
    }

    &--twitter {
        background: $social-twitter;

        &:hover {
            background: darken($social-twitter, 5%);
        }
    }

    &--follow-author {
        color: $color-light;

        .state--active {
            display: none;
        }

        &.active {
            background: none;
            box-shadow: 0 0 0 2px color('600', 'gray') inset;
            color: color('800', 'gray');

            .hover {
                display: none;
            }

            &:hover {
                background: color('600', 'red');
                box-shadow: 0 0 0 2px color('600', 'red') inset;
                color: #fff;

                .normal {
                    display: none;
                }

                .hover {
                    display: block;
                }
            }

            &:focus {
                background: none;
                box-shadow: 0 0 0 2px color('600', 'gray') inset;

                .normal {
                    display: block;
                }

                .hover {
                    display: none;
                }
            }

            .state--active {
                display: block;
            }

            .state--inactive {
                display: none;
            }
        }
    }

    &--ghost {
        background-color: transparent;
        padding: 0 $base-spacing;
        color: color('800', 'gray');
        font-size: $font-size-md;

        &:hover {
            color: color('800', 'gray');
            background-color: color('75', 'gray');
        }
    }

    i {
        font-size: 16px;

        &.flaticon-pinterest {
            color: $social-pinterest !important;

            &--light {
                color: $color-light !important;
            }
        }
    }

    span {
        margin: 0;
        padding: 0;
    }
}

.bg__social--instagram {
    background: linear-gradient(29.61deg, #f38334 0%, #da2e7d 50.39%, #6b54c6 100%);
}
html .medium {
    font-weight: $font-weight-semibold;
}
