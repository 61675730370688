.fi-modal {
    $base-border-color: gainsboro !default;
    $base-border-radius: 3px !default;
    $base-background-color: white !default;
    $base-line-height: $line-height-160 !default;
    $action-color: #477dca !default;
    $dark-gray: #333 !default;
    $light-gray: #ddd !default;
    $medium-screen: em(640) !default;
    $large-screen: em(860) !default;
    $base-font-color: $dark-gray !default;
    $modal-padding: 3em;
    $modal-background: $base-background-color;
    $modal-close-color: color('300', 'gray');
    $modal-image-height: 135px;
    $modal-image-width: $modal-image-height;
    $modal-trigger-image-width: 300px;

    label {
        cursor: pointer;
        margin-bottom: 0;
    }

    label img {
        border-radius: $modal-trigger-image-width / 2;
        display: block;
        max-width: $modal-trigger-image-width;
    }

    .modal-body {
        @include clearfix;
    }

    .modal-state {
        display: none;
    }

    .modal-trigger {
        padding: 0.8em 1em;
    }

    .modal-fade-screen {
        // overlay
        // @include transition(opacity 0.25s ease);

        @include position(fixed, 0px 0px 0px 0px);

        background: rgba(0, 0, 0, 0.85);
        // opacity: 0;
        padding-top: 0.6em;
        text-align: left;
        // visibility: hidden;
        z-index: 302;
        display: none;

        @media (min-width: 53.75em) {
            padding-top: 10em;
        }

        .modal-bg {
            @include position(absolute, 0px 0px 0px 0px);

            cursor: pointer;
        }
    }

    .modal-close {
        @include position(absolute, ($modal-padding / 2) ($modal-padding / 2) null null);

        @include size(1.5em);

        background: $modal-background;
        cursor: pointer;

        &:after,
        &:before {
            @include position(absolute, 3px 3px 0 50%);

            @include transform(rotate(45deg));

            @include size(0.15em 1.5em);

            background: $modal-close-color;
            content: '';
            display: block;
            margin: -3px 0 0 -1px;
        }

        &:hover:after,
        &:hover:before {
            background: color('400', 'gray');
        }

        &:before {
            @include transform(rotate(-45deg));
        }
    }

    .modal-inner {
        @include transition(all 0.25s ease);

        background: $modal-background;
        border-radius: $base-border-radius;
        margin-top: 0;
        margin: auto;
        max-height: 95%;
        overflow-x: hidden;
        overflow-y: auto;
        padding: $modal-padding / 2;
        position: relative;
        width: 95%;

        img {
            max-width: 100%;
            height: auto;
        }

        @include transform(scale(0.7));

        @media (min-width: 40em) {
            max-height: 90%;
            padding: $modal-padding;
            width: 60%;

            &-big {
                width: 70%;
            }
        }

        @media (min-width: 53.75em) {
            width: 50%;

            &-big {
                width: 60%;
            }
        }

        .modal-content {
            @media (min-width: 40em) {
                @include columns(2 8em);
            }
        }

        a.cta {
            color: white;
            display: inline-block;
            margin-right: 0.5em;
            margin-top: 1em;

            &:last-child {
                padding: 0 2em;
            }
        }
    }

    .modal-state:checked + .modal-fade-screen {
        // opacity: 1;
        // visibility: visible;
        display: block;
    }

    .modal-state:checked + .modal-fade-screen .modal-inner {
        top: 0.5em;

        @include transform(scale(1));
    }
}

.modal-open {
    overflow: hidden;
}
