.landing--iconfonts-sprites {
    header {
        background-color: color('800', 'blue');
        padding: $extra-large-spacing 0;
        margin-bottom: $extra-large-spacing;
    }

    .container {
        max-width: 1100px;
    }

    .features {
        margin-bottom: $extra-large-spacing;
    }

    .tutorial {
        &__text {
            max-width: 400px;
            padding: 0 $base-spacing;
        }

        &__image {
            img {
                border: $base-border;
            }
        }

        .row {
            padding: $extra-large-spacing $base-spacing 0;

            &:nth-child(even) {
                background-color: color('200', 'gray');
            }
        }

        @include breakpoint('md') {
            &__text {
                @include order(0);
                margin-bottom: $small-spacing;
            }

            &__image {
                @include order(1);
            }
        }
    }
}
