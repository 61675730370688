$collections-width: 320px;
$collections-breakpoint: 1024px;

$btn-size-small: 32px;

.notouch {
    //Add hover events here

    .collections-content {
        .icons-limit {
            &:hover {
                color: color('100', 'gray');
            }
        }
    }
}

.collections {
    @include position(fixed, 0 0 null null);
    @include transition(transform 150ms linear, width 150ms linear);
    @include transform(translateX(101%));
    background-color: color('900', 'gray');
    color: #fff;
    height: 100%;
    width: $collections-width;
    z-index: 100;

    &.visible {
        @include transform(translateX(0px));
    }
    &.expanded {
        width: 100%;
        z-index: 102;
        .paint-popover {
            .popover-content {
                width: 220px !important;
                top: 24px !important;
                left: 4px !important;
            }
        }
        .collection-section-header .tooltip .btn-pill {
            &.expand {
                display: none;
            }
            &.collapse {
                display: block;
            }
        }
    }
    &.loading {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: color('900', 'gray');
            z-index: 9;
            opacity: 0.3;
        }
        &:after {
            content: '';
            @include animation(spinner 800ms infinite linear);
            border: 2px solid #fff;
            border-right-color: transparent;
            border-radius: 100%;
            display: inline-block;
            position: relative;
            overflow: hidden;
            text-indent: -9999px;
            width: 48px;
            height: 48px;
            vertical-align: middle;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 10;
            margin-left: -24px;
            margin-top: -24px;
        }
    }

    .collection-section-header {
        background-color: color('900', 'blue');
        padding: 1em;
        position: relative;
        z-index: 300;

        .hide-collections {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color: color('800', 'blue');
            border-radius: 0;
            color: #fff;
            height: 100%;
            left: 0;
            padding: 1em;

            i {
                vertical-align: text-top;
            }
        }

        span {
            cursor: default;
            font-weight: $font-weight-bold;

            &:not(.badge) {
                margin-left: 3em;
            }

            &.collections-number:empty {
                display: none;
            }
        }

        .tooltip {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 1em;
            z-index: 3;
            &.expand-collapse-holder {
                right: 92px;
                @media (max-width: 768px) {
                    display: none;
                }
            }
            &.show-collections-holder {
                right: 54px;
            }

            &.add-collection {
                &:after {
                    @include transform(translate(0, 7px));

                    left: initial;
                    right: 0;
                }
            }
            .btn-pill {
                box-sizing: border-box;
                font-size: 16px;
                line-height: $btn-size-small;
                width: $btn-size-small;
                height: $btn-size-small;
                padding: 0;

                &.collapse {
                    display: none;
                }

                i {
                    color: #fff;
                    margin: auto;
                }
            }
        }

        .dropdown-upgrade {
            @include transition(opacity 0.1s linear, visibility 0.1s linear, transform 0.1s linear);

            background-color: #fff;
            border-radius: 4px;
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
            color: color('900', 'gray');
            font-size: 0.8em;
            opacity: 0;
            padding: 0.5em;
            position: absolute;
            right: 0.75em;
            text-align: center;
            visibility: hidden;
            width: 300px;
            z-index: 999999;

            &:before {
                border-bottom: 6px solid #fff;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                content: '';
                height: 0;
                position: absolute;
                right: 1.4em;
                top: -6px;
                width: 0;
            }

            p {
                cursor: default;
                line-height: 1.2em;
            }
        }
    }

    .collections-holder {
        @include display(flex);
        @include flex-direction(row);
        @include flex-wrap(wrap);
        @include align-content(flex-start);

        background-color: color('900', 'gray');
        height: calc(100% - 56px);
        position: relative;

        &.overflow {
            overflow: visible;
        }

        &.scrollable {
            overflow-y: auto;
        }

        &.logged {
            .empty-collection-register {
                display: none;
            }

            .empty-collection-registered {
                display: block;
            }
        }
    }

    .collection {
        @include transition(height 0.5s $ease-out-quart);

        height: calc(0% + 56px);
        position: relative;
        width: 100%;

        &.limit-reached {
            .collection-header {
                .badge {
                    background-color: color('600', 'red');
                    color: #fff;
                }
            }

            .collection-content {
                .icons-limit {
                    display: block;
                }
            }
        }

        .collection-header {
            background-color: color('800', 'blue');
            cursor: pointer;
            // overflow: hidden;
            padding: 1em;
            position: relative;
            // z-index: 99;
            // z-index: 258;
            height: 57px;

            .locked-options {
                display: none;
            }

            span {
                //@include ellipsis(63%);
                @include ellipsis(74%);

                font-weight: $font-weight-bold;
                margin-right: 0.5em;
                vertical-align: middle;
            }

            .header-options,
            .locked-options {
                position: absolute;
                top: 0;
                height: 100%;
                font-weight: $font-weight-regular;

                button {
                    height: 57px;
                    width: 50px;
                    color: #fff;
                    border-radius: 0;
                    font-size: 20px;
                    &:hover {
                        background-color: darken(color('800', 'blue'), 5);
                    }
                }
                > i {
                    line-height: 57px;
                    @include position(absolute, 0 null null 14px);
                }
            }

            .header-options {
                right: 0;

                .popover-container.menu-options {
                    z-index: 999;
                }
            }

            .locked-options {
                left: 0;
                i {
                    color: color('600', 'red');
                }
            }

            .popover {
                &:not(.popover-top) {
                    .menu-options {
                        .popover-content {
                            top: 58px;
                            bottom: initial;
                            a {
                                color: color('400', 'green');
                            }
                        }
                    }
                }
                .menu-options {
                    .popover-content {
                        padding: 5px 0;
                        z-index: 2;

                        &:before {
                            right: 14px;
                        }
                        &:after {
                            right: 12px;
                        }
                    }
                }
                hr {
                    margin: 8px 0;
                }
            }

            .option-buttons {
                li {
                    display: block;
                    &:hover {
                        background: none;
                    }
                }
                button {
                    font-size: 13px;
                    height: $btn-size-small;
                    font-weight: $font-weight-semibold;
                    width: 100%;
                    text-align: left;
                    border: none;
                    border-radius: 0;
                    float: none;
                    color: color('900', 'gray');
                    padding: 0 8px;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    width: 100%;
                    &.delete-collection {
                        color: color('600', 'red');
                    }
                    &:hover {
                        color: color('700', 'blue');
                        background: none;
                    }
                    i {
                        width: 20px;
                        display: inline-block;
                    }
                }
            }

            .edit-name {
                @include position(absolute, 0 null null 0);
                display: none;

                height: 100%;
                width: 100%;

                input {
                    background-color: color('900', 'blue');
                    border: none;
                    font-weight: $font-weight-bold;
                    height: 100%;
                    padding: 1em;
                    padding-right: 3em;
                    z-index: 1;
                    color: #fff;
                    width: 100%;
                }

                button {
                    @include position(absolute, 0 0 null null);
                    color: color('600', 'green');
                    height: 100%;
                    width: 50px;
                    display: block;
                    margin: 0;
                    z-index: 1;
                    font-weight: $font-weight-regular;
                }

                &.active {
                    display: block;
                    z-index: 999;

                    button {
                        background: color('900', 'blue');
                    }
                }
            }

            &.hovered {
                background-color: lighten(color('800', 'blue'), 5%);

                button {
                    display: block;
                }
            }
        }

        .collection-content {
            @include transition(height 0.5s $ease-out-quart);
            background-color: #fff;
            box-shadow: inset 0 -1px 1px color('800', 'blue');
            height: 0;
            overflow: hidden;
            position: relative;
            z-index: 2;

            &.nologged-limit-reached {
                display: none;
            }

            &.edit-mode .collection-options,
            &.sort-mode .collection-options {
                z-index: 256;
            }

            .upgrade_btn {
                display: none;
            }

            .collection-options {
                z-index: 258;

                &.no-icons {
                    display: none;
                }

                > ul {
                    @include clearfix;

                    > li {
                        float: left;

                        .popover-content {
                            width: auto;
                            min-width: 200px;
                            &:before {
                                right: 15px;
                            }
                            &:after {
                                right: 13px;
                            }
                        }

                        > button,
                        .popover-button {
                            border-radius: 0;
                            padding: 0;
                            width: 40px;
                            height: 41px;
                            line-height: 41px;
                            font-weight: $font-weight-regular;
                            color: color('900', 'gray');

                            &.popover-button {
                                width: 50px;
                            }
                        }
                    }
                }
            }

            .header-edit,
            .header-sort {
                @include transition(top 0.3s $ease-out-quart, opacity 0.3s $ease-out-quart);
                @include clearfix;

                background-color: color('100', 'gray');
                box-shadow: 1px -1px 0px 1px color('800', 'blue');
                opacity: 0;
                padding: 0 12px;
                position: absolute;
                top: -44px;
                width: 100%;
                height: 42px;
                line-height: 42px;
                z-index: 257;

                b {
                    color: color('900', 'blue');
                }

                button {
                    padding: 6px 20px;
                    position: relative;
                    top: 8px;
                }
            }

            .icons-limit {
                background: color('600', 'red');
                cursor: default;
                display: none;
                display: block;
                font-size: 0.9em;
                line-height: 1em;
                padding: 0.5em;
                text-align: center;
                margin-bottom: 3px;

                a {
                    color: #fff;

                    b {
                        text-decoration: underline;
                    }
                }
            }

            .premium-advise {
                background: color('900', 'blue');
                border-radius: $base-border-radius;
                color: #fff;
                font-size: 0.8em;
                left: 1em;
                line-height: 1.2em;
                padding: 0.5em 1.5em 0.5em 0.5em;
                position: absolute;
                text-align: center;
                top: 9em;
                z-index: 260;

                &:before {
                    border-bottom: 6px solid #333;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    content: '';
                    height: 0;
                    left: 2.5em;
                    position: absolute;
                    top: -6px;
                    width: 0;
                }

                button {
                    @include transform(translateY(-50%));

                    color: #fff;
                    height: 100%;
                    padding: 0.5em;
                    position: absolute;
                    right: 0;
                    top: 50%;
                }
            }

            ul.icons {
                height: 100%;
                margin: 0;
                overflow-y: scroll;
                padding: 0 4px;

                > li {
                    min-width: 70px;
                    @include animation(iconUp 0.3s $ease-out-cubic forwards);

                    &:hover {
                        z-index: 300;
                    }

                    img,
                    svg {
                        width: 48px;
                        height: 48px;
                        margin: -24px 0 0 -24px;
                    }

                    &[draggable] {
                        -moz-user-select: none;
                        -khtml-user-select: none;
                        -webkit-user-select: none;
                        user-select: none;
                        /* Required to make elements draggable in old WebKit */
                        -khtml-user-drag: element;
                        -webkit-user-drag: element;
                    }
                    //@include transition(all .3s $ease-out-quart);
                    &.icon-upload {
                        z-index: 250;
                        margin: 0;
                        &:after {
                            content: '';
                            display: block;
                            padding-bottom: 100%;
                        }
                        &[disabled] {
                            label {
                                cursor: not-allowed;
                            }
                        }
                        .tooltip {
                            width: 100%;
                            height: 100%;
                            bottom: 0;
                            position: absolute;
                            left: 50%;
                            @include transform(translateX(-50%));
                            z-index: 3;
                            .tooltip__content {
                                padding-left: 4px;
                            }
                        }
                        label {
                            border: 2px dashed color('800', 'blue');
                            display: block;
                            width: 100%;
                            height: 100%;
                            cursor: pointer;
                            color: color('800', 'blue');
                            &:hover {
                                border-color: color('500', 'gray');
                                color: color('500', 'gray');
                            }
                            span {
                                font-size: 12px;
                                padding: 4px;
                                font-weight: $font-weight-bold;
                                text-align: center;
                                display: block;
                                top: 50%;
                                position: relative;
                                @include transform(translateY(-50%));
                            }
                            input {
                                display: none;
                                @include appearance(none);
                            }
                        }
                    }
                    .personal-icon--text {
                        display: none;
                        @include position(absolute, null null 0 0);
                        width: 100%;
                        text-align: center;
                        font-size: 10px;
                        line-height: 10px;
                        color: color('800', 'blue');
                    }
                    &.selected {
                        .icon--holder {
                            background: none;
                            box-shadow: none;
                            border: none;
                        }
                    }
                    &.personal-icon {
                        .personal-icon--text {
                            display: block;
                        }
                    }

                    &.locked {
                        .flaticon-premium {
                            display: block;
                        }
                        .edit {
                            display: none;
                        }
                    }

                    .flaticon-premium {
                        display: none;
                        position: absolute;
                        font-size: 12px;
                        line-height: 12px;
                        z-index: 1;
                        top: 4px;
                        left: 4px;
                        &:before {
                            width: 12px;
                            height: 12px;
                        }
                    }

                    .btn {
                        width: $btn-size-small;
                        height: $btn-size-small;
                        line-height: $btn-size-small;
                        top: 3px;
                        right: 3px;

                        &:before {
                            line-height: $btn-size-small;
                        }
                    }

                    .icon-options {
                        top: $btn-size-small + 6px;
                        right: 3px;
                    }

                    .overlay {
                        // font-size: .8em;
                    }

                    .edit {
                        display: none;
                        padding-right: 44px;
                        position: relative;

                        > input {
                            border: 1px solid color('100', 'gray');
                            border-radius: $base-border-radius;
                            box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
                            color: color('900', 'gray');
                            font-size: 0.8em;
                            padding: 1em 0.5em;
                        }

                        .popover {
                            @include position(absolute, 50% 2em null null);
                            @include transform(translateY(-50%));

                            z-index: 1;

                            .popover-button {
                                padding: 1em;
                            }

                            .popover-content {
                                margin-bottom: 45px;
                                right: -10px;
                                top: 18px;
                                width: 215px;
                            }
                        }

                        label {
                            @include position(absolute, 50% 40px null null);
                            @include transform(translateY(-50%));

                            padding: 0.5em;
                            margin: 0;
                            font-weight: $font-weight-bold;
                            cursor: pointer;
                            color: color('900', 'blue');
                            input {
                                display: none;
                            }
                        }

                        .collection-icon-delete {
                            @include position(absolute, 50% 0 null null);
                            @include transform(translateY(-50%));

                            width: 44px;
                            height: 44px;
                        }

                        .popover-button {
                            @include position(absolute, 50% 40px null null);
                            @include transform(translateY(-50%));

                            padding: 0.5em;
                        }
                    }
                }
            }

            .overlay-drop {
                @include position(absolute, 0 null null 0);
                width: 100%;
                height: 100%;
                padding: 0 20px 20px 20px;
                background: $color-light;
                z-index: 300;
                display: none;
                border-left: 1px solid color('100', 'gray');
                overflow: hidden;

                > div {
                    &.upgrade,
                    &.register {
                        display: none;
                    }
                }

                &.upgrade {
                    .drop {
                        display: none;
                    }

                    .upgrade {
                        display: block;

                        .max-custom-icons {
                            width: 100%;
                            max-width: 250px;
                            margin: 0 auto;
                            margin-top: 41px;

                            .close {
                                display: flex;
                                justify-content: space-between;

                                .flaticon-cross {
                                    color: color('800', 'gray');
                                    font-size: 18px;
                                    cursor: pointer;
                                }
                            }

                            p {
                                margin: 0;
                                margin-bottom: 5px;
                                color: color('600', 'gray');
                                font-size: $font-size-sm;
                                text-align: center;

                                &.register-title {
                                    width: 100%;
                                    margin: 0 auto;
                                    margin-top: 15px;
                                    margin-bottom: 15px;
                                    font-weight: $font-weight-semibold;
                                    font-size: $font-size-lg !important;
                                    color: color('800', 'gray');
                                }

                                &.register-check {
                                    text-align: left;

                                    .flaticon-check {
                                        color: color('600', 'green');
                                        margin-right: 10px;
                                    }
                                }

                                a {
                                    color: color('800', 'gray');
                                    font-weight: $font-weight-semibold;
                                }
                            }

                            a {
                                margin: 20px 0;
                            }

                            &.limit-reached {
                                display: none;
                            }
                        }
                    }
                }

                &.register {
                    .drop {
                        display: none;
                    }
                    .register {
                        display: block;
                    }
                }

                .drop {
                    height: 100%;

                    &--box {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        color: color('800', 'blue');
                    }

                    i:not(.flaticon-check) {
                        font-size: 48px;
                        height: 48px;
                        width: 48px;
                        line-height: 48px;
                        margin-bottom: 10px;
                        display: block;
                    }
                }

                .register {
                    height: 100%;

                    &--box {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        color: color('800', 'blue');
                        text-align: center;

                        p {
                            color: color('800', 'gray');
                            font-size: 14px;

                            &:first-child {
                                margin: 0;
                            }
                        }
                    }
                }
            }

            .collection-share {
                @include position(absolute, 0 null null 0);
                width: 100%;
                height: 100%;
                background-color: rgba(255, 255, 255, 0.9);
                padding: 1em;
                z-index: 300;
                box-shadow: inset 0 -1px 1px color('800', 'blue');
                display: none;
                &.visible {
                    display: block;
                }
                .collection-share-content {
                    position: relative;
                    top: 50%;
                    @include transform(translateY(-50%));
                    color: color('900', 'blue');
                    text-align: center;
                    max-width: $collections-width;
                    margin: auto;
                }
                .form-feedback {
                    margin-top: 30px;
                }
                .close {
                    @include position(absolute, 0 0 null null);
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    font-size: 28px;
                    color: color('900', 'gray');
                    font-weight: $font-weight-regular;
                }
                input {
                    border: 1px solid color('100', 'gray');
                    border-radius: $base-border-radius;
                    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
                    font-size: 0.8em;
                    margin-top: 20px;
                }
                ul {
                    @include clearfix;

                    li {
                        float: left;
                        width: 33.3333%;

                        button {
                            border: none;
                            border-radius: 0;
                            width: 100%;

                            i {
                                color: #fff;
                                font-weight: $font-weight-regular;
                            }

                            &.bg-twitter:hover {
                                background-color: color('twitter', 'social') !important;
                            }

                            &.bg-facebook:hover {
                                background-color: color('facebook', 'social') !important;
                            }

                            &.bg-pinterest:hover {
                                background-color: color('pinterest', 'social') !important;
                            }
                        }

                        &:first-child button {
                            border-radius: 4px 0 0 4px;
                        }

                        &:last-child button {
                            border-radius: 0 4px 4px 0;
                        }
                    }
                }
            }

            .collection-backup {
                @include position(absolute, 0 null null 0);
                width: 100%;
                height: 100%;
                background-color: rgba(255, 255, 255, 0.9);
                padding: 1em;
                z-index: 300;
                box-shadow: inset 0 -1px 1px color('800', 'blue');
                display: none;
                &.visible {
                    display: block;
                }
                .collection-backup-content {
                    position: relative;
                    top: 50%;
                    @include transform(translateY(-50%));
                    color: color('900', 'blue');
                    text-align: center;
                    max-width: $collections-width;
                    margin: auto;

                    .tooltip {
                        margin-bottom: 20px;
                    }
                }

                .close {
                    @include position(absolute, 0 0 null null);
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    font-size: 28px;
                    color: color('900', 'gray');
                }
            }

            .empty-collection {
                color: color('900', 'gray');
                height: 100%;
                padding: 0 10px;
                position: relative;
                text-align: center;
                display: none;
            }

            .download-holder {
                @include position(absolute, null null 0 0);
                @include transition(all 0.3s $ease-out-quart);

                background-color: #fff;
                box-shadow: inset 0 -1px 1px color('800', 'blue');
                padding: 1em;
                text-align: center;
                width: 100%;
                z-index: 257;
            }

            .collection-download {
                @include position(absolute, null null -1em 0);
                @include transition(all 0.3s ease-in-out);

                background-color: #fff;
                box-shadow: inset 0 -1px 1px color('800', 'blue');
                color: color('900', 'gray');
                height: 0;
                overflow: hidden;
                overflow-y: scroll;
                text-align: center;
                width: 100%;
                z-index: 258;

                header {
                    padding: 1em;
                    position: relative;

                    b {
                        display: block;
                    }

                    button {
                        @include position(absolute, 0.3em 0.5em null null);
                        color: color('900', 'gray');
                        font-size: 2em;
                        font-weight: $font-weight-regular;
                    }
                }

                ul.download {
                    padding: 1em;
                    text-align: center;
                    max-width: $collections-width;
                    margin: auto;

                    li {
                        display: block;
                        margin: 0 0 1em 0;
                        &:before,
                        &:after {
                            @include transition(none);
                        }

                        &:before {
                            display: none;
                        }

                        &:after {
                            height: auto;
                            line-height: 1em;
                            padding-bottom: 11px;
                            max-width: 80%;
                            white-space: normal;
                            width: $collections-width;
                        }

                        .tooltip__content {
                            width: 100%;
                            white-space: normal;
                        }

                        &.extra-info {
                            button {
                                width: calc(100% - 30px);
                            }
                            a {
                                @include position(absolute, 0 0 null null);
                                display: block;
                                width: 30px;
                                line-height: 40px;
                                color: color('900', 'blue');
                                text-align: right;
                            }
                        }

                        &.customize {
                            margin: 0;
                            display: none;

                            &.active {
                                margin: -8px 0 1em 0;
                                padding: 0.5em;
                                border-radius: 4px;
                                overflow: visible;
                                position: relative;
                                display: block;

                                &:before {
                                    content: '';
                                    left: 1em;
                                    top: -8px;
                                    width: 0;
                                    height: 0;
                                    display: block;
                                    border-bottom: 8px solid color('900', 'blue');
                                    border-right: 8px solid transparent;
                                    border-left: 8px solid transparent;
                                    border-radius: 2px;
                                    position: absolute;
                                }
                            }

                            span {
                                display: block;
                                font-size: 0.9em;
                            }
                            ul.size {
                                margin-top: 0.5em;
                                li {
                                    margin-bottom: 0;
                                }
                            }
                        }

                        a,
                        button {
                            box-sizing: border-box;
                            display: block;
                            text-align: center;
                        }
                    }
                }

                .customize {
                    background: color('900', 'blue');
                    color: #fff;
                }

                &.active {
                    height: 100%;
                    overflow: auto;
                    bottom: 0;
                }
            }
        }

        .paint-popover {
            .popover-content {
                top: 36px;
                z-index: 300;
                &:before,
                &:after {
                    display: none;
                }
                @media (max-width: $collections-breakpoint) {
                    right: auto;
                    left: 0;
                }
            }
            .color-selector {
                margin-bottom: 7px;
                li:hover {
                    background: none;
                }
            }
        }

        &.expanded {
            height: 100%;

            &.nologged-limit-reached {
                background: $color-light;
                box-shadow: inset 0 -1px 1px color('800', 'blue');
            }

            @media (min-width: 480px) {
                &.collection-height-1 {
                    height: calc(100% - 56px);
                }

                &.collection-height-2 {
                    height: calc(100% - 112px);
                }
            }

            .collection-header {
                z-index: 258;
            }

            .collection-content {
                overflow: visible;
                height: calc(100% - 56px);
                padding-bottom: 115px;

                &.edit-mode {
                    padding-bottom: 39px;

                    .header-edit {
                        opacity: 1;
                        top: 0;
                    }

                    ul.icons {
                        padding: 1em 0;

                        > li {
                            @include animation(none);

                            height: auto;
                            margin: 0.3em 0;
                            padding: 0;
                            @include flex(none);
                            width: 100%;

                            .icon--holder {
                                padding-left: 55px;
                                background: none;
                                border: none;
                                box-shadow: none;
                                &:after {
                                    display: none;
                                }
                                > img,
                                > svg {
                                    left: 0;
                                    top: 0;
                                    margin: 6px 10px;
                                    width: $btn-size-small;
                                    height: $btn-size-small;
                                }
                            }

                            .edit {
                                display: block;
                            }

                            .view {
                                width: 48px;
                                height: 48px;
                            }

                            &:hover {
                                .overlay,
                                .icon-options {
                                    display: none;
                                }
                            }

                            &.icon-fill,
                            &.icon-upload,
                            &.locked,
                            .personal-icon--text {
                                display: none;
                            }

                            &.whitebg {
                                svg {
                                    background: color('800', 'blue');
                                }
                            }
                        }

                        ~ .download-holder {
                            @include transform(translateY(75px));

                            opacity: 0;
                        }
                    }

                    .collection-download {
                        display: none;
                    }
                }

                &:not(.edit-mode) {
                    ul.icons {
                        .icon--item.whitebg {
                            background-color: color('800', 'blue');
                        }
                    }
                }

                &.sort-mode {
                    padding-bottom: 39px;

                    .header-sort {
                        opacity: 1;
                        top: 0;
                    }

                    ul.icons {
                        padding: 10px 0;

                        > li:not(.icon-upload) {
                            cursor: move;
                            @include animation(none);
                            .overlay,
                            .popover {
                                display: none;
                            }
                            &.dragHover {
                                box-shadow: 0 0 0 3px color('600', 'green');
                            }
                        }
                        > li.icon-upload label {
                            opacity: 0.2;
                            cursor: not-allowed;
                        }
                        > li.first-icon {
                            cursor: auto;
                            &:hover,
                            &.dragHover {
                                box-shadow: none;
                            }
                        }
                    }

                    .download-wrapper {
                        display: none;
                    }
                }
            }

            &.limit-reached {
                .collection-content {
                    padding-bottom: 157px;

                    &.edit-mode {
                        padding-bottom: 82px;
                    }
                }
            }

            &.empty {
                ul.icons {
                    display: none;
                }

                .collection-content {
                    padding-bottom: 42px;

                    @media (max-width: 480px) {
                        padding-bottom: 0;
                    }
                }

                .empty-collection {
                    @include display(flex);
                    @include flex-direction(column);
                    overflow: auto;

                    .empty-collection-content {
                        margin-bottom: 10px;
                        @include flex(1);

                        p {
                            font-size: $font-size-sm;
                        }
                        label {
                            font-weight: $font-weight-bold;
                            text-decoration: underline;
                            cursor: pointer;
                        }
                        img {
                            display: block;
                            margin: 0 auto 12px;
                        }
                    }
                }

                .download-wrapper {
                    display: none;
                }

                p {
                    // font-size: 14px;
                }
            }
        }

        .empty-collection-gtm {
            margin-bottom: 10px;
            overflow: hidden;
        }

        &.locked {
            .collection-header {
                background-color: color('900', 'blue');
                .collection-name {
                    max-width: 63%;
                    margin-left: 22px;
                }
                .locked-options {
                    display: block;
                }
            }

            .popover-locked {
                font-size: 12px;
                text-align: center;
                padding: 8px;
            }

            &.expanded {
                .collection-content {
                    padding-bottom: 0;

                    ul.icons {
                        height: calc(100% - 50px);
                    }

                    .collection-options {
                        display: none;
                    }
                    .upgrade_btn {
                        display: block;
                        width: calc(100% - 8px);
                        text-align: center;
                        margin-bottom: 4px;
                        margin: 0 auto 8px;
                        @include transform(translateY(4px));
                    }
                    .download-wrapper {
                        display: none;
                    }
                }
            }

            .empty-collection {
                height: calc(100% - 51px);
            }
        }
    }

    .popover {
        &.popover-right .popover-content {
            left: -15px;
            &:before {
                left: 19px !important;
            }
            &:after {
                left: 17px !important;
            }
        }
        &.popover-left .popover-content {
            &:before {
                right: 6px !important;
            }
            &:after {
                right: 4px !important;
            }
        }
    }

    .empty-collection-register {
        width: 100%;
        max-width: 250px;
        margin: 0 auto;
        margin-top: 41px;

        &.icons-collection-noregister {
            display: none;

            &.limit-reached {
                display: block;
            }

            .close {
                display: flex;
                justify-content: space-between;

                .flaticon-cross {
                    color: color('800', 'gray');
                    font-size: 18px;
                    cursor: pointer;
                }
            }
        }

        p {
            margin: 0;
            margin-bottom: 5px;
            color: color('600', 'gray');
            font-size: 14px;
            text-align: center;

            &.register-title {
                width: 100%;
                margin: 0 auto;
                margin-top: 15px;
                margin-bottom: 15px;
                font-weight: $font-weight-semibold;
                font-size: $font-size-lg !important;
                color: color('800', 'gray');
            }

            &.register-check {
                text-align: left;

                .flaticon-check {
                    color: color('600', 'green');
                    margin-right: 10px;
                }
            }
        }

        a {
            margin: 20px 0;
            line-height: 16px;
        }

        &.limit-reached {
            display: none;
        }
    }

    .empty-collection-registered {
        display: none;
    }

    ul.size {
        @include clearfix;
        display: inline-block;

        li {
            float: left;

            .btn {
                padding: 0 8px;
            }

            button {
                border-radius: 0;

                height: 24px;

                border-right: 1px solid color('500', 'green');
            }

            &:first-child {
                button {
                    border-radius: 4px 0 0 4px;
                }
            }

            &:last-child {
                border: none;

                button {
                    border-radius: 0 4px 4px 0;
                    border: none;
                }
            }
        }
    }
}

.collections-overlay {
    @include position(fixed, 0 -100% null null);

    background: rgba(0, 0, 0, 0.8);
    display: none;
    height: 100%;
    width: 100%;
    z-index: 98;
}

@media (max-width: $collections-breakpoint) {
    #viewport {
        .collections-overlay {
            @include transition(opacity 250ms ease-in-out);

            display: block;
            opacity: 0;
        }

        &.collections-visible {
            height: 100%;
            overflow: hidden;

            .collections,
            .collections.visible {
                @include transition(right 250ms ease-in-out);

                height: 100%;
                width: 100%;
            }

            .collections.visible + .collections-overlay {
                opacity: 1;
                right: 0;
            }
        }
    }
}

.customize {
    height: 0;
    padding: 0;
    overflow: hidden;

    @include transform(perspective(500px) rotateX(-0.25turn));

    @include transform-origin(50% 0);

    @include transition(transform 0.4s $ease-out-quart, height 0.4s $ease-out-quart, padding 0.4s $ease-out-quart);

    .close-customize {
        @include position(absolute, 0em 0.5em null null);

        color: #fff;
        font-size: $line-height-160;
        cursor: pointer;
    }

    .color-selector {
        margin-bottom: 1em;
    }

    .size {
        margin-top: 1em;
    }

    &.active {
        @include transform(none);

        height: auto;
        padding: 1em;
    }
}

// ANCHOR: NUEVO
// Mejorar he integrar dentro del sass de arriba
.collection {
    &.locked {
        .collection-content {
            .collection_icons_list {
                height: calc(100% - 170px) !important;
                padding-top: 10px !important;
            }

            .empty-collection {
                display: none !important;
            }
        }
    }
}

.collection-locked-inside {
    padding-top: 10px;

    .collection-locked {
        max-width: 298px;
        display: flex;
        background: rgba(255, 178, 41, 0.1);
        margin: 0 auto;
        padding: 15px 15px 20px 15px;
        border-radius: 6px;

        &-box {
            .icon--exclamation {
                color: color('500', 'yellow');
                margin-top: 2px;
            }

            &--title {
                color: color('800', 'gray');
                font-size: 14px;
                font-weight: $font-weight-bold;
                margin-bottom: 5px;
            }

            &--text {
                color: color('700', 'gray');
                font-size: 14px;
                margin-bottom: $base-spacing;
            }

            .icon--cross {
                color: color('500', 'gray');
                margin-top: 2px;
                cursor: pointer;
            }

            &:first-child {
                margin-right: 10px;
            }

            &:last-child {
                margin-left: 15px;
            }
        }
    }
}

.collection-locked-outside {
    padding: 10px 0;
    margin: 0 auto;

    .collection-locked {
        max-width: 298px;
        display: flex;
        background: #fff7e9;
        padding: 15px 15px 20px 15px;
        border-radius: 6px;

        &-box {
            .icon--exclamation {
                color: color('500', 'yellow');
                margin-top: 2px;
            }

            &--title {
                color: color('800', 'gray');
                font-size: 14px;
                font-weight: $font-weight-bold;
                margin-bottom: 5px;
            }

            &--text {
                color: color('700', 'gray');
                font-size: 14px;
                margin-bottom: $base-spacing;
            }

            &:first-child {
                margin-right: 10px;
            }
        }
    }
}

.modal_delete_collection {
    .modal-inner {
        max-width: 560px;
        width: 100%;
    }
}
