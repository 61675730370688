.landing--styles {
    main {
        overflow: hidden;
    }

    section {
        position: relative;
    }

    .form {
        position: absolute;
        z-index: -1;
        pointer-events: none;
        max-width: initial;
    }

    #header {
        background: none;
        border: none;
        padding: $base-spacing 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;

        @include breakpoint('md') {
            position: absolute;
        }
    }

    .logo {
        @include breakpoint('xs') {
            width: auto;
        }
    }

    .hero {
        padding: 120px 0;

        .form {
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        p {
            color: color('300', 'gray');
            max-width: 440px;
            margin-left: auto;
            margin-right: auto;
        }

        .cards {
            position: relative;
            left: 50%;
            transform: translateX(-50%) rotate(-9deg);
            white-space: nowrap;
            text-align: center;
            top: 150px;

            @include breakpoint('md') {
                margin-left: -300px;
            }
        }

        .card {
            display: inline-block;
            margin: 0 $small-spacing;
            box-shadow: 17px 15px 23px rgba(29, 38, 45, 0.17);
            border-radius: 12px;
            opacity: 0;
            animation: card 2800ms ease-in-out infinite, fadeIn 800ms ease-in-out forwards;

            @for $i from 1 through 6 {
                &:nth-child(#{$i}) {
                    animation-delay: 150ms * $i;
                }
            }

            @include breakpoint('md') {
                width: 200px;

                margin: 0 $extra-small-spacing;
            }

            @include breakpoint('xs') {
                width: 160px;
            }
        }
    }

    .styles {
        margin-top: 100px;
        padding-top: 100px;

        .box-styles {
            > div {
                padding: 0 $small-spacing;
            }
        }

        .box-style {
            border-radius: $base-border-radius;
            background-color: color('body', 'background');
            box-shadow: 0 0 10px rgba(29, 38, 45, 0.1);
        }

        @include breakpoint('md') {
            margin-top: 0;

            .box-style {
                margin-bottom: $small-spacing;

                img {
                    width: 80px;
                }

                .text-holder {
                    text-align: left;
                }
            }
        }
    }

    .search {
        margin: 250px 0 150px;

        .laptop {
            position: relative;

            .screen {
                position: absolute;
                top: 5%;
                left: 50%;
                width: 76%;
                transform: translateX(-50%);
            }
        }

        @include breakpoint('lg') {
            text-align: center;
            margin: 130px 0 60px;

            .text-holder {
                @include order(1);
            }
            .laptop-holder {
                @include order(2);
            }
        }

        @include breakpoint('sm') {
            margin: 44px 0 120px;
        }
    }

    #footer {
        border: none;
        position: relative;
        background: none;
        min-height: 0;

        div {
            @include position(absolute, null null 0 0);

            width: 100%;
        }

        .form {
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            margin-left: -100px;
        }

        li {
            display: inline-block;
            a {
                color: color('link-inverted', 'general');
                font-weight: $font-weight-regular;
            }
            span {
                display: inline-block;
                margin: 0 10px;
            }
        }

        @include breakpoint('md') {
            .form {
                margin-left: -360px;
            }
        }
    }
}

@keyframes card {
    0% {
        transform: translateY(0);
        box-shadow: 17px 15px 23px rgba(29, 38, 45, 0.17);
    }
    50% {
        transform: translateY(10px);
        box-shadow: 9px 42px 31px rgba(29, 38, 45, 0.17);
    }
    100% {
        transform: translateY(0);
        box-shadow: 17px 15px 23px rgba(29, 38, 45, 0.17);
    }
}
