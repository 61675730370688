.fi-modal {
    &.modal-base64 {
        .modal-fade-screen {
            background-color: color('900', 'blue', 0.5);
            padding-top: 0;
            overflow: auto;

            .modal-inner {
                padding: 0;
                box-shadow: 0 0 1px color('900', 'gray');
                text-align: center;
                width: 100%;
                max-width: 960px;
                max-height: none;
            }
        }

        textarea {
            color: color('600', 'gray');
            padding: 0;
            word-break: break-all;
            overflow: hidden;
            background: transparent;
            border: none;
            font-size: $font-size-sm;
            line-height: $base-element-height;
        }

        .modal__banner-footer {
            p {
                color: #fff;
            }
        }

        @include breakpoint('md', 'min') {
            .modal-state:checked + .modal-fade-screen .modal-inner {
                top: 50%;
                transform: scale(1) translateY(-50%);
            }
        }
    }

    .modal-base64__content {
        padding: $extra-large-spacing;

        @include breakpoint('md') {
            padding: $base-spacing;
        }
    }
}
