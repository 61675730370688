.landing-gsuite {
    main {
        overflow: hidden;
    }

    .container {
        max-width: 1100px;
    }

    .logo {
        @include breakpoint('xs') {
            width: auto;
        }
    }

    #header {
        border: none;
        background: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        padding: $base-spacing 0;

        @include breakpoint('md') {
            position: absolute;
        }
    }

    .hero {
        margin: 150px 0 60px;
        position: relative;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: -150px;
            right: 0;
            width: 276px;
            height: 384px;
            background: url(~Media/landing/gsuite/top-right.svg) no-repeat center;
            z-index: -1;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 405px;
            height: 818px;
            background: url(~Media/landing/gsuite/top-left.svg) no-repeat center;
            z-index: -1;
        }

        @include breakpoint('lg') {
            &:after {
                left: -100px;
            }
        }

        @include breakpoint('xl') {
            &:after {
                left: -200px;
            }
        }

        @include breakpoint('md') {
            margin-bottom: 100px;

            &:before {
                top: -100px;
                right: -100px;
            }

            &:after {
                left: -300px;
            }
        }
    }

    .example {
        margin-bottom: 70px;

        video {
            width: 100%;
            height: auto;
            max-width: 1100px;
            display: block;
            margin: auto;
            box-shadow: 10px 10px 20px 0 rgba(8, 25, 43, 0.15);
            border-radius: 4px;
        }
    }

    .steps {
        background-color: color('800', 'blue');
        counter-reset: steps;
        padding: 0 0 100px;
        position: relative;
        margin: 0;

        &:before {
            content: '';
            display: block;
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            height: 500px;
            background: url(~Media/landing/gsuite/blue-form.svg) no-repeat center;
            margin-bottom: -2px;
            z-index: -1;
        }

        p:first-child {
            color: color('300', 'gray');
        }

        &__item {
            position: relative;
            max-width: 255px;
            margin: auto;
        }

        &__content {
            position: relative;

            &:before {
                counter-increment: steps;
                content: counter(steps);
                position: absolute;
                top: 0;
                right: 0;
                font-size: 200px;
                font-weight: bold;
                color: color('700', 'blue');
                line-height: 135px;
                font-family: $title-font-family;
            }

            * {
                position: relative;
                color: white;
            }
        }

        @include breakpoint('lg') {
            padding: 0 0 40px;

            &__item {
                flex: none;
                width: 100%;
                max-width: none;
                margin-bottom: 40px;
                max-width: 500px;
            }

            &__content {
                &:before {
                    font-size: 100px;
                    line-height: 100px;
                }
            }
        }
    }

    .applications {
        padding: 100px 0;
        margin: 0;

        &__content {
            padding: 0 $large-spacing;
        }

        h2 {
            max-width: 430px;
            margin: 0 auto 80px;
        }

        @include breakpoint('md') {
            padding: 40px 0;

            h2 {
                margin-bottom: 40px;
            }

            &__content {
                padding: 0;
            }

            &__text {
                font-size: $font-size-xs;
            }

            .font-h6 {
                font-size: $font-size-lg;
            }
        }
    }

    .blocks {
        margin-top: 100px;

        &__item {
            margin-bottom: 140px;

            &--search {
                .blocks__image {
                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        width: 718px;
                        height: 678px;
                        background: url(~Media/landing/gsuite/search-form.svg) no-repeat center;
                        z-index: -1;
                    }
                }
            }

            &--style {
                .blocks__image {
                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: -120px;
                        right: 65px;
                        width: 718px;
                        height: 678px;
                        background: url(~Media/landing/gsuite/style-form.svg) no-repeat center;
                        z-index: -1;
                    }
                }
            }

            &--color {
                .blocks__image {
                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: -50px;
                        left: -100px;
                        width: 612px;
                        height: 578px;
                        background: url(~Media/landing/gsuite/color-form.svg) no-repeat center;
                        z-index: -1;
                    }
                }
            }
        }

        @include breakpoint('md') {
            margin-top: 40px;

            &__item {
                margin-bottom: 40px;
            }

            &__text {
                @include order(0);
            }

            &__image {
                @include order(1);
            }
        }
    }

    .testimonial {
        padding: 100px 0;
        background: url(~Media/landing/gsuite/bg-testimonial.svg) no-repeat center;
        background-size: cover;
        margin: 0;

        &__holder {
            max-width: 625px;
            margin: auto;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 460px;
                height: 450px;
                background: url(~Media/landing/gsuite/testimonial-form.svg) no-repeat center;
                z-index: -1;
            }
        }

        &__swiper {
            background-color: #fff;
            border-radius: 10px;
            box-shadow: 0 0 30px 0 rgba(8, 25, 43, 0.1);
            overflow: hidden;
        }

        @include breakpoint('lg') {
            text-align: center;
            padding: 40px 0;

            &__holder {
                &:before {
                    margin-top: 40px;
                }
            }

            a.row {
                @include justify-content(center);
                margin-bottom: 40px;
            }
        }
    }

    .swiper {
        &__item {
            position: relative;
            text-align: left;

            &:before {
                content: open-quote;
                position: absolute;
                top: -2px;
                left: 24px;
                color: color('75', 'gray');
                font-size: 160px;
                line-height: 160px;
                z-index: 1;
            }

            .content {
                padding: 50px;
                position: relative;
                z-index: 2;
            }

            .avatar {
                width: 50px;
                height: 50px;
            }
        }

        &__navigation {
            position: absolute;
            bottom: $extra-large-spacing;
            right: $extra-large-spacing;

            button {
                &:hover {
                    background: none;
                }
            }
        }

        @include breakpoint('sm') {
            &__item {
                .content {
                    padding: 30px 30px 60px;

                    .font-lg {
                        font-size: $font-size-md;
                    }
                }
            }

            &__navigation {
                right: 30px;
                bottom: 30px;
            }
        }
    }

    .features {
        padding: 100px 0;
        margin: 0;

        h2 {
            margin-bottom: 80px;
        }

        li {
            margin-bottom: $base-spacing;
            max-width: 380px;
        }

        .circles-holder {
            img {
                &:nth-child(n + 2) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    animation: floating 5s ease-in-out infinite;
                }
                &:nth-child(3) {
                    animation-delay: 1s;
                }
                &:nth-child(4) {
                    animation-delay: 2s;
                }
                &:nth-child(5) {
                    animation-delay: 3s;
                }
                &:nth-child(6) {
                    animation-delay: 4s;
                }
            }
        }

        @include breakpoint('md') {
            padding: 40px 0;

            h2 {
                margin-bottom: 40px;
            }
        }
    }

    .banner {
        margin-bottom: 100px;

        &__container {
            border-radius: 10px;
            background: url(~Media/landing/gsuite/banner.svg) no-repeat center;
            background-size: cover;
        }

        &__content {
            max-width: 620px;
            margin: auto;
            padding: $extra-large-spacing $large-spacing;
        }

        @include breakpoint('md') {
            margin-bottom: 40px;

            &__content {
                padding: $large-spacing;
            }
        }
    }

    .slide-in {
        position: absolute;
        top: 0;
        left: 0;
    }

    .active {
        .slide-in {
            transform: none;
        }
    }

    a:not(.btn):not(.bj-button).button:hover {
        color: #fff;
    }

    @include breakpoint('md', 'min') {
        .slide-in {
            transform: translateY(100px);
            transition: transform 1s ease-in-out;
        }
    }

    .nowrap {
        white-space: nowrap;
    }

    #footer {
        background: color('900', 'blue');
        color: #fff;
        min-height: auto;
        margin: 0;
        border: none;
        padding: $small-spacing 0;

        p {
            font-size: $font-size-md;
        }

        div {
            position: relative;
        }

        li {
            display: inline-block;

            span {
                margin: 0 $extra-small-spacing;
            }
        }
    }
}
