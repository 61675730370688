.modal-funnel-premium {
    background: none;
    pointer-events: none;
    z-index: 999;

    .modal__container {
        width: 100%;
        max-width: 730px;
        vertical-align: middle;
        pointer-events: all;
        margin-bottom: -100%;

        @include breakpoint('md') {
            max-width: 412px;
        }

        .content {
            border-radius: 5px;
            box-shadow: 0 0 60px rgba(14, 42, 71, 0.25);
        }

        .row {
            height: 140px;
            align-items: center;
            justify-content: flex-end;
            padding: 0 40px !important;
            margin: 0 !important;

            @include breakpoint('md') {
                height: inherit;
                flex-direction: column;
                justify-content: center;
                padding: 30px !important;
            }

            .img {
                display: flex;
                align-items: center;
                width: 70px;
                height: 70px;
                margin: 0 40px 0 0;
                position: relative;

                @include breakpoint('md') {
                    margin: 0 0 40px 0;
                }

                i {
                    position: absolute;
                    top: -15px;
                    left: -15px;

                    @include breakpoint('md') {
                        top: inherit;
                        bottom: -30px;
                        left: 50%;
                        margin-left: -7px;
                    }
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .text {
                flex: 1;
                margin: 0;

                @include breakpoint('md') {
                    text-align: center;
                }

                &__items {
                    font-size: 14px;
                    margin: 0;

                    &:first-child {
                        margin-bottom: 10px;
                        font-size: 16px;
                        font-weight: $font-weight-bold;
                    }
                }
            }

            .modal__close {
                width: 177px;
                height: 44px;
                position: static;
                padding: 0;
                margin: 0;
                margin-left: 40px;

                @include breakpoint('md') {
                    margin: 20px 0 0 0;
                }

                &__register {
                    display: block;
                    padding: 0 10px;
                    border-radius: $base-border-radius;
                    color: $color-light;
                    font-size: 16px;
                    font-weight: $font-weight-semibold;
                    line-height: 44px;
                }
            }
        }
    }

    &.active {
        .modal__container {
            &.slide-in {
                margin-bottom: 0;
                animation: slideIn 0.5s;

                @keyframes slideIn {
                    from {
                        margin-bottom: -100%;
                    }

                    to {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

/* #modal-funnel-premium-half-downloads {

    .row {
        padding: 0 40px 0 0 !important;

        @include breakpoint('md') {
            padding: 0 0 30px 0 !important;
        }

        .img {
            width: 140px;
            height: 140px;
            background: url('~Media/banner-funnel-premium/half-downloads-small.png') no-repeat center;
            background-size: cover;

            @include breakpoint('md') {
                width: 100%;
                background: url('~Media/banner-funnel-premium/half-downloads-big.png') no-repeat center;
                background-size: cover;
            }
        }

        .text {

            @include breakpoint('md') {
                margin: 0 30px;
            }
        }
    }
}*/
