$carousel-width: 1940px;
$carousel-height: 368px;
$carousel-width-tablet: 1600px;
$carousel-height-tablet: 304px;
$carousel-width-mobile: 800px;
$carousel-height-mobile: 152px;

.landing--pattern {
    main {
        overflow: hidden;
    }

    #header {
        border: none;
        background: none;
        padding: $base-spacing;
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;

        @include breakpoint('sm') {
            text-align: center;
        }
    }

    .logo {
        display: inline-block;

        @include breakpoint('xs') {
            width: auto;
        }
    }

    section {
        margin: 0;
    }

    .container {
        max-width: 1080px;
        position: relative;
        z-index: 1;
    }

    .hero {
        background: url(~Media/landing/pattern/bg-hero.svg);
        padding: 90px 0 0;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(
                to bottom,
                color('400', 'purple') 0%,
                color('400', 'purple') 50%,
                transparent
            );
        }

        .container {
            position: relative;
            z-index: 1;
        }

        p {
            max-width: 460px;
            margin-left: auto;
            margin-right: auto;
        }

        img {
            box-shadow: 0 0 20px rgba(8, 25, 43, 0.5);
            position: relative;
            top: 70px;
        }
    }

    .features {
        background-color: color('900', 'blue');
        padding: 170px 0 100px;
        position: relative;

        &:before {
            content: '';
            display: block;
            width: 132px;
            height: 403px;
            position: absolute;
            left: 0;
            bottom: 0;
            pointer-events: none;
            background: url(~Media/landing/pattern/form-blue-left.svg) no-repeat;
            background-size: 100%;
        }
        &:after {
            content: '';
            display: block;
            width: 244px;
            height: 308px;
            position: absolute;
            right: 0;
            bottom: 0;
            pointer-events: none;
            background: url(~Media/landing/pattern/form-blue-right.svg) no-repeat;
            background-size: 100%;
        }

        i {
            color: $purple-shade-4;
        }

        p:not(.text-inverted) {
            color: color('400', 'gray');
            max-width: 420px;
        }

        @include breakpoint('md') {
            &:before {
                left: -100px;
            }
            &:after {
                right: -200px;
            }

            &__feature {
                margin-bottom: $large-spacing !important;
            }

            i {
                width: 20px;
                height: 20px;
                font-size: 20px;
                line-height: 20px;
                margin: 10px 20px 0 0;
            }
        }

        @include breakpoint('sm') {
            padding: 120px 0 0;

            &:before,
            &:after {
                display: none;
            }
        }
    }

    .edit-formats {
        padding-top: 100px;

        p {
            max-width: 480px;
        }

        @include breakpoint('md') {
            text-align: center;

            &__image {
                @include order(2);
            }

            &__text {
                @include order(1);
            }

            .row {
                @include justify-content(center);
            }

            p {
                margin-left: auto;
                margin-right: auto;
            }
        }

        @include breakpoint('sm') {
            padding: 40px 0;
        }
    }

    .inspired {
        padding: 100px 0;
        background-color: color('50', 'gray');
        position: relative;
        margin-top: 140px;

        &:before {
            content: '';
            display: block;
            width: 333px;
            height: 538px;
            position: absolute;
            left: 0;
            bottom: 0;
            pointer-events: none;
            background: url(~Media/landing/pattern/form-gray-left.svg) no-repeat;
            background-size: 100%;
        }
        &:after {
            content: '';
            display: block;
            width: 260px;
            height: 333px;
            position: absolute;
            right: 0;
            top: 0;
            pointer-events: none;
            background: url(~Media/landing/pattern/form-gray-right.svg) no-repeat;
            background-size: 100%;
        }

        p {
            max-width: 460px;
            margin-left: auto;
            margin-right: auto;
        }

        .carousel {
            position: relative;
            pointer-events: none;
            height: $carousel-height;
            margin-top: 70px;

            &__content {
                background-repeat: repeat-x;
                background-size: contain;
                height: $carousel-height;
                position: absolute;
                top: 0;
                left: -$carousel-width;
                width: $carousel-width * 3;
                background-image: url(~Media/landing/pattern/carousel.png);
                animation: carouselPattern 30s linear infinite;
            }
        }

        @include breakpoint('xl') {
            &:before {
                left: -200px;
            }
            &:after {
                right: -200px;
            }
        }

        @include breakpoint('lg') {
            &:before,
            &:after {
                display: none;
            }

            .carousel {
                height: $carousel-height-tablet;

                &__content {
                    height: $carousel-height-tablet;
                    animation: carouselPatternTablet 30s linear infinite;
                }
            }
        }

        @include breakpoint('md') {
            .carousel {
                height: $carousel-height-mobile;
                margin-top: 40px;

                &__content {
                    height: $carousel-height-mobile;
                    animation: carouselPatternMobile 30s linear infinite;
                }
            }
        }

        @include breakpoint('sm') {
            padding: 40px 0;
            margin-top: 40px;
        }
    }

    .end {
        background: url(~Media/landing/pattern/bg-bottom.svg);
        padding: 120px 0;
        position: relative;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(to bottom, color('800', 'blue'), transparent);
        }

        .container {
            position: relative;
            z-index: 1;
        }

        p {
            max-width: 460px;
            margin-left: auto;
            margin-right: auto;
        }

        .font-h4 {
            max-width: 590px;
        }

        @include breakpoint('sm') {
            padding: 60px 0;
        }
    }

    #footer {
        background: color('900', 'blue');
        color: #fff;
        min-height: auto;
        margin: 0;
        border: none;
        padding: 0;

        p {
            font-size: $font-size-md;
        }

        div {
            position: relative;
        }

        li {
            display: inline-block;

            span {
                margin: 0 $extra-small-spacing;
            }
        }
    }
}

@keyframes carouselPattern {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX($carousel-width);
    }
}
@keyframes carouselPatternTablet {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX($carousel-width-tablet);
    }
}
@keyframes carouselPatternMobile {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX($carousel-width-mobile);
    }
}
