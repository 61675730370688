$collections-width: 320px;

#viewport {
  width: 100%;
  background-color: #fff;
  min-height: 100%;
  position: relative;
  padding-top: $header-height;

  @media (min-width: 1024px) {
    &.collections-visible {
      @include calc(width, "100% - #{$collections-width}");      

      #header {
        @include calc(width, "100% - #{$collections-width}");
      }
    }
  }
}
