.pack-view {
    &__header {
        display: flex;
        align-items: baseline;
        padding: 30px 30px 15px 30px;

        &--title {
            margin: 0;
            font-size: 26px;
            color: color('800', 'gray');

            @include breakpoint('sm') {
                font-size: 22px;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;
            }
        }

        &--icons {
            margin: 0 0 4px 10px;

            @include breakpoint('sm') {
                margin-left: 0;
            }
        }
        @include breakpoint('lg') {
            padding: 30px 15px 15px 15px;
        }

        @include breakpoint('sm') {
            align-items: flex-start;
            flex-direction: column;
            padding: 30px 0 10px;
        }
    }

    &__filters {
        padding: 0 30px 15px 30px;
        overflow: auto;

        @include breakpoint('lg') {
            padding: 0 15px 15px 15px;
        }

        .style-link {
            position: relative;
            margin-right: 15px;
            font-size: 16px;
            border: 0 !important;
            color: color('500', 'gray');
            display: inline-block;

            &:hover {
                color: color('700', 'gray');
            }

            &:last-child {
                margin-right: 0;
            }

            span {
                height: 34px;

                .style-name {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    border-radius: $base-border-radius;
                    font-size: 16px;
                }

                [type='radio']:checked ~ .style-name {
                    color: color('800', 'gray');
                    font-weight: $font-weight-bold;
                }
            }

            .fake-radio {
                position: absolute;
                top: 0;
                opacity: 0;
            }
        }
    }
}
