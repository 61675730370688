.empty-search {
    &.icons-search {
        .container {
            margin: 0 auto;
        }
    }

    .container {
        max-width: 974px;
        margin: 80px auto;
        padding: 0 60px;
    }

    .list {
        list-style: disc;
        padding-left: $base-spacing;

        li {
            margin-bottom: $base-spacing;
        }
    }

    .category-box {
        width: 273px;
        margin: auto;
        display: block;
        position: relative;

        img {
            width: 100%;
            display: block;
        }

        p {
            position: absolute;
            bottom: 10px;
            left: 10px;
            margin: 0;
            color: color('text-inverted', 'general');
            font-weight: $font-weight-semibold;
            font-size: $font-size-lg;
            line-height: $line-height-160;
        }
    }

    .categories {
        columns: 4;

        a {
            color: color('text', 'general');
        }
    }

    @include breakpoint('md') {
        margin-top: 40px;
        .row--categories {
            margin-bottom: 50px;
        }
        .hide-mobile {
            display: none !important;
        }
    }

    .sponsor {
        a {
            color: red;
        }
    }
}

body[data-section='404'] .spirit-a {
    height: 417px;
}

body[data-section='404'] .spirit-a:not([data-section='empty']) {
    height: 291px;
}

@media screen and (max-width: 1280px) {
    body[data-section='404'] .spirit-a:not([data-section='detail']):not([data-section='empty']) {
        height: 533px;
    }
}

@media screen and (max-height: 768px) {
    body[data-section='404'] .spirit-a:not([data-section='detail']):not([data-section='empty']) {
        height: 290px;
    }
}

@media screen and (max-height: 768px) and (max-width: 1280px) {
    body[data-section='404'] .spirit-a:not([data-section='detail']):not([data-section='empty']) {
        height: 417px;
    }
}
