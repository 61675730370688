@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* :not(:required) hides this rule from IE9 and below */
.circle-spinner:not(:required) {
    animation: spinner 800ms infinite linear;
    border: 2px solid color('900', 'gray');
    border-right-color: transparent;
    border-radius: 100%;
    display: inline-block;
    position: relative;
    overflow: hidden;
    text-indent: -9999px;
    width: 18px;
    height: 18px;
    vertical-align: middle;
    margin: 20px 10px 40px;
}

.loader {
    display: none;

    @include position(fixed, 0 null null 0);

    width: 100%;
    height: 100%;
    background: color('800', 'blue');
    background: color('900', 'blue', 0.9);
    z-index: 300;

    .circle-spinner:not(:required) {
        border-color: #ffffff;
        border-right-color: transparent;
        width: 120px;
        height: 120px;
        border-width: 4px;

        @include position(fixed, 50% null null 50%);

        margin: -60px 0 0 -60px;
    }

    svg {
        width: 54px;

        @include position(fixed, 50% null null 50%);

        transform: translate(-50%, -50%);
    }
}
