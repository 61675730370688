.animated-icon-in-action {
    margin: 60px auto 40px;
    h2.font-h6 {
        font-size: $font-size-lg;
    }
    p {
        color: color('700', 'gray');
    }
    p.bold,
    p.medium {
        color: color('800', 'gray');
    }
    .row {
        margin: 0 -10px;
        flex-wrap: nowrap;
        article {
            margin: 10px;
            width: 100%;
            > p {
                margin-bottom: 10px;
            }
            img {
                padding: 4px;
            }
            img.pd-none {
                padding: 0px;
            }
            .image-example-interface {
                background-color: color('75', 'gray');
                border-radius: 12px;
                min-height: 240px;
                display: flex;
                justify-content: center;
                align-items: center;
                > div {
                    min-width: 250px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: white;
                    box-shadow: 0px 27px 80px rgba(95, 125, 149, 0.07),
                        0px 17.3274px 36.0449px rgba(95, 125, 149, 0.0503198),
                        0px 10.4775px 22.3564px rgba(95, 125, 149, 0.0417275),
                        0px 6.52814px 14.8269px rgba(95, 125, 149, 0.035),
                        0px 3.88539px 9.31526px rgba(95, 125, 149, 0.0282725),
                        0px 1.80585px 4.54341px rgba(95, 125, 149, 0.0196802);
                    border-radius: 16px;
                    padding: 20px 20px 20px 25px;
                    margin: 0 15px;
                }
            }
            .image-example-mobile-interface {
                background-color: color('75', 'gray');
                border-radius: 12px;
                min-height: 240px;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                .mobile-body {
                    max-width: 275px;
                    min-width: 236px;
                    height: 240px;
                    margin: -80px 15px 0 15px;
                    background-color: color('50', 'gray');
                    box-shadow: 0px 27px 80px rgba(95, 125, 149, 0.07),
                        0px 17.3274px 36.0449px rgba(95, 125, 149, 0.0503198),
                        0px 10.4775px 22.3564px rgba(95, 125, 149, 0.0417275),
                        0px 6.52814px 14.8269px rgba(95, 125, 149, 0.035),
                        0px 3.88539px 9.31526px rgba(95, 125, 149, 0.0282725),
                        0px 1.80585px 4.54341px rgba(95, 125, 149, 0.0196802);
                    border-radius: 16px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    overflow: hidden;
                    > p {
                        padding: 0 30px;
                    }
                    .mobile-menu {
                        height: 100px;
                        background-color: white;
                        padding: 30px;
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }
            .image-example-menu {
                background-color: color('75', 'gray');
                border-radius: 12px;
                min-height: 240px;
                display: flex;
                justify-content: center;
                align-items: center;
                &__container {
                    width: 250px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    background-color: white;
                    box-shadow: 0px 27px 80px rgba(95, 125, 149, 0.07),
                        0px 17.3274px 36.0449px rgba(95, 125, 149, 0.0503198),
                        0px 10.4775px 22.3564px rgba(95, 125, 149, 0.0417275),
                        0px 6.52814px 14.8269px rgba(95, 125, 149, 0.035),
                        0px 3.88539px 9.31526px rgba(95, 125, 149, 0.0282725),
                        0px 1.80585px 4.54341px rgba(95, 125, 149, 0.0196802);
                    border-radius: 16px;
                    padding: 10px 0;
                    margin: 0 15px;
                }
                &__item {
                    padding: 10px 30px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    p {
                        color: #000;
                    }
                }
                &__item:nth-child(2) {
                    border-top: 1px solid color('100', 'gray');
                    border-bottom: 1px solid color('100', 'gray');
                }
            }
            .image-example-scalable {
                background-color: color('75', 'gray');
                border-radius: 12px;
                min-height: 240px;
                display: flex;
                justify-content: center;
                align-items: center;
                &__container {
                    width: 250px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: white;
                    box-shadow: 0px 27px 80px rgba(95, 125, 149, 0.07),
                        0px 17.3274px 36.0449px rgba(95, 125, 149, 0.0503198),
                        0px 10.4775px 22.3564px rgba(95, 125, 149, 0.0417275),
                        0px 6.52814px 14.8269px rgba(95, 125, 149, 0.035),
                        0px 3.88539px 9.31526px rgba(95, 125, 149, 0.0282725),
                        0px 1.80585px 4.54341px rgba(95, 125, 149, 0.0196802);
                    border-radius: 16px;
                    padding: 30px;
                    margin: 0 15px;
                }
                &__item {
                    display: flex;
                    flex-direction: column;
                    height: 100px;

                    > div {
                        flex: 1;
                        display: flex;
                        align-items: center;
                    }

                    p {
                        text-align: center;
                        color: #000;
                    }
                }
                &__item:nth-child(2) {
                    img {
                        padding: 6px;
                    }
                    img.pd-none {
                        padding: 0px;
                    }
                }
                &__item:nth-child(3) {
                    img {
                        padding: 8px;
                    }
                    img.pd-none {
                        padding: 0px;
                    }
                }
            }
        }
    }

    @include breakpoint('xl') {
        .row {
            margin: 0 -10px;
            flex-wrap: wrap;
            article {
                width: calc(50% - 20px);
            }
        }
    }
    @include breakpoint('sm') {
        .row {
            margin: 0 -10px;
            flex-wrap: wrap;
            article {
                width: calc(100% - 20px);
            }
        }
    }
}
.stunning-animated-icons {
    position: relative;
    margin: 40px 0px;
    &__container {
        border-radius: 24px;
        background-color: color('75', 'gray');
        background-color: #f8f9fc;
        display: flex;
        overflow: hidden;
        padding: 60px;
        position: relative;
    }
    &__text {
        width: 40%;
        h5 {
            color: color('900', 'gray');
        }
        p {
            color: color('800', 'gray');
        }
    }
    &__video {
        width: 60%;
        position: absolute;
        bottom: 0;
        left: 45%;
        video {
            display: block;
            margin: 0 auto;
        }
    }
    @include breakpoint('md') {
        margin: 20px 0px;
    }
    @include breakpoint('lg') {
        margin-bottom: 0;
        &__container {
            flex-wrap: wrap;
            padding: 30px 0 0;
        }
        &__text {
            width: 100%;
            padding: 0 20px;
            text-align: center;
            margin: 0 auto 20px;
            max-width: 425px;
            a {
                line-height: 1.2;
            }
        }
        &__video {
            width: 100%;
            position: relative;
            left: unset;
            video {
                width: 100%;
            }
        }
    }
}
