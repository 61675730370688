$button-height: 32px;

.popover-share {
    ul.popover-content {
        padding: $small-spacing;
        right: -$small-spacing;
        z-index: 5;
    }
}
.detail__mobile-holder.only-share {
    .popover-share {
        margin-left: 0;
        width: 100%;
        .popover-container {
            width: 100%;
            .popover-button {
                width: 100%;
            }
        }
    }
}
@include breakpoint('lg') {
    .detail__mobile-holder.only-share {
        padding: $base-spacing $small-spacing;
        > .fi-premium-icon {
            margin: 0 $extra-small-spacing;
        }
    }
}
