.form-feedback {
    margin-top: 10px;
    margin-bottom: $base-spacing;

    > div {
        display: inline-block;
        border: 1px dashed color('200', 'gray');
        padding: 12px;
        border-radius: 4px;

        span {
            display: inline-block;
            margin-right: 10px;
            font-size: 14px;
        }
    }

    .popover-content {
        width: 320px;
        font-size: 14px;

        textarea {
            border: 1px solid color('75', 'gray');
            border-radius: $base-border-radius;
            box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
            height: 100px;
        }

        button {
            width: 50%;
            float: left;
            height: 42px;
            line-height: 42px;
            padding: 0;
        }
    }

    @include breakpoint('xs') {
        span {
            display: block;
        }
    }
}

.collections {
    .form-feedback {
        .popover-content {
            width: 300px;
            left: 50%;
            margin-left: -150px;

            &:before {
                right: 50%;
                margin-right: -11px;
            }

            &:after {
                right: 50%;
                margin-right: -13px;
            }
        }
    }
}
