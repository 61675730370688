#modal-attribution {
    &:not(.modal-attribution-detail) {
        .modal-attribution__wrapper {
            max-width: 960px;
            background-color: #fff;
            padding: 0 !important;
            position: relative;
            display: inline-block;
            width: 100%;
            margin: 60px 0;
            vertical-align: middle;
            text-align: left;
            line-height: $line-height-160;
            .modal__container {
                margin: 0;
            }
        }
    }

    .modal__container {
        max-width: 960px;
        background-color: #fff;
        padding: $large-spacing;
        border-radius: $base-border-radius;
    }

    .detail__download-confirmation__close {
        padding: 10px;
        position: absolute;
        top: 0;
        right: 0;
        background: none;
        z-index: 1;
    }

    .download-problems-box {
        justify-content: flex-start;

        .icon--bug {
            margin-right: 5px;
            font-size: 12px;
        }

        .download-problems {
            justify-content: flex-start;
            color: color('800', 'blue');
            text-decoration: underline;

            &:hover {
                color: #27dc4a;
            }
        }
    }

    .banner {
        background: color('800', 'blue') url('~Media/bg-banner.svg') no-repeat center;
        background-size: cover;

        padding: 40px 62px;

        color: #fff;
        text-align: center;

        h5 {
            margin-bottom: 12px;
        }

        p {
            color: #fff;
            max-width: 560px;
            margin: 0 auto;
            font-size: $font-size-sm;
            line-height: $line-height-160;
        }

        a {
            color: color('600', 'yellow');

            &:hover {
                color: color('700', 'yellow');
            }
        }
    }

    &.modal-attribution-detail {
        .banner {
            background: color('50', 'gray') url('~Media/bg-banner-detail.png') no-repeat right;
            background-size: cover;
            background-position: left;
            padding: $base-spacing + $small-spacing;
            text-align: left;
            display: flex;
            align-items: center;
            position: relative;
            width: 100%;
            bottom: 0;
            border-radius: $base-border-radius;
            h6 {
                color: color('800', 'blue');
            }

            p {
                color: color('700', 'gray');
                max-width: 385px;
                margin: unset;
                font-size: $font-size-sm;
                line-height: $line-height-160;
            }
            > a {
                color: color('900', 'gray');
                line-height: 1.2;
            }
            @include breakpoint('md') {
                display: none;
            }

            @include breakpoint('xl', 'min') {
                .banner {
                    padding: 30px 15%;
                    background-position: 90%;
                    background-size: contain;
                }
            }
        }
    }

    @include breakpoint('md') {
        overflow: auto;

        .modal__container {
            margin-top: 30px;
        }

        .copy {
            &__trigger {
                border-radius: $base-border-radius;
            }
        }

        .download-problems-box {
            justify-content: center;
        }
    }

    .social-media-buttons {
        .bj-button {
            @include prefixer(flex, 1, ms spec);
        }
    }
}

.attribution {
    padding: 30px;

    .content {
        @include prefixer(flex, 1, ms spec);
        max-width: 770px;
        margin: 0 auto 0 0 !important;

        p {
            a,
            button {
                color: color('800', 'gray');

                &:hover {
                    color: color('900', 'gray');
                }
            }
        }
    }

    &__video {
        width: 280px;
        height: auto;
        margin: auto 20px 0 0;

        @include breakpoint('md') {
            display: none;
        }

        video {
            width: 100%;
            height: auto;
        }
    }

    .banner {
        background: color('800', 'blue') url('~Media/bg-banner.svg') no-repeat center;
        background-size: cover;
        padding: 12px 62px;
        color: #fff;

        h5 {
            margin-bottom: 12px;
        }

        p {
            color: #fff;
            margin: 0;
        }
    }

    @include breakpoint('md') {
        .content {
            text-align: center;

            button[data-modal='modal-report'] {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}
