@include keyframes(floatingOvni) {
    0% {
        @include transform(translateY(0px));
    }
    50% {
        @include transform(translateY(40px));
    }
    100% {
        @include transform(translateY(0px));
    }
}