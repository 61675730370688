.author__header {
    @include display(flex);
    @include flex-direction(column);
    padding: 40px 0 30px;

    @include breakpoint('lg') {
        height: inherit;
        overflow: visible;
        padding: 20px 0 30px;
    }
}

.author__header__content {
    .container {
        @include display(flex);
        @include align-items(center);
        @include justify-content(space-between);

        @include breakpoint('lg') {
            @include flex-direction(column);
        }
    }

    .avatar-info-holder {
        @include flex(1);
        @include display(flex);
        @include flex-wrap(wrap);

        @include breakpoint('lg') {
            @include flex-direction(column);
            margin: 0;
        }

        .avatar-holder {
            @include display(flex);
            @include align-items(start);
            margin-right: 40px;
            z-index: 10;

            @include breakpoint('lg') {
                @include flex-direction(column);
                @include align-items(center);
                margin: 0;
                text-align: center;
            }

            .avatar {
                width: 80px;
                height: 80px;
                border-radius: 100%;
                box-shadow: 0 0 1px 1px color('200', 'gray');

                @include breakpoint('lg') {
                    width: 48px;
                    height: 48px;
                    margin-bottom: 10px;
                }
            }

            > div {
                a,
                button {
                    display: block;
                    margin-left: $base-spacing;

                    @include breakpoint('lg') {
                        margin-left: 0;
                        button {
                            margin-top: unset;
                        }
                    }
                }
            }

            .author-name {
                font-weight: $font-weight-bold;
                color: color('800', 'gray');
            }

            .author-web {
                font-size: $font-size-md;
                color: color('600', 'gray');
                font-weight: $font-weight-regular;

                @include breakpoint('lg') {
                    font-size: 14px;
                }
            }
        }

        .info-holder {
            display: flex;

            @include breakpoint('lg') {
                margin: 20px 0;
                text-align: center;
            }

            &-packs,
            &-icons,
            &-social {
                margin: 0 15px;

                i {
                    height: 26px;
                    line-height: $extra-small-element-height;
                }

                @include breakpoint('lg') {
                    margin: 0 10px;

                    ul {
                        @include justify-content(center);
                    }
                }
            }

            p {
                margin: 0;

                @include breakpoint('lg') {
                    font-size: 14px;
                }

                &.uppercase {
                    color: color('800', 'gray');
                    font-size: 13px;
                    font-weight: $font-weight-semibold;
                }
            }

            a {
                color: color('200', 'gray');
            }
        }
    }

    .button-holder {
        display: flex;
        align-items: center;

        .popover-share {
            margin-left: $extra-small-spacing * 2;
            margin-right: 0;

            .bj-button.active {
                background: color('100', 'gray');
            }
        }

        .bj-button--pinterest-red {
            margin-left: $extra-small-spacing;
        }

        .bj-button--follow-author {
            margin-right: $extra-small-spacing;
        }

        @include breakpoint('lg') {
            .bj-button {
                height: $small-element-height;
                line-height: $small-element-height;
                padding: 0 $base-spacing;
                font-size: $font-size-sm;

                &.bj-button--square {
                    width: $small-element-height;
                    min-width: 0;
                    padding: 0;
                }
            }
        }
    }
}

.author__style {
    &:first-child {
        margin-top: 22px;
    }

    &__header {
        padding: 0 10px;
        margin-bottom: 10px !important;

        .badge {
            color: #fff;
            height: 36px;
            line-height: 36px;
            padding: 0 22px;
            font-size: 14px;
        }

        p.font-h6 {
            font-size: $font-size-xl;
            line-height: $line-height-150;

            a {
                font-weight: $font-weight-bold;
            }
        }

        .col span {
            display: inline-block;
            font-size: $font-size-lg;
        }
    }

    .box-container {
        // width: calc(100% + 20px);
        // margin-left: -10px;
    }

    &--list {
        margin-bottom: 22px;

        .box-container {
            height: 244px;
            overflow: hidden;
        }
    }

    @include breakpoint('lg') {
        .box-container {
            width: calc(100% + 10px);
            margin-left: -5px;
        }

        &--list {
            .box-container {
                margin-bottom: 22px;
                height: auto;

                .box:nth-child(n + 3) {
                    display: none;
                }
            }
        }
    }
}

.author {
    .username {
        font-weight: $font-weight-semibold;
        color: color('800', 'blue');
        display: block;
    }
    .resources {
        font-size: $font-size-xs;
        line-height: $line-height-175;
        color: color('600', 'gray');
        display: block;
    }

    a:hover {
        .resources {
            text-decoration: underline;
        }
    }
}

// ANCHOR: NUEVO
// Avisar para quitar con php
.author__header + .search-data {
    display: none;
}
