$sidebar-width: 470px;

.pack-view {
    aside {
        width: $sidebar-width;
        position: relative;
        z-index: 2;
        .sidebar-holder {
            position: sticky;
            top: 110px;
            max-width: 470px;
        }

        h1 {
            font-size: 18px;
        }

        .pinterest--button {
            i {
                color: color('pinterest', 'social') !important;
            }
        }
        .modal-download-detail {
            padding: 0 $base-spacing $extra-small-spacing;
        }
        #pack-lincense {
            .attr-required {
                color: color('800', 'gray');
            }
        }
        .sidebar-holder {
            .not-pattern {
                > div {
                    display: flex;
                    > div:first-child {
                        flex-grow: 1;
                    }
                }
                .bj-button {
                    padding: 0 $base-spacing;
                }
            }
        }

        .sidebar__formats {
            margin-top: $small-spacing;
            border-top: 1px solid color('100', 'gray');
            p :first-child {
                color: color('800', 'gray');
                font-weight: $font-weight-semibold;
            }
        }

        .sidebar__author {
            padding: $small-spacing 0;
            border-top: 1px solid color('100', 'gray');
        }

        .share {
            margin-left: 0;
        }

        .btn-square {
            width: 44px;
            height: 44px;

            padding: 0;
        }

        .a-wrapper {
            overflow: hidden;
        }
    }

    .style-link {
        border-top: 1px solid color('100', 'gray');

        span.mg-right-lv1,
        a.link--normal {
            font-size: $font-size-sm;
        }
    }

    .search-result {
        min-height: 400px;
    }

    .sidebar__download__buttons {
        .bj-button--primary {
            span {
                &.desktop {
                    display: block;
                    line-height: 1.2;

                    @media (max-width: 380px) {
                        display: none;
                    }
                }

                &.mobile {
                    display: none;

                    @media (max-width: 380px) {
                        display: block;
                    }
                }
            }
        }
    }

    @media (max-width: 1120px) {
        .search-result {
            min-height: 0;
        }

        aside {
            width: 100%;
            border-top: 1px solid color('100', 'gray');
            border-left: none;
            z-index: 11;
            padding-bottom: 85px;
            .sidebar-holder {
                position: relative;
                max-width: unset;
                top: unset;
                padding: 10px 0 20px;
            }
            .modal-download-detail {
                padding: 0 0 $extra-small-spacing;
                bottom: 0;
            }

            .sidebar__author {
                // width: 50%;
                border: none;

                // margin-top: -62px !important;
            }

            &.footer-visible {
                .sidebar__download__buttons {
                    position: absolute;
                    bottom: -45px;
                    padding: 10px 55px 10px 0px;
                }

                .share {
                    position: absolute;
                    bottom: -35px;
                    right: 0;
                }
            }
        }

        .sidebar__download {
            width: 100%;

            > div {
                padding: 0 !important;
            }
        }

        .sidebar__download__buttons {
            width: 100%;
            position: fixed;
            bottom: 0;
            left: 0;
            background-color: #fff;
            border-top: 1px solid color('100', 'gray');
            padding: 10px 74px 10px 20px;
            z-index: 4;

            .btn-upgrade {
                height: 44px !important;
                line-height: 44px !important;
                padding: 0 1em !important;

                .desktop-copy {
                    display: none;
                }
                .mobile-copy {
                    display: block;
                    font-size: 16px;
                }
            }

            .col {
                margin-bottom: 0;
                max-width: 400px;
            }

            .tooltip__content {
                top: initial;
                bottom: 100%;
                padding: 0 0 0.75em;
                width: 100%;
            }
        }

        .sidebar-holder {
            &.bottom {
                padding-bottom: 80px;

                .popover-share {
                    display: none;
                }

                .sidebar__download__buttons {
                    position: absolute;
                    padding: 20px;
                }
            }

            .not-pattern {
                > div {
                    display: flex;
                }
            }
        }

        .sidebar__license-formats {
            // width: 50%;
            // border-left: 1px solid color('100', 'gray');
            padding: 0 0 6px;
            min-height: 140px;
            border-bottom: 1px solid color('100', 'gray');
        }

        .patterns--button,
        .pinterest--button {
            display: none;
        }

        .popover-share {
            position: fixed;
            right: 20px;
            bottom: 10px;
            z-index: 4;
        }

        .style-link {
            font-size: 14px;

            text-align: center;
        }

        .popover-share {
            .popover-content {
                top: initial;
                bottom: 100%;
                margin-bottom: 10px;

                &:before {
                    border-top: 10px solid #000;
                    border-bottom: none;
                    top: auto;
                    bottom: -0.45em;
                }

                &:after {
                    border-top: 12px solid #fff;
                    border-bottom: none;
                    top: auto;
                    bottom: -0.45em;
                }
            }
        }
    }

    @media (max-width: 680px) {
        aside {
            > .row > div {
                width: 100%;

                border-left: none;

                margin: 0 !important;
            }

            .sidebar__license-formats {
                padding: 0;

                min-height: 0;
            }
        }
    }
}

.collections-visible {
    .pack-view {
        @media (max-width: 1440px) {
            .search-result {
                min-height: 0;
            }

            aside {
                width: 100%;
                border-top: 1px solid color('100', 'gray');
                .sidebar-holder {
                    max-width: unset;
                }
            }

            .sidebar__download {
                width: 50%;
                padding-right: 20px;
            }

            .sidebar__license-formats {
                width: 50%;
                border-left: 1px solid color('100', 'gray');
                padding: 6px 0;
                min-height: 140px;
                padding-left: 20px;
            }

            .sidebar__author {
                width: 50%;
                margin-top: -32px;
                padding-right: 20px;
            }

            .style-link {
                font-size: 14px;
                text-align: center;
            }

            &__premium {
                .sidebar__author {
                    margin-top: 0;
                }
            }
        }

        @media (max-width: 1120px) {
            aside {
                padding-bottom: 85px;

                &.footer-visible {
                    .sidebar__download__buttons {
                        position: absolute;
                        width: 100%;
                    }

                    .share {
                        position: absolute;
                    }
                }
            }

            .sidebar__download__buttons {
                width: calc(100% - 320px);
            }
        }
    }
}

#viewport {
    &:not(.collections-visible) {
        .pack-view {
            @media (min-width: 1120px) {
                .sidebar-holder {
                    &.fixed {
                        position: fixed;
                        top: 64px;
                        right: 0;
                        width: $sidebar-width;
                    }

                    &.fixed-bottom {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        width: $sidebar-width;
                    }
                }
            }
        }
    }

    &.collections-visible {
        .pack-view {
            @media (min-width: 1441px) {
                .sidebar-holder {
                    &.fixed {
                        position: fixed;
                        top: 106px;
                        right: 320px;
                        width: $sidebar-width;
                    }

                    &.fixed-bottom {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        width: $sidebar-width;
                    }
                }
            }
        }
    }
}

// @media (max-width: 1120px) {
//     .sidebar__download {
//         &.sticker {
//             .sidebar__download__others {
//                 > div {
//                     padding: 0;
//                     width: unset;
//                 }
//                 .popover-share {
//                     .share-sticker-text {
//                         display: none;
//                     }
//                 }
//             }
//         }
//     }
// }
