.errorpage.e500 {
    background-color: color('50', 'gray');
    text-align: center;
    padding: $extra-large-spacing * 2 $base-spacing;

    .image {
        width: 606px;
        height: 326px;
        background: url('~Media/error/500.png') no-repeat center;
        background-size: cover;
        position: relative;
        margin: auto;
        overflow: hidden;
    }
    .ufo {
        width: 65px;
        height: 65px;
        background: url('~Media/error/ufo500.png') no-repeat center;
        background-size: cover;
        position: absolute;
        top: 274px;
        right: 63px;
        @include animation(floatingOvni ease-in-out 1500ms infinite);
    }
    .alien {
        width: 13px;
        height: 21px;
        background: url('~Media/error/alien.png') no-repeat center;
        background-size: cover;
        position: absolute;
        bottom: $base-spacing * 2;
        right: 522px;
        @include transform-origin(bottom left);
        @include animation(alien linear 300ms infinite);
    }
    .satellite-container {
        width: 0px;
        height: 100px;
        top: 108px;
        left: $base-spacing;
        position: absolute;
        @include animation(rotate linear 2000ms infinite);
    }
    .satellite {
        width: 65px;
        height: 65px;
        background: url('~Media/error/satellite.png') no-repeat center;
        background-size: cover;
        position: relative;
        @include transform-origin(bottom left);
        @include animation(rotateInverse linear 2000ms infinite);
    }
    h1 {
        color: color('800', 'blue');
        font-size: 24px;
        margin: $large-spacing 0 $extra-large-spacing;
    }
    h3 {
        font-size: 18px;
        line-height: 1.8;
    }
    p {
        margin-top: $base-spacing;
    }
    .mask {
        width: 135px;
        height: 9px;
        background: url('~Media/error/mask.svg') no-repeat center;
        background-size: cover;
        position: absolute;
        bottom: 0;
        right: $large-spacing;
    }
    @include breakpoint('md') {
        padding: $base-spacing;
        .image {
            width: 320px;
            height: 172px;
        }
        .alien,
        .ufo,
        .satellite-container,
        .mask {
            display: none;
        }
    }
}
