.modal-remove-palette {
    &.active {
        .modal__container {
            max-width: 555px;
            .content {
                padding: 40px;
                border-radius: 16px;
            }
            .modal-remove-palette-footer {
                display: flex;
                justify-content: flex-end;
                gap: 10px;
                .no-thanks-palette {
                }
                .remove-palette {
                    background-color: color('500', 'red');
                    color: white;
                }
            }
        }
    }
}
