.relateds-bar {
    margin: $large-spacing 0 $small-spacing;

    .related-search {
        display: block;
    }

    .related-search-box {
        white-space: nowrap;
        overflow: auto;
        display: flex;
        flex-wrap: wrap;

        a {
            height: 28px;
            line-height: 23px;
            padding: 0 $extra-small-spacing;
            margin-right: $extra-small-spacing;
            margin-bottom: $extra-small-spacing;
            font-size: $font-size-sm;
            color: color('600', 'gray');
            border: 2px solid color('200', 'gray');
            border-radius: $base-border-radius;
        }
    }
}
