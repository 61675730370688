.ghost-collection {
	width: 320px;
	height: 502px;
	margin: $base-spacing auto;
	background: url('~Media/collection-unavailable.png') no-repeat;
	background-size: cover;
	
	img {
		position: relative;
		top: 200px;
		animation: floating 2000ms ease-in-out infinite;
	}
}