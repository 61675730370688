$modal-width: 960px;

.fi-modal {
    &.modal-download {
        .modal-state:checked + .modal-fade-screen .modal-inner {
            top: 0;
            transform: none;
        }

        .modal-fade-screen {
            background-color: color('900', 'blue', 0.5);
            padding-top: 0;
            overflow: auto;
        }

        .modal-inner {
            width: 100%;
            padding: 0;
            text-align: center;
            max-height: none;
            border-radius: 0;
        }

        header {
            padding: $base-spacing $base-spacing 0;

            p {
                margin: 0 $small-spacing $small-spacing;

                span {
                    vertical-align: middle;
                }
            }

            .icon-holder {
                display: inline-block;

                img {
                    width: 24px;
                    height: 24px;
                    display: inline-block;
                    vertical-align: middle;
                }
            }

            .author {
                img {
                    width: 30px;
                    height: 30px;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }

        .modal-body {
            padding: $base-spacing 0;

            &.nopremium {
                .column-free {
                    width: 100%;
                }

                .column-premium {
                    display: none;
                }
            }
        }

        .column {
            width: 100%;
            padding: 0 $base-spacing;
        }

        .column-premium {
            border-right: 1px solid color('75', 'gray');
        }

        .btn {
            padding: $base-spacing;
            margin-bottom: $base-spacing;
            width: 100%;
            color: #fff;
            display: inline-block;

            b {
                font-size: $font-size-2xl;
                line-height: $line-height-160;
            }

            p {
                margin: 0;
                color: #fff;
                white-space: normal;
                line-height: $line-height-160;
            }

            span {
                vertical-align: initial;
            }
        }

        ul {
            li {
                margin-bottom: $small-spacing;

                &:last-child {
                    margin-bottom: $base-spacing;
                    @include breakpoint('md', 'min') {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .hide-phone--xs {
            display: none;
        }

        &.modal-collection-pack {
            .modal-fade-screen .modal-inner .modal-body {
                height: auto;

                .column-free,
                .column-premium {
                    p:empty {
                        display: none;
                    }
                }
            }
        }

        @media (min-width: 375px) {
            .show-phone--xs {
                display: none;
            }
            .hide-phone--xs {
                display: block;
            }

            .modal-inner {
                max-width: 600px;
            }
        }

        @include breakpoint('md', 'min') {
            .modal-state:checked + .modal-fade-screen .modal-inner {
                top: 50%;
                transform: scale(1) translateY(-50%);
            }

            header {
                p {
                    display: inline-block;
                    vertical-align: middle;
                }
            }

            .modal-inner {
                max-width: $modal-width;
                border-radius: $base-border-radius;
            }

            .column {
                width: 50%;
            }
        }
    }
}
