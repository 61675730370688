.landing-craft {
    h6 {
        color: color('800', 'blue');
    }
    &__hero {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $extra-large-spacing + $large-spacing;

        &__text {
            width: 50%;
            max-width: 510px;
            .pre-title {
                color: color('800', 'green');
            }
            h1 {
                margin-bottom: $base-spacing;
                color: color('800', 'blue');
                font-size: $font-size-7xl;
            }
            p {
                color: color('800', 'gray');
                // margin: 0;
            }
        }
        &__image {
            width: 50%;
            text-align: center;
        }

        @include breakpoint('md') {
            margin-bottom: $extra-large-spacing;
            flex-wrap: wrap;
            &__text {
                max-width: unset;
                width: 100%;
                text-align: center;
                margin-bottom: $large-spacing;
                h1 {
                    font-size: $font-size-5xl;
                }
            }
            &__image {
                width: 100%;
                // text-align: center;
            }
        }
        @include breakpoint('xs') {
            margin-bottom: $large-spacing;
            &__text {
                .pre-title {
                    font-size: 0.82em;
                }
                h1 {
                    font-size: 1.92em;
                }
                p {
                    font-size: 14px;
                }
            }
        }
    }
    &__where {
        padding: $extra-large-spacing;
        margin-bottom: $extra-large-spacing + $large-spacing;
        background-color: rgba(74, 210, 149, 0.1);
        display: flex;
        justify-content: space-between;
        border-radius: 20px;
        h6 {
            max-width: 270px;
            width: 30%;
        }
        .where__item {
            width: 33%;
            display: flex;

            &__image {
                flex-shrink: 0;
                margin-right: $base-spacing;
                width: 44px;
                i::before {
                    width: 44px;
                    height: 44px;
                    background-color: color('600', 'green');
                    color: color('50', 'gray');
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 8px;
                }
            }

            &__text {
                p:first-child {
                    color: color('800', 'blue');
                }
            }
        }

        @include breakpoint('lg') {
            flex-wrap: wrap;
            h6 {
                max-width: unset;
                width: 100%;
                margin-bottom: $large-spacing;
            }
            .where__item {
                width: 46%;
            }
        }
        @include breakpoint('sm') {
            padding: $large-spacing;
            .where__item {
                margin-bottom: $base-spacing;
            }
            .where__item {
                width: 100%;
            }
        }
        @include breakpoint('xs') {
            margin-bottom: $large-spacing;
            .where__item {
                &__image {
                    display: none;
                }
            }
        }
    }
    &__questions {
        padding: $large-spacing ($extra-large-spacing + $small-spacing);
        background-color: color('500', 'green');
        border-radius: 15px;
        color: color('50', 'gray');
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $extra-large-spacing + $large-spacing;
        &__text {
            margin-right: $base-spacing;
            h6 {
                color: color('50', 'gray');
                margin: 0 0 $small-spacing;
            }
        }
        a {
            flex-shrink: 0;
        }
        @include breakpoint('md') {
            margin-bottom: $base-spacing * 2;
            padding: $large-spacing ($large-spacing + $small-spacing);
            flex-direction: column;
            justify-content: center;

            &__text {
                text-align: center;
                margin-right: 0;
                margin-bottom: $large-spacing;
            }
        }
        @include breakpoint('xs') {
            padding: $large-spacing;
            margin-bottom: $large-spacing;
            a {
                width: 100%;
            }
        }
    }
    &__comparison {
        margin-top: 30px;
        margin-bottom: $extra-large-spacing + $large-spacing;

        input[type='radio'] {
            display: none;
        }

        input[type='radio'].r-advantages ~ .advantages,
        input[type='radio'].r-cantdo ~ .cantdo {
            display: none;
        }

        input[type='radio'].r-advantages:checked ~ .advantages,
        input[type='radio'].r-cantdo:checked ~ .cantdo {
            display: grid;
        }

        input[type='radio'].r-advantages:checked ~ .tabs .item__link.advantages__button,
        input[type='radio'].r-cantdo:checked ~ .tabs .item__link.cantdo__button {
            background-color: #fff;
            color: color('800', 'gray');
            border-radius: $base-border-radius;
        }
        input[type='radio'].r-advantages:checked ~ .tabs .item__link.advantages__button:hover,
        input[type='radio'].r-cantdo:checked ~ .tabs .item__link.cantdo__button:hover {
            border: 2px solid color('100', 'gray');
        }

        .tabs {
            align-items: stretch;
            background-color: color('100', 'gray');
            border-radius: $base-border-radius;
            flex-wrap: nowrap;
            margin: 0 auto 30px;
            overflow: hidden;
            width: fit-content;

            .item {
                padding: 0;
                min-height: $small-element-height;
                align-items: stretch;
                display: flex;
                border-radius: $base-border-radius;
                margin: 0;
                text-transform: none;
                font-weight: 600;
                white-space: nowrap;
            }
            .item__link {
                border: 2px solid color('100', 'gray');
                background-color: color('100', 'gray');
                height: unset;
                display: flex;
                align-items: center;
                padding: 0 $base-spacing;
                color: color('700', 'gray');
                min-height: 30px;
                cursor: pointer;
                border-radius: $base-border-radius;
            }
            .item__link:hover {
                background-color: color('200', 'gray');
                border: 2px solid color('200', 'gray');
            }

            .icon {
                padding-left: 10px;
            }
            .icon--check::before {
                color: color('700', 'green');
            }
            .icon--cross::before {
                color: color('500', 'red');
            }
        }
        .table_content {
            display: grid;
            color: color('800', 'gray');
            grid-template-areas:
                'd d d f'
                'g g g i'
                'j j j l'
                'm m m o'
                'p p p r'
                's s s u';
            grid-template-columns: 1fr 1fr 1fr 0.7fr;

            > div {
                padding: $base-spacing;
            }
            div[class^='header'] {
                font-weight: $font-weight-bold;
                text-align: center;
            }

            .green-line {
                grid-area: 1/4/8/5;
                border: 1px solid color('500', 'green');
                border-radius: 20px;
                z-index: 10;
            }

            .red-line {
                grid-area: 1/4/8/5;
                border: 1px solid;
                border-color: color('500', 'red');
                border-radius: 20px;
                z-index: 10;
            }

            .gray-line {
                grid-area: 1/1/8/5;
                border: 1px solid color('100', 'gray');
                border-radius: 20px;
                z-index: 5;
            }

            div[class$='-regular'],
            div[class$='-craft'] {
                text-align: center;
                display: flex;
                align-items: center;
            }
            div[class^='first'],
            div[class^='second'],
            div[class^='third'],
            div[class^='forth'],
            &.advantages div[class^='fifth'] {
                border-bottom: 1px solid color('100', 'gray');
            }

            .first {
                &-text {
                    grid-area: d;
                }
                &-craft {
                    grid-area: f;
                }
            }
            .second {
                &-text {
                    grid-area: g;
                }
                &-craft {
                    grid-area: i;
                }
            }
            .third {
                &-text {
                    grid-area: j;
                }
                &-craft {
                    grid-area: l;
                }
            }
            .forth {
                &-text {
                    grid-area: m;
                }
                &-craft {
                    grid-area: o;
                }
            }
            .fifth {
                &-text {
                    grid-area: p;
                }
                &-craft {
                    grid-area: r;
                }
            }
            .sixth {
                &-text {
                    grid-area: s;
                }
                &-craft {
                    grid-area: u;
                }
            }
            .icon--check::before {
                color: color('700', 'green');
            }
            .icon--cross::before {
                color: color('500', 'red');
            }
            @include breakpoint('md') {
                margin-bottom: $extra-large-spacing;
            }

            @include breakpoint('xs') {
                margin-bottom: $large-spacing;
                > div {
                    padding: $extra-small-spacing;
                    font-size: 0.82em;
                }
                .green-line {
                    border-radius: 8px;
                }
                .gray-line {
                    border-radius: 8px 0px 8px 8px;
                }
            }
        }
        .summary-text {
            text-align: center;
            max-width: 610px;
            margin: 0 auto;
            color: color('800', 'gray');
            font-size: $font-size-xs;
            a {
                color: color('700', 'green');
            }
        }
    }
    &__faq {
        padding: $large-spacing 0;

        li {
            border-radius: 4px;
            border: 1px solid color('200', 'gray');
            position: relative;
            padding: $extra-large-spacing $large-spacing;
            margin-bottom: $base-spacing;
        }
        a {
            color: color('800', 'green');
        }

        &__row {
            padding-left: 140px;
            position: relative;

            > strong {
                color: color('700', 'green');
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 120px;
                font-size: $font-size-sm;
            }

            p {
                margin: 0;
            }
        }

        @include breakpoint('md') {
            li {
                padding: $base-spacing;
            }

            &__row {
                padding-left: 0;

                > strong {
                    width: auto;
                    position: relative;
                }
            }
        }
    }
}

.craft-licenses {
    background-color: color('50', 'gray');

    &__list {
        background-color: #fff;
        width: 100%;
        padding: $base-spacing + $small-spacing;
        border-radius: 8px;

        .table_content {
            display: grid;
            grid-template-columns: repeat(4, 1fr);

            > div {
                padding: 15px 30px;
            }

            > div[class^='table-header'] {
                font-weight: $font-weight-bold;
                text-transform: uppercase;
            }

            > div:not([class^='table-header']) {
                align-items: center;
                border-top: 1px solid color('100', 'gray');
                display: flex;
            }

            .license button,
            .license a {
                padding: 0 20px;
            }

            .download {
                button {
                    align-items: center;
                    display: flex;
                    padding: 0 20px;

                    i {
                        color: white;
                    }
                }
                .popover {
                    .popover-content {
                        right: unset;
                        width: 100%;
                        top: 44px;
                        border: none;
                        &::after,
                        &::before {
                            display: none;
                        }
                        input {
                            display: none;
                            &[type='checkbox']:checked + div {
                                display: block;
                            }
                        }
                        label,
                        a {
                            width: 100%;
                        }
                        label {
                            .icon--caret-down {
                                position: absolute;
                                right: 20px;
                            }
                        }
                        .sizes {
                            display: none;
                            margin-top: 10px;
                        }
                    }
                }
            }

            &.gray-line {
                border: 1px solid color('100', 'gray');
                border-top: none;
                border-radius: $base-border-radius;
            }

            .pending {
                color: color('600', 'yellow');

                .icon {
                    color: color('600', 'yellow');
                    padding-left: 5px;
                    vertical-align: text-bottom;
                }

                .tooltip__content .content {
                    background-color: white;
                    color: color('700', 'gray');
                }

                .tooltip__trigger::after {
                    border-top: 6px solid white !important;
                    z-index: 400;
                }
            }

            @include breakpoint('lg') {
                grid-template-columns: 1fr 115px 115px;
                .table-header:nth-child(2) {
                    display: none;
                }
                .table-header:not(:first-child) {
                    padding: 15px 0;
                }
                > div:not([class^='table-header']).pack {
                    display: none !important;
                }
                > div {
                    padding: 15px 20px;
                }
                .license,
                .download {
                    padding: 0;
                }
            }
        }
    }

    .empty {
        text-align: center;
        height: calc(100vh - 200px);

        a {
            color: color('800', 'green');
        }
    }
}

.page-craft-purchases {
    padding-top: 0px !important;

    #viewport {
        padding-top: $header--menu-height;
    }
}
