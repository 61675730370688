.box-container {
    @include display(flex);
    @include flex-flow(row wrap);
    @include justify-content(center);
    width: 100%;
    gap: 10px;
}

.box__inner {
    position: relative;
}

.box {
    min-width: 315px;
    // padding: 10px;
    @include flex(1);

    a {
        display: block;
        border: 1px solid color('200', 'gray');
        position: relative;
        border-radius: $base-border-radius;
        overflow: hidden;
        background-color: $color-light;

        &:hover {
            border-color: darken(color('200', 'gray'), 20);
        }
    }

    .box__icons-number {
        font-size: 12px;
        color: color('600', 'gray');
        text-transform: uppercase;
        font-weight: $font-weight-semibold;

        @include breakpoint('xs') {
            display: none;
        }
    }

    .badge-license {
        position: absolute;
        z-index: 1;
        bottom: 13px;
        left: 12px;

        i:before {
            width: 14px;
            height: 14px;
        }

        @include breakpoint('xs') {
            bottom: 9px;
        }
    }

    .img-holder {
        overflow: hidden;

        img {
            display: block;
            margin: auto;
            height: auto;
        }
    }

    footer {
        padding: 0 12px;
        position: relative;
        color: color('900', 'gray');
        height: 44px;
        line-height: 44px;
        background: $color-light;
        font-size: 14px;
        font-weight: $font-weight-semibold;

        @include breakpoint('xs') {
            height: 34px;
            line-height: 34px;
        }

        .avatar {
            width: 32px;
            height: 32px;

            @include breakpoint('xs') {
                width: 22px;
                height: 22px;
            }
        }

        .name {
            color: color('900', 'gray');
            font-size: $font-size-sm;
        }
    }

    .name {
        text-transform: capitalize;
    }

    &.box--category {
        .img-holder {
            height: auto;
            padding-bottom: 0;
        }
    }

    &.emptybox {
        // padding: 0 10px;
    }

    &.box-premium {
        footer {
            .name {
                margin-left: 20px;
            }
        }
    }

    &.box--bookmark {
        .box__inner {
            background-color: $color-light;
            border: 1px solid color('300', 'gray');
        }

        a {
            border: none;
            display: initial;
        }

        footer {
            color: color('600', 'gray');
            height: auto;
            line-height: normal;

            strong {
                font-size: 18px;
                color: color('900', 'gray');
            }

            a {
                color: color('800', 'gray');
                font-weight: $font-weight-semibold;
            }
        }
    }

    @include breakpoint('md') {
        @include flex(none);
        float: left;
        width: calc(50% - 5px);
        min-width: initial;

        .badge {
            display: none;
        }
    }

    @include breakpoint('xs') {
        .img-holder {
            max-width: 150px;
            margin: auto;
        }
    }

    &--author {
        @include breakpoint('sm') {
            min-width: 100%;
            padding: 5px 0;
        }

        a {
            .box--author-pt1 {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 20px;

                .author-img {
                    max-width: inherit;
                    width: 54px;
                    height: auto;

                    img,
                    source {
                        max-width: inherit;
                        width: 100%;
                        height: auto;
                        border-radius: 100%;
                    }
                    img {
                        display: block;
                    }
                }

                .author-info {
                    flex: 1;
                    margin-left: 15px;
                    overflow: hidden;

                    &--name {
                        font-size: $font-size-sm;
                        font-weight: $font-weight-semibold;
                        margin: 0;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        color: color('800', 'gray');
                    }

                    &--icons {
                        font-size: $font-size-xs;
                        margin: 0;
                        color: color('600', 'gray');
                    }
                }

                .bj-button {
                    min-width: 81px;
                }
            }

            .box--author-pt2 {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;

                @media only screen and (max-width: 320px) {
                    justify-content: center;
                }

                .sprite {
                    min-width: 100px;
                    padding: 0 $small-spacing;

                    @media only screen and (max-width: 320px) {
                        &:last-child {
                            display: none;
                        }
                    }

                    .img-wrapper {
                        overflow: hidden;
                        position: relative;

                        &::before {
                            display: block;
                            padding: 0 0 94%;
                            content: '';
                        }
                    }

                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 150%;
                        height: 100%;
                        max-width: inherit;
                    }

                    &.empty {
                        .img-wrapper {
                            &:before {
                                background: color('75', 'gray');
                                border-radius: 2px;
                            }
                        }
                    }
                }
            }
        }
    }
}
